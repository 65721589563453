import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Failure, Comment } from '@frontend-monorepo/core';
import { RepositoryService } from 'libs/core/src/lib/services/repository/repository.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'frontend-monorepo-all-parking-space-comment-dialog',
  templateUrl: './all-parking-space-comment-dialog.component.html',
  styleUrls: ['./all-parking-space-comment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllParkingSpaceCommentDialogComponent implements OnInit, OnDestroy {

  commentList: Comment[];
  private _subscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public parkingLotId,
    private repository: RepositoryService,
    private cd: ChangeDetectorRef
  ) { 
    this._subscription = new Subscription();
  }
  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this._getAllComment();
  }

  private _getAllComment() {
    this._subscription = this.repository
      .parkingSpaceCommentRepository()
      .getAllCommentbyParkingLot(this.parkingLotId)
      .subscribe({
        next: (_comments) => {
          this.commentList = _comments;
          this.cd.detectChanges();
        },
        error: (_error: Failure) => console.log(_error.errorMessage)
      });
  }

}
