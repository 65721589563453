import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ParkingLotMap
} from '../interfaces/parkingLotMap';
import { HttpClient } from '@angular/common/http';
import { retry } from 'rxjs/operators';
import { API_BASE_URL, AuthService } from '@frontend-monorepo/core';

@Injectable({
  providedIn: 'root',
})
export class ParkingLotMapApiService {
  constructor(private authService: AuthService, private http: HttpClient) {}

  /**
   * Fetching a parking lot´s map.
   *
   * Parsing the fetched JSON-data to a `ParkingLotMap` object.
   *
   * @param parkingLotID ID of a parking lot, whichs map-xml should be fetched.
   */
  fetchMapOfPL(parkingLotID: number): Observable<ParkingLotMap> {
    let obj = this.http.get<ParkingLotMap>(API_BASE_URL + `parkinglots/${parkingLotID}/map-json`).pipe(retry(5));
    return obj;
  }
}
