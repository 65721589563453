<div *transloco="let t; read: 'RESET_SETTING_DIALOG'">
  <h2>{{ t('header') }}</h2>
  <p>
    {{ t('description') }}
  </p>
  <p>
    <span>{{ t('example') }}</span>
    <br />
    {{ t('exmaple_content') }}
  </p>
  <button mat-flat-button color="cancel" (click)="close()">{{ t('cancel') }}</button>
</div>
