import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslocoService } from '@ngneat/transloco';
import { Failure } from 'libs/core/src/lib/repository/http-response/failure.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Comment, RepositoryService } from '@frontend-monorepo/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddParkingSpaceCommentDialogComponent } from '../add-parking-space-comment-dialog/add-parking-space-comment-dialog.component';
import { ConfirmationDialogComponent } from '../../../confirmation-dialog/src/components/confirmation-dialog/confirmation-dialog.component';
import { TableDataObject } from 'apps/customer-dashboard/src/app/helper/models';
import { Networking } from 'apps/customer-dashboard/src/app/helper/networking';

@Component({
  selector: 'frontend-monorepo-parking-space-comment-list',
  templateUrl: './parking-space-comment-list.component.html',
  styleUrls: ['./parking-space-comment-list.component.scss']
})
export class ParkingSpaceCommentListComponent implements OnInit, OnDestroy {
  @Input() parkingLotId: number;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  private columns: string[] = [
    'delete_on_free',
    'psid',
    'xml',
    'creation_time',
    'comment',
    'edit',
    'delete'
  ];
  displayedColumns: string[];

  searchValue: string;
  tableData: MatTableDataSource<Comment>;
  unfilteredTableData: Comment[];
  isLoading: boolean;

  parkingSpaceIdMap: BehaviorSubject<Map<number, number>>;

  psidList: number[];

  private _subscription: Subscription;

  constructor(
    private cd: ChangeDetectorRef,
    private translocoService: TranslocoService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private repository: RepositoryService,
    private networking: Networking
  ) {
    this.displayedColumns = this.columns;
    this._subscription = new Subscription();
    this.parkingSpaceIdMap = new BehaviorSubject(new Map());
    this.tableData = new MatTableDataSource<Comment>();
    this.isLoading = true;
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.getXmlIds();
    this._setupTableData();
  }

  private _setupTableData() {
    this._subscription = this.repository
      .parkingSpaceCommentRepository()
      .getAllCommentbyParkingLot(this.parkingLotId)
      .subscribe({
        next: (_comments) => {
          this.parkingSpaceIdMap.subscribe((_map) => {
            if (_map != undefined) {
              this.unfilteredTableData = _comments;
              this.unfilteredTableData = [...this.unfilteredTableData].map((_comment) => {
                _comment.xml_id = _map.get(_comment.device_space_id);
                return _comment;
              });
              this.tableData = new MatTableDataSource<Comment>(
                Object.assign(this.unfilteredTableData)
              );
              this.filterPsidList(this.unfilteredTableData);
              this.tableData.sort = this.sort;
              this.isLoading = false;
              this.cd.detectChanges();
            }
          });
        },
        error: (_error: Failure) => {
          this.snackbar.open(this.translocoService.translate(`${_error.errorMessage}`));
          this.isLoading = false;
        }
      });
  }

  getXmlIds() {
    this.networking.fetchParkingSpacesOfPLApiKey(this.parkingLotId).subscribe({
      next: (_devices) => {
        const parkingSpaceIdMap = new Map<number, number>();
        _devices.forEach((obj) => {
          parkingSpaceIdMap.set(obj.id, obj.xml_id);
        });
        this.parkingSpaceIdMap.next(parkingSpaceIdMap);
      }
    });
  }

  editComment(selectedComment: Comment) {
    const dialogRef = this.dialog.open(AddParkingSpaceCommentDialogComponent, {
      minWidth: '30vw',
      maxWidth: '50vw',
      maxHeight: '50vh',
      autoFocus: false,
      data: {
        psid: selectedComment.device_space_id,
        _currentComment: selectedComment,
        action: 'edit_comment'
      }
    });
    dialogRef.afterClosed().subscribe((_jsonComment) => {
      if (_jsonComment) {
        this.updateComment(_jsonComment, selectedComment);
      }
    });
  }

  updateComment(newComment: Comment, selectedComment: Comment) {
    let index: number = this.tableData.data.findIndex((data) => data === selectedComment);
    this.tableData.data[index] = newComment;
    this.tableData.sort = this.sort;
    this.cd.detectChanges();
  }

  deleteComment(selectedComment: Comment) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '30vw',
      maxWidth: '50vw',
      maxHeight: '50vh',
      autoFocus: false,
      data: {
        confirmText: this.translocoService.translate('delete_comment'),
        description: this.translocoService.translate('delete_comment_description'),
        header: this.translocoService.translate('delete_comment_header'),
        confirmColor: 'warn',
        cancelText: this.translocoService.translate('cancel')
      }
    });
    dialogRef.afterClosed().subscribe((_selection) => {
      if (_selection.data) {
        this._subscription = this.repository
          .parkingSpaceCommentRepository()
          .deleteCommentbyDeviceSpaceId(selectedComment.device_space_id)
          .subscribe({
            next: (_resp) => {
              let index: number = this.tableData.data.findIndex((data) => data === selectedComment);
              this.tableData.data.splice(index, 1);
              this.tableData.sort = this.sort;
              this.cd.detectChanges();
            },
            error: (_error: Failure) =>
              this.snackbar.open(this.translocoService.translate('delete_comment_sucessfully'))
          });
      }
    });
  }

  getPsidSelection(psidSelectionList: number[]) {
    let filteredDataList = this.unfilteredTableData;
    if (psidSelectionList.length > 0) {
      filteredDataList = this.unfilteredTableData.filter((_item) =>
        psidSelectionList.includes(_item.xml_id)
      );
    }
    this.tableData = new MatTableDataSource<Comment>(Object.assign(filteredDataList));
    this.tableData.sort = this.sort;
    this.cd.detectChanges();
  }

  getDateSelection(dateInUnix: number[]) {
    let filteredDataList = this.unfilteredTableData;
    if (dateInUnix.length == 0) {
      filteredDataList = this.unfilteredTableData;
    } else if (dateInUnix[0] != null && dateInUnix[1] != null) {
      filteredDataList = this.unfilteredTableData.filter(
        (_comment) => _comment.timestamp >= dateInUnix[0] && _comment.timestamp <= dateInUnix[1]
      );
    }
    this.tableData = new MatTableDataSource<Comment>(Object.assign(filteredDataList));
    this.filterPsidList(filteredDataList);
    this.tableData.sort = this.sort;
    this.cd.detectChanges();
  }

  filterComment(filterSubstring: string) {
    let filteredDataList = this.unfilteredTableData;
    if (filterSubstring.length > 0) {
      filteredDataList = this.unfilteredTableData.filter((_comment) =>
        _comment.comment.toLowerCase().includes(filterSubstring.toLowerCase())
      );
    }
    this.tableData = new MatTableDataSource<Comment>(Object.assign(filteredDataList));
    this.filterPsidList(filteredDataList);
    this.tableData.sort = this.sort;
    this.cd.detectChanges();
  }

  filterPsidList(commentList: Comment[]) {
    this.psidList = [...new Set(commentList.map((_entry) => _entry.xml_id))];
  }
}
