<ng-container *transloco="let t">
  <frontend-monorepo-breadcrumbs></frontend-monorepo-breadcrumbs>
  <div *ngIf="!accessDenied" fxLayout="column">
    <h2>{{ t('header') }}</h2>
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex="45">
        <h4>{{ t('step_1') }}</h4>
        <frontend-monorepo-parking-lot-list
          *ngIf="parkingLotList.length > 0; else loading"
          [selectedListStatus]="selectedListStatus"
          [parkingLotList]="parkingLotList"
          (sendSelectedList)="getSelectedList($event)"
        >
        </frontend-monorepo-parking-lot-list>
        <ng-template #loading>
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" style="margin-top: 5rem">
            <mat-progress-spinner
            mode="indeterminate"
            ></mat-progress-spinner>
            <div>
              {{t('loading_parkinglots')}}
            </div>
          </div>
        </ng-template>
      </div>
      <div class="right-container" fxLayout="column" fxLayoutGap="30px">
        <h4>{{ t('step_2') }}</h4>
        <div>{{ t('day_of_analysis') }} {{ downloadDate | dateTimePipe: 'date' }}</div>
        <button
          mat-flat-button
          color="primary"
          (click)="startDownload()"
          [disabled]="selectedParkingLotList.length === 0"
        >
          {{ t('start_download') }}
        </button>
        <div>
          <div class="notice-wrapper">{{ t('notice') }}</div>
          <div class="notice-wrapper">{{ t('notice_text') }}</div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="accessDenied"
    style="margin: 0 auto; margin-top: 4rem"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="30px"
  >
    <img class="access-denied"/>
    <h3 style="text-align: center">{{ t('permission') }}</h3>
  </div>
</ng-container>