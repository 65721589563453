<mat-card>
  <div *transloco="let t" fxLayout="row" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutGap="10px" fxFlex="50">
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <div id="color-box" [ngStyle]="{ 'background-color': presetColor }"></div>
        <div *ngIf="presetName; else noName">
          {{ presetName }}
        </div>
      </div>

      <!-- weekdays bar setting -->
      <div class="preset-bar-container" fxLayout="row" fxLayoutGap="10px">
        <frontend-monorepo-preset-color-bar
          *ngFor="let dayBar of weeklyPresetsDisplay | keyvalue"
          [hourArray]="dayBar.value"
          [day]="dayBar.key">
        </frontend-monorepo-preset-color-bar>
      </div>
      <frontend-monorepo-parking-duration-reset-info
        class="text-wrapper"
      ></frontend-monorepo-parking-duration-reset-info>
    </div>
    <div fxFlex="50">
      <frontend-monorepo-parking-duration-info
        class="text-wrapper"
      ></frontend-monorepo-parking-duration-info>
    </div>
  </div>
</mat-card>
