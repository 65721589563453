<ng-container *transloco="let t">
  <div class="background"></div>
  <div
    *ngIf="token_valid; else tokenInvalid"
    fxLayout="column"
    fxLayoutGap="10px"
    class="parent-wrapper mat-elevation-z3 mp-widget"
  >
    <h2>{{ t('welcome_message') }}</h2>

    <p>{{ t('info_text') }}</p>

    <span class="note-wrapper">{{ t('note') }}</span>
    <span>{{ t('note_text') }}</span>

    <form [formGroup]="userCompletionForm" class="mp-widget">
      <h4>{{ t('usernameHeader') }}</h4>
      <div>
        <mat-form-field>
          <mat-label>{{ t('usernameInputPlaceholder') }}</mat-label>
          <input
            matInput
            placeholder="{{ t('usernameInputPlaceholder') }}"
            name="username"
            formControlName="username"
            type="text"
            (input)="changeValueUsername($event.target.value)"
            required
          />
          <mat-error style="text-align: end" *ngIf="showUserError">
            {{ userErrorText }}
          </mat-error>
        </mat-form-field>
      </div>
      <h4>{{ t('passwordHeader') }}</h4>
      <div fxLayout="column" fxLayoutGap="15px">
        <mat-form-field>
          <mat-label>{{ t('passwordInputPlaceholder') }}</mat-label>
          <input
            (input)="changeValuePassword($event.target.value)"
            [type]="hide ? 'password' : 'text'"
            matInput
            placeholder="{{ t('passwordInputPlaceholder') }}"
            formControlName="password"
            required
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon  >
              {{ hide ? 'visibility_off' : 'visibility' }}
            </mat-icon>
          </button>
        </mat-form-field>
        <div fxLayout="column" style="margin-bottom: 5px">
          <div fxLayout="row" fxLayoutGap="15px">
            <mat-icon
               
              *ngIf="password_upper_case"
              style="color: #008000"
              >check_circle_outline</mat-icon
            >
            <mat-icon
               
              *ngIf="!password_upper_case"
              style="color: #ff0000"
              >highlight_off</mat-icon
            >
            <span style="font-size: small; align-self: center">{{
              t('validator_upper_case')
            }}</span>
          </div>
          <div fxLayout="row" fxLayoutGap="15px">
            <mat-icon   *ngIf="password_lower_case" style="color: green"
              >check_circle_outline</mat-icon
            >
            <mat-icon   *ngIf="!password_lower_case" style="color: red"
              >highlight_off</mat-icon
            >
            <span style="font-size: small; align-self: center">{{
              t('validator_lower_case')
            }}</span>
          </div>
          <div fxLayout="row" fxLayoutGap="15px">
            <mat-icon   *ngIf="password_number" style="color: green"
              >check_circle_outline</mat-icon
            >
            <mat-icon   *ngIf="!password_number" style="color: red"
              >highlight_off</mat-icon
            >
            <span style="font-size: small; align-self: center">{{ t('validator_number') }}</span>
          </div>
          <div fxLayout="row" fxLayoutGap="15px">
            <mat-icon
               
              *ngIf="password_special_char"
              style="color: green"
              >check_circle_outline</mat-icon
            >
            <mat-icon   *ngIf="!password_special_char" style="color: red"
              >highlight_off</mat-icon
            >
            <span style="font-size: small; align-self: center"
              >{{ t('validator_special_char') }} {{ specialCharacter }}</span
            >
          </div>
          <div fxLayout="row" fxLayoutGap="15px">
            <mat-icon   *ngIf="password_min_length" style="color: green"
              >check_circle_outline</mat-icon
            >
            <mat-icon   *ngIf="!password_min_length" style="color: red"
              >highlight_off</mat-icon
            >
            <span style="font-size: small; align-self: center">{{ t('validator_length') }}</span>
          </div>
        </div>
        <mat-form-field>
          <mat-label>{{ t('repeat_password') }}</mat-label>
          <input
            (input)="changeValuePasswordControl($event.target.value)"
            [type]="hide ? 'password' : 'text'"
            matInput
            placeholder="{{ t('repeat_password') }}"
            formControlName="password_second"
            required
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon  >
              {{ hide ? 'visibility_off' : 'visibility' }}
            </mat-icon>
          </button>
          <mat-error style="text-align: end" *ngIf="showPasswordControlError">
            {{ passwordControlErrorText }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <div fxLayoutAlign="space-between">
      <button
        mat-flat-button
        color="cancel"
        (click)="returnToLoginPage()"
        style="padding-left: 0px"
      >
        {{ t('login_with_existing_user') }}
      </button>
      <button
        [disabled]="userCompletionForm.invalid"
        mat-flat-button
        color="primary"
        (click)="completeAuthentication()"
      >
        {{ t('create_new_user') }}
      </button>
    </div>
  </div>

  <ng-template #tokenInvalid>
    <div fxLayout="column" fxLayoutGap="10px" class="parent-wrapper mat-elevation-z3 mp-widget">
      <h2>{{ t('error_header') }}</h2>
      <p>{{ t('error_content') }}</p>
      <div style="margin-top: 1rem" fxLayoutAlign="start">
        <button mat-flat-button color="cancel" (click)="returnToLoginPage()">
          {{ t('login_with_existing_user') }}
        </button>
      </div>
    </div>
  </ng-template>

  <mat-progress-bar
    style="bottom: 0px; position: absolute"
    *ngIf="loading"
    color="primary"
    [mode]="'indeterminate'"
  ></mat-progress-bar>
</ng-container>
