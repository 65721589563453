import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanAndZoomDirective } from './directives/pan-and-zoom.directive';
import { ParkingLotMapComponent } from './components/parking-lot-map/parking-lot-map.component';
import { SwitchParkingLotLevelsFabComponent } from './components/switch-parking-lot-levels-fab/switch-parking-lot-levels-fab.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ParkingSpaceComponent } from './components/parking-space/parking-space.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ParkingLotMapContainerComponent } from './components/parking-lot-map-container/parking-lot-map-container.component';
import { OverlayingSidebarComponent } from './components/overlaying-sidebar/overlaying-sidebar.component';
import { LeftSideBarComponent } from './components/left-side-bar/left-side-bar.component';
import { ParkingLotMapLegendComponent } from './components/parking-lot-map-legend/parking-lot-map-legend.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import de from './i18n/de.json';
import en from './i18n/en.json';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { DrawablesComponent } from './components/drawables/drawables.component';
import { LabelComponent } from './components/label/label.component';
import { DeviceSpaceComponent } from './components/device-space/device-space.component';
import { AuthInterceptor, authInterceptorProviders } from '@frontend-monorepo/core';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    PanAndZoomDirective,
    ParkingLotMapComponent,
    SwitchParkingLotLevelsFabComponent,
    ParkingSpaceComponent,
    ParkingLotMapContainerComponent,
    OverlayingSidebarComponent,
    LeftSideBarComponent,
    ParkingLotMapLegendComponent,
    DrawablesComponent,
    LabelComponent,
    DeviceSpaceComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule,
    TranslocoModule,
    NgxPanZoomModule,
    FlexLayoutModule
  ],
  exports: [
    ParkingLotMapContainerComponent,
    ParkingSpaceComponent
  ]
})
export class ParkingLotMapCommonModule {
  constructor(translocoService: TranslocoService) {
    translocoService.setTranslation(en, 'en');
    translocoService.setTranslation(de, 'de');
  }
}
