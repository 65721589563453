import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePickerModel } from '@frontend-monorepo/common';
import { TranslocoService } from '@ngneat/transloco';
import { SatDatePickerModel } from 'apps/customer-dashboard/src/app/helper/models';
import moment from 'moment';

/**
 * Simple DatePicker Component for choosing a time range
 *
 * @example simply add the HTML-Tag into your HTML-File
 *  `<shared-date-picker [showHeader]="true/false" (sendDateRange)="yourFunction($event)"></shared-date-picker>`
 * showHeader is an optioanl property to define if header should be displayed
 * `$event`-Input is an Object of type {DatePickerModel} containing both `begin` and `end` in unix timepstamp
 */

@Component({
  selector: 'shared-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input() minDate: Date = new Date();
  @Output() sendDateRange = new EventEmitter();
  @Optional() showHeader: boolean;
  dateInputForm: FormGroup;
  maxDate: Date = new Date();

  constructor(private translocoService: TranslocoService, private snackbar: MatSnackBar) {}

  ngOnInit(): void {
    this.dateInputForm = new FormGroup({
      date: new FormControl('', [Validators.required, this.limitRange()])
    });
    this.sendInputData();
  }

  limitRange(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.dateInputForm !== undefined) {
        const start_1 =
          this.dateInputForm.get('date') !== undefined
            ? moment(this.dateInputForm.get('date').value.begin).unix()
            : 0;
        const end_1 =
          this.dateInputForm.get('date') !== undefined
            ? moment(this.dateInputForm.get('date').value.end).unix()
            : 0;

        if (end_1 != null && end_1 != null && (end_1 - start_1) / 2635200 > 2) {
          return {
            dateInputError: true
          };
        }
      }
    };
  }

  sendInputData() {
    if (this.dateInputForm.controls['date'] != undefined) {
      this.dateInputForm.valueChanges.subscribe((value) => {
        if (this.dateInputForm.controls['date'].value != null) {
          let satDateObject: SatDatePickerModel = this.dateInputForm.controls['date'].value;
          this.sendDateRange.next(
            new DatePickerModel(
              moment(satDateObject.begin).unix(),
              moment(satDateObject.end).endOf('day').unix()
            )
          );
        } else {
          this.snackbar.open(this.translocoService.translate('select_date_range'));
        }
      });
    }
  }
}
