import { Component, OnInit, Input } from '@angular/core';
import { HEATMAP_COMPARISON_TYPE } from '@frontend-monorepo/parking-lot-map';

/**
 * The main component for displaying a parking lot's map with the two following siderbars:
 * * {@link LeftSideBarComponent}
 * * {@link OverlayingSidebarComponent}
 */
@Component({
  selector: 'mp-parking-lot-map-container',
  templateUrl: './parking-lot-map-container.component.html',
  styleUrls: ['./parking-lot-map-container.component.css']
})
export class ParkingLotMapContainerComponent {
  /**
   * The ID of the parking lot for which the map should be displayed.
   */
  @Input() parkingLotID: number;

  /**
   * The tag is used to define, which project/backend is being build.
   */
  @Input() dashboard_tag: string;

  /**
   * Scale applied to the {@link ParkingLotMapComponent}, after scaling it to fit to the screen´s boundaries.
   *
   * Example:
   *
   * If the {@link ParkingLotMapComponent} was scaled to 0.7 to fit to the screen´s boundaries,
   * the maps final scale corresponds to `initialScale * 0.7`.
   */
  @Input() initialScale = 1;

  /**
   * Optional parameter reacting to different actions in your custom parking space overlay.
   */
  @Input() optionalParkingSpaceType: number = -1;
  /**
   * Optional parameter defining the type of map:
   * `heatmap`
   * `normal map display`
   */
  @Input() heatmapGroupComparison: HEATMAP_COMPARISON_TYPE;


  constructor() {}
}
