import { Injectable } from '@angular/core';
import { ParkingLotsDataService, ParkingSpace, Device } from '@frontend-monorepo/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ParkingSpaceMapInfo, Level } from '../interfaces/parkingLotMap';
import { GROUP_A } from '@frontend-monorepo/heatmap-ui';

/**
 * Service containing shared functions relating a parking lot's map.
 */
@Injectable({
  providedIn: 'root'
})
export class ParkingLotMapService {
  id: number;
  /**
   * PLID of the parking lot which is actively displayed in the route '/parking-lot'
   */
  private _activePLID: number;
  private _idsOfParkingSpacesOfParkingLot: BehaviorSubject<Array<number>>;
  /**
   * BehaviourSubject for storing and updating a list of all selected parking spaces.
   * Mouse event interaction is left click
   * Initial value: []
   */
  selectedParkingSpacesSubject: BehaviorSubject<Array<number>>;

  private _parkingSpacesMapCollection: BehaviorSubject<Array<ParkingSpaceMapInfo>>;

  /**
   * BehaviourSubject for storing and updating selected deviceSpaceIds coming in from {@link DeviceSpaceComponent}
   */
  public selectedDeviceSpaceSubject: BehaviorSubject<Array<number>>;

  /**
   * BehaviourSubject for storing and updating the state of parkingLotMap objects
   *
   * The parkingLotMapState determines on which parkingLotMap objects the cickable-listener should be applied
   * @default parkingLotMapState.everythingClickable
   */
  public parkingLotMapObjectState: BehaviorSubject<ParkingLotMapState>;

  constructor(private parkingLotsDataService: ParkingLotsDataService) {
    this.selectedParkingSpacesSubject = new BehaviorSubject<Array<number>>(new Array());
    this._idsOfParkingSpacesOfParkingLot = new BehaviorSubject<Array<number>>(new Array());
    this._parkingSpacesMapCollection = new BehaviorSubject<Array<ParkingSpaceMapInfo>>(new Array());
    this.selectedDeviceSpaceSubject = new BehaviorSubject<Array<number>>(new Array());
    this.parkingLotMapObjectState = new BehaviorSubject<ParkingLotMapState>(
      ParkingLotMapState.everythingClickable
    );
    this.id = Math.random();
  }

  getAllPsidsOfParkingLot(): Array<number> {
    return this._idsOfParkingSpacesOfParkingLot.getValue();
  }

  /**
   * @returns ID of the currently active parkingLot
   */
  getActivePLID(): number {
    return this._activePLID;
  }

  setParkingLotAsActive(plid: number) {
    this._activePLID = plid;
    const psids = this.parkingLotsDataService.parkingSpacesOfPLSubjectMap
      .get(plid)
      .getValue()
      .map((parkingSpace) => parkingSpace.id);
    this._idsOfParkingSpacesOfParkingLot.next(psids);
  }

  /**
   * Retrieving a ParkingSpace-Object from the {@link ParkingLotsDataService}
   *
   * Retrieving the actual ParkingSpace object from the {@link ParkingLotsDataService}
   * by searching with the parameter `xmlID` in the array of parkingSpaces of the currently active (`activePLID`) parkingLot.
   *
   *
   * **May throw exception**
   *
   * @param xmlID The parkingSpace's xmlID
   * @returns ParkingSpace of the parking lot where the xmlIDs are matching.
   */
  getParkingSpaceFromXmlID(xmlID: number): ParkingSpace {
    if (this._activePLID === undefined) {
      throw new Error('No active parking lot (missing `activePLID`).');
    }

    const parkingSpacesSubject = this.parkingLotsDataService.parkingSpacesOfPLSubjectMap.get(
      this._activePLID
    );

    if (parkingSpacesSubject === undefined) {
      throw new Error(`The active parking lot (PLID: ${this._activePLID}) has no parking spaces.`);
    }

    return parkingSpacesSubject.value.find((x) => x.xml_id === xmlID);
  }

  /**
   * Retrieving a Device-object from the {@link ParkingLotsDataService}
   *
   * @param psid The ID of the parking space which's corresponding sensor is returned
   * @returns device by searching in the array of devices of the `installedDevicesOfPLSubjectMap` by a given psid
   */
  getDeviceFromPSID(psid: number): Device {
    if (this._activePLID === undefined) {
      throw new Error('No active parking lot (missing `activePLID`).');
    }

    const installedDevicesSubject = this.parkingLotsDataService.installedDevicesOfPLSubjectMap.get(
      this._activePLID
    );

    if (installedDevicesSubject === undefined) {
      throw new Error(
        `The active parking lot (PLID: ${this._activePLID}) has no installed devices.`
      );
    }
    return installedDevicesSubject.value.find((x) => x.psid === psid);
  }

  /**
   * 
   * @param deviceSpaceId 
   */
   addSelectedDeviceSpace(deviceSpaceId: number): void {
    let deviceSpaceIds = [...this.selectedDeviceSpaceSubject.getValue()];

    if (
      deviceSpaceIds.length > 0 &&
      deviceSpaceIds[0] == deviceSpaceId
    ) {
      deviceSpaceIds = [];
    } else {
      deviceSpaceIds = [deviceSpaceId];
    }

    this._clearNormalSelectedParkingSpaces();

    this.selectedDeviceSpaceSubject.next(deviceSpaceIds);
  }

  private _clearNormalSelectedParkingSpaces(): void {
    if (this.selectedParkingSpacesSubject.getValue().length > 0) {
      this.selectedParkingSpacesSubject.next([]);
    }
  }

  fillPSMapCollection(levels: Level[]) {
    let psList: Array<ParkingSpaceMapInfo> = new Array();
    levels.forEach((item) => {
      item.parkingSpaces.forEach((parkingspace) => {
        psList.push(parkingspace);
      });
    });
    this._parkingSpacesMapCollection.next(psList);
  }

  getPSMapInfo(psid: Number): ParkingSpaceMapInfo {
    if (this._parkingSpacesMapCollection.value != null) {
      return this._parkingSpacesMapCollection.value.find(
        (element) => element.parkingSpaceId === psid
      );
    } else {
      return null;
    }
  }

  /**
   * Resets all Behavior Subjects to their initial values
   */
  reset(): void {
    this.selectedParkingSpacesSubject = new BehaviorSubject<Array<number>>(new Array());
    this._idsOfParkingSpacesOfParkingLot = new BehaviorSubject<Array<number>>(new Array());
    this._parkingSpacesMapCollection = new BehaviorSubject<Array<ParkingSpaceMapInfo>>(new Array());
    this.selectedDeviceSpaceSubject = new BehaviorSubject<Array<number>>(new Array());
    this.parkingLotMapObjectState = new BehaviorSubject<ParkingLotMapState>(
      ParkingLotMapState.everythingClickable
    );
  }
}

export enum ParkingLotMapState {
  deviceSpacesClickable,
  parkingSpacesClickable,
  everythingClickable,
  nothingClickable
}
