import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParkingDurationInfoComponent } from './components/parking-duration-info/parking-duration-info.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialComponentsModule } from 'apps/customer-dashboard/src/app/pages/materialComponents.module';
import { TranslocoService } from '@ngneat/transloco';
import de from './i18n/de.json';
import en from './i18n/en.json';
import { ParkingDurationResetInfoComponent } from './components/parking-duration-reset-info/parking-duration-reset-info.component';
import { PresetDayTimeConverterPipe } from './utils/preset-day-time-converter.pipe';

@NgModule({
  declarations: [ParkingDurationInfoComponent, ParkingDurationResetInfoComponent, PresetDayTimeConverterPipe],
  imports: [CommonModule, MaterialComponentsModule, FlexLayoutModule],
  exports: [ParkingDurationInfoComponent, ParkingDurationResetInfoComponent, PresetDayTimeConverterPipe]
})
export class ParkingDurationInfoModule {
  constructor(translocoService: TranslocoService) {
    translocoService.setTranslation(en, 'en');
    translocoService.setTranslation(de, 'de');
  }
}
