import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParkingDurationsColorBeamComponent } from './component/parking-durations-color-beam.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';

import de from './i18n/de.json';
import en from './i18n/en.json';

/**
 * 
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@NgModule({
    declarations: [
        ParkingDurationsColorBeamComponent
    ],
    imports: [
        CommonModule,
        MatTooltipModule,
        TranslocoModule
    ],
    exports: [
        ParkingDurationsColorBeamComponent
    ],
})
export class ParkingDurationsColorBeamModule {
    constructor(translocoService: TranslocoService) {
        translocoService.setTranslation(en, 'en');
        translocoService.setTranslation(de, 'de');
    }
}