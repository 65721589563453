<div *transloco="let t">
  <div
    *ngIf="activeDurationPresetList != 0 && activeDurationPresetList?.length > 0; else emptyData"
    fxLayout="column"
    fxLayoutGap="10px"
  >
    <div *ngFor="let durationList of activeDurationPresetList">
      <h4>{{ durationList | presetDayTimeConverter }}</h4>

      <div
        *ngIf="
          isSensor &&
          durationList[0].due_warning_duration != 0 && durationList[0].due_warning_duration != null
        "
        fxLayout="row"
      >
        <div fxFlex="70">{{ t('soon_due_after') }}</div>
        <div fxFlex="30" fxFlexAlign="end" class="text-wrapper">
          {{
            (durationList[0].max_parking_duration - durationList[0].due_warning_duration) / 60
          }}
          min
        </div>
      </div>
      <div
        *ngIf="
          durationList[0].max_parking_duration != 0 && durationList[0].max_parking_duration != null
        "
        fxLayout="row"
      >
        <div fxFlex="70">{{ t('due_after') }}</div>
        <div fxFlex="30" fxFlexAlign="end" class="text-wrapper">
          {{ durationList[0].max_parking_duration / 60 }} min
        </div>
      </div>
      <div
        *ngIf="
          durationList[0].max_overdue_duration != 0 && durationList[0].max_overdue_duration != null
        "
        fxLayout="row"
      >
        <div fxFlex="70">{{ t('overdue_after') }}</div>
        <div fxFlex="30" fxFlexAlign="end" class="text-wrapper">
          {{ durationList[0].max_overdue_duration / 60 }} min
        </div>
      </div>
    </div>
  </div>
  <ng-template #emptyData>
    <h4>{{ t('no_setting') }}</h4>
  </ng-template>
</div>
