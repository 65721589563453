import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { GuardLoadingDialogComponent } from './pages/parking-lot/guards/guard-loading-dialog/guard-loading-dialog.component';
import { TranslocoModule, TRANSLOCO_CONFIG, translocoConfig } from '@ngneat/transloco';
import { environment } from '../../src/environments/environment';
import { httpLoader } from './helper/transloco-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { GuardAuthDialogComponent } from './guards/guard-auth-dialog/guard-auth-dialog.component';
import { RoutingProgressIndicatorModule, NavigationBarModule, CountDownModule, NavDrawerModule } from '@frontend-monorepo/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { ImprintDialogComponent } from './pages/footer/imprint-dialog/imprint-dialog.component';
import { PolicyDialogComponent } from './pages/footer/policy-dialog/policy-dialog.component';
import { authInterceptorProviders } from '@frontend-monorepo/core';
import { CookieModule } from 'libs/common/projects/common-lib/login/src/helper/cookie-directive/cookie.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DashboardV2Component } from './components/dashboard-v2/dashboard-v2.component';
import { RocketFabComponent } from './components/dashboard-v2/rocket-fab/rocket-fab.component';


registerLocaleData(localeDE);


@NgModule({
  declarations: [
    AppComponent,
    GuardAuthDialogComponent,
    GuardLoadingDialogComponent,
    PolicyDialogComponent,
    ImprintDialogComponent,
    DashboardV2Component,
    RocketFabComponent
    
    // ParkingLotDataComponent,
    // ReservationDialogComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatProgressBarModule,
    MatIconModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatOptionModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    NgxPermissionsModule.forRoot(),
    RoutingProgressIndicatorModule,
    NavigationBarModule,
    MatTabsModule,
    MatCardModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSidenavModule,
    MatMenuModule,
    TranslocoModule,
    CookieModule,
    MatCheckboxModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    CountDownModule,
    NavDrawerModule
  ],
  entryComponents: [
    GuardAuthDialogComponent,
    GuardLoadingDialogComponent,
    PolicyDialogComponent,
    ImprintDialogComponent
    //OverlayRightbarReservationComponent,
    //ReservationDialogComponent
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: "de",
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 3000, horizontalPosition: "right", verticalPosition: "bottom" }
    },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'de'],
        defaultLang: 'de',
        fallbackLang: 'de',
        reRenderOnLangChange: true,
        prodMode: environment.production,
      })
    },
    authInterceptorProviders,
    httpLoader
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
