<ng-container *transloco="let t">
  <div
    *ngIf="unfilteredTableData != null && unfilteredTableData.length > 0"
    fxLayout="row"
    fxLayoutGap="15px"
    fxLayoutAlign="space-between"
  >
    <frontend-monorepo-comment-date-picker
      [headerText]="'filter_date_range'"
      (sendDate)="getDateSelection($event)"
    ></frontend-monorepo-comment-date-picker>
    <frontend-monorepo-parking-space-filter
      [psidlist]="psidList"
      [parkingLotId]="parkingLotId"
      (sendPsidList)="getPsidSelection($event)"
    ></frontend-monorepo-parking-space-filter>
    <div fxLayout="column" fxLayoutGap="5px">
      <span class="smaller-text">{{ t('filter_comment') }}</span>
      <mat-form-field>
        <input
          matInput
          placeholder="{{ t('search_comment') }}"
          (keyup)="filterComment($event.target.value)"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <div
  *ngIf="(tableData.data != null && tableData.data.length > 0)&& !isLoading"
    style="overflow: auto; width: 100%; max-height: 45vh;"
  >
    <table mat-table [dataSource]="tableData" matSort matSortActive="psid" matSortStart="desc">
      <!-- delete on free -->
      <ng-container matColumnDef="delete_on_free">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let comment">
          <div *ngIf="comment.delete_on_free" matTooltip="{{ t('delete_on_free_icon_tooltip') }}">
            <mat-icon svgIcon="leaving_car"></mat-icon>
          </div>
        </td>
      </ng-container>

      <!-- parking space id -->
      <ng-container matColumnDef="psid">
        <th mat-header-cell *matHeaderCellDef>
          {{ t('parking_space') }}
        </th>
        <td mat-cell *matCellDef="let comment">
          <div>
            {{ comment.device_space_id }}
          </div>
        </td>
      </ng-container>

      <!-- parking space xml-id -->
      <ng-container matColumnDef="xml">
        <th mat-header-cell *matHeaderCellDef disableClear>
          {{ t('parking_space') }}
        </th>
        <td mat-cell *matCellDef="let comment">
          <div>
            {{ comment.xml_id }}
          </div>
        </td>
      </ng-container>

      <!-- timestamp -->
      <ng-container matColumnDef="creation_time">
        <th mat-header-cell *matHeaderCellDef>
          {{ t('creation_time') }}
        </th>
        <td mat-cell *matCellDef="let comment">
          <div>
            {{ comment.timestamp | dateTimePipe:'date_time' }}
          </div>
        </td>
      </ng-container>

      <!-- comment -->
      <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef>
          {{ t('comment') }}
        </th>
        <td mat-cell *matCellDef="let comment">
          <div>
            {{ comment.comment }}
          </div>
        </td>
      </ng-container>

      <!-- edit -->
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef style="text-align: center"></th>
        <td style="text-align: left" mat-cell *matCellDef="let comment">
          <button color="primary" mat-icon-button (click)="editComment(comment)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Delete -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef style="text-align: center"></th>
        <td style="text-align: left" mat-cell *matCellDef="let comment">
          <button color="warn" mat-icon-button (click)="deleteComment(comment)">
            <mat-icon>remove_circle</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <ng-container *ngIf="!isLoading && tableData.data.length === 0">
    <div style="padding-top: 10px; padding-bottom: 10px">
      {{ t('no_history_data') }}
    </div>
  </ng-container>
</ng-container>
