import { Observable } from 'rxjs/internal/Observable';
import { ParkingLot } from '../parking-lot/models/parking-lot.model';
import { OccupationDuration } from './models/occupation-duration.model';

export abstract class ParkingLotSettingsBase {
  abstract updateDurationSettings(parkingLot: ParkingLot): Observable<any>;
//   abstract fetchParkingSpaceOccupationDurations(
//     PSIDs: Array<number> | number,
//     startTime: number,
//     endTime: number,
//     amountOfRetries: number
//   ): Observable<Map<string, Array<OccupationDuration>>>;
}
