<div *transloco="let t; read: 'DURATION_SETUP'">
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <mat-card [class.mat-elevation-z2]="true">
      <mat-card-content>
        <div>
          <div fxLayout="row-wrap" fxLayoutGap="20px">
            <div fxFlex="40" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center start">
              <div>
                <h4>{{ t('weekdays') }}</h4>
                <mat-chip-list selectable multiple>
                  <mat-chip
                    [class.mat-elevation-z2]="enableWeekdayFilter"
                    #d="matChip"
                    *ngFor="let day of weekdayList"
                    [value]="day"
                    [color]="primary"
                    [selected]="getSelectedDay(day)"
                    (click)="onWeekdaySelection(d, day)"
                  >
                    <div class="text-wrapper">
                      {{ t(day) }}
                    </div>
                  </mat-chip>
                </mat-chip-list>
              </div>

              <form [formGroup]="timeFormGroup">
                <div fxLayout="row" fxLayoutGap="5px" class="time-range-wrapper">
                  <span fxLayoutAlign="start end">{{ t('between') }}</span>
                  <mat-form-field appearance="fill" id="time-input-wrapper">
                    <input
                      matInput
                      type="time"
                      name="time"
                      value="00:00"
                      formControlName="startTime"
                      required
                    />
                  </mat-form-field>
                  <span fxLayoutAlign="start end">{{ t('and_time') }}</span>
                  <mat-form-field appearance="fill" id="time-input-wrapper">
                    <input matInput type="time" formControlName="endTime" value="00:00" required />
                  </mat-form-field>
                  <span fxLayoutAlign="start end">{{ t('time') }}</span>
                </div>
              </form>
            </div>

            <!-- duraiton setting -->
            <form
              fxFlex="45"
              [formGroup]="parkingDurationsForm"
              fxLayout="column"
              fxLayoutGap="5px"
              autocomplete="off"
            >
              <div
                *ngIf="parkinglotType != 'camera'"
                fxLayout="row"
                fxLayoutGap="5px"
                fxFlexAlign="center center"
              >
                <span fxFlex="70" fxFlexAlign="center">{{ t('soon_due_after') }}</span>
                <mat-form-field fxFlex="30" id="duration-input-wrapper">
                  <input matInput type="number" formControlName="soonDueDuration" required />
                </mat-form-field>
                <span fxFlexAlign="center">min</span>
              </div>
              <div fxLayout="row" fxLayoutGap="5px" fxFlexAlign="center center">
                <span fxFlex="70" fxFlexAlign="center">{{ t('due_after') }}</span>
                <mat-form-field fxFlex="30" id="duration-input-wrapper">
                  <input matInput type="number" formControlName="dueDuration" required />
                </mat-form-field>
                <span fxFlexAlign="center">min</span>
              </div>
              <div
                *ngIf="showOverdueSetting; else addOverdueTemplate"
                fxLayout="row"
                fxLayoutGap="5px"
                fxFlexAlign="center center"
              >
                <span fxFlex="70" fxFlexAlign="center">{{ t('overdue_after') }}</span>
                <mat-form-field fxFlex="30" id="duration-input-wrapper">
                  <input matInput type="number" formControlName="overdueDuration" required />
                  <button
                    matTooltip="{{ t('remove_overdue_setting') }}"
                    matSuffix
                    mat-icon-button
                    (click)="setupOverdueSetting(false)"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <span fxFlexAlign="center">min</span>
              </div>
              <ng-template #addOverdueTemplate>
                <div class="add-container" (click)="setupOverdueSetting(true)" matTooltip="{{t('add_overdue_setting_tooltip')}}">
                  {{ t('add_overdue_setting') }}
                </div>
              </ng-template>
            </form>
          </div>
          <mat-error
            *ngIf="
              this.timeFormGroup != null &&
              (this.timeFormGroup.get('startTime').hasError('timeError') ||
                this.timeFormGroup.get('endTime').hasError('timeError'))
            "
          >
            {{ t('timeErrorMsg') }}</mat-error
          >
          <mat-error
            *ngIf="
              this.timeFormGroup != null &&
              (this.timeFormGroup.get('startTime').hasError('overlapError') ||
                this.timeFormGroup.get('endTime').hasError('overlapError'))
            "
          >
            {{ t('overlapErrorMsg') }}</mat-error
          >
        </div>
        <div style="text-align: right">
          <button
            mat-flat-button
            color="primary"
            (click)="updateSetting()"
            [disabled]="
              buttonDisableState ||
              (this.timeFormGroup != null &&
                (this.timeFormGroup.get('startTime').hasError('timeError') ||
                  this.timeFormGroup.get('endTime').hasError('timeError') ||
                  this.timeFormGroup.get('startTime').hasError('overlapError') ||
                  this.timeFormGroup.get('endTime').hasError('overlapError'))) ||
              this.parkingDurationsForm.invalid ||
              this.timeFormGroup.get('startTime').value == null ||
              this.timeFormGroup.get('endTime').value == null
            "
          >
            {{ t('apply') }}
          </button>
        </div>
      </mat-card-content>
      <mat-error
        *ngIf="
          (this.parkingDurationsForm != null &&
            this.parkingDurationsForm.get('soonDueDuration').hasError('maxDurationError')) ||
          this.parkingDurationsForm.get('dueDuration').hasError('max') ||
          this.parkingDurationsForm.get('overdueDuration').hasError('maxDurationError')
        "
      >
        {{ t('maxDurationError') }}</mat-error
      >
      <mat-error
        *ngIf="
          (this.parkingDurationsForm != null &&
            this.parkingDurationsForm
              .get('soonDueDuration')
              .hasError('prevDurationExceedingError')) ||
          this.parkingDurationsForm.get('dueDuration').hasError('prevDurationExceedingError') ||
          this.parkingDurationsForm.get('overdueDuration').hasError('prevDurationExceedingError')
        "
      >
        {{ t('prevDurationExceedingErrorMsg') }}</mat-error
      >
    </mat-card>

    <button id="delete-button" color="warn" mat-icon-button (click)="deleteSetting()">
      <mat-icon>remove_circle</mat-icon>
    </button>
  </div>
</div>
