import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  ParkingLotsDataService,
  ParkingLot,
  ParkingLotState,
  ParkingSpaceContiniousObject,
  ParkingSpaceInitialStateObject,
  ParkingSpaceState,
  ParkingSpaceStateDurations,
  ParkingSpaceStateTemporary,
  WebSocketObject,
  CURRENT_STATES,
  PARKING_SPACE_STATE,
  PARKING_LOT_STATE,
  PARKING_SPACE_STYLE_TYPE,
  CustomerPLIDModel,
  CameraParkingLotState
} from '@frontend-monorepo/core';
import { DeviceHealth, Incidents, SLADeviceHealth } from '../interfaces/parking_space';
import {
  CAMERA_PARKING_LOT_STATE,
  DEVICE_HEALTH_READY,
  DEVICE_HEALTH_STATE,
  INCIDENTS_READY,
  INCIDENTS_STATE_INITIAL,
  INCIDENTS_STATE_UPDATE,
  SLA_DEVICE_HEALTH_READY,
  SLA_DEVICE_HEALTH_STATE
} from 'libs/core/src/lib/utils/constants/constants';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MainDataService {
  listOPL: BehaviorSubject<Map<number, BehaviorSubject<ParkingLotState>>>;
  listCameraOPL: BehaviorSubject<Map<number, BehaviorSubject<CameraParkingLotState>>>;
  listOPS: Map<number, BehaviorSubject<ParkingSpaceState>>;
  parkingLotDurations: BehaviorSubject<ParkingSpaceStateDurations>;
  parkingLotData: Subject<ParkingLotState>;
  listDeviceHealth: Map<number, BehaviorSubject<DeviceHealth>>;
  listDeviceHealthReady: Subject<String>;

  /**
   * BehaviorSubject of a map, containing one Object for each parking lot, to pass and update the list of parking lot device health states
   * The parking lot id is used as KEY
   */
  listSLADeviceHealth: BehaviorSubject<Map<number, SLADeviceHealth>>;
  /**
   * Subject as helper to notify if {@link listSLADeviceHealth} BehaviorSubject is filled with data
   */
  listSLADeviceHealthReady: Subject<String>;

  /**
   * BehaviorSubject of a map, containing one Object for each parking lot, to pass and update the list of parking lot incidents states
   * The parking lot id is used as KEY
   */
  listIncidents: BehaviorSubject<Map<number, Incidents>>;
  /**
   * Subject as helper to notify if {@link listIncidents} BehaviorSubject is filled with data
   */
  listIncidentsReady: Subject<String>;

  /**
   * Map containing customerPlid, each mapped to a parking lot id
   * The parking lot id is used as KEY
   */
  listCustomerPLID: Array<CustomerPLIDModel>;

  constructor(private parkingLotDataService: ParkingLotsDataService) {
    this.listOPL = new BehaviorSubject(new Map());
    this.listCameraOPL = new BehaviorSubject(new Map());
    this.listOPS = new Map();
    this.parkingLotDurations = new BehaviorSubject({
      max_parking_duration: 0,
      soon_due_duration: 0,
      overdue_duration: 0
    });
    this.parkingLotData = new Subject();
    this.listDeviceHealth = new Map();
    this.listDeviceHealthReady = new Subject();
    this.listSLADeviceHealth = new BehaviorSubject(new Map());
    this.listSLADeviceHealthReady = new Subject();
    this.listCustomerPLID = new Array();
    this.listIncidents = new BehaviorSubject(new Map());
    this.listIncidentsReady = new Subject();
  }

  // #region listOPL
  updateListOPL(object: WebSocketObject) {
    switch (object.type) {
      case CURRENT_STATES:
        // console.log("WebSocketData Available");
        this.updateListOPLInitial(object.data);
        break;
      case PARKING_LOT_STATE:
        this.updateListOPLContinious(object.data);
        this.updateParkingLotStateObject(object.data);
        break;
      default:
        break;
    }
  }

  private updateListOPLInitial(list: ParkingLotState[]) {
    list.forEach((plState) => {
      this.updateListOPLContinious(plState);
    });
    this.listOPL.next(this.listOPL.getValue());
    this.fillParkingLotDataService();
  }

  private fillParkingLotDataService() {
    let listPL: ParkingLot[] = Array.from(this.listOPL.getValue().values()).map((object) => ({
      id: object.getValue().parking_lot_id,
      name: object.getValue().name,
      max_parking_duration: 0,
      max_overdue_duration: 0,
      due_warning_duration: 0
    }));
    this.parkingLotDataService.parkingLotsSubject.next(listPL);
  }

  private updateListOPLContinious(plState: ParkingLotState) {
    if (this.listOPL.getValue().has(plState.parking_lot_id)) {
      let itemOld: ParkingLotState = this.listOPL.getValue().get(plState.parking_lot_id).getValue();
      plState.name = itemOld.name;
      this.listOPL.getValue().get(plState.parking_lot_id).next(plState);
      this.listOPL.next(this.listOPL.getValue());
    } else {
      this.listOPL.getValue().set(plState.parking_lot_id, new BehaviorSubject(plState));
    }
  }

  private updateParkingLotStateObject(plState: ParkingLotState) {
    this.parkingLotData.next(plState);
  }
  // #endregion

  // #region cameraParkingLotWebsocket
  updateCameraParkingLotState(object: WebSocketObject) {
    switch (object.type) {
      case CURRENT_STATES:
        const map: Map<number, CameraParkingLotState> = new Map();
        for (const [key, value] of Object.entries(object.data)) {
          map.set(+key, value as CameraParkingLotState);
        }
        this.updateCameraParkingLotStateInitial(map);
        break;
      case CAMERA_PARKING_LOT_STATE:
        this.updateCameraParkingLotStateContinious(object.data);
        this.updateParkingLotStateObject(object.data);
        break;
      default:
        break;
    }
  }

  private updateCameraParkingLotStateInitial(list: Map<number, CameraParkingLotState>) {
    Array.from(list.values()).forEach((plState: CameraParkingLotState) => {
      this.updateCameraParkingLotStateContinious(plState);
    });
    this.listCameraOPL.next(this.listCameraOPL.getValue());
  }

  private updateCameraParkingLotStateContinious(plState: CameraParkingLotState) {
    if (this.listCameraOPL?.getValue().has(plState.parking_lot_id)) {
      this.listCameraOPL.getValue().get(plState.parking_lot_id).next(plState);
      this.listCameraOPL.next(this.listCameraOPL.getValue());
    } else {
      this.listCameraOPL.getValue().set(plState.parking_lot_id, new BehaviorSubject(plState));
    }
  }
  // #endregion 

  // #region listOPS
  updateListOPS(object: WebSocketObject, plid: number) {
    // console.log(object);
    switch (object.type) {
      case CURRENT_STATES:
        let psList = Array.from(this.listOPS.values()).filter(
          (element) => element.getValue().plid === plid
        );
        if (psList.length > 0) {
          this.updateListOPSAfterReconnect(object.data, plid);
        } else {
          this.updateListOPSInitial(object.data, plid);
        }
        break;
      case PARKING_SPACE_STATE:
        this.updateListOPSContinious(object.data, plid);
        break;
      default:
        break;
    }
  }

  private updateListOPSInitial(data: ParkingSpaceInitialStateObject, plid: number) {
    for (const [key, value] of Object.entries(data.parking_space_state_map)) {
      let psState: ParkingSpaceStateTemporary = value;

      /**
       * State Object is added for connected parking spaces
       * Only virtual parking spaces should be taken into account
       */
      if (psState.connected_parking_space != null) {
        if (+key == psState.connected_parking_space) {
          this.listOPS.set(
            +key,
            new BehaviorSubject({
              last_change: psState.last_change,
              monitoring_status: psState.monitoring_status,
              state: this.convertState(psState.state),
              psid: +key,
              plid: plid,
              reservation_end: psState.reservation_end,
              reservation_id: psState.reservation_id,
              reservation_placed_by_user: psState.reservation_placed_by_user,
              reservation_start: psState.reservation_start,
              connected_parking_space: psState.connected_parking_space
            })
          );
        }
      } else {
        /**
         * default State Object handling
         */
        this.listOPS.set(
          +key,
          new BehaviorSubject({
            last_change: psState.last_change,
            monitoring_status: psState.monitoring_status,
            state: this.convertState(psState.state),
            psid: +key,
            plid: plid,
            reservation_end: psState.reservation_end,
            reservation_id: psState.reservation_id,
            reservation_placed_by_user: psState.reservation_placed_by_user,
            reservation_start: psState.reservation_start,
            connected_parking_space: psState.connected_parking_space
          })
        );
      }
    }
    this.parkingLotDurations.next({
      max_parking_duration: data.max_parking_duration,
      soon_due_duration: data.due_warning_duration,
      overdue_duration: data.max_overdue_duration
    });
  }

  private updateListOPSAfterReconnect(data: ParkingSpaceInitialStateObject, plid: number) {
    for (const [key, value] of Object.entries(data.parking_space_state_map)) {
      if (this.listOPS.has(+key)) {
        let actualObject: ParkingSpaceState = this.listOPS.get(+key).getValue();
        let psState: ParkingSpaceStateTemporary = value;

        /**
         * State Object is added for connected parking spaces
         * Only virtual parking spaces should be taken into account
         */
        if (psState.connected_parking_space != null) {
          if (+key == psState.connected_parking_space) {
            this.listOPS.get(+key).next({
              last_change: psState.last_change,
              monitoring_status: psState.monitoring_status,
              state: this.convertState(psState.state),
              psid: +key,
              plid: actualObject.plid,
              reservation_end: psState.reservation_end,
              reservation_id: psState.reservation_id,
              reservation_placed_by_user: psState.reservation_placed_by_user,
              reservation_start: psState.reservation_start,
              connected_parking_space: psState.connected_parking_space
            });
          }
        } else {
          /**
           * default State Object handling
           */
          this.listOPS.get(+key).next({
            last_change: psState.last_change,
            monitoring_status: psState.monitoring_status,
            state: this.convertState(psState.state),
            psid: +key,
            plid: actualObject.plid,
            reservation_end: psState.reservation_end,
            reservation_id: psState.reservation_id,
            reservation_placed_by_user: psState.reservation_placed_by_user,
            reservation_start: psState.reservation_start,
            connected_parking_space: psState.connected_parking_space
          });
        }
      }
    }
  }

  private updateListOPSContinious(data: ParkingSpaceContiniousObject, plid: number) {
    if (this.listOPS.has(data.parking_space_id)) {
      let actualObject: ParkingSpaceState = this.listOPS.get(data.parking_space_id).getValue();

      /**
       * State Object is added for connected parking spaces
       * Only virtual parking spaces should be taken into account
       */
      if (data.connected_parking_space != null) {
        if (data.parking_space_id == data.connected_parking_space) {
          this.listOPS.get(data.parking_space_id).next({
            last_change: data.last_change,
            monitoring_status: data.monitoring_status,
            state: this.convertState(data.state),
            psid: data.parking_space_id,
            plid: actualObject.plid,
            reservation_end: data.reservation_end,
            reservation_id: data.reservation_id,
            reservation_placed_by_user: data.reservation_placed_by_user,
            reservation_start: data.reservation_start
          });
        }
      } else {
        /**
         * default State Object handling
         */
        this.listOPS.get(data.parking_space_id).next({
          last_change: data.last_change,
          monitoring_status: data.monitoring_status,
          state: this.convertState(data.state),
          psid: data.parking_space_id,
          plid: actualObject.plid,
          reservation_end: data.reservation_end,
          reservation_id: data.reservation_id,
          reservation_placed_by_user: data.reservation_placed_by_user,
          reservation_start: data.reservation_start,
          connected_parking_space: data.connected_parking_space
        });
      }
    } else {
      /**
       * State Object is added for connected parking spaces
       * Only virtual parking spaces should be taken into account
       */
      if (data.connected_parking_space != null) {
        if (data.parking_space_id == data.connected_parking_space) {
          this.listOPS.set(
            data.parking_space_id,
            new BehaviorSubject({
              last_change: data.last_change,
              monitoring_status: data.monitoring_status,
              state: this.convertState(data.state),
              psid: data.parking_space_id,
              plid: plid,
              reservation_end: data.reservation_end,
              reservation_id: data.reservation_id,
              reservation_placed_by_user: data.reservation_placed_by_user,
              reservation_start: data.reservation_start,
              connected_parking_space: data.connected_parking_space
            })
          );
        }
      } else {
        /**
         * default State Object handling
         */
        this.listOPS.set(
          data.parking_space_id,
          new BehaviorSubject({
            last_change: data.last_change,
            monitoring_status: data.monitoring_status,
            state: this.convertState(data.state),
            psid: data.parking_space_id,
            plid: plid,
            reservation_end: data.reservation_end,
            reservation_id: data.reservation_id,
            reservation_placed_by_user: data.reservation_placed_by_user,
            reservation_start: data.reservation_start,
            connected_parking_space: data.connected_parking_space
          })
        );
      }
    }

    this.parkingLotDurations.next({
      max_parking_duration: data.max_parking_duration,
      soon_due_duration: data.due_warning_duration,
      overdue_duration: data.max_overdue_duration
    });
  }

  private convertState(state: string): PARKING_SPACE_STYLE_TYPE {
    switch (state) {
      case 'OCCUPIED':
        return PARKING_SPACE_STYLE_TYPE.OCCUPIED;
      case 'SOON_DUE':
        return PARKING_SPACE_STYLE_TYPE.SOON_DUE;
      case 'DUE':
        return PARKING_SPACE_STYLE_TYPE.DUE;
      case 'OVERDUE':
        return PARKING_SPACE_STYLE_TYPE.OVERDUE;
      case 'FREE':
        return PARKING_SPACE_STYLE_TYPE.FREE;
      case 'OCCUPIED_PRELIMINARY':
        return PARKING_SPACE_STYLE_TYPE.PRELIMINARY_DECISION;
      case 'IGNORED':
        return PARKING_SPACE_STYLE_TYPE.IGNORED;
      case 'DEAD_SHORT_OCCUPIED':
        return PARKING_SPACE_STYLE_TYPE.OCCUPIED;
      case 'DEAD_SHORT_FREE':
        return PARKING_SPACE_STYLE_TYPE.FREE;
      case 'NOT_INSTALLED':
        return PARKING_SPACE_STYLE_TYPE.NOT_INSTALLED;
      default:
        return PARKING_SPACE_STYLE_TYPE.DEFAULT;
    }
  }
  // #endregion

  // #region device_health
  updateDeviceHealth(object: WebSocketObject) {
    // console.log(object);
    switch (object.type) {
      case CURRENT_STATES:
        this.updateDeviceHealthInitial(object.data);
        break;
      case DEVICE_HEALTH_STATE:
        this.updateDeviceHealtContiniously(object.data);
        break;
      default:
        break;
    }
  }

  private updateDeviceHealthInitial(data: DeviceHealth[]) {
    if (data != null) {
      data.forEach((item) => {
        this.updateDeviceHealtContiniously(item);
      });
      this.listDeviceHealthReady.next(DEVICE_HEALTH_READY);
    }
  }

  private updateDeviceHealtContiniously(data: DeviceHealth) {
    if (this.listDeviceHealth.has(data.parking_space_id)) {
      this.listDeviceHealth.get(data.parking_space_id).next(data);
    } else {
      if (data.parking_space_id != undefined) {
        this.listDeviceHealth.set(data.parking_space_id, new BehaviorSubject(data));
      }
    }
  }
  // #endregion

  // #region sla_device_health
  updateSLADeviceHealth(object: WebSocketObject) {
    switch (object.type) {
      case CURRENT_STATES:
        this.updateSLADeviceHealthInitial(object.data);
        break;
      case SLA_DEVICE_HEALTH_STATE:
        this.updateSLADeviceHealtContiniously(object.data);
        break;
      default:
        break;
    }
  }

  private updateSLADeviceHealthInitial(data: SLADeviceHealth[]) {
    if (data != null) {
      data.forEach((item) => {
        this.updateSLADeviceHealtContiniously(item);
      });
      this.listIncidentsReady.next(SLA_DEVICE_HEALTH_READY);
    }
  }

  private updateSLADeviceHealtContiniously(data: SLADeviceHealth) {
    if (this.listSLADeviceHealth.getValue().has(data.parking_lot_id)) {
      this.listSLADeviceHealth.getValue().set(data.parking_lot_id, {
        parking_lot_id: data.parking_lot_id,
        total_sensors: data.total_sensors,
        down_medium_sensors: data.down_medium_sensors,
        down_long_sensors: data.down_long_sensors,
        detached_sensors: data.detached_sensors,
        broken_sensors: data.broken_sensors,
        timestamp: data.timestamp
      });
    } else {
      if (data.parking_lot_id != undefined) {
        this.listSLADeviceHealth.getValue().set(data.parking_lot_id, {
          parking_lot_id: data.parking_lot_id,
          total_sensors: data.total_sensors,
          down_medium_sensors: data.down_medium_sensors,
          down_long_sensors: data.down_long_sensors,
          detached_sensors: data.detached_sensors,
          broken_sensors: data.broken_sensors,
          timestamp: data.timestamp
        });
      }
    }
  }
  //#endregion

  //#region incidents
  updateIncidents(object: WebSocketObject) {
    switch (object.type) {
      case INCIDENTS_STATE_INITIAL:
        this.updateIncidentsInitial(object.data);
        break;
      case INCIDENTS_STATE_UPDATE:
        this.updateIncidentsContiniously(object.data);
        break;
      default:
        break;
    }
  }

  private updateIncidentsInitial(data: Incidents[]) {
    if (data != null) {
      data.forEach((item) => {
        this.updateIncidentsContiniously(item);
      });
      this.listIncidentsReady.next(INCIDENTS_READY);
    }
  }

  private updateIncidentsContiniously(data: Incidents) {
    if (this.listIncidents.getValue().has(data.parking_lot_id)) {
      this.listIncidents.getValue().set(data.parking_lot_id, {
        parking_lot_id: data.parking_lot_id,
        start: data.start,
        end: data.end,
        comment: data.comment,
        priority: data.priority,
        id: data.id,
        deadline_reaction: data.deadline_reaction,
        deadline_resolution: data.deadline_resolution,
        paused_since: data.paused_since,
        vandalism: data.vandalism,
        external_maintenance: data.external_maintenance
      });
    } else {
      if (data.parking_lot_id != undefined) {
        this.listIncidents.getValue().set(data.parking_lot_id, {
          parking_lot_id: data.parking_lot_id,
          start: data.start,
          end: data.end,
          comment: data.comment,
          priority: data.priority,
          id: data.id,
          deadline_reaction: data.deadline_reaction,
          deadline_resolution: data.deadline_resolution,
          paused_since: data.paused_since,
          vandalism: data.vandalism,
          external_maintenance: data.external_maintenance
        });
      }
    }
  }
  //#endregion

  setCustomerPLID(customerPLIDList: Array<CustomerPLIDModel>) {
    this.listCustomerPLID = customerPLIDList;
  }

  resetBehaviorSubjects() {
    this.listOPL = new BehaviorSubject(new Map());
    this.listCameraOPL = new BehaviorSubject(new Map());
    this.listOPS = new Map();
    this.listDeviceHealth = new Map();
    this.listDeviceHealthReady = new Subject();
    this.listSLADeviceHealth = new BehaviorSubject(new Map());
    this.listSLADeviceHealthReady = new Subject();
    this.listIncidents = new BehaviorSubject(new Map());
    this.listIncidentsReady = new Subject();
  }

  resetListOPS() {
    this.listOPS = new Map();
  }
}
