import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TWO_FACTOR_INPUT_STATUS } from '@frontend-monorepo/common';
import { Variable } from 'apps/customer-dashboard/src/app/helper/variable';
import { TotpToken } from 'libs/core/src/lib/repository/auth/models/totp-token';
import { AuthService } from 'libs/core/src/lib/services/auth.service';
import { RepositoryService } from 'libs/core/src/lib/services/repository/repository.service';

@Component({
  selector: 'frontend-monorepo-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.scss']
})
export class TwoFactorAuthenticationComponent implements OnInit {
  totpCodeForm: FormGroup;

  constructor(
    private repositoryService: RepositoryService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    this.totpCodeForm = this.formBuilder.group({
      twoFactorCode: this.formBuilder.control('', Validators.required)
    });
  }

  ngOnInit(): void {}

  get2FA_status(status: TWO_FACTOR_INPUT_STATUS) {
    if (status === TWO_FACTOR_INPUT_STATUS.INPUT_SUCCESS) {
      this.navigateToHomeScreen();
    }
  }

  navigateToHomeScreen(): void {
    if (localStorage.getItem(Variable.LIST_SELECTION_URL) != null) {
      this.router.navigateByUrl(localStorage.getItem(Variable.LIST_SELECTION_URL));
    } else {
      this.router.navigateByUrl('landing/parking-violations');
    }
  }

  authenticateUsingTotp(): void {
    this.repositoryService
      .authRepository()
      .postAuthTotp(this.totpCodeForm.get('totp_code').value)
      .subscribe({
        next: (resp: TotpToken) => {
          if (resp != undefined) {
            this.authService.setTwoFactorToken(resp.two_factor_token);
          }
        }
      });
  }
}
