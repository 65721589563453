<div *transloco="let t" fxLayout="column" >
  <h2>{{ t('header') }}</h2>

  <p>{{ t('description') }}</p>
  <p>{{ t('choose_parkinglot') }}</p>

  <div fxLayout="row" style="padding: 10px 16px 10px 0px">
    <div fxFlex="94" fxLayoutAlign="end center">
      {{ t('select_all') }}
    </div>
    <div fxFlex="6" fxLayoutAlign="center center">
      <mat-checkbox
        color="primary"
        [(indeterminate)]="selectAllIndeterminateState"
        [checked]="selectAllState"
        (change)="selectAll($event.checked)"
      >
      </mat-checkbox>
    </div>
  </div>

  <div *ngIf="presetParkinglotList.length > 0" fxLayout="row">
    <mat-list class="list-item">
      <mat-list-item class="list-item-box" *ngFor="let item of presetParkinglotList">
        <div fxFlex="94" fxLayout="row" fxLayoutGap="5px">
          <div>{{ item.parking_lot_id }}:</div>
          <div>{{ item.parking_lot_name }}</div>
        </div>
        <div fxFlex="6" fxLayout="column" fxLayoutAlign="end center">
          <div >
            <mat-checkbox
              color="primary"
              [checked]="singleItemState"
              (change)="selectParkinglot($event.checked, item)"
            ></mat-checkbox>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>

  <div fxLayout="column" class="selection-overview">
    <div fxFlexAlgin="end">{{ selectedParkingLots.length }} {{ t('chosen_parkinglot') }}</div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between" class="button-wrapper">
    <button mat-flat-button color="cancel" (click)="close()">{{ t('cancel') }}</button>
    <button mat-flat-button color="primary" (click)="applyForAllPreset()">
      {{ applySettingText }}
    </button>
  </div>
</div>
