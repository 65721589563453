<div *transloco="let t">
  <div *ngIf="showHeader">
    <h4>{{ t('date_range') }}</h4>
  </div>

  <div fxLayout="column">
    <form [formGroup]="dateInputForm">
      <div>
        <mat-form-field>
          <input
            matInput
            [max]="maxDate"
            [min]="minDate"
            placeholder="{{ t('date_range_placeholder') }}"
            [satDatepicker]="picker"
            formControlName="date"
            autocomplete="off"
            (click)="picker.open()"
          />
          <sat-datepicker
            #picker
            [rangeMode]="true"
            [rangeHoverEffect]="true"
            [closeAfterSelection]="true"
          >
          </sat-datepicker>
          <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
        </mat-form-field>
      </div>
    </form>
    <mat-error
      id="dateInputError"
      class="mat-caption"
      *ngIf="this.dateInputForm.get('date').hasError('dateInputError')"
    >
      {{ t('range_exceeds_2months') }}</mat-error
    >
  </div>
</div>
