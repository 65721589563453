<div
  [@fadeInOut]="close"
  id="overlayingSidebarContainer"
  *transloco="let t; read: 'overlayingSidebar'"
>
  <button
    id="closeSidebarButton"
    mat-icon-button
    (click)="closeSidebar()"
    (touchstart)="closeSidebar()"
    [attr.aria-label]="t('closeSidebarButtonTitle')"
    title="{{ t('closeSidebarButtonTitle') }}"
  >
    <mat-icon>close</mat-icon>
  </button>
  <ng-container #contentRef></ng-container>
</div>
