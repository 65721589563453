import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HeatmapDataService } from 'libs/heatmap-ui/src/lib/services/local-data.service';
import { Subject, Subscription } from 'rxjs';


/**
 * Reusable Component displaying progress bar over the length of the whole page 
 * Simply add:
 * @example
 * `<frontend-monorepo-loading-progress-indicator 
 *   [loadingListener]="subject"
 * ></frontend-monorepo-loading-progress-indicator>`
 * to your Component
 */

@Component({
  selector: 'frontend-monorepo-loading-progress-indicator',
  templateUrl: './loading-progress-indicator.component.html',
  styleUrls: ['./loading-progress-indicator.component.scss']
})
export class LoadingProgressIndicatorComponent implements OnInit, OnDestroy {
  /**
   * Subject holding loading status
   */
  @Input() loadingListener: Subject<boolean>;

  showProgressBar = true;
  subscription = new Subscription();

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscription = this.loadingListener.subscribe((status) => {
      if (status) {
        this.showProgressBar = true;
        this.cd.detectChanges();
      } else {
        this.showProgressBar = false;
        this.cd.detectChanges();
      }
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
