<ng-container *transloco="let t">
  {{ t('reset_confirmation') }}
  <div
    mat-dialog-actions
    fxLayoutAlign="space-between"
    style="padding-top: 1rem;"
  >
    <button
      id="button"
      mat-flat-button
      color="cancel"
      (click)="_cancelAction()"
      title="{{ t('cancel') }}"
    >
      {{ t('cancel') }}
    </button>
    <span id="flex-spacer"></span>
    <button
      mat-flat-button
      color="warn"
      title="{{ t('confirm') }}"
      (click)="_confirm()"
    >
      {{ t('confirm') }}
    </button>
  </div>
</ng-container>
