<div id="circleArea">
  <svg class="empty" viewBox="-2.5 -2.5 41 41">
    <path class="empty" d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831" />
    <path [style.stroke]="color" [style.stroke-dasharray]="percentage +', 100'" class="filling" d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831" />
  </svg>
</div>
<div id="labelArea">
  <span>{{label}}</span>
</div>
<div id="percentageArea">{{percentage}}%</div>
<div id="subTitleArea">{{subTitle}}</div>