<div class="login mat-elevation-z3 mp-widget" *transloco="let t">
  <h3>{{ t('2fa_input_label') }}</h3>
  <div fxLayout="column" fxLayoutGap="10px">
    <div>{{ t('2fa_description') }}</div>

    <form [formGroup]="totpCodeForm" class="mp-widget">
      <mat-form-field>
        <mat-label>{{ t('2fa_code_label') }}</mat-label>
        <input
          send2FACode action="login"
          (responseStatus)="get2FA_status($event)"
          type="number"
          matInput
          placeholder="{{ t('2fa_code_label') }}"
          formControlName="twoFactorCode"
          required
        /> 
      </mat-form-field>
    </form>
    <button mat-flat-button color="primary" (click)="authenticateUsingTotp()">
      {{ t('send') }}
    </button>
  </div>
</div>
