export class Failure {
  public apiError: ApiError | undefined;
  public errorMessage: string;

  constructor(errorCode: number, errorMessage: string) {
    this.apiError = this.apiErrorMap.get(errorCode);
    this.errorMessage = errorMessage;
  }

  private apiErrorMap: Map<number, ApiError> = new Map([
    [400, ApiError.badRequest],
    [401, ApiError.unauthorized],
    [403, ApiError.forbidden],
    [404, ApiError.notFound],
    [405, ApiError.notAllowed],
    [408, ApiError.timeout],
    [409, ApiError.conflict],
    [413, ApiError.payloadToLarge],
    [415, ApiError.unsupportedMediaType],
    [417, ApiError.expectationFailed],
    [429, ApiError.tooManyRequests],
    [500, ApiError.internalServerError]
  ]);
}

export enum ApiError {
  badRequest,
  unauthorized,
  forbidden,
  notFound,
  notAllowed,
  timeout,
  conflict,
  payloadToLarge,
  unsupportedMediaType,
  expectationFailed,
  tooManyRequests,
  internalServerError,
  unexpected,
  parsingError
}