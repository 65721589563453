import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL, AuthService, CoreApiService, ParkingLot } from '@frontend-monorepo/core';
import { ParkingLotMap } from '@frontend-monorepo/parking-lot-map';
import { Observable } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Failure } from '../http-response/failure.model';
import { DeviceObject } from './models/device-object.model';
import { DeviceSpaces } from './models/device-spaces.model';
import { ParkingLotBase } from './parking-lot.base';

export class ParkingLotRepository extends ParkingLotBase {
  constructor(private http: HttpClient, private authService: AuthService) {
    super();
  }

  /**
   * Retrieves a JSON representation of the parking lot and its parking spaces the user is authenticated for.
   * @requires parking_lot_permission(s): `access`
   * @param parkingLotId Id of parking lot
   * @returns `ParkingLotMap`
   */
  fetchParkingLotMap(parkingLotId: number): Observable<ParkingLotMap> {
    return this.http.get<ParkingLotMap>(API_BASE_URL + `parkinglots/${parkingLotId}/map-json`).pipe(
      catchError((error: HttpErrorResponse) => {
        throw new Failure(error.status, error.error);
      })
    );
  }

  /**
   * Gets a map of device space ids to installed device objects the user is authenticated for.
   * @requires parking_lot_permission(s): `access`
   * @param parkingLotId Id of parking lot
   * @returns Map of `DeviceObject`-related data for each device space id
   */
  fetchAllDeviceSpacesByPlid(parkingLotId: number): Observable<Array<DeviceSpaces>> {
    return this.http
      .get<Array<DeviceSpaces>>(API_BASE_URL + `parkinglots/${parkingLotId}/devicespaces`)
      .pipe(
        map((list: Array<DeviceSpaces>) =>
          list.filter((_item) => _item.device_space_type === 'camera')
        ),
        catchError((error: HttpErrorResponse) => {
          throw new Failure(error.status, '404_parking_lot_not_exist');
        })
      );
  }

  /**
   * Fetches all parking lots the user is authenticated for.
   * @requires parking_lot_permission(s): `install`
   * @param amountOfRetries The amount of retries after failing requests before returning an error.
   * @returns List of `ParkingLot`
   */
  fetchParkingLots(): Observable<Array<ParkingLot>> {
    return this.http.get<Array<ParkingLot>>(API_BASE_URL + 'parkinglots').pipe(
      retry(5),
      catchError((error: HttpErrorResponse) => {
        throw new Failure(error.status, error.message);
      })
    );
  }
}
