import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Send2FACodeDirective } from './send-2-fa-code.directive';



@NgModule({
  declarations: [Send2FACodeDirective],
  imports: [
    CommonModule
  ],
  exports: [
    Send2FACodeDirective
  ]
})
export class Send2faCodeModule { }
