import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ParkingSpace, AuthService } from '@frontend-monorepo/core';
import { Variables } from '../pages/admin/variables';
import { UserModel } from '../pages/admin/sub-user-management/models/user-model.models';
import {
  AccountInfo,
  OverdueSubscription,
  User,
  ParkingLotInfo,
  AnalysisModel,
  ContractActiveModel,
  CustomerInfo,
  SettingChange,
  Reservation,
  ReservationSettings,
  CustomerParkingLot,
  ApiKey,
  ApiKeyStatus,
  OverdueHistoryData,
  ParkingOperationHistoryData,
  ParkingLotLPD,
  WhitelistedLicensePlate,
  CameraParkingOperationsDue,
  ParkingOperationImage,
  ParkingLotModel
} from './models';

@Injectable({ providedIn: 'root' })
export class Networking {
  constructor(private httpClient: HttpClient) {}

  // --------------------------------- get requests -----------------------------------
  fetchAccountInformationApiKey(apikey: string): Observable<AccountInfo> {
    let url = 'https://api.parking-pilot.com/account';
    return this.httpClient
      .get<AccountInfo>(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'x-api-key': apikey
        }
      })
      .pipe(retry(5));
  }

  fetchOverdueSubscriptions(id: number): Observable<Array<OverdueSubscription>> {
    let url = 'https://api.parking-pilot.com/parkinglots/' + id + '/overdue-subscriptions';
    return this.httpClient.get<Array<OverdueSubscription>>(url).pipe(retry(5));
  }

  fetchOverdueData(id: number, start: number, end: number): Observable<OverdueHistoryData[]> {
    let url =
      'https://api.parking-pilot.com/parkingspaces/' +
      id +
      '/parking-operations/due?start=' +
      start +
      '&end=' +
      end;
    return this.httpClient.get<OverdueHistoryData[]>(url);
  }

  fetchParkingOperationData(
    id: number,
    start: number,
    end: number
  ): Observable<ParkingOperationHistoryData[]> {
    let url =
      'https://api.parking-pilot.com/parkinglots/' +
      id +
      '/parking-operations?start=' +
      start +
      '&end=' +
      end;
    return this.httpClient.get<ParkingOperationHistoryData[]>(url).pipe(retry(5));
  }

  fetchAllParkingSpaces(): Observable<Array<ParkingSpace>> {
    let url = 'https://api.parking-pilot.com/parkingspaces';
    return this.httpClient.get<Array<ParkingSpace>>(url).pipe(retry(5));
  }

  fetchAllParkinglots(): Observable<Array<ParkingLotModel>> {
    let url = 'https://api.parking-pilot.com/parkinglots';
    return this.httpClient.get<Array<ParkingLotModel>>(url);
  }

  fetchChildUsers(username: string, page: number): Observable<Array<User>> {
    var url = Variables.SEARCH_USER_URL + username + Variables.SEARCH_USER_PAGE + page;
    return this.httpClient.get<Array<User>>(url);
  }

  searchAccount(username: string): Observable<Array<User>> {
    let url = `https://api.parking-pilot.com/account/search?query=${encodeURIComponent(username)}`;
    return this.httpClient.get<User[]>(url);
  }

  fetchParkingSpace(psid: number): Observable<ParkingSpace> {
    let url = 'https://api.parking-pilot.com/parkingspaces/' + psid;
    return this.httpClient.get<ParkingSpace>(url);
  }

  fetchParkingLot(plid: number): Observable<ParkingLotInfo> {
    let url = 'https://api.parking-pilot.com/parkinglots/' + plid;
    return this.httpClient.get<ParkingLotInfo>(url);
  }

  fetchAccountExists(username: string): Observable<boolean> {
    let url = 'https://api.parking-pilot.com/account/exists?username=' + username;
    return this.httpClient.get<boolean>(url);
  }

  fetchParkingSpacesOfPLApiKey(plid: number, apikey?: string): Observable<ParkingSpace[]> {
    let url = 'https://api.parking-pilot.com/parkinglots/' + plid + '/parkingspaces';
    return this.httpClient.get<ParkingSpace[]>(url);
  }

  fetchMidnightReset(plid: number): Observable<number[]> {
    let url = 'https://api.parking-pilot.com/parkinglots/' + plid + '/midnight-monitoring-reset';
    return this.httpClient.get<number[]>(url).pipe(retry(5));
  }

  fetchDueAnalysis(): Observable<AnalysisModel[]> {
    let url = 'https://api.parking-pilot.com/analysis/due/user';
    return this.httpClient.get<AnalysisModel[]>(url).pipe(retry(5));
  }

  fetchAnalysisData(date: string, type: string): Observable<any> {
    let params = new HttpParams().set('analysis_date', date).set('analysis_type', type);

    let url =
      'https://api.parking-pilot.com/analysis/files?analysis_date=' +
      date +
      '&analysis_type=' +
      type;
    return this.httpClient.get(url, {
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      })
    });
  }

  fetchContractAnalysisActive(): Observable<ContractActiveModel> {
    let url = 'https://api.parking-pilot.com/account/contract/active?contract_name=analysis';
    return this.httpClient.get<ContractActiveModel>(url).pipe(retry(5));
  }

  fetchCustomerInfo(): Observable<CustomerInfo> {
    let url = 'https://api.parking-pilot.com/user/customer';
    return this.httpClient.get<CustomerInfo>(url);
  }

  fetchSettingChanges(plid: number, start: number, end: number): Observable<SettingChange[]> {
    let url =
      'https://api.parking-pilot.com/parkinglots/' +
      plid +
      '/settings-change-logs?start=' +
      start +
      '&end=' +
      end;
    return this.httpClient.get<SettingChange[]>(url);
  }

  fetchReservation(psid: number): Observable<Reservation[]> {
    let url = 'https://api.parking-pilot.com/parkingspaces/' + psid + '/reservation';
    return this.httpClient.get<Reservation[]>(url);
  }

  fetchAllReservation(plid: number): Observable<Reservation[]> {
    let url = 'https://api.parking-pilot.com/parkinglots/' + plid + '/reservations';
    return this.httpClient.get<Reservation[]>(url);
  }

  fetchReservationSettings(plid: number): Observable<ReservationSettings> {
    let url = 'https://api.parking-pilot.com/parkinglots/' + plid + '/reservation-settings';
    return this.httpClient.get<ReservationSettings>(url);
  }

  fetchCustomerParkinglots(): Observable<CustomerParkingLot[]> {
    let url = 'https://api.parking-pilot.com/parking-lot-owner/own';
    return this.httpClient.get<CustomerParkingLot[]>(url).pipe(retry(5));
  }

  fetchApiKey(): Observable<ApiKeyStatus> {
    let url = 'https://api.parking-pilot.com/api-key';
    return this.httpClient.get<ApiKeyStatus>(url).pipe(retry(5));
  }

  fetchParkingspaceMapping(plid: number): Observable<Map<string, number>> {
    let url = 'https://api.parking-pilot.com/parkinglots/' + plid + '/map-mapping';
    return this.httpClient.get<Map<string, number>>(url).pipe(retry(5));
  }

  fetchWhitelistedLicensePlate(plid: number): Observable<Array<WhitelistedLicensePlate>> {
    let url = 'https://api.parking-pilot.com/parkinglots/' + plid + '/cameras/whitelist';
    return this.httpClient.get<Array<WhitelistedLicensePlate>>(url).pipe(retry(5));
  }

  fetchCameraParkingOperations(
    plid: number,
    start?: number,
    end?: number,
    format?: string
  ): Observable<HttpResponse<Blob>> {
    let url = 'https://api.parking-pilot.com/parkinglots/' + plid + '/cameras/analysis/due';

    let params = new HttpParams()
      .append('start', start.toString())
      .append('end', end.toString())
      .append('format', format);

    return this.httpClient.get<Blob>(url, {
      params: params,
      observe: 'response',
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        "Accept": 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    });
    // return this.httpClient.get<Blob>(url, {
    //   params: params,
    //   headers: new HttpHeaders({
    //     Accept: 'application/json',
    //     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    //   }
    //   )
    // });
  }

  async fetchCameraParkingOperationsDue(): Promise<Map<number, CameraParkingOperationsDue[]>> {
    let url = 'https://api.parking-pilot.com/parkinglots/cameras/parking-operations/due';
    const data = await this.httpClient.get<Map<number, string[]>>(url).toPromise();
    const map: Map<number, CameraParkingOperationsDue[]> = new Map();
    for (const [key, value] of Object.entries(data)) {
      map.set(+key, value);
    }
    return map;
  }

  fetchCameraParkingOperationsDueByPLID(plid: number): Observable<CameraParkingOperationsDue[]> {
    let url =
      'https://api.parking-pilot.com/parkinglots/' + plid + '/cameras/parking-operations/due';
    return this.httpClient.get<CameraParkingOperationsDue[]>(url).pipe(retry(5));
  }

  fetchParkingOperationImages(po_id: number): Observable<ParkingOperationImage[]> {
    let url = 'https://api.parking-pilot.com/cameras/parking-operations/' + po_id + '/images';
    return this.httpClient.get<ParkingOperationImage[]>(url).pipe(retry(5));
  }

  // --------------------------------- put requests -----------------------------------
  putCreateUser(json: string, parentID?: number): Observable<UserModel> {
    let url = 'https://api.parking-pilot.com/account/interface?parent_account_id=' + parentID;
    return this.httpClient.put<UserModel>(url, json);
  }

  resetPutCreateUser(json: string): Observable<UserModel> {
    let url = 'https://api.parking-pilot.com/account/interface';
    return this.httpClient.put<UserModel>(url, json).pipe(retry(5));
  }

  putOverdueSubscription(json: string, id: number): Observable<any> {
    let url = 'https://api.parking-pilot.com/parkinglots/' + id + '/overdue-subscriptions';
    return this.httpClient.put<any>(url, json).pipe(retry(5));
  }

  putPermissionToUser(id: number, json: string): Observable<any> {
    let url = 'https://api.parking-pilot.com/account/' + id + '/parking-lot-permissions';
    return this.httpClient.put<any>(url, json).pipe(retry(5));
  }

  putCustomerPLID(
    plid: number,
    customer_parking_lot_id: number,
    _delete: boolean
  ): Observable<any> {
    let url = _delete
      ? 'https://api.parking-pilot.com/parkinglots/' + plid + '/customer-parking-lot-id'
      : 'https://api.parking-pilot.com/parkinglots/' +
        plid +
        '/customer-parking-lot-id?customer_parking_lot_id=' +
        customer_parking_lot_id;
    return this.httpClient.put<any>(url, '', {
      headers: {
        Accept: 'application/json'
      }
    });
  }

  putReservationSettings(
    plid: number,
    check_current_occupancy: boolean,
    default_time: number
  ): Observable<any> {
    let url =
      'https://api.parking-pilot.com/parkinglots/' +
      plid +
      '/reservation-settings?default_reservation_time=' +
      default_time +
      '&check_current_occupancy=' +
      check_current_occupancy;
    return this.httpClient.put<any>(url, '', {
      headers: {
        Accept: 'application/json'
      }
    });
  }

  putDurationSettings(parkingLot: ParkingLotLPD): Observable<any> {
    let url = 'https://api.parking-pilot.com/' + `parkinglots/${parkingLot.id}/duration-settings`;

    return this.httpClient.put(
      url,
      {},
      {
        headers: {
          Accept: 'application/json'
        },
        params: {
          max_parking_duration: parkingLot.max_parking_duration.toString()
        }
      }
    );
  }

  putConfirmParkingOperationById(po_id: number, json: string): Observable<any> {
    let url = 'https://api.parking-pilot.com/cameras/parking-operations/' + po_id + '/confirm';
    return this.httpClient.put(url, json);
  }

  putUserPermissionById(user_id: number, json: string): Observable<any> {
    let url = 'https://api.parking-pilot.com/account/' + user_id + '/user-permissions';
    return this.httpClient.put(url, json);
  }

  putContractById(user_id: number, contract: string) {
    let url = 'https://api.parking-pilot.com/account/' + user_id + '/contract?type=' + contract;
    return this.httpClient.put(url, {});
  }

  // --------------------------------- post requests -----------------------------------
  postChangePasswordFromChild(oldPW: string, newPW: string, token: string): Observable<any> {
    let url = 'https://api.parking-pilot.com/account/change-password';
    let body = {
      current_password: oldPW,
      new_password: newPW
    };
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': token
      })
    };
    // console.log(token)
    return this.httpClient.post<any>(url, body, httpOptions);
  }

  postChangePassword(old: string, newPW: string): Observable<any> {
    let body =
      'currentPassword=' + encodeURIComponent(old) + '&newPassword=' + encodeURIComponent(newPW);
    let url = 'https://api.parking-pilot.com/account/change-password';
    return this.httpClient
      .post<any>(url, body, {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        })
      })
      .pipe(retry(5));
  }

  postSetMidnightReset(plid: number): Observable<any> {
    let url = 'https://api.parking-pilot.com/parkinglots/' + plid + '/midnight-monitoring-reset';
    return this.httpClient
      .post<any>(url, '', {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        })
      })
      .pipe(retry(5));
  }

  postSetReservation(psid: Number, startDate: String, endDate: String): Observable<any> {
    let url =
      'https://api.parking-pilot.com/parkingspaces/' +
      psid +
      '/reservation?start_date=' +
      startDate +
      '&end_date=' +
      endDate;
    return this.httpClient.post<any>(url, '', {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      })
    });
  }

  postApiKey(): Observable<ApiKey> {
    let url = 'https://api.parking-pilot.com/api-key';
    return this.httpClient.post<ApiKey>(url, '', {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      })
    });
  }

  postLicensePlate(plid: number, json: string): Observable<any> {
    let url = 'https://api.parking-pilot.com/parkinglots/' + plid + '/cameras/whitelist';
    return this.httpClient.post<any>(url, json, {
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    });
  }

  postPersistManualConfirmation(): Observable<number> {
    let url = 'https://api.parking-pilot.com/cameras/persist-manual-confirmation';
    return this.httpClient.post<number>(url, '', {
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    });
  }

  // --------------------------------- delete requests -----------------------------------

  deleteOverdueSubscription(email: String, id: number): Observable<any> {
    let url =
      'https://api.parking-pilot.com/parkinglots/' + id + '/overdue-subscriptions/?email=' + email;
    return this.httpClient.delete<any>(url).pipe(retry(5));
  }

  deleteUser(id: number): Observable<any> {
    let url = 'https://api.parking-pilot.com/account/' + id;
    return this.httpClient.delete<any>(url).pipe(retry(5));
  }

  deleteMidnightReset(plid: number): Observable<any> {
    let url = 'https://api.parking-pilot.com/parkinglots/' + plid + '/midnight-monitoring-reset';
    return this.httpClient.delete<any>(url).pipe(retry(5));
  }

  deleteReservation(reservation_id: number): Observable<any> {
    let url = 'https://api.parking-pilot.com/reservation/' + reservation_id;
    return this.httpClient.delete<any>(url);
  }

  deleteApiKey(): Observable<any> {
    let url = 'https://api.parking-pilot.com/api-key';
    return this.httpClient.delete<any>(url);
  }

  deleteWhitelistLicensePlate(plid: number, license_plate: string): Observable<any> {
    let url =
      'https://api.parking-pilot.com/parkinglots/' +
      plid +
      '/cameras/whitelist/?license_plate=' +
      license_plate;
    return this.httpClient.delete<any>(url);
  }

  fetchETag(): Observable<HttpResponse<Object>> {
    let url = 'https://dashboard-testing-edf2f.web.app/ngsw.json';
    // let url = "https://dashboard-testing-edf2f.web.app/ngsw.json" + "?ngsw-cache-bust=" + Math.random();
    // this.http.post(url, data, {observe: 'response'}).subscribe((res:Response) => {});
    // const res = await this.safeFetch(this.adapter.newRequest('ngsw.json?ngsw-cache-bust=' + Math.random()));
    return this.httpClient.get<any>(url, { observe: 'response' });
  }
}
