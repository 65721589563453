<div
  #divContainer
  style="position: absolute; width: 100%; height: 100%"
  *ngIf="!map_loading_error; else error_message"
>
  <pan-zoom [config]="panzoomConfig">
    <div
      #mapContainer
      id="mapContainer"
      [style.width]="mapWidth + 'px'"
      [style.height]="mapHeight + 'px'"
    >
      <ng-container *ngIf="parkingLotMap !== undefined">
        <!-- deviceSpace -->
        <ng-container *ngIf="showDeviceSpaces">
          <frontend-monorepo-device-space
            *ngFor="let deviceSpace of activeDeck.deviceSpaces"
            [deviceSpace]="deviceSpace"
          >
          </frontend-monorepo-device-space>
        </ng-container>

        <!--parkingspaces-->
        <mp-parking-space
          *ngFor="let parkingSpace of activeDeck.parkingSpaces"
          [parkingSpaceMapInfo]="parkingSpace"
          [dashboard_tag]="dashboard_tag"
          [optionalParkingSpaceType]="optionalParkingSpaceType"
          [heatmapGroupComparison]="heatmapGroupComparison"
        >
        </mp-parking-space>

        <!--drawables-->
        <ng-container *ngFor="let drawable of activeDeck.drawables">
          <mp-drawables
            [drawable]="drawable"
            [heatmapGroupComparison]="heatmapGroupComparison"
          ></mp-drawables>
        </ng-container>

        <!--labels-->
        <frontend-monorepo-label
          *ngFor="let label of activeDeck.labels"
          [label]="label"
        ></frontend-monorepo-label>
      </ng-container>
    </div>
  </pan-zoom>
</div>

<div style="margin-top: 0.8rem; margin-left: 0.8rem" *transloco="let t; read: 'parkingLotMap'">
  <button
    id="homeButton"
    mat-raised-button
    color="primary"
    mat-fab
    [attr.aria-label]="t('resetParkingLotMapPositionButtonTitle')"
    title="{{ t('resetParkingLotMapPositionButtonTitle') }}"
    (click)="resetParkingLotMapPosition()"
  >
    <mat-icon>home</mat-icon>
  </button>
</div>
<div style="margin-top: 0.6rem; margin-left: 0.8rem">
  <mp-switch-parking-lot-levels-fab
    *ngIf="parkingLotMap !== undefined && parkingLotMap.levels.length > 1"
    [levels]="parkingLotMap.levels"
    [layerViewActive]="layerViewActive"
    (activeDeckChange)="onActiveDeckChange($event)"
    [(activeDeck)]="activeDeck"
  ></mp-switch-parking-lot-levels-fab>
</div>

<ng-template #error_message>
  <div *transloco="let t;" class="error_wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div>{{t('error_message')}}</div> 
  </div>
</ng-template>

<mp-parking-lot-map-legend *ngIf="!checkParkingLotType()"></mp-parking-lot-map-legend>
