import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API_BASE_URL, Failure } from '@frontend-monorepo/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CommentLog } from './models/parking-space-comment-log.model';
import { Comment } from './models/parking-space-comment.model';
import { ParkingSpaceCommentsBase } from './parking-space-comments.base';

export class ParkingSpaceCommentRepository extends ParkingSpaceCommentsBase {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Gives a device space comment of a device space
   * @param device_space_id id of device
   * @requires parking_lot_permission: access
   */
  getCommentbyDeviceSpaceId(device_space_id: number): Observable<Comment> {
    return this.http.get<Comment>(API_BASE_URL + `devicespaces/${device_space_id}/comment`).pipe(
      catchError((error: HttpErrorResponse) => {
        let message = 'getCommentError';
        if (error.status == 404) {
          message = 'device_not_found';
        }
        throw new Failure(error.status, message);
      })
    );
  }

  /**
   * Creates or updates the device space comment of a device space
   * @param device_space_id id of device
   * @param body json string containing actual comment and delete_on_free status
   * @requires parking_lot_permission: access
   */
  putCommentbyDeviceSpaceId(device_space_id: number, body: string): Observable<any> {
    return this.http
      .put<Comment>(API_BASE_URL + `devicespaces/${device_space_id}/comment`, body)
      .pipe(
        retry(3),
        catchError((error: HttpErrorResponse) => {
          let message = 'getCommentError';
          if (error.status == 400) {
            message = 'comment_length_error';
          } else if (error.status == 404) {
            message = 'device_not_found';
          }
          throw new Failure(error.status, message);
        })
      );
  }

  /**
   * Deletes a device space comment of a device space
   * @param device_space_id id of device
   * @requires parking_lot_permission: access
   */
  deleteCommentbyDeviceSpaceId(device_space_id: number): Observable<any> {
    return this.http.delete<Comment>(API_BASE_URL + `devicespaces/${device_space_id}/comment`).pipe(
      retry(3),
      catchError((error: HttpErrorResponse) => {
        let message = 'deleteCommentError';
        if (error.status == 404) {
          message = 'device_not_found';
        }
        throw new Failure(error.status, message);
      })
    );
  }

  /**
   * Gives a all device space comment of the given parking lot
   * @param parking_lot_id id of parking lot
   * @requires parking_lot_permission: access
   */
  getAllCommentbyParkingLot(parking_lot_id: number): Observable<Comment[]> {
    return this.http
      .get<Comment[]>(API_BASE_URL + `parkinglots/${parking_lot_id}/devicespaces/comments`)
      .pipe(
        retry(3),
        catchError((error: HttpErrorResponse) => {
          let message = 'getCommentError';
          if (error.status == 404) {
            message = 'parking_lot_not_found';
          }
          throw new Failure(error.status, message);
        })
      );
  }

  /**
   * Gives a all device space comment logs of the given parking lot
   * @param parking_lot_id id of parking lot
   * @requires parking_lot_permission: access
   */
  getCommentLogByParkinglot(parking_lot_id: number, start: number, end: number): Observable<CommentLog[]> {
    return this.http
    .get<CommentLog[]>(API_BASE_URL + `parkinglots/${parking_lot_id}/devicespaces/comment-log?start=${start}&end=${end}`)
    .pipe(
      retry(3),
      catchError((error: HttpErrorResponse) => {
        let message = 'getCommentError';
        if (error.status == 404) {
          message = 'parking_lot_not_found';
        }
        throw new Failure(error.status, message);
      })
    );
  }
}
