import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { WEEKDAY_DISPLAY } from 'libs/core/src/lib/utils/enums/weekday.enum';

/**
 * Displays a single color bar UI without any logic in {@link PresetSummaryComponent} with its preset settings
 *
 * @example
 * `<frontend-monorepo-preset-color-bar
 *  [hourArray]="yourArray"
 *  [day]="yourDay">
 * </frontend-monorepo-preset-color-bar>`
 */
@Component({
  selector: 'frontend-monorepo-preset-color-bar',
  templateUrl: './preset-color-bar.component.html',
  styleUrls: ['./preset-color-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresetColorBarComponent implements OnInit, OnChanges {
  // takes in hourArray and day as Input and displays wording and color accordingly
  @Input() hourArray: string[];
  @Input() day: number;

  dayInString: string;
  hourArrayDisplay: string[];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hourArray) {
      this.hourArrayDisplay = this.hourArray;
      this.cd.detectChanges();
    }
  }

  ngOnInit(): void {
    this.dayInString = WEEKDAY_DISPLAY[this.day];

    if (this.hourArray) {
      this.hourArrayDisplay = this.hourArray;
      this.cd.detectChanges();
    }
  }
}
