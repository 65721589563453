<ng-container *transloco="let t; read: 'DASHBOARD_V2_NOTIFICATION'">
  <div *ngIf="!url.includes('iframe')">
    <div
      *ngIf="!minimized"
      class="container"
      [@minimize]="animation"
      (@minimize.done)="onMinimizeEnd()"
    >
      <div *ngIf="animation !== 'min'" class="inner_container">
        <div class="color_rocket"></div>
        <div class="text-content">
          <div class="headline">{{ t('header') }}</div>
          <div class="description">
            {{ t('description') }}
          </div>
        </div>
        <div
          class="rocket"
          (mouseenter)="onMouseEnter($event)"
          (mouseleave)="onMouseLeave($event)"
          (click)="onClick($event)"
        ></div>
        <div class="footer" (click)="onMinimize()">{{ t('minimize') }}</div>
      </div>
    </div>

    <div *ngIf="minimized">
      <frontend-monorepo-rocket-fab></frontend-monorepo-rocket-fab>
    </div>
  </div>
</ng-container>
