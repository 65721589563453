import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API_BASE_URL, Failure } from '@frontend-monorepo/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ParkingSpace } from './models/parking-space.model';
import { ParkingSpacesBase } from './parking-spaces.base';

export class ParkingSpacesRepository extends ParkingSpacesBase {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Fetching all parking spaces associated with a parking lot.
   * @param parkingLotID ID of a parking lot, whichs parking spaces should be fetched.
   * @param amountOfRetries The amount of retries after failing requests before returning an error.
   */
  fetchParkingSpacesOfPL(
    parkingLotID: number,
    amountOfRetries: number = 0
  ): Observable<Array<ParkingSpace>> {
    return this.http
      .get<Array<ParkingSpace>>(API_BASE_URL + `parkinglots/${parkingLotID}/parkingspaces`)
      .pipe(
        retry(amountOfRetries),
        catchError((error: HttpErrorResponse) => {
          throw new Failure(error.status, error.message);
        })
      );
  }
}
