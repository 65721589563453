import { Observable } from 'rxjs';
import { Failure } from '../http-response/failure.model';
import { ChargingPointIdModel } from './models/evse-id.model';
import { ChargingPointIdMapping } from './models/evse-id-mapping.model';

export abstract class EvseIdBase {
  abstract editChargingPointId(parking_space_id: number,  charging_point_id: string): Observable<ChargingPointIdMapping | Failure>;
  abstract fetchChargingPointId(parking_space_id: number): Observable<ChargingPointIdModel | Failure>;
  abstract deleteChargingPointId(parking_space_id: number): Observable<any | Failure>;
}
