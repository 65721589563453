import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService, RepositoryService } from '@frontend-monorepo/core';
import { Variable } from 'apps/customer-dashboard/src/app/helper/variable';

/**
 * Guard for assuring that a user is authorized before allowing the activation of a new route.
 * 
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private repositoryService: RepositoryService, private router: Router) { }

  canActivate(): boolean {
    if (this.repositoryService.authService().is2FactorAuthorized() && this.repositoryService.authService().isTwoFactorEnabled) {
      return true;
    } else if (this.repositoryService.authService().isUserAuthorized()) {
      return true;
    } else {
      this.router.navigateByUrl('login');
    }
  }
}
