import { Injectable } from '@angular/core';
import { WebSocketBase } from '../websocket_base.interface';
import { WebSocketSubject } from 'rxjs/internal/observable/dom/WebSocketSubject';
import { Subscription } from 'rxjs';
import { WebSocketObject } from '../../interfaces/websocket';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { MainDataService } from '../../services/main-data.service';
import { RepositoryService } from '../../services/repository/repository.service';
import { webSocket } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root'
})
export class CameraParkinglotStateWebsocketService implements WebSocketBase {
  private _parkingLotCameraWebsocket: WebSocketSubject<any>;
  private _subscription: Subscription = new Subscription();

  constructor(
    private repositoryService: RepositoryService,
    private mainDataService: MainDataService,
    private snackbar: MatSnackBar,
    private router: Router,
    private translocoService: TranslocoService
  ) {}
  start() {
    if (this.repositoryService.authService().getToken() !== undefined) {
      this._parkingLotCameraWebsocket = webSocket({
        url: 'wss://api.parking-pilot.com/parkinglots/camera-parkinglot-state-websocket',
        openObserver: {
          next: () => {
            const authMessage = this._createAuthMessage();
            this._parkingLotCameraWebsocket?.next(authMessage);
          }
        }
      });
      this._subscribeToWebsocket();
    }
  }
  private _createAuthMessage() {
    if (this.repositoryService.authService().useApiKey()) {
      return {
        api_key: this.repositoryService.authService().getApiKey()
      };
    } else if (this.repositoryService.authService().getTwoFactorToken() != undefined) {
      return this.repositoryService.authService().getTokens();
    } else if (this.repositoryService.authService().getToken() != undefined) {
      return {
        token: this.repositoryService.authService().getToken()
      };
    } else {
      return {};
    }
  }

  private _subscribeToWebsocket() {
    this._subscription.add(
      this._parkingLotCameraWebsocket?.subscribe({
        next: (data) => {
          this.mainDataService.updateCameraParkingLotState(data);
        },
        error: (err) => {
          console.error(err);
          this.errorAction();
          this.stop();
        }
      })
    );
  }

  stop() {
    this._subscription?.unsubscribe();
    this._parkingLotCameraWebsocket?.complete();
    this._parkingLotCameraWebsocket?.unsubscribe();
  }
  isRunning(): boolean {
    return this._parkingLotCameraWebsocket == undefined ? false : true;
  }

  private errorAction() {
    let snackBarRef = this.snackbar.open(
      this.translocoService.translate('SERVICE.auth_error'),
      this.translocoService.translate('SERVICE.login'),
      { duration: 8000 }
    );
    snackBarRef.onAction().subscribe(() => this.router.navigate(['/login']));
  }
}
