import { Component, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

/**
 * 
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@Component({
  selector: 'mp-routing-progress-indicator',
  templateUrl: './routing-progress-indicator.component.html',
  styleUrls: ['./routing-progress-indicator.component.scss']
})
export class RoutingProgressIndicatorComponent implements OnDestroy {

  showProgressBar = false;
  subscription = new Subscription();
  constructor(
    private router: Router,
    private cd: ChangeDetectorRef) {
    this.subscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.showProgressBar = true;
        this.cd.detectChanges();
      } else if (event instanceof NavigationEnd) {
        this.showProgressBar = false;
        this.cd.detectChanges();
      }
    })
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
