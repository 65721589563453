import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PresetSummaryComponent } from './components/preset-summary/preset-summary.component';
import { PresetColorBarComponent } from './components/preset-color-bar/preset-color-bar.component';
import { ParkingDurationInfoModule } from '@frontend-monorepo/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import de from './i18n/de.json';
import en from './i18n/en.json';
import { MaterialComponentsModule } from 'apps/customer-dashboard/src/app/pages/materialComponents.module';



@NgModule({
  declarations: [
    PresetSummaryComponent,
    PresetColorBarComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslocoModule,
    ParkingDurationInfoModule,
    MaterialComponentsModule
  ],
  exports: [
    PresetSummaryComponent,
    PresetColorBarComponent
  ]
})
export class PresetSummaryModule {
  constructor(translocoService: TranslocoService) {
    translocoService.setTranslation(en, 'en');
    translocoService.setTranslation(de, 'de');
  }
 }
