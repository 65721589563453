<ng-container *transloco="let t">
  <div fxLayout="column" fxLayoutGap="10ox">
    <h2 mat-dialog-title>{{ header }}</h2>
    <div mat-dialog-content>
      {{ description }}
    </div>
    <span id="flex-spacer"></span>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
      <button mat-flat-button color="cancel" (click)="onCancel()">
        {{ cancelButtonText }}
      </button>
      <span id="flex-spacer"></span>
      <button mat-flat-button color="{{ buttonColor }}" (click)="onConfirm()" [mat-dialog-close]>
        {{ confirmButtonText }}
      </button>
    </div>
  </div>
</ng-container>
