import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject} from 'rxjs/webSocket';
import { TranslocoService } from '@ngneat/transloco';
import { WebSocketBase } from './websocket_base.interface';
import { Subscription } from 'rxjs';
import { AUTHENTICATION_FAILURE, AuthService, MainDataService, RepositoryService, WebSocketObject } from '@frontend-monorepo/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ParkinglotWebsocket implements WebSocketBase{

  private parkingLotWS: WebSocketSubject<any>; 
  private mainSubscription: Subscription;
  private websocketMessage: WebSocketObject;
  private reconnectTrials: number = 0;

  // test params
  private numberOfMessages: number = 0;

  constructor(
    private repositoryService: RepositoryService,
    private mainDataService: MainDataService,
    private snackbar: MatSnackBar,
    private router: Router,
    private translocoService: TranslocoService) { 
  }


  start(plid?: number){
    if (this.repositoryService.authService().getToken() !== undefined){
      // console.log("auth token available");
      this.mainSubscription = new Subscription();
      this.parkingLotWS = webSocket({
        url: 'wss://api.parking-pilot.com/parkinglots/parkinglot-state-websocket',
        openObserver: {
          next: () => {
           if (this.repositoryService.authService().isTwoFactorEnabled) {
              const tokens = this.repositoryService.authService().getTokens();
              
              this.parkingLotWS.next({
                token: tokens?.token,
                two_factor_token: tokens?.two_factor_token
              })
            } else {
              this.parkingLotWS.next({
                token: this.repositoryService.authService().getToken(),
              })
            }
            // let message = this.authService.getToken();
            // this.parkingLotWS.next(message);
          }
        },
        // serializer: msg => JSON.stringify({token: msg})
      });
  
      this.mainSubscription.add(
        this.parkingLotWS.subscribe(parkingLots => {
          if (parkingLots != null) {
            this.websocketMessage = parkingLots;
            this.mainDataService.updateListOPL(parkingLots);
          } else {
            
          }
        },
        (err) => {
          this.stop();
          this.errorAction();
        })
      );
    } else {
      console.log("no authentication available");
    }
  }

  // private log(){
  //   this.numberOfMessages++;
  //   console.log(this.numberOfMessages + "--" + Date.now());
  //   console.log("------------------------------------------------------------");
  // }

  private errorAction(){
    if (this.websocketMessage.type === AUTHENTICATION_FAILURE){
      let snackBarRef = this.snackbar.open(this.translocoService.translate('SERVICE.auth_error'), this.translocoService.translate('SERVICE.login'), {duration: 8000});
      snackBarRef.onAction().subscribe(() => this.router.navigate(["/login"]));
    } else {
      console.log("reconnecting");
      this.tryReconnect();
    }
  }

  private tryReconnect(){
    if (this.reconnectTrials === 5) {
      let snackBarRef = this.snackbar.open(this.translocoService.translate('SERVICE.reconnect'), this.translocoService.translate('SERVICE.login'), {duration: undefined});
      snackBarRef.onAction().subscribe(() => {
        this.router.navigate(["/login"]);
      });
    } else {
      this.reconnectTrials += 1;
      this.start();
    }
    
  }

  stop(){
    this.parkingLotWS.complete();
    this.parkingLotWS = undefined;
    this.mainSubscription.unsubscribe();
  }

  isRunning(): boolean {
    return this.parkingLotWS == undefined ? false : true;
  }
}
