<div class="content-wrapper">
  <h3 class="paragraph-wrapper">Impressum</h3>

  <h3 class="impressum-wrapper">Smart City System Parking Solutions GmbH</h3>

  <div class="paragraph-wrapper">
    <h4>Angaben gemäß § 5 TMG</h4>
    <p>
      Smart City System Parking Solutions GmbH <br />
      Schwabacher Str. 510c <br />
      90763 Fürth Deutschland <br />
      Handelsregister: HRB 19026 <br />
      Registergericht: Amtsgericht Fürth
    </p>
  </div>

  <div class="paragraph-wrapper">
    <h4>Vertreten durch die Geschäftsführer:</h4>
    <p>
      Stefan Eckart <br />
      Andreas Jaumann <br />
      Peter Förster
    </p>
  </div>

  <div style="padding-bottom: 2rem;">
    <h4>Kontakt</h4>
    <span>Telefon: <a href="tel:+491629747570">+49 162 974 7570</a></span>
    <br />
    <span>E-Mail: </span><a href="mailto:info@smart-city-system.com">info@smart-city-system.com</a>
  </div>

  <div class="paragraph-wrapper">
    <h4>Umsatzsteuer</h4>
    <p>
      Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE345974332
    </p>
  </div>

  <div class="paragraph-wrapper">
    <h4>Streitschlichtung</h4>
    <p>
      Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
      <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>. Unsere
      E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht bereit oder verpflichtet, an
      Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
    </p>
  </div>

  <div class="paragraph-wrapper">
    <h4>Haftung für Inhalte</h4>
    <p>
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
      allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
      nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder
      nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen
      zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
      bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
      Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
      Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
    </p>
  </div>

  <div class="paragraph-wrapper">
    <h4>Haftung für Links</h4>
    <p>
      Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen
      Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für
      die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
      Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
      erkennbar.
    </p>
    <p>
      Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
      Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
      werden wir derartige Links umgehend entfernen.
    </p>
  </div>

  <div class="paragraph-wrapper" style="padding-bottom: 2rem;">
    <h4>Urheberrecht</h4>
    <p>
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
      deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
      Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
      jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
      privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht
      vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
      Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
      Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
      Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
    </p>
  </div>

<div *transloco="let t; read: 'FOOTER'">
  <mat-dialog-actions>
    <button mat-flat-button color="cancel" mat-dialog-close>
      {{ t('cancel') }}
    </button>
  </mat-dialog-actions>
</div>
