import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver
} from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Subscription } from 'rxjs';
import { ParkingLotMapComponentInjectorService } from '../../services/parking-lot-map-component-injector.service';
import { ParkingLotMapService } from '@frontend-monorepo/parking-lot-map';
import { AuthService } from '@frontend-monorepo/core';
import { LocalDataService } from 'apps/customer-dashboard/src/app/services/data/local-data.service';

/**
 * Sidebar that is displayed on the right side of the screen on top of the parking lot map.
 *
 * Other components can/should be injected into this sidebar via the {@link ParkingLotMapExtension#rightSidebarFn}
 *
 * The sidebar is hidden/closed, if the selectedParkingSpaceIDs-array or selectedDeviceSpacesIDs-array are empty. If there is minimum one parking space selected, the sidebar is being shown.
 * Furthermore the hiding/showing of the sidebar is animated,
 */
@Component({
  selector: 'mp-overlaying-sidebar',
  templateUrl: './overlaying-sidebar.component.html',
  styleUrls: ['./overlaying-sidebar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'false',
        style({
          transform: 'translateX(100%)'
        })
      ),
      state(
        'true',
        style({
          transform: 'translateX(0)'
        })
      ),

      transition('false <=> true', animate('.5s ease-in-out'))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlayingSidebarComponent implements OnInit, OnDestroy {
  /**
   * The sidebar's ViewContainerRef, which is used by the  {@link ParkingLotMapExtension#rightSidebarFn} to inject/create child components.
   */
  @ViewChild('contentRef', { static: false, read: ViewContainerRef }) vcRef: ViewContainerRef;

  private _subscriptions: Subscription;

  close: boolean;

  selectedParkingSpaceIDs: Array<number>;
  selectedDeviceSpacesIDs: Array<number>;
  master_licensePlatePermission = false;

  constructor(
    private parkingLotMapService: ParkingLotMapService,
    public cd: ChangeDetectorRef,
    private parkingLotMapComponentInjectorService: ParkingLotMapComponentInjectorService,
    public componentFactoryResolver: ComponentFactoryResolver,
    public authService: AuthService,
    public plmService: ParkingLotMapService
  ) {
    this._subscriptions = new Subscription();
    this.close = false;
  }

  /**
   * right sidebar should not open when license_plate_detection is set for a parkinglot
   */
  ngOnInit() {
    this._subscriptions.add(
      this.parkingLotMapService.selectedParkingSpacesSubject.subscribe((selectedParkingSpaces) => {
        if (selectedParkingSpaces.length > 0 && selectedParkingSpaces != undefined) {
          this.close = true
          this.selectedParkingSpaceIDs = selectedParkingSpaces;
        } else {
          this.close = false;
        }
        this.cd.detectChanges();
      })
    );
    this._subscriptions.add(
      this.parkingLotMapService.selectedDeviceSpaceSubject.subscribe((deviceSpaces) => {
        if (deviceSpaces.length > 0 && deviceSpaces != undefined) {
          this.selectedDeviceSpacesIDs = deviceSpaces;
          this.close = true;
        } else {
          this.close = false;
        }
        this.cd.detectChanges();
      })
    );

    this._subscriptions.add(
      this.parkingLotMapComponentInjectorService
        .getRightSidebarContentFn()
        .subscribe((componentUpdateFn) => {
          if (componentUpdateFn) {
            componentUpdateFn(this);
          }
        })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  /**
   * Closing the sidebar by updating the selectedParkingSpacesSubject and selectedDeviceSpacesIDs with an empty array.
   */
  closeSidebar() {
    this.selectedDeviceSpacesIDs = [];
    this.parkingLotMapService.selectedDeviceSpaceSubject.next([]);
    this.parkingLotMapService.selectedParkingSpacesSubject.next([]);
  }
}
