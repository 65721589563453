import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { LocalDataService } from 'apps/customer-dashboard/src/app/services/data/local-data.service';
import { PresetModel } from 'libs/core/src/lib/repository/parking-lot-preset/models/preset.model';
import { MainDataService } from 'libs/core/src/lib/services/main-data.service';
import { RepositoryService } from 'libs/core/src/lib/services/repository/repository.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PresetParkingLot } from '../../viewmodel/preset-parkinglot.model';

@Component({
  selector: 'frontend-monorepo-check-existing-preset-dialog',
  templateUrl: './check-existing-preset-dialog.component.html',
  styleUrls: ['./check-existing-preset-dialog.component.scss']
})
export class CheckExistingPresetDialogComponent implements OnInit {
  presetParkinglotList: PresetParkingLot[];
  selectedParkingLots: PresetParkingLot[];

  applySettingText: string;

  loading: boolean;

  // checkbox state
  selectAllIndeterminateState: boolean;
  selectAllState: boolean;
  singleItemState: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      presetList: PresetModel[];
    },
    private mainDataService: MainDataService,
    private translocoService: TranslocoService,
    private dialogRef: MatDialogRef<CheckExistingPresetDialogComponent>
  ) {
    this.selectAllState = false;
    this.selectAllIndeterminateState = false;
    this.loading = true;
    this.presetParkinglotList = new Array();
    this.selectedParkingLots = [];
  }

  ngOnInit(): void {
    this.applySettingText = this.translocoService.translate('apply_preset_for_this_pl');
    this.data.presetList.map((_preset) =>
      this.presetParkinglotList.push({
        preset_id: _preset.preset_id,
        name: _preset.name,
        parking_lot_id: _preset.parking_lot_id,
        parking_lot_name: this.mainDataService.listOPL
          .getValue()
          .get(_preset.parking_lot_id)
          .getValue().name
      })
    );
    this.loading = false;
  }

  selectAll(checked: boolean) {
    this.selectedParkingLots = [];
    if (checked) {
      this.selectedParkingLots = [...this.presetParkinglotList];
    }
    this.selectAllState = checked;
    this.singleItemState = checked;
  }

  selectParkinglot(checked: boolean, selectedItem: PresetParkingLot) {
    if (checked) {
      this.selectedParkingLots.push(selectedItem);
    } else {
      let index = this.selectedParkingLots.findIndex((_elemement) => selectedItem == _elemement);
      this.selectedParkingLots.splice(index, 1);
    }
    this.selectAllIndeterminateState =
      this.selectedParkingLots.length > 0 &&
      this.selectedParkingLots.length < this.presetParkinglotList.length;
    this.selectAllState = this.selectedParkingLots.length == this.presetParkinglotList.length;

    this.applySettingText =
      this.selectedParkingLots.length > 0
        ? this.translocoService.translate('apply_preset')
        : this.translocoService.translate('apply_preset_for_this_pl');
  }

  close() {
    this.dialogRef.close(null);
  }

  applyForAllPreset() {
    this.dialogRef.close(this.selectedParkingLots);
  }
}
