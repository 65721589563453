import { Component, OnInit, Input, ChangeDetectionStrategy, HostBinding, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

/**
 * Simple component for displaying a percentage as a circular svg with a corresponding subtitle.
 * 
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@Component({
  selector: 'mp-circle-percentage-indicator',
  templateUrl: './circle-percentage-indicator.component.html',
  styleUrls: ['./circle-percentage-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CirclePercentageIndicatorComponent implements OnInit {
  @Input() percentage: number;
  @Input() subTitle: string;
  @Input() color: string;
  @Input() label: number;
  @Input() minWidth: string = "3.2rem";

  @HostBinding('style.grid-template-columns') size = this.getWidth;

  constructor(
    private santizier: DomSanitizer
  ) { }

  ngOnInit() {
    this.size = this.getWidth;
  }

  get getWidth(): SafeStyle {
    return this.santizier.bypassSecurityTrustStyle(`minmax(${this.minWidth}, 30%) 70%`);
  }

}
