import { Component, Input, OnInit } from '@angular/core';
import { Label } from 'libs/parking-lot-map/projects/parking-lot-map/core/src/public_api';

@Component({
  selector: 'frontend-monorepo-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {
  @Input() label: Label;

  constructor() {}

  ngOnInit(): void {}
}
