<ng-container *transloco="let t">
  <mat-form-field class="example-full-width">
    <mat-label>{{ t('searchbarLabel') }}</mat-label>

    <input
      matInput
      placeholder="{{ t('searchbarPlaceholder') }}"
      [attr.aria-label]="t('searchbarLabel')"
      [matAutocomplete]="multiSearchAutocomplete"
      [formControl]="stateCtrl"
    />
    <mat-icon matSuffix>search</mat-icon>
    <mat-autocomplete
      [id]="multiSearchAutocomplete"
      #multiSearchAutocomplete="matAutocomplete"
    >
      <mat-option
        class="multiSearchAutocompleteOption"
        *ngFor="let result of searchResults | async"
      >
        <span [ngSwitch]="result.type">
          <ng-container *ngSwitchCase="SEARCHBAR_RESULT_TYPE.PARKING_LOT">
            {{ t('parkingLot') }}, PLID {{ result.element.id }}
          </ng-container>
          <ng-container *ngSwitchCase="SEARCHBAR_RESULT_TYPE.DEVICE">
            {{ t('sensor') }}, ID {{ result.element.device_id }}
          </ng-container>
          <ng-container *ngSwitchCase="SEARCHBAR_RESULT_TYPE.REPEATER">
            {{ t('repeater') }}, ID {{ result.element.device_id }}
          </ng-container>
          <ng-container *ngSwitchCase="SEARCHBAR_RESULT_TYPE.GATEWAY">
            {{ t('gateway') }}, ID {{ result.element.device_id }}
          </ng-container>
          <ng-container *ngSwitchCase="SEARCHBAR_RESULT_TYPE.PARKING_SPACE">
            {{ t('parkingSpace') }}, PSID {{ result.element.id }}
          </ng-container>
        </span>
        <span class="parkignLotName">{{ result.parkingLotName }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>
