import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  PRESET_COLOR_SCHEME,
  RepositoryService} from '@frontend-monorepo/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'frontend-monorepo-preset-summary',
  templateUrl: './preset-summary.component.html',
  styleUrls: ['./preset-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresetSummaryComponent implements OnInit, OnDestroy {
  presetName: string;
  presetColor: string;
  weeklyPresetsDisplay: Map<number, string[]>;

  private _subscription: Subscription;

  constructor(private repositoryService: RepositoryService, private cd: ChangeDetectorRef) {
    this._subscription = new Subscription();
    this.presetColor = PRESET_COLOR_SCHEME[0];
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.setupGeneralInformation();
    this.weeklyPresetsDisplay = this.repositoryService.presetService().getDefaultColorBar();
    this.udpateSummaryDisplay();
  }

  /**
   * Updates color list according to weekday, starttime and endtime of preset
   */
  private udpateSummaryDisplay(): void {
    /**
     * listens to update on applied duration settings
     */
    this._subscription = this.repositoryService
      .presetService()
      .activeDurationPreset.subscribe((_durationPreset) => {
        if (_durationPreset) {
          /**
           * always start by filling the bar with default grey color
           * to avoid previous color or deleted color settings from overlapping
           */
          this.weeklyPresetsDisplay = this.repositoryService.presetService().getDefaultColorBar();
          this.cd.detectChanges();

          this.weeklyPresetsDisplay = this.repositoryService
            .presetService()
            .colorList(_durationPreset);

          this.cd.detectChanges();
        }
      });
  }

  setupGeneralInformation(): void {
    // get color of preset
    this._subscription = this.repositoryService.presetService().presetColor.subscribe((_color) => {
      if (_color) {
        this.presetColor = decodeURI(_color);
      } else {
        this.presetColor = PRESET_COLOR_SCHEME[0];
      }
      this.cd.detectChanges();
    });

    // get name of preset
    this._subscription = this.repositoryService.presetService().presetName.subscribe((_name) => {
      if (_name) {
        this.presetName = _name != 'legacy_duration_preset' ? _name : '';
      }
      this.cd.detectChanges();
    });
  }
}
