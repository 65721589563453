import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router
} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordGuard implements CanActivate {
  private token: string = '';
  private email: string = '';

  constructor(private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.email = route.queryParamMap.get('email');
    this.token = route.queryParamMap.get('token');

    if (this.email != null || this.token != null) {
      return true;
    } else {
      this.router.navigateByUrl('login');
    }
  }
}
