<ng-container *transloco="let t">
  <div class="background"></div>
  <div fxLayout="column" fxLayoutGap="10px" class="parent-wrapper mat-elevation-z3 mp-widget">
    <h3>{{ t('reset_password') }}</h3>

    <form [formGroup]="resetPasswordForm" class="mp-widget">
      <mat-form-field>
        <mat-label>{{ t('new_password') }}</mat-label>
        <input
          (input)="changeValuePassword($event.target.value)"
          matInput
          placeholder="{{ t('new_password') }}"
          name="new_password"
          formControlName="new_password"
          type="password"
          [type]="hide ? 'password' : 'text'"
          required
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon  >
            {{ hide ? 'visibility_off' : 'visibility' }}
          </mat-icon>
        </button>
      </mat-form-field>
      <div fxLayout="column" style="margin-bottom: 5px">
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-icon   *ngIf="password_upper_case" style="color: green"
            >check_circle_outline</mat-icon
          >
          <mat-icon   *ngIf="!password_upper_case" style="color: red"
            >highlight_off</mat-icon
          >
          <span style="font-size: small; align-self: center">{{ t('validator_upper_case') }}</span>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-icon   *ngIf="password_lower_case" style="color: green"
            >check_circle_outline</mat-icon
          >
          <mat-icon   *ngIf="!password_lower_case" style="color: red"
            >highlight_off</mat-icon
          >
          <span style="font-size: small; align-self: center">{{ t('validator_lower_case') }}</span>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-icon   *ngIf="password_number" style="color: green"
            >check_circle_outline</mat-icon
          >
          <mat-icon   *ngIf="!password_number" style="color: red"
            >highlight_off</mat-icon
          >
          <span style="font-size: small; align-self: center">{{ t('validator_number') }}</span>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-icon   *ngIf="password_special_char" style="color: green"
            >check_circle_outline</mat-icon
          >
          <mat-icon   *ngIf="!password_special_char" style="color: red"
            >highlight_off</mat-icon
          >
          <span style="font-size: small; align-self: center"
            >{{ t('validator_special_char') }} {{ specialCharacter }}</span
          >
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <mat-icon   *ngIf="password_min_length" style="color: green"
            >check_circle_outline</mat-icon
          >
          <mat-icon   *ngIf="!password_min_length" style="color: red"
            >highlight_off</mat-icon
          >
          <span style="font-size: small; align-self: center">{{ t('validator_length') }}</span>
        </div>
      </div>
      <mat-form-field style="padding-top: 0.5rem">
        <mat-label>{{ t('repeat_new_password') }}</mat-label>
        <input
          matInput
          placeholder="{{ t('repeat_new_password') }}"
          name="repeat_new_password"
          formControlName="repeat_new_password"
          type="password"
          [type]="hide ? 'password' : 'text'"
          required
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon  >
            {{ hide ? 'visibility_off' : 'visibility' }}
          </mat-icon>
        </button>
        <mat-error
          style="text-align: end"
          *ngIf="resetPasswordForm.get('repeat_new_password').hasError('passwordsDoNotMatch')"
        >
          {{ t('passwords_not_match') }}
        </mat-error>
      </mat-form-field>
      <div fxLayout="row" class="button-wrapper" fxLayoutAlign="space-between">
        <button mat-flat-button color="cancel" (click)="backToLogin()" style="padding-left: 0">
          {{ t('back_to_login') }}
        </button>
        <button
          type="submit"
          mat-flat-button
          color="primary"
          [disabled]="!resetPasswordForm.valid"
          (click)="resetPassword()"
        >
          {{ t('change_password') }}
        </button>
      </div>

      <span *ngIf="version != null" id="version" class="mat-caption">v{{ version }}</span>
    </form>
  </div>

  <mat-progress-bar
    style="bottom: 0px; position: absolute"
    *ngIf="loading"
    color="primary"
    [mode]="'indeterminate'"
  ></mat-progress-bar>
</ng-container>
