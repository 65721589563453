import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepeatersOfParkingLotComponent } from './component/repeaters-of-parking-lot.component';

/**
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@NgModule({
    declarations: [
        RepeatersOfParkingLotComponent
    ],
    imports: [CommonModule],
    exports: [
        RepeatersOfParkingLotComponent
    ],
})
export class RepeatersOfParkingLotModule { }