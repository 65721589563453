import { Injectable } from '@angular/core';
import { ParkingLot, ParkingLotIssue, ParkingSpace, Device, Gateway, Repeater, OccupanyStatus } from '../interfaces/ParkingLot';
import { BehaviorSubject } from 'rxjs';
import { ParkingLotWithOccupancies } from '../interfaces/ParkingLotWithOccupancies';

@Injectable({
  providedIn: 'root'
})
export class ParkingLotsDataService {

  /**
   * BehaviorSubject for passing and updating the list of parking lots.
   */
  parkingLotsSubject: BehaviorSubject<Array<ParkingLot | ParkingLotWithOccupancies>> = new BehaviorSubject<Array<ParkingLot | ParkingLotWithOccupancies>>([]);


  occupancyStatusOfParkingLotSubjectMap: Map<number, BehaviorSubject<Array<OccupanyStatus>>> = new Map<number, BehaviorSubject<Array<OccupanyStatus>>>();

  /**
   * Map containing one behaviour subject for each parking lot to pass on and update parking lot issues
   * The parking lot´s id is hereby used as KEY.
   */
  issueOfParkingLotSubjectMap: Map<number, BehaviorSubject<Array<ParkingLotIssue>>> = new Map<number, BehaviorSubject<Array<ParkingLotIssue>>>();

  /**
   * Map containing one behaviour subject for each parking lot to pass on and update the list of a parking lot´s parking spaces.
   * The parking lot´s id is hereby used as KEY.
   */
  parkingSpacesOfPLSubjectMap: Map<number, BehaviorSubject<Array<ParkingSpace>>> = new Map<number, BehaviorSubject<Array<ParkingSpace>>>();


  /**
   * Map containing one behaviour subject for each parking lot to pass on and update the list of a parking lot´s installed devices
   * The parking lot´s id is hereby used as KEY.
   */
  installedDevicesOfPLSubjectMap: Map<number, BehaviorSubject<Array<Device>>> = new Map<number, BehaviorSubject<Array<Device>>>();

  /**
   * Map containing one behaviour subject for each parking lot to pass on and update the list of a parking lot´s gateways
   * The parking lot´s id is hereby used as KEY.
   */
  gatewaysOfPLSubjectMap: Map<number, BehaviorSubject<Array<Gateway>>> = new Map<number, BehaviorSubject<Array<Gateway>>>();

  /**
   * Map containing one behaviour subject for each parking lot to pass on and update the list of a parking lot´s repeaters
   * The parking lot´s id is hereby used as KEY.
   */
  repeatersOfPLSubjectMap: Map<number, BehaviorSubject<Array<Repeater>>> = new Map<number, BehaviorSubject<Array<Repeater>>>();

  /**
   * Updating a parkingLot´s issues
   * 
   * Calling the parking lot´s issue-subject next-function with the new issues.
   * If the `issueOfParkingLotSubjectMap` does not contain an instantiated BehaviorSubject for this parking lot,
   * a new BehaviourSubject with the [issues] as initial value is created and added to the `issueOfParkingLotSubjectMap`.
   *
   * @param parkingLotID The id of the parking lot, which´s issues should be updated
   * @param issues New issues which should be publicated via the behaviour subject.
   */
  updateIssuesOfParkingLot(parkingLotID: number, issues: Array<ParkingLotIssue>) {
    if (this.issueOfParkingLotSubjectMap.has(parkingLotID)) {
      this.issueOfParkingLotSubjectMap.get(parkingLotID).next(issues);
    } else {
      const subject = new BehaviorSubject<Array<ParkingLotIssue>>(issues);
      this.issueOfParkingLotSubjectMap.set(parkingLotID, subject);
    }
  }

  /**
   * Updating a parking lot´s list of parking spaces.
   * 
   * Calling the parking lot´s parkingSpaces-subject next-function with the new parking spaces.
   * If the `parkingSpacesOfPLSubjectMap` does not contain an instantiated BehaviorSubject for this parking lot,
   * a new BehaviourSubject with the [parkingSpaces] as initial value is created and added to the `parkingSpacesOfPLSubjectMap`.
   * @param parkingLotID The id of the parking lot, which´s parking spaces should be updated
   * @param parkingSpaces New list of parking spaces which should be publicated via the behaviour subject.
   */
  updateParkingSpacesOfParkingLot(parkingLotID: number, parkingSpaces: Array<ParkingSpace>) {
    if (this.parkingSpacesOfPLSubjectMap.has(parkingLotID)) {
      this.parkingSpacesOfPLSubjectMap.get(parkingLotID).next(parkingSpaces);
    } else {
      const subject = new BehaviorSubject<Array<ParkingSpace>>(parkingSpaces);
      this.parkingSpacesOfPLSubjectMap.set(parkingLotID, subject);
    }
  }

  /**
   * Updating a parking lot´s list of installed devices
   * 
   * Calling the parking lot´s installed-devices-subject next-function with the new list of installed devices
   * If the `installedDevicesOfPLSubjectMap` does not contain an instantiated BehaviorSubject for this parking lot,
   * a new BehaviourSubject with the [installedDevices] as initial value is created and added to the `installedDevicesOfPLSubjectMap`.
   * @param parkingLotID The id of the parking lot, which´s installed devices should be updated
   * @param installedDevices New list of installed devices which should be publicated via the behaviour subject.
   */
  updateInstalledDevicesOfParkingLot(parkingLotID: number, installedDevices: Array<Device>) {
    if (this.installedDevicesOfPLSubjectMap.has(parkingLotID)) {
      this.installedDevicesOfPLSubjectMap.get(parkingLotID).next(installedDevices);
    } else {
      const subject = new BehaviorSubject<Array<Device>>(installedDevices);
      this.installedDevicesOfPLSubjectMap.set(parkingLotID, subject);
    }
  }

  /**
   * Updating a parking lot´s gateways
   * 
   * Calling the parking lot´s gateways-subject´s next-function with the new gateways
   * If the `gatewaysOfPLSubjectMap` does not contain an instantiated BehaviorSubject for this parking lot,
   * a new BehaviourSubject with the [gateways] as initial value is created and added to the `gatewaysOfPLSubjectMap`.
   * @param parkingLotID The id of the parking lot, which´s gateways should be updated
   * @param gateways New list of gateways which should be publicated via the behaviour subject.
   */
  updateGatewaysOfParkingLot(parkingLotID: number, gateways: Array<Gateway>) {
    if (this.gatewaysOfPLSubjectMap.has(parkingLotID)) {
      this.gatewaysOfPLSubjectMap.get(parkingLotID).next(gateways);
    } else {
      const subject = new BehaviorSubject<Array<Gateway>>(gateways);
      this.gatewaysOfPLSubjectMap.set(parkingLotID, subject);
    }
  }

  /**
   * Updating a parking lot´s repeaters
   * 
   * Calling the parking lot´s repeaters-subject´s next-function with the new repeaters
   * If the `gatewaysOfPLSubjectMap` does not contain an instantiated BehaviorSubject for this parking lot,
   * a new BehaviourSubject with the [repeaters] as initial value is created and added to the `gatewaysOfPLSubjectMap`.
   * @param parkingLotID The id of the parking lot, which´s repeaters should be updated
   * @param repeaters New list of repeaters which should be publicated via the behaviour subject.
   */
  updateRepeatersOfParkingLot(parkingLotID: number, repeaters: Array<Repeater>) {
    if (this.repeatersOfPLSubjectMap.has(parkingLotID)) {
      this.repeatersOfPLSubjectMap.get(parkingLotID).next(repeaters);
    } else {
      const subject = new BehaviorSubject<Array<Repeater>>(repeaters);
      this.repeatersOfPLSubjectMap.set(parkingLotID, subject);
    }
  }

  constructor() {
  }
}
