<div fxLayout="row">
  <div *ngFor="let data of breadcrumbString">
    <div fxLayout="row">
      <div [class.activLink]="data === breadcrumbString[breadcrumbString.length - 1]">
        {{ data }}
      </div>
      <ng-container *ngIf="data !== breadcrumbString[breadcrumbString.length - 1]">
        <mat-icon   >chevron_right</mat-icon>
      </ng-container>
    </div>
  </div>
</div>
