<div *transloco="let t">
 
  <div fxLayout="row">
    <div fxFlex="30" fxFlexAlign="start">
      <mat-form-field
        *ngIf="parkingLotList.length > 0"
      >
        <mat-label >{{ t('parkinglot_search') }}</mat-label>
        <input
          #inputField
          matInput
          placeholder="{{ t('search_input') }}"
          [formControl]="searchControl"
          (keyup.enter)="searchRequest($event.target.value)"
        />
        <mat-icon style="margin-left: 6px" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxFlex="68" style="padding: 10px 16px 10px 0px">
      <div fxFlex="94" fxLayoutAlign="end center">
        {{ t('select_all') }}
      </div>
      <div fxFlex="6" fxLayoutAlign="center center">
        <mat-checkbox
          color="primary"
          [(indeterminate)]="selectAllIndeterminateState"
          [checked]="selectAllState"
          (change)="selectAll($event.checked)"
        >
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div *ngIf="parkingLotListFilter.length > 0" fxLayout="row">
    <mat-list class="list-item">
      <mat-list-item class="list-item-box" *ngFor="let item of parkingLotListFilter">
        <div fxFlex="92" fxLayout="row" fxLayoutGap="5px">
          <div>{{ item.id }}:</div>
          <div>{{ item.name }}</div>
        </div>
        <div fxFlex="8" fxLayout="column" fxLayoutAlign="end center">
          <div>
            <mat-checkbox
              color="primary"
              [checked]="singleItemState"
              (change)="selectParkinglot($event.checked, item)"
            ></mat-checkbox>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <div fxLayout="column" class="selection-overview">
    <div fxFlexAlign="end">{{ selectedParkingLots.length }} {{ t('chosen_parkinglot') }}</div>
  </div>
</div>
