import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API_BASE_URL, Failure } from '@frontend-monorepo/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ParkingLot } from '../parking-lot/models/parking-lot.model';
import { OccupationDuration } from './models/occupation-duration.model';
import { ParkingLotSettingsBase } from './parking-lot-settings.base';

export class ParkingLotSettingsRepository extends ParkingLotSettingsBase {
  constructor(private http: HttpClient) {
    super();
  }
  
  /**
   * Updating a parking lot's duration settings
   *
   * Making a PUT-request with the new duration settings.
   * @param parkingLot
   */
  updateDurationSettings(parkingLot: ParkingLot): Observable<any> {
    const url = API_BASE_URL + `parkinglots/${parkingLot.id}/duration-settings`;

    return this.http
      .put(
        url,
        {},
        {
          params: {
            max_parking_duration: parkingLot.max_parking_duration.toString(),
            max_overdue_duration: parkingLot.max_overdue_duration.toString(),
            due_warning_duration: parkingLot.due_warning_duration.toString()
          }
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw new Failure(error.status, error.message);
        })
      );
  }
}
