import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { FormGroup, FormControl, Validators, MinLengthValidator } from '@angular/forms';
import {
  AuthService,
  CoreApiService,
  DASHBOARD_TAG,
  Failure,
  MainDataService,
  RepositoryService
} from '@frontend-monorepo/core';
import { Observable, Subscription, timer } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { Networking } from '../../helper/networking';
import { HttpErrorResponse } from '@angular/common/http';
import { take, map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { UserDataService } from '@frontend-monorepo/parking-lot-map';
import { TWO_FACTOR_INPUT_STATUS } from '@frontend-monorepo/common';
import { LoginService } from 'libs/common/projects/common-lib/login/src/helper/login-service/login.service';

@Component({
  selector: 'scs-guard-auth-dialog',
  templateUrl: './guard-auth-dialog.component.html',
  styleUrls: ['./guard-auth-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuardAuthDialogComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  emailForm: FormGroup;
  resetPasswordActiv: boolean;
  loading: boolean = false;
  _subscription: Subscription;

  login_attempts: number;
  counter$: Observable<number>;
  lockedUserList: LockedUser[];
  counter: number;
  counter_type: string;
  showError: Boolean;
  showButton: Boolean;

  isTwoFactorEnabled: boolean;
  totpCodeForm: FormGroup;
  checkCookie: boolean;

  constructor(
    private repositoryService: RepositoryService,
    private authService: AuthService,
    private userDataService: UserDataService,
    private snackbar: MatSnackBar,
    private loginService: LoginService,
    private dialogRef: MatDialogRef<GuardAuthDialogComponent>,
    private transloco: TranslocoService,
    private cd: ChangeDetectorRef
  ) {
    this.resetPasswordActiv = false;
    this.lockedUserList = [];
    this.login_attempts = 0;
    this.showError = false;
    this.showButton = true;
    this.counter_type = '';
    this._subscription = new Subscription();
    this.loginForm = new FormGroup({
      username: new FormControl(undefined, Validators.required),
      password: new FormControl(undefined, Validators.required)
    });
    this.emailForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required])
    });
    this.totpCodeForm = new FormGroup({
      twoFactorCode: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  /**
   * Start listening to loginForm changes if cookie was not set
   * @param response coming in from {@link CookieDirective} determinating whether cookie is set
   */
  getCookieResponse(response: string) {
    if (response == 'NO_COOKIE') {
      this.loginForm.get('username').valueChanges.subscribe((user) => {
        let lockedUser = this.lockedUserList.find((item) => item.username == user);
        if (user.length == 0) {
          this.showButton = true;
          this.showError = false;
        } else if (lockedUser != null && lockedUser.username == user) {
          this.showError = true;
          this.showButton = false;
          this.errorHandling(this.lockedUserList.find((item) => item.username == user).errorObject);
        } else {
          this.showError = false;
          this.showButton = true;
          this.loginForm.get('password').enable();
        }
        this.cd.detectChanges();
      });
    } else if (response == 'HAS_COOKIE') {
      this.isTwoFactorEnabled = this.repositoryService.authService().isTwoFactorEnabled;

      // setup data if
      if (!this.isTwoFactorEnabled) {
        this.loginService.setupBasicData().subscribe((ready) => {
          this.closeDialog();
        });
      }
    }
  }

  login() {
    let username = this.loginForm.controls['username'].value;
    let password = this.loginForm.controls['password'].value;
    this.loading = true;

    this._subscription = this.repositoryService
      .authRepository()
      .doLogin(username, password, this.checkCookie)
      .subscribe(
        (result) => {
          this.authService.setToken(result.token);
          this.isTwoFactorEnabled = result.two_factor_authentication_type !== undefined;
          this.authService.setTwoFactorEnabled(this.isTwoFactorEnabled);
          this.cd.detectChanges();

          // set cookie if user chose to
          if (this.checkCookie) {
            this.loginService.setupCookie(result);
          }
 
          if (!this.isTwoFactorEnabled) {
            this.loginService.setupBasicData().subscribe((ready) => {
              this.closeDialog();
            });
          }
          this.loading = false;
          this.cd.detectChanges();
        },
        (err: HttpErrorResponse) => {
          this.loading = false;
          this.cd.detectChanges();
          if (err.status == 403) {
            this.snackbar.open(this.transloco.translate('account_locked'));
          } else {
            this.errorHandling(err);
          }
        }
      );
  }

  /**
   * show reset password form and switches back to login
   */
  toggleResetPassword() {
    this.resetPasswordActiv = !this.resetPasswordActiv;
  }

  /**
   * Reset password
   */
  resetPassword() {
    this.resetPasswordActiv = false;
    let json = JSON.stringify({ email: this.emailForm.get('email').value, brand: DASHBOARD_TAG });
    this.repositoryService
      .loginRepository()
      .postGeneratePasswordResetToken(json)
      .subscribe(
        (resp) => {
          this.snackbar.open(this.transloco.translate('PARKINGLOT.reset_password_email_send'));
        },
        (error: HttpErrorResponse) => {
          this.snackbar.open(this.transloco.translate('PARKINGLOT.error_reset_password_email'));
        }
      );
  }

  /**
   * Handles error on failed attempt to login with a backoff-timer
   * @param err error Response
   */
  private errorHandling(err: HttpErrorResponse) {
    if (err.status == 401) {
      if (err.error.locked_until != null) {
        this.setLoginFormState('disabled');
        this.addUserToList(this.loginForm.get('username').value, err);
        let count = +err.error.locked_until - Math.floor(Date.now() / 1000);
        if (count < 0) {
          this.setLoginFormState('enable');
        } else {
          this.counter = count;
          if (count == 0) {
            this.snackbar.open(this.transloco.translate('PARKINGLOT.snackbarError401'));
          } else {
            this.counter$ = timer(0, 1000).pipe(
              take(count + 1),
              map(() => {
                if (count == 0) {
                  this.setLoginFormState('enable');
                  this.counter = count;
                }
                --count;
                return count;
              })
            );
            this.login_attempts = err.error.failed_login_attempts;
            this.cd.detectChanges();
          }
        }
      } else {
        this.snackbar.open(this.transloco.translate('PARKINGLOT.snackbarError401'));
      }
    }
  }

  /**
   * disables and enables loginFrom
   * @param state action
   */
  private setLoginFormState(state: string) {
    if (state == 'enable') {
      this.loginForm.get('password').enable();
      this.removeUserFromList(this.loginForm.get('username').value);
      this.showButton = true;
      this.showError = false;
    } else {
      this.loginForm.get('password').disable();
      this.showError = true;
      this.showButton = false;
    }
    this.loading = false;
    this.cd.detectChanges();
  }

  /**
   * Adds locked user to the list to retrieve and display when user account changes back
   * @param username the username of currently selected account
   * @param errObject http error response containing the failed login lock data
   */
  private addUserToList(username: string, errObject: HttpErrorResponse) {
    let user: LockedUser = {
      username: username,
      errorObject: errObject
    };
    if (this.lockedUserList.find((user) => user.username == username) == null) {
      this.lockedUserList.push(user);
    }
  }

  /**
   * Removes locked user from the list, when the countdown is finished
   * @param username which due to failed login attempts is temporary locked
   */
  private removeUserFromList(username: String) {
    let currentUser = this.lockedUserList.findIndex((item) => item.username === username);
    this.lockedUserList.splice(currentUser, 1);
  }

  closeDialog() {
    this.loading = false;
    this.dialogRef.close();
  }

  authenticateUsingTotp(input: number) {
    if (input.toString().length == 6) {
      this.repositoryService
        .authRepository()
        .postAuthTotp(input)
        .subscribe({
          next: (resp) => {
            this.authService.setTwoFactorToken(resp.two_factor_token);
            this._subscription = this.repositoryService
              .loginRepository()
              .fetchCustomerPLIDMapping()
              .subscribe((customerPlid) => this.userDataService.setCustomerPlidList(customerPlid));

            this._subscription = this.repositoryService
              .userRepository()
              .fetchAccountInfo()
              .subscribe((userData) => {
                this.userDataService.setUserData(userData);
                this.closeDialog();
              });
          },
          error: (err: Failure) => {
            this.snackbar.open(this.transloco.translate(`PARKINGLOT.${err.errorMessage}`));
          }
        });
    }
  }

  get2FA_status(status: TWO_FACTOR_INPUT_STATUS) {
    this.closeDialog();
  }

  /**
   * activates cookie setting
   * @param checked
   */
  private activateCookie(checked: boolean): void {
    this.checkCookie = checked;
    this.cd.detectChanges();
  }
}

export interface LockedUser {
  username: string;
  errorObject: HttpErrorResponse;
}
