import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import de from './i18n/de.json';
import en from './i18n/en.json';
import { TranslocoService } from '@ngneat/transloco';
import { UserInviteCompletionComponent } from './components/user-invite-completion/user-invite-completion.component';
import { MaterialComponentsModule } from 'apps/customer-dashboard/src/app/pages/materialComponents.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';


@NgModule({
  declarations: [UserInviteCompletionComponent],
  imports: [
    CommonModule,
    MaterialComponentsModule
  ],
  exports: [
    UserInviteCompletionComponent,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
  ]
})
export class UserInviteModule { 
  constructor(translocoService: TranslocoService) {
    translocoService.setTranslation(en, 'en');
    translocoService.setTranslation(de, 'de');
}
}
