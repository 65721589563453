import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'frontend-monorepo-comment-date-picker',
  templateUrl: './comment-date-picker.component.html',
  styleUrls: ['./comment-date-picker.component.scss']
})
export class CommentDatePickerComponent implements OnInit {
  @Input() headerText: string;
  @Output() sendDate = new EventEmitter();

  range: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.range = new FormGroup({
      start: new FormControl(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)),
      end: new FormControl(new Date())
    });
  }

  onDateRangeChange() {
    let startUnix = moment(this.range.get('start').value).unix();
    let endUnix = moment(this.range.get('end').value).unix();
    if (moment(this.range.get('start').value).format("DD.MM.YYY") == moment(this.range.get('end').value).format("DD.MM.YYY")) {
      startUnix = moment(this.range.get('start').value).startOf('day').unix();
      endUnix = moment(this.range.get('end').value).endOf('day').unix();
    }
    this.sendDate.emit([startUnix, endUnix])
  }

  clearInput() {
    this.range.get('start').setValue(null);
    this.range.get('end').setValue(null);
    this.sendDate.emit([])
  }
}
