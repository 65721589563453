import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { API_BASE_URL, CoreApiService, Failure } from '@frontend-monorepo/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Account2faBase } from './account-2fa.base';
import { serializeTotpCode, TotpCode } from './models/totp-code.model';
import { TotpKey } from './models/totp-key.model';

export class Account2faRepository extends Account2faBase {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Disables two-factor-authentication for authenticated user
   *
   * @description
   * This function can only be used with bearer token authentication and a valid two-factor token
   */
  disableTotp(): Observable<any | Failure> {
    let object = this.http
      .post<any | Failure>(API_BASE_URL + 'account/disable-totp', '')
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw new Failure(error.status, 'disable_totp_error');
        })
      );
    return object;
  }

  /**
   * Disables two-factor-authentication for a spedific user-id
   *
   * @description
   * This function can only be used with bearer token authentication and a valid two-factor token
   * Requires `admin` user-permission
   * @param userId id of selected user
   */
  disableTotpById(userId: boolean): Observable<any | Failure> {
    let object = this.http
    .post<any | Failure>(API_BASE_URL + `account/${userId}/disable-totp`, '')
    .pipe(
      catchError((error: HttpErrorResponse) => {
        throw new Failure(error.status, error.message);
      })
    );
  return object;
  }

  /**
   * 2.Step of enabling two factor authentication
   *
   * @description
   * After using the generate-totp-key route, this route can be used to verify that the user
   * has correctly stored the TOTP key and is able to generate TOTP codes, by providing a valid TOTP code.
   * If successful, TOTP will be enabled for this account, which will log out the user and
   * further authentication attempts will require the user to additionally provide a two-factor token,
   * which can be generated by the auth-totp route. The token must be provided in the header "X-Two-Factor-Token"
   * in addition to the "X-Auth-Token" header used for the standard bearer token.
   *
   * @param totpCode TOTP code generated using authenticator app
   */
  enableTotp(totpCode: number): Observable<any | Failure> {
    let object = this.http
    .post<any | Failure>(API_BASE_URL + 'account/enable-totp', serializeTotpCode(totpCode))
    .pipe(
      catchError((error: HttpErrorResponse) => {
        throw new Failure(error.status, 'activation_error');
      })
    );
  return object;
  }

  /**
   * Generates a new TOTP key and returns it to the user.
   *
   * @description
   * This key should generally be displayed to the user as a QRCode (in the appropriate format),
   * to be used with apps like Google Authenticator or similar. This is the first step in the process of
   * enabling TOTP for a user account. TOTP will not be enabled until successfully verifying,
   * that the user has saved the key using the enable-totp route.
   *
   * @returns a new TOTP key
   */
  generateTotpKey(): Observable<TotpKey | Failure> {
    let object = this.http
    .post<any | Failure>(API_BASE_URL + 'account/generate-totp-key', '')
    .pipe(
      catchError((error: HttpErrorResponse) => {
        throw new Failure(error.status, error.message);
      })
    );
  return object;
  }
}
