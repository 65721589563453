import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  API_BASE_URL,
  Failure
} from '@frontend-monorepo/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { OpenBarrierRequest } from './models/open-barrier-request';
import { OpenBarrierBase } from './open-barrier.base';
/**
 * Repository containing Camera Snaphsot Feature related API's
 */
export class OpenBarrierRepository extends OpenBarrierBase {

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * POST Call for sending a request to an installed camera to get a request id for opening the barrier
   * @requires `camera_barrier` lot permission on the parking lot
   *
   * @param device_id Id of the device space that has a camera installed
   * @returns a `request_id` including a `request_time`
   */
  postOpenBarrier(
    device_id: number,
  ): Observable<OpenBarrierRequest> {
    return this.http
    .post<OpenBarrierRequest>(
      API_BASE_URL +
        `devicespaces/${device_id}/camera/open-barrier`,
      ''
    ).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          let message = 'not_successful';
          if (error.status == 400) {
            message = (error.error == 	
              "There is no camera installed on the device space") ? "no_camera_installed" : "camera_no_barrier_control";
          } else if (error.status == 404) {
            message = 'device_not_found';
          } else if (error.status == 409) {
            message = 'disconnected';
          }
          throw new Failure(error.status, message);
        }
      )
    );
  }
}
