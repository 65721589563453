import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationComponent } from './components/preset-configuration/configuration.component';
import de from './i18n/de.json';
import en from './i18n/en.json';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslocoService } from '@ngneat/transloco';
import { MaterialComponentsModule } from 'apps/customer-dashboard/src/app/pages/materialComponents.module';
import { PresetDurationSetupComponent } from './components/preset-duration-setup/preset-duration-setup.component';
import { PresetAddSettingComponent } from './components/preset-add-setting/preset-add-setting.component';
import { PresetResetSettingDialogComponent } from './components/preset-reset-setting-dialog/preset-reset-setting-dialog.component';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [
    ConfigurationComponent,
    PresetDurationSetupComponent,
    PresetAddSettingComponent,
    PresetResetSettingDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FlexLayoutModule,
    OverlayModule
  ],
  exports: [
    ConfigurationComponent,
    PresetDurationSetupComponent,
    PresetAddSettingComponent,
    PresetResetSettingDialogComponent
  ]
})
export class PresetConfigurationModule {
  constructor(translocoService: TranslocoService) {
    translocoService.setTranslation(en, 'en');
    translocoService.setTranslation(de, 'de');
  }
}
