import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
  HttpEventType
} from '@angular/common/http';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, CoreApiService, RepositoryService } from '@frontend-monorepo/core';
import { catchError, tap } from 'rxjs/operators';
import { HEADERS } from '../utils/constants/headers';
import { LoginService } from 'libs/common/projects/common-lib/login/src/helper/login-service/login.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private loginService: LoginService,
    private activRouter: ActivatedRoute,
    private repositoryService: RepositoryService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // skip change child password request header
    if (
      request.url.includes('account/change-password') &&
      request.headers.get('x-auth-token') != undefined
    ) {
      return next.handle(request);
    }

    // get original headers
    let headers = request.headers;
    // by default set content type as json
    if (headers.get(HEADERS.CONTENT_TYPE) == null) {
      headers = headers.set(HEADERS.CONTENT_TYPE, HEADERS.CONTENT_TYPE_JSON);
    }

    // skip httpLoader provider
    if (
      request.url.includes('assets') ||
      request.url.includes('get-token') ||
      request.url.includes('store-token') ||
      request.url.includes('auth?remember') ||
      request.url.includes('logout') ||
      request.url.includes('account/accept-invite')
    ) {
      let authReq = request.clone({ headers: headers });
      return next.handle(authReq).pipe(
        catchError((x) => {
          return throwError(x);
        })
      );
    }

    // Get the auth token from the service.
    const bearerToken = this.repositoryService.authService().getToken();
    const twoFactorToken = this.repositoryService.authService().getTwoFactorToken();
    const apiKey = this.repositoryService.authService().getApiKey();

    if (bearerToken != null) {
      headers = headers.set(HEADERS.X_AUTH_TOKEN, bearerToken);
    }

    if (twoFactorToken != null && request.url != `https://api.parking-pilot.com/auth-totp`) {
      headers = headers.set(HEADERS.X_TWO_FACTOR_TOKEN, twoFactorToken);
    }

    if (apiKey != null) {
      headers = headers.set(HEADERS.X_API_KEY, apiKey);
    }

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    let authReq = request.clone({ headers: headers });

    // send cloned request with header to the next handler.

    return next.handle(authReq).pipe(catchError((x) => this.handleAuthError(x, request.url)));
  }

  /**
   * Logs out user on failed request and rethrows error
   *
   * @param err http error response coming from failed request
   * @returns http error response
   */
  private handleAuthError(err: HttpErrorResponse, url: string): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 && !url.includes('logout')) {
      //navigate /delete cookies or whatever
      this.repositoryService
        .authRepository()
        .doLogout()
        .subscribe((res) => {
          this.loginService.resetCookie();
        });
      // this._router.navigateByUrl(`/login`);
      return of(false);
    }
    return throwError(err);
  }
}

/** Provide in app.module.ts */
export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
