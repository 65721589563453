import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageObject } from '../../helper/models';
import { Variable } from '../../helper/variable';



@Injectable({
  providedIn: 'root'
})
export class MessageService {

  messageSubject: Subject<MessageObject>;
  reconnectSubject: Subject<String>;

  constructor() { 
    this.messageSubject = new Subject();
    this.reconnectSubject = new Subject();

    this.setupInternetListener();
  }

  sendMessage(message: MessageObject){
    this.messageSubject.next(message);
  }

  private setupInternetListener() {
    window.addEventListener('online', () => {
      // console.log('came online');
      this.reconnectSubject.next(Variable.RECONNECT_SERVICE_ONLINE);
    });

    window.addEventListener('offline', () => {
      // console.log('came offline');
      this.reconnectSubject.next(Variable.RECONNECT_SERVICE_OFFLINE);
    });
  }
}
