export const URL_DRAWER_CONTENT_MAP: Map<string, string> = new Map([
    ['occupancy', "landing/occupancy"],
    ['parking_violations', "landing/parking-violations"],

    ['system_status', "parking-lot-management/system-status"],
    ['customer_plids', "parking-lot-management/customer-plids"],
    ['permitted_parking_durations', "parking-lot-management/permitted-parking-durations"],
    ['email_notifications', "parking-lot-management/email-notifications"],

    ['via_invitation_link', "user-management/create-new-user/via-invitation-link"],
    ['via_username_password', "user-management/create-new-user/via-username-password"],

    ['change_user_password', "user-management/change-user-password"],
    ['delete_user', "user-management/delete-user"],
    ['edit_user_permission', "user-management/edit-user-permission"],
    ['edit_parkinglot_permission', "user-management/edit-parkinglot-permission"],
    ['assign_parkinglot', "user-management/assign-parkinglot"],

    ['manage_api_key', "manage-personal-account/manage-api-key"],
    ['change_personal_password', "manage-personal-account/change-personal-password"],
    ['manage_2_factor_authentication', "manage-personal-account/manage-2-factor-authentication"],

    ['interactive_analysis_sensor', "analysis/parking-lot-usage/sensor/interactive-analysis"],
    ['csv_export', "analysis/parking-lot-usage/sensor/csv-export"],

    ['interactive_analysis_camera', "analysis/parking-lot-usage/camera/interactive-analysis"],

    ['export_violation_potential_analysis', "analysis/parking-lot-control/sensors/export-violation-potential-analysis"],
    ['search_for_violations', "analysis/parking-lot-control/sensors/search-for-violations"],

    ['validate_parking_violations', "analysis/parking-lot-control/camera/validate-parking-violations"],
    ['export_parking_violations', "analysis/parking-lot-control/camera/export-parking-violations"],
    ['export_camera_events', "analysis/parking-lot-control/camera/export-camera-events"],
])