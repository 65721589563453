import { Injectable } from '@angular/core';
import { CustomerPLIDModel } from '@frontend-monorepo/core';
import { ParkingLotModel } from 'apps/customer-dashboard/src/app/helper/models';
import { UserData } from '../../../../../parking-lot-map/projects/parking-lot-map/core/src/models/user-data';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  private userData: UserData;
   /**
    * Map containing a optional customer-plid for each parking lot id
   */
  private customerPlidList: Map<number,number>;
    /**
     * Map of PLIDs to arrays of [PARKING_LOT_PERMISSION]
     * 
     * @example
     * 
     * {
     *  "1": [
     *     "access",
     *     "rental"   
     *  ],
     * 
     *  "2": [
     *     "access",
     *     "analytics"
     *  ]
     * }
     * 
     */
  private parkingLotPermissions: Map<number, string[]>

  /**
   * list of parking lots calling user owns
   */
  private parkinglot: ParkingLotModel[];
     

  constructor() {}

  /**
   * Overriding the current userdata with new data.
   * Flushing all and setting new permissions depending on the userData.
   * @param userData
   * @todo set permissions
   */
  setUserData(userData: UserData): void {
    this.userData = userData;
    this.parkingLotPermissions = userData.parking_lot_permissions;
  }

  /**
   * Returning the userData.
   */
  getUserData(): UserData {
    return this.userData;
  }

  /**
   * Stores all parking lot id and its corresponding customer-plid (optional)
   */
  setCustomerPlidList(customerPlidMap: Map<number,number>) {
    this.customerPlidList = customerPlidMap;
  }

  /**
   * Gets all parking lot id and its corresponding customer-plid
   * @returns a list of parking lot id and its corresponding customer-plid
   */
  getCustomerPlisList(): Map<number,number> {
    return this.customerPlidList;
  }

  /**
   * Stores all parking lot permissions current user owns
   */
  setParkinglotPermissions(): void {
    this.parkingLotPermissions = this.getUserData().parking_lot_permissions;
  }

  /**
   * Gets all parking lot permissions a specific parking lot was granted 
   * @param plid parking lot id
   * @returns a list of parking lot permissions
   */
  getParkinglotPermissionsByPLID(plid: number): string[] {
    return this.parkingLotPermissions.get(plid);
  }


  /**
   * Stores all parking lots the calling user owns
   */
  set setParkingLotBasicData(data: ParkingLotModel[]) {
    this.parkinglot = data;
  }

  /**
   * Gets all parking lots
   * @returns a list of parking lots
   */
  get getParkingLotBasicData(): ParkingLotModel[] {
    return this.parkinglot;
  }

}
