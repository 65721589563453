import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from "@angular/core";
import { AuthResponse, AuthService } from '@frontend-monorepo/core';
import { ParkingLotMap } from '@frontend-monorepo/parking-lot-map';
import { Observable, of } from 'rxjs';
import { EventLocations, EventLocation } from '../models/event_location';
import { Event, EventSubjectData } from '../models/event';
import * as _2634 from '../test/2634.json';
import * as _2707 from '../test/2707.json';
import * as _2708 from '../test/2708.json';
import * as _2842 from '../test/2842.json';
import * as _117 from '../test/117.json';
import { Reservation } from '../models/reservation';
import { AuthResponseEvent } from '../models/user';

/**
 * Includes all API-Requests for the event-dashboard.
 * Authentication service is used from the core-library to login and sage user data.
 * 
 * This discrepancy between the core-event/api.service and the core/coraApi.service is a complete differend end-point with new backend data.
 */

@Injectable({
    providedIn: "root"
})
export class ApiService {

    /**
     * Instead of the bearer token, you can also set a static apiKey for performing requests
     */
    private apiKey: string;

    /**
     * The useApiKey parameter refers to the core-event.module.ts withConfig result.
     */
    private useApiKey: boolean;

    /**
     * This is the base url for all requests
     */
    private baseApi: string = 'https://event-api.smart-city-system.com/';

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {
        // if (config){
        //     this.useApiKey = config.useApiToken;
        // }
    }

    setApiKey(_apiKey: string) {
        this.apiKey = _apiKey;
    }

    setUseApiKey(state: boolean) {
        this.useApiKey = state;
    }

    getHeaders(contentType: string = "application/json"): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set("Content-Type", contentType);

        if (this.useApiKey){
            headers = headers.set("X-Api-Key", this.apiKey);
        } else {
            headers = headers.set("X-Auth-Token", this.authService.getToken());
        }

        return headers;
    }

    /**
     * get requests
     */

    fetchAllEventLocations(): Observable<Array<EventLocations>>{
        let url = this.baseApi + 'event-locations';
        return this.http.get<Array<EventLocations>>(url, {headers: this.getHeaders()});
    }

    fetchEventLocationById(eventLocationId: number): Observable<EventLocation>{
        let url = this.baseApi + 'event-locations/' + eventLocationId;
        return this.http.get<EventLocation>(url, {headers: this.getHeaders()});
    }

    fetchEventByEventLocationId(eventLocationId: number): Observable<Array<Event>> {
        let url = this.baseApi + 'event-locations/' + eventLocationId + '/events';
        return this.http.get<Array<Event>>(url, {headers: this.getHeaders()});
    }

    fetchEventLocationMap(eventLocationId: number): Observable<ParkingLotMap> {
        if (eventLocationId == 4) {
            let a = JSON.stringify(_2707);
            let test = <ParkingLotMap>JSON.parse(a).default;
            return of(test);
        } else if (eventLocationId == 3) {
            let a = JSON.stringify(_2634);
            let test = <ParkingLotMap>JSON.parse(a).default;
            return of(test);
        } else if (eventLocationId == 5) {
            let json = JSON.stringify(_2708);
            let data = <ParkingLotMap>JSON.parse(json).default;
            return of(data);
        } else if (eventLocationId == 6) {
            let json = JSON.stringify(_2842);
            let data = <ParkingLotMap>JSON.parse(json).default;
            return of(data);
        }
        // let json = JSON.stringify(_117);
        // let data = <ParkingLotMap>JSON.parse(json).default;
        // return of(data);
    }

    fetchEventbyId(eventId: number): Observable<EventSubjectData> {
        let url = this.baseApi + 'events/' + eventId;
        return this.http.get<EventSubjectData>(url, {headers: this.getHeaders()});
    }

    fetchReservationForEvent(eventId: number): Observable<Array<Reservation>> {
        let url = this.baseApi + 'events/' + eventId + "/reservations";
        return this.http.get<Array<Reservation>>(url, {headers: this.getHeaders()});
    }

    /**
     * post requests
     */

    doLogin(username: string, password: string): Observable<AuthResponseEvent> {
        let url = this.baseApi + 'auth';

        var body  = {
            username: username,
            password: password
        };

        return this.http.post<AuthResponseEvent>(url, JSON.stringify(body), {headers: {
            "Content-Type": "application/json"
          }});
    }

    postReservation(eventId: number, json: string) {
        let url = this.baseApi + "events/" + eventId + "/reservations" 
        return this.http.post<any>(url, json, {headers: this.getHeaders()});
    }

    postEvent(eventLocationId: number, json: string) {
        let url = this.baseApi + 'event-locations/' + eventLocationId + '/events';
        return this.http.post<any>(url, json, {headers: this.getHeaders()});
    }

    doLogout() {

    }



    /**
     * put requests
     */

    putSeatTags(eventlocationId: number, seatId: number, json: string): Observable<any> {
        let url = this.baseApi + "event-locations/" + eventlocationId + "/seats/" + seatId + "/tags"
        return this.http.put<any>(url, json, {headers: this.getHeaders()});
    }


    putReservable(eventId: number, seatId: number): Observable<any> {
        let url = this.baseApi + "events/" + eventId + "/seats/" + seatId + "/reservable"
        return this.http.put<any>(url, "", {headers: this.getHeaders()});
    }


    /**
     * delete requests
     */
    deleteReservable(eventId: number, seatId: number): Observable<any> {
        let url = this.baseApi + "events/" + eventId + "/seats/" + seatId + "/reservable"
        return this.http.delete<any>(url, {headers: this.getHeaders()});
    }

    deleteReservation(eventId: number, seatId: number) {
        let url = this.baseApi + "events/" + eventId +  "/seats/" + seatId +"/reservation" 
        return this.http.delete<any>(url, {headers: this.getHeaders()});
    }

    deleteEvent(eventId: number) {
        let url = this.baseApi + "events/" + eventId 
        return this.http.delete<any>(url, {headers: this.getHeaders()});
    }
    
}



