import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, Observer, Subscription, VirtualTimeScheduler } from 'rxjs';
import { AuthService, RepositoryService } from '@frontend-monorepo/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GuardAuthDialogComponent } from '../../../guards/guard-auth-dialog/guard-auth-dialog.component';
import { GuardLoadingDialogComponent } from './guard-loading-dialog/guard-loading-dialog.component';
import { Variable } from '../../../helper/variable';
import { UserDataService } from '@frontend-monorepo/parking-lot-map';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class ParkingLotInternalGuardService implements CanActivate, OnDestroy {
  mainSubscription: Subscription;
  plid: string;

  constructor(
    private repositoryService: RepositoryService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private userDataService: UserDataService,
    private translocoService: TranslocoService,
    private router: Router
  ) {
    this.mainSubscription = new Subscription();
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    this.plid = route.paramMap.get('PLID');

    return Observable.create((observer: Observer<boolean>) => {
      if (this.repositoryService.authService().getTokens() != null) {
        this.setupRequiredData(observer);
      } else {
        let dialogRef = this.dialog.open(GuardAuthDialogComponent, { disableClose: true });

        this.mainSubscription.add(
          dialogRef.afterClosed().subscribe((x) => {
            this.setupRequiredData(observer);
          })
        );
      }
    });
  }

  private setupRequiredData(observer: Observer<boolean>) {
    if (this.userDataService.getParkinglotPermissionsByPLID(+this.plid) == undefined) {
      this.snackbar.open(this.translocoService.translate('PARKINGLOT.error_guard_loading'));
      this.router.navigateByUrl('login');
    } else {
      let dialogRef = this.dialog.open(GuardLoadingDialogComponent, {
        disableClose: true,
        data: this.plid
      });

      this.mainSubscription.add(
        dialogRef.afterClosed().subscribe((result) => {
          if (result.data === Variable.LOADING_DATA_SUCCESSFUL) {
            // console.log(Variable.LOADING_DATA_SUCCESSFUL)
            observer.next(true);
            observer.complete();
          } else if (result.data === Variable.LOADING_DATA_UNSUCCESSFUL) {
            // console.log(Variable.LOADING_DATA_UNSUCCESSFUL)
            observer.next(false);
            observer.complete();
            this.snackbar.open('Während dem Laden der Daten ist leider ein Fehler aufgetreten!');
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.mainSubscription.unsubscribe();
  }
}
