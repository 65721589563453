import { UserData } from '@frontend-monorepo/parking-lot-map';
import { UserDao } from '../models/user-dao.model';

/**
 * Helper function merging all permissions with inherited permissions
 * @param _accountInfo incoming data set from api call
 * @returns `User-Data`-Type Object with all merged permissions
 */
export function mergeUserPermissions(_accountInfo: UserDao): UserData {
  let userData: UserData = {
    id: _accountInfo.id,
    email: _accountInfo.email,
    locked: _accountInfo.locked,
    name: _accountInfo.name,
    username: _accountInfo.username,
    settings: _accountInfo.settings,
    user_permissions: _accountInfo.user_permissions,
    parking_lot_permissions: new Map(
      Object.keys(_accountInfo.parking_lot_permissions).map((key) => [
        +key,
        _accountInfo.parking_lot_permissions[+key]
      ])
    ),
    parent_user_id: _accountInfo.parent_user_id
  };

  // checking inherited user permissions and merges if necessary
  if (_accountInfo.inherited_user_permissions.length) {
    userData.user_permissions = userData.user_permissions.concat(
      _accountInfo.inherited_user_permissions
    );
  }

  // checking inherited parking lot permissions and merges if necessary
  if (_accountInfo.inherited_parking_lot_permissions.size) {
    let inherited_perm = new Map(
      Object.keys(_accountInfo.inherited_parking_lot_permissions).map((key) => [
        +key,
        _accountInfo.inherited_parking_lot_permissions[+key]
      ])
    );
    Array.from(inherited_perm.entries()).map((parkinglot) => {
      if (userData.parking_lot_permissions.get(parkinglot[0])) {
        userData.parking_lot_permissions.set(
          parkinglot[0],
          userData.parking_lot_permissions.get(parkinglot[0]).concat(parkinglot[1])
        );
      } else {
        userData.parking_lot_permissions.set(parkinglot[0], parkinglot[1]);
      }
    });
  }
  return userData;
}
