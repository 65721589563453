import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectionListChange } from '@angular/material/list';
import { Comment, Failure, RepositoryService } from '@frontend-monorepo/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'frontend-monorepo-parking-space-filter',
  templateUrl: './parking-space-filter.component.html',
  styleUrls: ['./parking-space-filter.component.scss']
})
export class ParkingSpaceFilterComponent implements OnInit {
  @Input()
  set psidlist(psidList: number[]) {
    this.psidList = psidList;
    this.filteredPsidList = psidList;
  }
  @Input() parkingLotId: number;
  @Output() sendPsidList = new EventEmitter();

  psidList: number[];
  filteredPsidList: number[] = [];
  selectedPsidList: number[] = [];
  isOpen = false;

  constructor() {}

  ngOnInit(): void {}

  onSelectPsid(event: MatSelectionListChange) {
    this.selectedPsidList = event.source.selectedOptions.selected.map((item) => item.value);
    this.sendPsidList.emit(this.selectedPsidList);
  }

  changeValuePsid(id: number) {
    const psids = [...this.filteredPsidList];
    this.psidList = psids.filter((_psid) => _psid.toString().includes(id.toString()));
  }

  remove(psid: number) {
    let index: number = this.selectedPsidList.findIndex((_psid) => _psid === psid);
    this.selectedPsidList.splice(index, 1);
    this.sendPsidList.emit(this.selectedPsidList);
  }
}
