import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { TranslocoService, AvailableLangs } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

/**
 * Simple LanguagePicker Component
 * 
 * 
 * Provides a [MatMenu] for changing the app's language.
 * 
 * Currently supported languages: 'de' & 'en'
 */
@Component({
  selector: 'mp-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguagePickerComponent implements OnInit, OnDestroy {


  selectedLanguage: string;
  availableLanguages: AvailableLangs;

  private _subscriptions: Subscription;
  constructor(
    private translocoService: TranslocoService,
    private cd: ChangeDetectorRef
  ) {
    this._subscriptions = new Subscription();
  }

  ngOnInit() {
    this._initializeLangChangeSubscription();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _initializeLangChangeSubscription() {
    this._subscriptions.add(
      this.translocoService.langChanges$.subscribe(lang => {
        this.selectedLanguage = lang;
        this.cd.detectChanges();
      })
    );
  }

  setActiveLang(newLang: 'de' | 'en'): void {
    this.translocoService.setActiveLang(newLang);
  }

}
