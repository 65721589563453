import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthResponse } from '../interfaces/Authentication';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import {
  ParkingLot,
  ParkingSpace,
  Gateway,
  Repeater,
  ParkingLotIssue,
  Device,
  OccupationDuration,
  ParkingSpaceDevice
} from '../interfaces/ParkingLot';
import { map, retry } from 'rxjs/operators';
import { ParkingLotWithOccupancies } from '../interfaces/ParkingLotWithOccupancies';
import { ContactDetails } from '../interfaces/ContactDetails';
import { USE_API_KEY } from '../tokens/useApiKey.token';
import { API_BASE_URL, CustomerPLIDModel } from '@frontend-monorepo/core';

/**
 * Consists of all API-related functions to fetch or manipulate data.
 *
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@Injectable({
  providedIn: 'root'
})
export class CoreApiService {
  /**
   * API key used for authentication.
   *
   * An API key must be provided, if the {@link CoreModule} was imported with `CoreModule.withConfig({ useApiKey: true })`
   */
  private _apiKey?: string;

  /**
   *
   * @param http
   * @param authService
   * @param _useApiKey Whether or not to use the {@link _apiKey} for authentication instead of a bearer token.
   */
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Optional() @Inject(USE_API_KEY) private _useApiKey: boolean
  ) {}

  /**
   * Setting the `_apiKey`.
   *
   * If you want to use an API key instead of a bearer token for authentication (requires the importing of the `CoreModule` like `CoreModule.withConfig({useApiKey: true})`),
   * setting the API key globally by calling this method is absolutely necessary.
   *
   * @param apiKey The API key used for authentication instead of the bearer token.
   */
  setApiKey(apiKey: string) {
    this._apiKey = apiKey;
  }

  getApiKey(): string {
    return this._apiKey;
  }

  /**
   * Returning a basic HTTPHeaders Object with `application/json` as `Content-Type`.
   *
   * @param withAuthentication If true, depending on {@link CoreApiService._useApiKey} either a bearer token or an API key is used for authentication.
   * @param contentType The header's `Content-Type`. Defaults to `application/json`
   */
  getHeaders(withAuthentication?: boolean, contentType: string = 'application/json'): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', contentType);

    if (withAuthentication) {
      if (this._useApiKey) {
        headers = headers.set('X-API-Key', this._apiKey);
      } else {
        if (this.authService.getToken() != null) {
          headers = headers.set('X-Auth-Token', this.authService.getToken());
        }
        if (this.authService.getTwoFactorToken() != null) {
          headers = headers.set('X-Two-Factor-Token', this.authService.getTwoFactorToken());
        }
      }
    }
    return headers;
  }

  /**
   * Fetching all installed devices associated with a parking lot as a map.
   * The object fetched from the API looks like this:
   *``` 
    {
      {<PSID>: <Device>},
      {<PSID>: <Device>},
      ...
    }
    ```
   * #### Transforming the key-value map to an array of devices by adding the key (aka PSID) as a parameter to the devices.
   * 
   *
   * @param parkingLotID ID of a parking lot, whichs list of installed devices should be fetched.
   * @param amountOfRetries The amount of retries after failing requests before returning an error.
   */
  fetchInstalledDevicedOfPL(
    parkingLotID: number,
    amountOfRetries: number = 0
  ): Observable<Array<Device>> {
    const headers = this.getHeaders(true);
    return this.http.get<Object>(API_BASE_URL + `parkinglots/${parkingLotID}/devices`).pipe(
      retry(amountOfRetries),
      map((installedDevicesMap) => {
        Object.entries(installedDevicesMap).map(([_psid, _device]) => {
          const psid = parseInt(_psid);
          const device = <Device>_device;
          device.psid = psid;
        });
        return Object.values(installedDevicesMap);
      })
    );
  }

  /**
   * Fetching parkingspace device associated with a parking lot as a map.
   *
   * @param parkingspaceID ID of a parkingspace, from which the device information should be fetched.
   * @param amountOfRetries The amount of retries after failing requests before returning an error.
   */
  fetchInstalledParkingspaceDevicedOfPL(
    parkingspaceID: number,
    amountOfRetries: number = 0
  ): Observable<ParkingSpaceDevice> {
    const headers = this.getHeaders(true);
    return this.http
      .get<ParkingSpaceDevice>(API_BASE_URL + `parkingspaces/${parkingspaceID}/device`)
      .pipe(retry(amountOfRetries));
  }

  /**
   * Fetching all gateways associated with a parking lot.
   * @deprecated
   * @param parkingLotID ID of a parking lot, whichs gateways should be fetched.
   * @param amountOfRetries The amount of retries after failing requests before returning an error.
   */
  fetchGateawaysOfPL(
    parkingLotID: number,
    amountOfRetries: number = 0
  ): Observable<Array<Gateway>> {
    const headers = this.getHeaders(true);
    return this.http
      .get<Array<Gateway>>(API_BASE_URL + `parkinglots/${parkingLotID}/gateways`)
      .pipe(retry(amountOfRetries));
  }

  /**
   * Fetching all repeaters associated with a parking lot.
   *
   * @deprecated
   * @param parkingLotID ID of a parking lot, whichs repeaters should be fetched.
   * @param amountOfRetries The amount of retries after failing requests before returning an error.
   */
  fetchRepeatersOfPL(
    parkingLotID: number,
    amountOfRetries: number = 0
  ): Observable<Array<Repeater>> {
    const headers = this.getHeaders(true);
    return this.http
      .get<Array<Gateway>>(API_BASE_URL + `parkinglots/${parkingLotID}/repeaters`)
      .pipe(retry(amountOfRetries));
  }

  /**
   * Fetching all issues associated with a parking lot.
   *
   * @param parkingLotID ID of a parking lot, whichs issues should be fetched.
   * @param amountOfRetries The amount of retries after failing requests before returning an error.
   */
  fetchIssuesOfPL(
    parkingLotID: number,
    amountOfRetries: number = 0
  ): Observable<Array<ParkingLotIssue>> {
    const headers = this.getHeaders(true);
    return this.http
      .get<Array<ParkingLotIssue>>(API_BASE_URL + `parkinglots/${parkingLotID}/issues`)
      .pipe(retry(amountOfRetries));
  }

  /**
   * Fecthing a parking lots SCS-internal contact
   */
  fetchInternalContactOfParkingLot(): Observable<ContactDetails> {
    const mockContactDetails: ContactDetails = {
      fullName: 'Maximilian Mustermann',
      email: 'maximilian.mustermann@smart-city-system.com',
      phoneNumer: '+49 1234 56 78',
      avatarUrl: 'https://top5rio.com.br/images/user-placeholder.svg'
    };
    return of(mockContactDetails);
  }
}
