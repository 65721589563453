<div
  *ngIf="checkDeviceType()"
  [class.deviceSpaceImage]="checkDeviceType()"
  [style.width]="deviceSpace.width-15 + 'px'"
  [style.height]="deviceSpace.height-15 + 'px'"
  [style.top]="deviceSpace.y+8 + 'px'"
  [style.left]="deviceSpace.x+8 + 'px'"
  [style.transform]="'rotate(' + deviceSpace.rotation + 'deg)'"
  (touchstart)="onDeviceSpaceClick()"
  (click)="onDeviceSpaceClick()"
> 
</div>

<!-- <div
  #deviceSpaceDiv
  [style.width]="deviceSpace.width + 'px'"
  [style.height]="deviceSpace.height + 'px'"
  [style.top]="deviceSpace.y + 'px'"
  [style.left]="deviceSpace.x + 'px'"
  [style.transform]="'rotate(' + deviceSpace.rotation + 'deg)'"
  (click)="onDeviceSpaceClick()"
  [ngClass]="{gateway: getDeviceType()}"
> 
</div> -->