import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IssuesOfParkingLotComponent } from './component/issues-of-parking-lot.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';


import de from './i18n/de.json';
import en from './i18n/en.json';

/**
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@NgModule({
    declarations: [
        IssuesOfParkingLotComponent
    ],
    imports: [
        CommonModule,
        TranslocoModule
    ],
    exports: [
        IssuesOfParkingLotComponent
    ],
})
export class IssuesOfParkingLotModule {
    constructor(translocoService: TranslocoService) {
        translocoService.setTranslation(en, 'en');
        translocoService.setTranslation(de, 'de');
    }
}