import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'frontend-monorepo-policy-dialog',
  templateUrl: './policy-dialog.component.html',
  styleUrls: ['./policy-dialog.component.scss']
})
export class PolicyDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
