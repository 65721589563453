import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingProgressIndicatorComponent } from './component/loading-progress-indicator.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
@NgModule({
    declarations: [
        LoadingProgressIndicatorComponent
    ],
    imports: [
        CommonModule,
        MatProgressBarModule
    ],
    exports: [
        LoadingProgressIndicatorComponent
    ],
})
export class LoadingProgressIndicatorModule { }