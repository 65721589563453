import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import de from './i18n/de.json';
import en from './i18n/en.json';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [ConfirmationDialogComponent],
  imports: [CommonModule, TranslocoModule, MatButtonModule, MatDialogModule, MatInputModule],
  exports: [ConfirmationDialogComponent]
})
export class ConfirmationDialogModule {
  constructor(translocoService: TranslocoService) {
    translocoService.setTranslation(en, 'en');
    translocoService.setTranslation(de, 'de');
  }
}
