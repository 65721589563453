import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SkipSelf } from '@angular/core';
import { API_BASE_URL, CoreApiService, Failure } from '@frontend-monorepo/core';
import { UserData } from '@frontend-monorepo/parking-lot-map';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { UserDao } from './models/user-dao.model';
import { UserBase } from './user.base';
import { mergeUserPermissions } from './utils/user-data-construct.helper';

export class UserRepository extends UserBase {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Fetching account info of the authenticated user.
   *
   * @returns `UserData`-Object containing all user related information
   */
  fetchAccountInfo(): Observable<UserData> {
    return this.http.get<UserDao>(API_BASE_URL + 'account').pipe(
      map(
        (_accountInfo: UserDao) => mergeUserPermissions(_accountInfo)
      ),
      catchError( (error: HttpErrorResponse) => {
        throw new Failure(error.status, error.message);
      })
    );
  }

  /**
   * Fetching account info by user id
   *
   * @param userId id of user
   * @returns `UserData`-Object containing all user related information
   */
  fetchAccountInfoById(userId: number): Observable<UserData> {
    let observable = new Observable<UserData>((subscriber) => {
      this.http.get<UserDao>(API_BASE_URL + `account/${userId}`).subscribe({
        next: (_accountInfo) => {
          subscriber.next(<UserData>mergeUserPermissions(_accountInfo));
        },
        error: (error: HttpErrorResponse) => {
          subscriber.error(new Failure(error.status, error.message));
        }
      });
    });
    return observable;
  }

  fetchAccountInformationApiKey(apikey: string): Observable<UserData> {
    let url = 'https://api.parking-pilot.com/account';
    return this.http
      .get<UserData>(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'x-api-key': apikey
        }
      })
      .pipe();
  }
}
