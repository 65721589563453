import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Failure } from 'libs/core/src/lib/repository/http-response/failure.model';
import { RepositoryService } from 'libs/core/src/lib/services/repository/repository.service';

/**
 * Components displaying a Form to complete user creation via invitation Link
 * @example https://dashboard.smart-city-system.com/user-invite?token=jaksdvashgdv751253asd8
 * User will be redireted to Login-Page when user was created successfully
 */
@Component({
  selector: 'frontend-monorepo-user-invite-completion',
  templateUrl: './user-invite-completion.component.html',
  styleUrls: ['./user-invite-completion.component.scss']
})
export class UserInviteCompletionComponent implements OnInit {
  userCompletionForm: FormGroup;
  inviteToken: string;

  private token_valid: boolean = true;

  /**
   * password related declaration
   */
  private specialCharacter: string = '!?=_@#&*;:-_+§£()><|.,}{^°';
  private userErrorText: string = '';
  private showUserError: boolean = false;
  private showPasswordControlError: boolean = true;
  private passwordControlErrorText: string = '';
  private password_min_length = false;
  private password_upper_case = false;
  private password_lower_case = false;
  private password_number = false;
  private password_special_char = false;
  private username_special_char = false;
  private hide: boolean = true;

  constructor(
    private translocoService: TranslocoService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private repositoryService: RepositoryService
  ) {}

  ngOnInit(): void {
    this.getInviteToken();
    this.setupFormGroup();
  }

  /**
   * retrieves token
   */
  private getInviteToken() {
    this.inviteToken = this.activatedRoute.snapshot.queryParamMap.get('invite_token');
    this.repositoryService
      .userInvitationRepository()
      .getAccountInvite(this.inviteToken)
      .subscribe({
        next: (_) => (this.token_valid = true),
        error: (_err: Failure) => (this.token_valid = false)
      })
  }

  setupFormGroup() {
    this.userCompletionForm = new FormGroup({
      username: new FormControl(undefined, [Validators.minLength(5), this.validatorUsername()]),
      password: new FormControl(undefined, [
        this.validatorUpperCase(),
        this.validatorLowerCase(),
        this.validatorNumber(),
        this.validatorSpecialChar(),
        this.validatorMinLength()
      ]),
      password_second: new FormControl(undefined, [
        Validators.minLength(8),
        this.passwordValidator()
      ])
    });
  }

  returnToLoginPage(): void {
    this.route.navigateByUrl('login');
  }

  completeAuthentication(): void {
    this.repositoryService
      .userInvitationRepository()
      .postAcceptInvite(
        this.inviteToken,
        this.userCompletionForm.get('username').value,
        this.userCompletionForm.get('password').value
      )
      .subscribe({
        next: (_) => {
          this.snackBar.open(this.translocoService.translate('createUser_successful'));
          this.returnToLoginPage();
        },
        error: (error: Failure) =>
          this.snackBar.open(this.translocoService.translate(`${error.errorMessage}`))
      });
  }

  changeValueUsername(value: string) {
    let username = this.userCompletionForm.controls['username'];

    if (username.hasError('minlength')) {
      this.userErrorText = this.translocoService.translate('minimal_length_5');
      this.showUserError = true;
    } else if (username.hasError('usernameValidation')) {
      this.userErrorText = this.translocoService.translate('invalid_character');
      this.showUserError = true;
    } else {
      this.showUserError = false;
    }
  }

  changeValuePassword(value: string) {
    let psControl = this.userCompletionForm.controls['password_second'];
    let password = this.userCompletionForm.controls['password'];

    if (psControl.value != null) {
      if (psControl.value !== value) {
        this.passwordControlErrorText = this.translocoService.translate('passwords_not_match');
        this.showPasswordControlError = true;
        psControl.setErrors['passwordsDoNotMatch'];
      } else if (psControl.value === value) {
        this.showPasswordControlError = false;
        psControl.setErrors(null);
        this.userCompletionForm.updateValueAndValidity();
      }
    }

    if (password.value != null) {
      this.password_upper_case = !password.hasError('validateUpperCase');
      this.password_lower_case = !password.hasError('validateLowerCase');
      this.password_number = !password.hasError('validateNumber');
      this.password_special_char = !password.hasError('validateSpecialChar');
      this.password_min_length = !password.hasError('validatorLength');
    }
  }

  /**
   * Validation
   */
  private validatorUsername(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.userCompletionForm !== undefined) {
        const regEx = new RegExp('^[A-z0-9.\\-_]+$');
        const value: string = this.userCompletionForm.controls['username'].value;
        const condition = regEx.test(value);

        return condition ? null : { usernameValidation: { valid: true, value: true } };
      }
    };
  }

  private passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.userCompletionForm !== undefined) {
        const condition =
          this.userCompletionForm.controls['password'].value !==
          this.userCompletionForm.controls['password_second'].value;
        return condition ? { passwordsDoNotMatch: { valid: false, value: true } } : null;
      }
    };
  }

  private validatorUpperCase(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.userCompletionForm !== undefined) {
        const regEx = new RegExp('^(?=[^A-Z]*[A-Z])');
        const value: string = this.userCompletionForm.controls['password'].value;
        const condition = regEx.test(value);

        return condition ? null : { validateUpperCase: { valid: false, value: true } };
      }
    };
  }

  private validatorLowerCase(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.userCompletionForm !== undefined) {
        const regEx = new RegExp('(?=[^a-z]*[a-z])');
        const value: string = this.userCompletionForm.controls['password'].value;
        const condition = regEx.test(value);

        return condition ? null : { validateLowerCase: { valid: false, value: true } };
      }
    };
  }

  private validatorNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.userCompletionForm !== undefined) {
        const regEx = new RegExp('(?=\\D*\\d)');
        const value: string = this.userCompletionForm.controls['password'].value;
        const condition = regEx.test(value);

        return condition ? null : { validateNumber: { valid: false, value: true } };
      }
    };
  }

  private validatorSpecialChar(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.userCompletionForm !== undefined) {
        const regEx = new RegExp('(?=.*[!?=()€£/~@#\\$%\\^&\\*;:\\-_+§<>|.,}{^^°])');
        const value: string = this.userCompletionForm.controls['password'].value;
        const condition = regEx.test(value);

        return condition ? null : { validateSpecialChar: { valid: false, value: true } };
      }
    };
  }

  private validatorMinLength(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.userCompletionForm !== undefined) {
        const regEx = new RegExp('[A-Za-z\\d!?=()~$%/@#£€*?&;:\\-_+§<>|.,}{^^°]{8,}$');
        const value: string = this.userCompletionForm.controls['password'].value;
        const condition = regEx.test(value);

        return condition ? null : { validatorLength: { valid: true, value: true } };
      }
    };
  }
}
