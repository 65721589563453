import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthResponse } from '../interfaces/Authentication';
import { USE_API_KEY } from '../tokens/useApiKey.token';
import { HEADERS } from '../utils/constants/headers';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private hasCookie: boolean;
  private token: string;
  private twoFactorToken: string;
  /**
   * determines whether two factor authentication is enbaled
   */
  isTwoFactorEnabled: boolean;
  /**
   * API key used for authentication.
   *
   * An API key must be provided, if the {@link CoreModule} was imported with `CoreModule.withConfig({ useApiKey: true })`
   */
  private _apiKey?: string;

  constructor(
    @Optional() @Inject(USE_API_KEY) private _useApiKey: boolean,
    private http: HttpClient
  ) {
    this.isTwoFactorEnabled = false;
    this.hasCookie = false;
  }

  /**
   * Overriding the current token with a new one.
   * @todo Save token in localstorage
   * @todo Set token-expiration
   * @param newToken
   */
  setToken(newToken: string): void {
    this.token = newToken;
  }
  /**
   * Returning the current auth token.
   */
  getToken(): string {
    return this.token;
  }

  resetToken(): void {
    this.token = undefined;
    this.hasCookie = false;
  }

  /**
   * Returns both token in AuthResponse or null if bearer token is not set
   */
  getTokens(): any {
    return this.token == null ? null : { token: this.token, two_factor_token: this.twoFactorToken };
  }

  /**
   * Determines if a user is logged in.
   * @todo Check expiration date.
   */
  isUserAuthorized(): boolean {
    return this.token !== undefined;
  }

  /**
   * Determines if a user is logged in using 2 factor authentication
   * @todo Check expiration date.
   */
  is2FactorAuthorized(): boolean {
    return this.token !== undefined && this.twoFactorToken !== undefined;
  }

  /**
   * Set two factor token
   * @param newToken
   */
  setTwoFactorToken(twoTactorToken: string): void {
    this.twoFactorToken = twoTactorToken;
  }
  /**
   * Returning the current two factor token.
   */
  getTwoFactorToken(): string {
    return this.twoFactorToken;
  }

  /**
   *
   * @param isTwoFactorEnabled
   */
  setTwoFactorEnabled(isTwoFactorEnabled: boolean): void {
    this.isTwoFactorEnabled = isTwoFactorEnabled;
  }

  /**
   * Setting the `_apiKey`.
   *
   * If you want to use an API key instead of a bearer token for authentication (requires the importing of the `CoreModule` like `CoreModule.withConfig({useApiKey: true})`),
   * setting the API key globally by calling this method is absolutely necessary.
   *
   * @param apiKey The API key used for authentication instead of the bearer token.
   */
  setApiKey(apiKey: string) {
    this._apiKey = apiKey;
  }

  getApiKey(): string {
    return this._apiKey;
  }

  useApiKey(): boolean {
    return this._apiKey != null;
  }

  setCookieStatus(cookieState: boolean) {
    this.hasCookie = cookieState;
  }

  getCookieStatus(): boolean {
    return this.hasCookie;
  }
}
