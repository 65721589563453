import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MainDataService, PARKING_LOT_DEVICE_TYPE, ParkingLotModel } from '@frontend-monorepo/core';
import { UserDataService, PARKING_LOT_PERMISSION } from '@frontend-monorepo/parking-lot-map';
import { BehaviorSubject, Subscription, interval } from 'rxjs';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { DownloadProgressBarComponent } from '../download-progress-bar/download-progress-bar.component';
import { filter } from 'rxjs/internal/operators/filter';
import { take } from 'rxjs/operators';

@Component({
  selector: 'frontend-monorepo-export-camera-events',
  templateUrl: './export-camera-events.component.html',
  styleUrls: ['./export-camera-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportCameraEventsComponent implements OnInit, OnDestroy {
  private $parkingList: Subscription;

  accessDenied: boolean;
  downloadDate: number;

  private parkingLots: number[];
  parkingLotList: ParkingLotModel[];
  selectedListStatus: boolean;
  selectedParkingLotList: ParkingLotModel[];

  constructor(
    private userDataService: UserDataService,
    private mainDataService: MainDataService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) {
    this.selectedListStatus = false;
    this.parkingLotList = [];
    this.selectedParkingLotList = [];
  }

  ngOnInit(): void {
    this.downloadDate = moment().subtract(1, 'day').unix();

    this._checkPermission();

    if (!this.accessDenied) {
      const duration = 3; // Duration in seconds
      interval(1000) // Emit a value every second
        .pipe(take(duration + 1)) // Take the specified number of values
        .subscribe({
          next: () => {},
          complete: () => {
            this._setUpList();
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.$parkingList.unsubscribe();
  }

  /**
   * check for available parking lots with "camera_events" parking lot permission
   */
  private _checkPermission() {
    this.accessDenied =
      Array.from(this.userDataService.getUserData().parking_lot_permissions.entries()).filter(
        (pl) => pl[1].includes(PARKING_LOT_PERMISSION.CAMERA_EVENTS)
      ).length < 1;
  }

  /**
   * check for available parking lots with "camera_events" parking lot permission
   */
  private _setUpList() {
    this.$parkingList = this.mainDataService.listOPL
      .pipe(filter((_parkingLot) => _parkingLot.size > 0))
      .subscribe((_parkingLot) => {
        if (_parkingLot.size > 0) {
          this.parkingLots = Array.from(
            this.userDataService.getUserData().parking_lot_permissions.entries()
          )
            .filter(
              (pl) =>
                pl[1].includes(PARKING_LOT_PERMISSION.CAMERA_EVENTS) &&
                _parkingLot.get(pl[0])?.getValue().parking_lot_device_type ==
                  PARKING_LOT_DEVICE_TYPE.CAMERA &&
                PARKING_LOT_DEVICE_TYPE.MIX
            )
            .map((_pl) => _pl[0]);
          this.cd.detectChanges();
        }
      });
      this._prepareParkingLotList();
  }

  private _prepareParkingLotList() {
      this.accessDenied = this.parkingLots.length < 1;
      this.parkingLotList = this.parkingLots.map((_pl) => {
        return {
          id: _pl,
          name: this.userDataService.getParkingLotBasicData.find((pl) => pl.id == _pl).name
        };
      });
      this.cd.detectChanges();
  }

  /**
   * gets selected list from `ParkingLotsListComponent`
   * @param list of selected parking lots
   */
  getSelectedList(list: ParkingLotModel[]) {
    this.selectedParkingLotList = list;
  }

  /**
   * opens Dialog and start the sequentiell file download of each parking lot
   * if the download is completed, the `selectedListStatus` will be set to true to return list
   * to its defualt state
   */
  startDownload() {
    let dialogRef = this.dialog.open(DownloadProgressBarComponent, {
      data: this.selectedParkingLotList,
      autoFocus: false,
      width: '25vw',
      maxWidth: '40wvw'
    });

    dialogRef.afterClosed().subscribe((action: string) => {
      if (action == 'finish') {
        this.selectedListStatus = true;
        this.cd.detectChanges();
      }
    });
  }
}
