import { Injectable } from '@angular/core';
import { AuthService, MainDataService, WebsocketMessageModel } from '@frontend-monorepo/core';
import { Subscription } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { WebSocketBase } from './websocket_base.interface';

const INCIDENTS_URL = 'wss://api.parking-pilot.com/incidents/websocket';

export class IncidentsWebsocketService implements WebSocketBase{

  private mainSubscription: Subscription;
  private incidentsWS: WebSocketSubject<any>

  constructor(
    private authService: AuthService,
    private mainDataService: MainDataService
  ) { 
    this.mainSubscription = new Subscription();
  }


  start() {
    this.incidentsWS = webSocket({
      url: INCIDENTS_URL,
      openObserver: {
        next: () => {
          if (this.authService.isTwoFactorEnabled) {
            const tokens = this.authService.getTokens();
            this.incidentsWS.next({
              token: tokens?.token,
              two_factor_token: tokens?.two_factor_token
            })
          } else {
            this.incidentsWS.next({
              token: this.authService.getToken(),
            })
          }
        }
      }
    });

    this.mainSubscription.add(
      this.incidentsWS.subscribe(data => {
        this.mainDataService.updateIncidents(data);
      }, (err) => {
        this.stop();
      })
    )
  }
  stop() {
    this.incidentsWS.complete();
    this.incidentsWS = undefined;
    this.mainSubscription.unsubscribe();
  }
  isRunning(): boolean {
    return this.incidentsWS == undefined ? false : true; 
  }
}
