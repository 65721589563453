import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[onlyNumbers]'
})
export class OnlyNumbersDirective {
  constructor() { }

  @HostListener('keypress', ['$event']) 
  onKeyPress(event) {
   return new RegExp('^[0-9]+$').test(event.key)
  }
}
