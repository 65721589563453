// parkinglot analysis table
export const SEARCH_SORT: string = "SEARCH_SORT";
export const PAGINATOR_SIZE: string = "PAGINATOR_SIZE";
export const SORT_DIRECTION: string = "SORT_DIRECTION";
export const SORT_ACTIVE: string = "SORT_ACTIVE";

export const EMPTY_JSON: string = "EMPTY_JSON";


// analysis selection page
export enum QUESTION_TYPE {
    TYPE_1 = "QUESTION_TYPE_1", /// general analysis
    TYPE_3 = "QUESTION_TYPE_3", /// time range analysis
    TYPE_4 = "QUESTION_TYPE_4",  /// parking duration analysis
    TREND_ANALYSIS = "TREND_ANALYSIS" /// trend analysis
}

export enum FILTER_OPTIONS {
    INIT = 0,
    WEEKDAY = 1,
    TIME = 2,
    DURATION = 3
}

// heatmap data detail

export enum CHART {
  UTILISATION = "utilisation",
  OPERATIONS_START = "operations_start",
  OPERATIONS_ACTIVE = "operations_active",
  OPERATIONS_END = "operations_end",
  AVG_DUDRATION = "avgDuration",
}

export enum ANALYSIS_TYPE {
    CAMERA = "camera",
    SENSOR = "sensor"
}

export const LOCAL_STORAGE_QUESTION_TYPE: string = "QUESTION_TYPE";

export const GROUP_A: string = "groupA";
export const GROUP_B: string = "groupB";

// parkingspace tags
export enum PARKINGSPACE_TAG {
    ELECTROCHARTER = "electrocharger",
    FAMILY = "family",
    ALL_PARKINGSPACE = "select_all",
    DISABLED = "disabled"
}

// routing states
export const PARKINGLOT_TABLE: string = "PARKINGLOT_TABLE";
export const QUESTION_TYPE_SELECTION: string = "QUESTION_TYPE_SELECTION";
export const HEATMAP_PAGE: string = "HEATMAP_PAGE";