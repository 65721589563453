import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subscription, from, interval } from 'rxjs';
import moment from 'moment';
import { HttpResponse } from '@angular/common/http';
import { concatMap, retry, take } from 'rxjs/operators';
import { Failure, ParkingLotModel, RepositoryService } from '@frontend-monorepo/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { saveAs } from 'file-saver';

@Component({
  selector: 'frontend-monorepo-download-progress-bar',
  templateUrl: './download-progress-bar.component.html',
  styleUrls: ['./download-progress-bar.component.scss']
})
export class DownloadProgressBarComponent implements OnInit, OnDestroy {
  private $download: Subscription;

  /**
   * Streams to track download progress
   */
  private downloadValueSubject = new BehaviorSubject<number>(0);
  downloadValue$ = this.downloadValueSubject.asObservable();
  private progressValueSubject = new BehaviorSubject<number>(0);
  progressValue$ = this.progressValueSubject.asObservable();

  /**
   * counter stream to automatically close download window after files were completed downloaded
   */
  private counterSubject = new BehaviorSubject<number>(10);
  counter$ = this.counterSubject.asObservable();

  downloadDate: moment.Moment;
  hideDownloadProgress: boolean;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public selectedParkingLotList: ParkingLotModel[],
    private translocoService: TranslocoService,
    private repositoryService: RepositoryService,
    private dialogRef: MatDialogRef<DownloadProgressBarComponent>
  ) {
    this.$download = new Subscription();
    this.hideDownloadProgress = false;
  }

  ngOnDestroy(): void {
    this.$download.unsubscribe();
  }

  ngOnInit(): void {
    this.downloadDate = moment().subtract(1, 'day');
    this.startDownload();
  }

  /**
   * download files for each selected parking lot and track the download progress
   */
  startDownload() {
    const incrementValue = 100 / this.selectedParkingLotList.length;
    let downloadValue = 0;
    let progressValue = 0;
    let requests = [];
    for (let index = 0; index < this.selectedParkingLotList.length; index++) {
      requests.push(
        this.repositoryService
          .cameraEventsRepository()
          .postLiveEvents(
            this.selectedParkingLotList[index].id,
            this.downloadDate.startOf('day').unix(),
            this.downloadDate.endOf('day').unix()
          )
      );
    }
    this.$download = from(requests)
      .pipe(
        concatMap((request) => request),
        retry(3)
      )
      .subscribe({
        next: (resp: HttpResponse<Blob>) => {
          let blob = new Blob([resp.body], { type: 'application/zip' });
          let contentDisposition = resp.headers.get('Content-Disposition');
          let filename = contentDisposition.split('=')[1].split(',')[0].trim();
          // const contentLength = resp.headers.get('Content-Length');
          // const fileSize = resp.body;
          saveAs(blob, filename);
          downloadValue += 1;
          progressValue += incrementValue;
          this.downloadValueSubject.next(downloadValue);
          this.progressValueSubject.next(progressValue);
        },
        error: (_error: Failure) => {
          this.errorMessage = this.translocoService.translate(_error.errorMessage);
          this.hideDownloadProgress = true;
        },
        complete: () => {
          this.progressValueSubject.next(100);
          this.hideDownloadProgress = true;
          this.startTimer();
        }
      });
  }

  /**
   * function to start a 10 sec timer to automatically close the download window
   */
  startTimer() {
    const duration = 10; // Duration in seconds

    interval(1000) // Emit a value every second
      .pipe(take(duration + 1)) // Take the specified number of values
      .subscribe({
        next: () => {
          const currentValue = this.counterSubject.getValue();
          this.counterSubject.next(currentValue - 1);
        },
        complete: () => {
          this.counterSubject.next(0);
          this.close('finish');
        }
      });
  }

  /**
   * closes download window and returns either a "finish"-state for a successful download
   * and "cancel"-state to cancel and unsubscribe the download action 
   * @param action 
   */
  close(action: string) {
    if (action === 'cancel') {
      this.$download.unsubscribe();
    }
    this.dialogRef.close(action);
  }
}
