import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'frontend-monorepo-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  private _subscription;
  breadcrumbString: string[];

  constructor(private route: ActivatedRoute, private translocoService: TranslocoService) {
    this._subscription = new Subscription();
  }
  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) =>
      this.translocoService.langChanges$.subscribe((_) => {
          this.breadcrumbString = data.breadcrumb?.map((text) =>
            this.translocoService.translate(`${text}`)
          );
      })
    );
  }
}
