import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SkipSelf } from '@angular/core';
import { API_BASE_URL, AuthResponse, CoreApiService } from '@frontend-monorepo/core';
import { UserData } from '@frontend-monorepo/parking-lot-map';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HEADERS } from '../../utils/constants/headers';
import { Failure } from '../http-response/failure.model';
import { LoginBase } from './login.base';
import { CookieResponse } from './models/cookieResponse';

export class LoginRepository extends LoginBase {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Fetches customer parking lot ids and maps them to each parking lot id
   * @returns an Observable of type Map<number,number> where as parking lot id is KEY and customer-plid VALUE
   */
  fetchCustomerPLIDMapping(): Observable<Map<number, number>> {
    return this.http
      .get<Map<number, number>>(API_BASE_URL + 'parking-lot-owner/user/customer-parkinglot-ids')
      .pipe(
        map(
          (item: Map<number, number>) => new Map(Object.keys(item).map((key) => [item[key], +key]))
        ),
        catchError((error: HttpErrorResponse) => {
          throw new Failure(error.status, error.message);
        })
      );
  }

  /**
   * Sending email containing a reset token
   * @param email the email address of the user
   */
  postGeneratePasswordResetToken(json: string): Observable<any> {
    return this.http.post<any>(API_BASE_URL + 'account/generate-password-reset-token', json).pipe(
      catchError((error: HttpErrorResponse) => {
        throw new Failure(error.status, error.message);
      })
    );
  }

  /**
   * Resetting the password
   * @param json containing the new password, token and the email of the user
   */
   postResetPassword(email: String, token: String, password: String): Observable<any> {
    let json = {
      "new_password": password,
      "token": token,
      "email": email
    }
    return this.http.post<any>(API_BASE_URL + 'account/reset-password', JSON.stringify(json)).pipe(
      catchError((error: HttpErrorResponse) => {
        throw new Failure(error.status, error.message);
      })
    );
  }

  /**
   * Headers Setting for fetching and storing cookie
   * @returns
   */
  getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': HEADERS.CONTENT_TYPE_JSON
    });
  }

  /**
   * Store token in cookie to enable remember user function
   * cookie storing is linked to smart-city-system.com server
   * @param json containing authenticated user `token`
   */
  setCookie(response: AuthResponse): Observable<any> {
    let body = {
      token: response.token + response.two_factor_authentication_type
    }
    return this.http.post<any>(`/auth/store-token`, body);
  }

  /**
   * Retrieve token from cookie to enable remember user function
   * cookie storing is linked to smart-city-system.com server
   * @returns `CookieResponse`-Object containing authenticated user `token`
   */
  getCookie(): Observable<CookieResponse> {
    return this.http.post<CookieResponse>(`/auth/get-token`, '');
  }
}
