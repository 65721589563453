import { Injectable } from '@angular/core';
import {
  DurationSetting,
  ParkingDurationPreset,
  PRESET_COLOR_SCHEME
} from '@frontend-monorepo/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Service for storing, managing and updating data regarding parking duration preset setting for both parkinglots and parkingspaces
 */
@Injectable({
  providedIn: 'root'
})
export class PresetService {
  currentPlid: number;
  presetName: BehaviorSubject<string>;
  presetColor: BehaviorSubject<string>;
  presetResetSetting: BehaviorSubject<boolean>;
  /**
   * store and updates preset summary for each day, whereas 0 would represents Monday and 6 represents Sunday
   */
  weeklyPresetSubject: Map<number, BehaviorSubject<string[]>>;
  /**
   * behavior subject containing active preset object from backend
   * NOTE: might not be necessary anymore, revision needed
   */
  activePreset: BehaviorSubject<ParkingDurationPreset>;
  /**
   * BehaviorSubject containing an active preset object to a map whereas VALUE combines all evquivalent duration settings (in time and duration)
   * and KEY the tag as the numbered settings list
   */
  activeDurationPreset: BehaviorSubject<Map<number, DurationSetting[]>>;
  /**
   * BehaviorSubject containing the state (VALUE) of each setting card determining whether a changed setting (KEY) was activley applied
   */
  presetAppliedStateList: BehaviorSubject<Map<number, boolean>>;

  constructor() {
    this.activePreset = new BehaviorSubject(null);
    this.activeDurationPreset = new BehaviorSubject(null);
    this.presetName = new BehaviorSubject(null);
    this.presetColor = new BehaviorSubject(PRESET_COLOR_SCHEME[0]);
    this.presetResetSetting = new BehaviorSubject(null);
    this.presetResetSetting.next(false);
    this.presetAppliedStateList = new BehaviorSubject(new Map());
  }

  get getCurrentPlid(): number {
    return this.currentPlid;
  }

  get getActivePreset(): ParkingDurationPreset {
    return this.activePreset?.getValue();
  }
  get getActiveDurationPreset(): Map<number, DurationSetting[]> {
    return this.activeDurationPreset?.getValue();
  }
  get getColor(): string {
    return this.presetColor?.getValue();
  }
  get getName(): string {
    return this.presetName?.getValue();
  }
  get getResetSetting(): boolean {
    return this.presetResetSetting?.getValue();
  }

  get getPresetAppliedState(): Map<number, boolean> {
    return this.presetAppliedStateList.getValue();
  }

  set setCurrentPlid(plid: number) {
    this.currentPlid = plid;
  }

  /**
   * adds new Parking Duration object to active duration setting list
   */
  addDurationSetting() {
    let newSetting = {
      preset_id:
        this.activePreset.getValue() != null ? this.activePreset.getValue().preset.preset_id : -1,
      day: 1,
      max_parking_duration: 0,
      due_warning_duration: 0,
      start_time: '',
      end_time: ''
    };
    if (this.activeDurationPreset?.getValue()?.size > 0) {
      let settingTag = Array.from(this.activeDurationPreset.getValue().keys())[
        this.activeDurationPreset.getValue().size - 1
      ];
      let durationList = this.presetAppliedStateList.getValue().set(settingTag + 1, true);
      this.presetAppliedStateList.next(durationList);
      let currentList = this.activeDurationPreset.getValue().set(settingTag + 1, [newSetting]);
      // add new setting with incremented setting count as KEY
      this.activeDurationPreset.next(currentList);
    } else {
      this.activeDurationPreset.next(new Map([[1, [newSetting]]]));
      this.presetAppliedStateList.next(new Map([[1, true]]));
    }
  }

  updateActivePreset(preset: ParkingDurationPreset) {
    this.activePreset.next(preset);
    this.activeDurationPreset.next(this.filterActivePreset(preset));
    this.presetName.next(preset.preset.name);
    this.presetColor.next(
      preset.preset.color == '' || preset.preset.color == null
        ? PRESET_COLOR_SCHEME[0]
        : preset.preset.color
    );
    this.presetResetSetting.next(preset.preset.reset_after_free_parking);
    let presetAppliedMap = new Map(
      Array.from(this.activeDurationPreset.getValue().keys()).map((_tag) => [_tag, true])
    );
    this.presetAppliedStateList.next(presetAppliedMap);
  }

  updateChangedActivePreset() {
    let currentPreset = this.activePreset.getValue();
    currentPreset.preset.name = this.getName;
  }

  /**
   * Updates the duration settings
   *
   * For initial durations in the set-parking-time.component.ts the presetId is set to -1 for activating all days as default.
   * To prevent this behavior with setting the first duration, the presetId will be changed to 0.
   *
   * @param settingTag
   * @param durationSetting
   */
  updateDurationSetting(settingTag: number, durationSetting: DurationSetting[]) {
    if (durationSetting.find((item) => item.preset_id == -1) != undefined) {
      durationSetting.forEach((duration) => {
        duration.preset_id = 0;
      });
    }
    this.activeDurationPreset.getValue().set(settingTag, durationSetting);
    this.activeDurationPreset.next(this.activeDurationPreset.getValue());
    let presetAppliedMap = new Map(
      Array.from(this.activeDurationPreset.getValue().keys()).map((_tag) => [_tag, true])
    );
    this.presetAppliedStateList.next(presetAppliedMap);
  }

  /**
   * delete a set of setting which is tagged with `settingTag` from activeDurationPreset BehaviorSubject
   * and updates it afterwards
   *
   * @param settingTag tag which is conntected to a duration setting card
   */
  deleteDurationSetting(settingTag: number) {
    let deletedSetting = this.activeDurationPreset.getValue().get(settingTag);
    this.activeDurationPreset.getValue().delete(settingTag);
    this.activeDurationPreset.next(this.activeDurationPreset.getValue());
    this.presetAppliedStateList.getValue().delete(settingTag);
  }

 /**
 * Group duration settings based on multiple properties.
 *
 * This function takes a `preset` object and groups its `duration_settings` array
 * based on a composite key formed by properties such as `start_time`, `end_time`,
 * `max_parking_duration`, `due_warning_duration`, and `max_overdue_duration`. (i.e 16:00-17:17-7200-6600-undefined)
 * The result is a Map where each key represents a unique combination of these properties,
 * and the corresponding value is an array of duration settings belonging to that group.
 *
 * @param preset - The input preset object containing duration settings to be grouped.
 * @returns A Map where keys are incremental indices and values are arrays of grouped duration_settings.
 */
  filterActivePreset(preset: ParkingDurationPreset): Map<number, DurationSetting[]> {
    if (preset) {
      const result = preset.duration_settings.reduce((map, obj) => {
        const key = `${obj.start_time}-${obj.end_time}-${obj.max_parking_duration}-${obj.due_warning_duration}-${obj.max_overdue_duration}`;

        map[key] = map[key] == null ? [obj] : [...map[key], obj];

        return map;
      }, {});

      const presetMap = new Map();

      Object.values(result).forEach((value, index) => {
        presetMap.set(index + 1, value);
      });

      return presetMap;
    }
  }

  //#region
  // preset summary color bar settings

  /**
   * generate a color list for the overview weekly color bar presentation based on the duration settings provided in the durationSettingMap.
   *
   * The function first initialises a weeklyPresets map with default color bar settings using the getDefaultColorBar() function.
   * It then iterates through each value in the durationSettingMap and extracts the duration settings for each entry.
   * The function then checks if the start_time property of the duration setting is not null or an empty string.
   * The function then calculates the start and end time of the duration setting and sets the corresponding color in the weeklyPresets map using the fill() method.
   *
   * @param durationSettingMap duration setting map
   * @returns
   */
  colorList(durationSettingMap: Map<number, DurationSetting[]>): Map<number, string[]> {
    let weeklyPresets = this.getDefaultColorBar();
    for (let entry of Array.from(durationSettingMap.entries())) {
      const durationSettings = entry[1];
      durationSettings.forEach((_setting) => {
        if (_setting.start_time != null && _setting.start_time != '') {
          // iterate through each setting and get corresponding day in weeklyPreset map
          /**
           * NOTE:
           * Random color variable, if you want to take a random color instead of your predefined ones
           */
          const randomColor = this._generateRandomColor();

          if (_setting.day > 0) {
            let _day = _setting.day;
            // set color for time range
            let startTime = 0;
            let endTime = 0;

            // time range within an hour range
            // color the complete hour
            if (
              +_setting.start_time.substring(0, 2) == +_setting.end_time.substring(0, 2) &&
              +_setting.end_time.substring(3, 4) > +_setting.start_time.substring(3, 4)
            ) {
              startTime = +_setting.start_time.substring(0, 2);
              endTime = +_setting.end_time.substring(0, 2) + 1;
            } else {
              startTime = +_setting.start_time.substring(0, 2);
              endTime =
                +_setting.end_time.substring(0, 2) == 0 ||
                // time later then 23:30 color the bar to the bottom
                (+_setting.end_time.substring(0, 2) == 23 &&
                  +_setting.end_time.substring(3, 4) >= 30)
                  ? 24
                  : +_setting.end_time.substring(0, 2);
            }

            weeklyPresets.get(_day).fill(PRESET_COLOR_SCHEME[entry[0]], startTime, endTime);
          }
        }
      });
    }
    return weeklyPresets;
  }

  /**
   * Generate a random hex color
   * @returns a hex color code as string
   */
  private _generateRandomColor(): string {
    var randomColor = '#000000'.replace(/0/g, function () {
      return (~~(Math.random() * 16)).toString(16);
    });
    return randomColor;
  }

  /**
   * generates a random HSL light color
   * HSL stands for hue, saturation, and lightness - and represents a cylindrical-coordinate representation of colors
   * - Hue is a value between 0 and 359
   * - Saturation is a value between 75 and 100
   * - Lightness is a value between 70 and 79
   * @returns hsl string color
   */
  private generateRandomLightColor(weeklyPreset: Map<number, string[]>): string {
    let color: string;
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * 25) + 75;
    const lightness = Math.floor(Math.random() * 10) + 70;
    color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return color;
  }

  //#endregion

  getDefaultColorBar(): Map<number, string[]> {
    return new Map([
      [1, new Array(24).fill('#F1F1F1')],
      [2, new Array(24).fill('#F1F1F1')],
      [3, new Array(24).fill('#F1F1F1')],
      [4, new Array(24).fill('#F1F1F1')],
      [5, new Array(24).fill('#F1F1F1')],
      [6, new Array(24).fill('#F1F1F1')],
      [7, new Array(24).fill('#F1F1F1')]
    ]);
  }

  /**
   * reseting all Subjects
   */
  reset(): void {
    this.getDefaultColorBar();
    this.activePreset = new BehaviorSubject(null);
    this.activeDurationPreset = new BehaviorSubject(null);
    this.presetName = new BehaviorSubject(null);
    this.presetColor = new BehaviorSubject(PRESET_COLOR_SCHEME[0]);
    this.presetAppliedStateList = new BehaviorSubject(new Map());
  }

  /**
   * reset parking lot id when leaving parking lot map page
   */
  resetParkingLotID() {
    this.setCurrentPlid = 0;
  }
}
