<div class="content-wrapper">
  <h3 class="paragraph-wrapper">Datenschutzerklärung nach Art. 13 DSGVO</h3>

  <p>
    Die vorliegende Datenschutzerklärung bezieht sich auf die Verarbeitung Ihrer
    personenbezogenen Daten im Sinne der DSGVO, wenn Sie unser Dashboard in Form
    einer kostenfreien Dienstleistung nutzen möchten. Bitte beachten Sie, dass
    diese Datenschutzerklärung Änderungen bei Verfahrensanpassungen unterliegen
    kann.
  </p>

  <div>
    <h4>Verantwortliche Stelle:</h4>
    <p>
      Smart City System Parking Solutions GmbH, nachfolgend SCS, verarbeitet Ihre Personendaten
      als Verantwortlicher im Sinne des Art. 4 Nr. 7 DSGVO zum Betrieb des
      vorliegenden Dashboards und den damit verbundenen Leistungen. Sie
      erreichen uns wie folgt:
    </p>
    <p>
      Smart City System Parking Solutions GmbH
      <br />
      Schwabacher Straße 510 c
      <br />
      90763 Fürth
    </p>
  </div>

  <div class="paragraph-wrapper">
    <p>
      Beschreibung der Datenverarbeitung, Zwecke und Datenarten im Rahmen der
      Nutzung des Dashboards
    </p>
    <p>
      Zur Durchführung unserer Dienstleistung und im Rahmen der Ausstattung
      eines oder mehrerer Parkplätze mit den von SCS entwickelten
      ParkingPilot-Sensoren wird dem Kunden ein Zugang zum Dashboard ermöglicht.
      Hierrüber kann der Kunde alle mit dem System ausgestatteten Parkplätze
      verwalten und überwachen.
    </p>
    <p>
      Je nach Art und Umfang der Nutzung des Dashboards werden verschiedene
      Arten von Daten erhoben bzw. verarbeitet. Hierzu gehören insbesondere:
    </p>
    <div class="paragraph-wrapper">
      <li>
        Benutzername (Benutzername wird im Rahmen der Anmeldung/Account für das
        Dashboard benötigt)
      </li>
      <li>Benutzer-ID</li>
      <li>E-Mail- Adresse</li>
      <li>Passwort</li>
      <li>Log-Dateien</li>
    </div>
    <p>
      Nachfolgend möchten wir Sie näher über den Umfang der Datenverarbeitung
      informieren.
    </p>
  </div>

  <div class="paragraph-wrapper">
    <h4>
      Erforderliche Daten und Funktionen
    </h4>
    <p>
      Um eine Nutzung und einen sicheren Betrieb des Dashboards gewährleiten zu
      können, erstellen wir von unseren Kunden im Rahmen der Anmeldung/
      Registrierung einen Benutzernamen sowie ein Passwort, welches der Kunde
      nach seiner ersten Anmeldung ändern kann. Zudem speichern wir aus
      Sicherheitsgründen Log-Dateien unserer Nutzer, um einen sicheren Betrieb
      des Dashboards gewährleisten zu können.
    </p>
    <p>
      Im Übrigen werden sonst keine Daten unserer Kunden im Rahmen der Nutzung
      des Dashboards gespeichert.
    </p>
  </div>

  <div class="paragraph-wrapper">
    <h4>Freiwillige Angaben und Funktionen</h4>
    <p>
      Unser Dashboard bietet zudem unseren Kunden die Möglichkeit weitere Nutzer
      zum System hinzuzufügen. Bei Erstellung weiterer Nutzer kann es vorkommen,
      dass der Kunde personenbezogene Daten in Form eines Benutzernamens sowie
      einer E-Mail- Adresse an uns übermittelt, damit SCS einen weiteren Nutzer
      im System anlegen kann. Das Erstellen solcher zusätzlichen Nutzer ist
      freiwillig und nicht zwingend notwendig für die generelle Nutzung des
      Dashboards.
    </p>
    <p>
      Des Weiteren besteht die Möglichkeit für den Kunden, dass dieser im Falle
      einer Parkdauerüberschreitung per E-Mail benachrichtigt wird. Hierfür muss
      der Kunde eine entsprechende E-Mail hinterlegen. Auch die Nutzung dieser
      Funktion ist freiwillig und kann jederzeit wieder rückgängig gemacht
      werden.
    </p>
  </div>

  <div class="paragraph-wrapper">
    <h4>Erforderliche Cookies</h4>
    <p>
      Im Rahmen der Bereitstellung unseres Dashboards setzen wir zudem ein
      Cookie ein, welcher für die Nutzung unseres Dashboards erforderlich ist.
    </p>
    <p>
      Cookies sind kleine Textdateien, die auf Ihrem Endgerät gespeichert und
      ausgelesen werden können. Man unterscheidet zwischen Session-Cookies, die
      wieder gelöscht werden, sobald Sie ihren Browser schließen und permanenten
      Cookies, die über die einzelne Sitzung hinaus gespeichert werden.
    </p>
    <p>
      Wir nutzen dieses erforderliche Cookie nicht für Analyse-, Tracking- oder
      Werbezwecke.
    </p>
    <p>
      Das Cookie enthält lediglich Informationen zu bestimmten Einstellungen und
      ist nicht personenbeziehbar. Er ist notwendig, um die Benutzerführung,
      Sicherheit und Umsetzung der Seite zu ermöglichen.
    </p>
  </div>

  <div class="paragraph-wrapper">
    <h4>Rechtsgrundlagen für die Datenverarbeitung</h4>
    <p>
      Die Verarbeitung Ihrer Daten erfolgt im Rahmen der Nutzung unseres
      Dashboards auf Grundlage von Art. 6 Abs.1 S.1 lit. b DSGVO, da Ihre
      Nutzung teil einer vertraglichen Vereinbarung mit Ihnen ist und der
      geschlossene Vertrag dadurch erfüllt werden kann.
    </p>
    <p>
      Die Speicherung Ihrer Log-Dateien erfolgt auf Grundlage unseres
      berechtigten Interesses nach Art. 6 Abs.1 S.1 lit. f DSGVO. Durch die
      Speicherung kann ein regelkonformer sowie sicherer Betrieb unseres
      Dashboards gewährleistet werden.
    </p>
    <p>
      Die erforderlichen Cookies setzten wir auch Grundlage unseres berechtigten
      Interesses, da nur so ein ordnungsgemäßer Betrieb des Dashboards möglich
      ist.
    </p>
    <p>
      Sofern Sie bei der Nutzung unseres Dashboards darüber hinaus freiwillige
      Angaben machen oder freiwillig nicht zwingend erforderliche Funktionen
      nutzen, erfolgt die damit einhergehende Datenverarbeitung auf Grundlage
      Ihrer widerrufbaren Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO.
      Ihre Einwilligung können Sie jederzeit mit Wirkung für die Zukunft
      widerrufen. Bitte beachten Sie, dass Verarbeitungen, die vor dem Widerruf
      erfolgt sind, davon nicht betroffen sind.
    </p>
  </div>

  <div class="paragraph-wrapper">
    <h4>Weitergabe Ihrer Daten</h4>
    <p>
      Wir übermitteln Ihre Daten grundsätzlich nicht an Dritte. Eine Weitergabe
      erfolgt nur, sofern die Daten gerade zur Weitergabe bestimmt sind, Sie
      vorher ausdrücklich in die Übermittlung eingewilligt haben oder wir
      aufgrund gesetzlicher Vorschriften hierzu verpflichtet bzw. berechtigt
      sind.
    </p>
  </div>

  <div class="paragraph-wrapper">
    <h4>Speicherort der personenbezogenen Daten</h4>
    <p>
      Bei der Verarbeitung Ihrer personenbezogenen Daten, welche Sie im Rahmen
      der Nutzung unserer Dashboards zur Verfügung stellen, werden wir von
      Auftragsverarbeitern unterstützt. Alle von SCS beauftragten Unternehmen
      sind nach den Regelungen der DSGVO gemäß Art. 28 DSGVO zur
      weisungsgebundenen Datenverarbeitung verpflichtet. Als Verantwortlicher
      hat SCS das ausschließliche Weisungsrecht und kann die Verarbeitung der
      personenbezogenen Daten durchgängig kontrollieren und sicherstellen
    </p>
    <p>
      Die personenbezogenen Daten werden, sofern nichts anderes nachfolgend
      beschrieben wird, ausschließlich auf den Servern innerhalb Europäischen
      Union verarbeitet.
    </p>
  </div>

  <div class="paragraph-wrapper">
    <h4>Löschung Ihrer Daten</h4>
    <p>
      Wir verarbeiten Ihre Daten grundsätzlich nur solange, wie sie für die
      Zwecke, für die sie erhoben worden sind, erforderlich sind. Wir zeichnen
      nichts auf. Ihre Daten werden daher nicht gespeichert.
    </p>
  </div>

  <div class="paragraph-wrapper">
    <h4>Ihre Rechte als betroffenen Person</h4>
    <p>
      Sie haben gemäß Art. 15 DSGVO das Recht auf Auskunft seitens des
      Verantwortlichen über die Sie betreffenden personenbezogenen Daten sowie
      auf Berichtigung unrichtiger Daten gemäß Art. 16 DSGVO oder auf Löschung,
      sofern einer der in Art. 17 DSGVO genannten Gründe vorliegt, z.B. wenn die
      Daten für die verfolgten Zwecke nicht mehr benötigt werden. Sie haben
      zudem das Recht auf Einschränkung der Verarbeitung, wenn eine der in Art.
      18 DSGVO genannten Voraussetzungen vorliegt und in den Fällen des Art. 20
      DSGVO das Recht auf Datenübertragbarkeit. In Fällen, in denen wir Ihre
      personenbezogenen Daten auf der Rechtsgrundlage von Art. 6 Abs. 1 S. 1
      lit. f DSGVO verarbeiten, haben Sie zudem das Recht, aus Gründen, die sich
      aus Ihrer besonderen Situation ergeben, jederzeit Widerspruch einzulegen.
      Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn,
      es liegen nachweisbar zwingende schutzwürdige Gründe für die Verarbeitung
      vor, die gegenüber Ihren Interessen, Rechten und Freiheiten überwiegen,
      oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
      von Rechtsansprüchen.
    </p>
    <p>
      Sie haben zudem das Recht hat auf Beschwerde bei einer Aufsichtsbehörde,
      wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
      Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das
      Beschwerderecht kann insbesondere bei einer Aufsichtsbehörde in dem
      Mitgliedstaat des Aufenthaltsorts der betroffenen Person oder des Orts des
      mutmaßlichen Verstoßes geltend gemacht werden.
    </p>
  </div>

  <div>
    <h4>Kontaktdaten der Datenschutzbeauftragten</h4>
    <p>
      Unser externer Datenschutzbeauftragter steht Ihnen gerne für Auskünfte zum
      Thema Datenschutz unter folgenden Kontaktdaten zur Verfügung:
    </p>
  </div>

  <p>
    Datenschutz süd GmbH <br />
    Wörthstraße 15<br />
    97082 Würzburg<br />
  </p>

  <p>
    Web: <a href="https://www.datenschutz-sued.de">www.datenschutz-sued.de</a> <br />
    E-Mail: <a href="mailto:office@datenschutz-sued.de">office@datenschutz-sued.de</a>
  </p>
  <span
    >Wenn Sie sich an unseren Datenschutzbeauftragten wenden, geben Sie hierbei
    bitte auch den Verantwortlichen an.</span
  >
</div>

<div *transloco="let t; read: 'FOOTER'">
  <mat-dialog-actions>
    <button mat-flat-button color="cancel" mat-dialog-close>
      {{ t('cancel') }}
    </button>
  </mat-dialog-actions> 
</div>
