import { AfterViewInit, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ParkingLot } from 'libs/core/src/lib/interfaces/ParkingLot';
import { Failure } from 'libs/core/src/lib/repository/http-response/failure.model';
import { ParkingLotsDataService } from 'libs/core/src/lib/services/parking-lots-data.service';
import { RepositoryService } from 'libs/core/src/lib/services/repository/repository.service';
import { Subscription } from 'rxjs';
import { skip, startWith, map } from 'rxjs/operators';

/**
 * Simple Component for searching existing parking time settings by user selected parking lot
 */
@Component({
  selector: 'frontend-monorepo-search-preset-by-parkinglot',
  templateUrl: './search-preset-by-parkinglot.component.html',
  styleUrls: ['./search-preset-by-parkinglot.component.scss']
})
export class SearchPresetByParkinglotComponent implements OnInit, OnDestroy, AfterViewInit {
  private _subscription: Subscription;
  searchParkingLotForm: FormGroup;

  parkingLotList: ParkingLot[] = new Array<ParkingLot>();
  parkingLotListFilter: ParkingLot[] = undefined;
  plid = new FormControl();

  constructor(
    private repositoryService: RepositoryService,
    private snackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private dataService: ParkingLotsDataService
  ) {
    this._subscription = new Subscription();
    this.searchParkingLotForm = new FormGroup({
      plid: new FormControl(undefined)
    });
  }
  ngAfterViewInit() {
    let currentPlid = this.repositoryService.presetService().getCurrentPlid;

    this._subscription.add(
      this.dataService.parkingLotsSubject.subscribe((parkingLots) => {
        if (currentPlid > 0) {
          this.parkingLotList = parkingLots.filter(
            (_entry) => _entry.id != this.repositoryService.presetService().getCurrentPlid
          );
        } else {
          this.parkingLotList = parkingLots;
        }
      })
    );
    this.plid.valueChanges
      .pipe(
        skip(1),
        startWith(''),
        map((pl) => {
          if (this.parkingLotList.length > 0) {
            return pl ? this.filterStates(pl) : [...this.parkingLotList];
          } else {
            return [];
          }
        })
      )
      .subscribe((result) => {
        this.parkingLotListFilter = result;
      });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {}

  getParkingTimeSettings(id: number) {
    const parkingLots = [...this.parkingLotList];
    let parkingLotId = parkingLots.find(
      (_parkinglot) => (_parkinglot.id == id)
    ).id;
    this._subscription = this.repositoryService
      .parkingLotPresetRepository()
      .fetchActiveParkingLotPreset(parkingLotId)
      .subscribe({
        next: (_activePreset) =>
          this.repositoryService.presetService().updateActivePreset(_activePreset),
        error: (_error: Failure) =>
          this.snackBar.open(this.translocoService.translate(_error.errorMessage))
      });
  }

  filterStates(value: string): ParkingLot[] {
    const filterValue = value.toString().toLowerCase();
    const parkinglots = [...this.parkingLotList]

    let list = parkinglots.filter(
      (pl) =>
        pl.name.toLowerCase().includes(filterValue) ||
        pl.id.toString().toLowerCase().includes(filterValue)
    );

   
    return list;
  }

  trackById(index: number, item: ParkingLot) {
    return item.id;
  }
}
