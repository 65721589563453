import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import {
  CameraParkinglotStateWebsocketService,
  ParkinglotWebsocket
} from '@frontend-monorepo/core';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParkinglotWebsocketGuard implements CanActivate, OnDestroy {
  constructor(
    private plWebsocket: ParkinglotWebsocket,
    private cameraParkinglotWebsocket: CameraParkinglotStateWebsocketService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return Observable.create((observer: Observer<boolean>) => {
      if (!this.plWebsocket.isRunning()) {
        this.plWebsocket.start();
      } else {
        // console.log("Websocket still running");
      }
      if (!this.cameraParkinglotWebsocket.isRunning()) {
        this.cameraParkinglotWebsocket.start();
      }

      observer.next(true);
      observer.complete();
    });
  }

  ngOnDestroy(): void {
    this.cameraParkinglotWebsocket.stop();
  }
}
