export const API_BASE_URL = 'https://api.parking-pilot.com/';
export const IMAGE_TYPE: string = 'data:image/PNG;base64,';

/**
 * Used to identify dashboard-branding (scs, vodafone)
 */
export const DASHBOARD_TAG = "scs";
export const DASHBOARD_URL = "https://dashboard.smart-city-system.com/";
export const DATA_POLICY_URL = "https://smart-city-system.com/datenschutzerklaerung/";

export enum PERMISSION {
    ADMIN = "admin"
}

// mainDataService
export const AUTHENTICATION_SUCCESS: string = "authentication_success";
export const AUTHENTICATION_FAILURE: string = "authentication_failure";
export const CURRENT_STATES: string = "current_states";
export const PARKING_LOT_STATE: string = "parking_lot_state";
export const CAMERA_PARKING_LOT_STATE: string = "camera_parking_lot_state";
export const PARKING_SPACE_STATE: string = "parking_space_state";
export const ENTITLED_TO_PARK: string = "ENTITLED_TO_PARK";
export const ENTITLED_TO_PARK_SAFE: string = "ENTITLED_TO_PARK_SAFE";
export const TICKET_ISSUED: string = "TICKET_ISSUED";
export const TICKET_COULD_NOT_BE_ISSUED: string = "TICKET_COULD_NOT_BE_ISSUED";
export const IGNORED: string = "IGNORED";
export const REMEMBER_FOR_LATER: string = "REMEMBER_FOR_LATER";
export const DEVICE_HEALTH_STATE: string = "device_health_state";
export const DEVICE_HEALTH_READY: string = "DEVICE_HEALTH_READY";
export const SLA_DEVICE_HEALTH_READY: string = "SLA_DEVICE_HEALTH_READY";
export const SLA_DEVICE_HEALTH_STATE: string = "parking_lot_health_state";
export const INCIDENTS_READY: string = "INCIDENTS_READY";
export const INCIDENTS_STATE_INITIAL: string = "initial";
export const INCIDENTS_STATE_UPDATE: string = "update";
export const LIST_CUSTOMER_PLID_READY: string = "LIST_CUSTOMER_PLID_READY";

// DeviceHealth state
export enum DEVICE_HEALTH_STATE_TYPE {
    DEVICE_HEALTH_OK = "ok",
    DEVICE_HEALTH_INACTIVE = "inactive",
    DEVICE_HEALTH_INSTALLED = "installed",
    DEVICE_HEALTH_IN_CALIBRATION = "in_calibration",
    DEVICE_HEALTH_BROKEN = "broken",
    DEVICE_HEALTH_DOWN_SHORT = "down_short",
    DEVICE_HEALTH_DOWN_MEDIUM  = "down_medium",
    DEVICE_HEALTH_DOWN_LONG = "down_long",
    DEVICE_HEALTH_CALIBRATION_OFF = "calibration_off",
    DEVICE_HEALTH_DETACHED = "detached",
    DEVICE_HEALTH_LOW_BATTERY = "low_battery"
}

export enum PARKING_SPACE_STYLE_TYPE {
    OCCUPIED = 'OCCUPIED',
    SOON_DUE = 'SOON_DUE',
    DUE = 'DUE',
    OVERDUE = 'OVERDUE',
    FREE = 'FREE',
    PRELIMINARY_DECISION = 'PRELIMINARY_DECISION',
    IGNORED = 'IGNORED',
    MONITORING = 'MONITORING',
    PAC = 'PAC',
    NOT_INSTALLED = 'NOT_INSTALLED',
    DEFAULT = 'DEFAULT',
    REMEMBER_FOR_LATER = 'REMEMBER_FOR_LATER',
    OCCUPANCY_OCCUPIED = 'OCCUPANCY_OCCUPIED',
    OCCUPANCY_FREE = 'OCCUPANCY_FREE'
}

export enum PARKING_LOT_DEVICE_TYPE {
    SENSOR = "sensor",
    CAMERA = "camera",
    MIX = "mix"
}

