import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MaterialComponentsModule } from 'apps/customer-dashboard/src/app/pages/materialComponents.module';
import de from './i18n/de.json';
import en from './i18n/en.json';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DrawerContentComponent } from './src/drawer-content/drawer-content.component';
import { DirectivesModule } from '@frontend-monorepo/common';
import { MatTreeModule } from '@angular/material/tree';

@NgModule({
  declarations: [DrawerContentComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    MaterialComponentsModule,
    MatSidenavModule,
    DirectivesModule,
    MatTreeModule
  ],
  exports: [DrawerContentComponent]
})
export class NavDrawerModule {
  constructor(translocoService: TranslocoService) {
    translocoService.setTranslation(en, 'en');
    translocoService.setTranslation(de, 'de');
  }
}
