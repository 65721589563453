interface DatePickerModelBase {
  begin: number;
  end: number;
}

/**
 * @param begin
 * @param end
 */
export class DatePickerModel implements DatePickerModelBase {
  begin: number;
  end: number;

  constructor(_begin: number, _end: number) {
    this.begin = _begin;
    this.end = _end;
  }
}
