import { Observable } from "rxjs";
import { DurationSetting } from "./models/duration-settings.model";
import { ParkingDurationPreset } from "./models/parking-duration-preset.model";
import { PresetModel } from "./models/preset.model";

export abstract class ParkingLotPresetBase {
    abstract fetchAvailableParkingLotPreset(parkingLotId: number) :Observable<Array<PresetModel>>;
    abstract createParkingLotPreset(parkingLotId: number, name: string, reset_after_free_parking: boolean, color?: string) :Observable<ParkingDurationPreset>;
    abstract updateParkingLotPreset(parkingLotId: number, presetId: number) :Observable<any>;
    abstract deleteParkingLotPreset(parkingLotId: number, presetId: number) :Observable<any>;
    abstract fetchActiveParkingLotPreset(parkingLotId: number) :Observable<ParkingDurationPreset>;
    abstract udpateParkingSpacePresetByPresetId(parkingLotId: number, parkingSpaceId: number, presetId: number) :Observable<any>;
    abstract fetchPresetByPresetId(parkingLotId: number,  presetId: number) :Observable<ParkingDurationPreset>;
    abstract updateDurationSettingsByPresetId(parkingLotId: number,  presetId: number, name: string, duration_setting: DurationSetting[], resetAfterFreeParking: boolean, color?: string) :Observable<DurationSetting>;
    abstract searchPresetbyName(name: string): Observable<Array<PresetModel>>;
    abstract searchPresetbyWildcard(name_part: string): Observable<Array<PresetModel>>;
}