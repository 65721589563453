import { Component, OnInit, ViewChild, ViewContainerRef, ChangeDetectorRef, ComponentFactoryResolver } from '@angular/core';
import { Subscription } from 'rxjs';
import { ParkingLotMapComponentInjectorService } from '../../services/parking-lot-map-component-injector.service';


/**
 * Container for displaying the parking lot map's legend that is positioned on the bottom of the parking lot map
 * 
 * Other components can/should be injected into this component via the {@link ParkingLotMapExtension#legendFn}
 * 
 * This component is only displayed, if content was being injected.
 */
@Component({
  selector: 'mp-parking-lot-map-legend',
  templateUrl: './parking-lot-map-legend.component.html',
  styleUrls: ['./parking-lot-map-legend.component.scss']
})
export class ParkingLotMapLegendComponent implements OnInit {

  /**
   * The legend's ViewContainerRef, which is used by the  {@link ParkingLotMapExtension#legendFn} to inject/create child components.
   */
  @ViewChild('parkingLotMapLegend', { static: true, read: ViewContainerRef }) vcr: ViewContainerRef;

  private _subscriptions: Subscription;

  constructor(
    private parkingLotMapComponentInjectorService: ParkingLotMapComponentInjectorService,
    public componentFactoryResolver: ComponentFactoryResolver,
    public cd: ChangeDetectorRef
  ) {
    this._subscriptions = new Subscription();
  }

  ngOnInit() {
    this._subscriptions.add(
      this.parkingLotMapComponentInjectorService.getLegendContentFn().subscribe(componentUpdateFn => {
        if (componentUpdateFn) {
          componentUpdateFn(this);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
