import { CAMERA_PARKING_LOT_STATE } from "@frontend-monorepo/core";

export interface UserData extends User {
  id: number;
  email: string;
  locked: boolean;
  name: string;
  username: string;
  settings: any;
  user_permissions: Array<string>;
  parent_user_id: number;

  /**
   * Map of PLIDs to arrays of [PARKING_LOT_PERMISSION]
   *
   * @example
   *
   * {
   *  "1": [
   *     "access",
   *     "rental"
   *  ],
   *
   *  "2": [
   *     "access",
   *     "analytics"
   *  ]
   * }
   *
   */
  parking_lot_permissions: Map<number, string[]>;
}

export interface User {
  id: number;
  name: string;
}

/**
 * Enum of permissions a user can have for a specific parking lot.
 */
export enum PARKING_LOT_PERMISSION {
  /**
   * The user can access the parking lot
   */
  ACCESS = 'access',
  /**
   * The user has access to all installation-related API-requests
   */
  INSTALL = 'install',
  /**
   * The user has access to parking lot to parking duration settings and other settings
   * On sensor related left sidebar settings
   */
  EDIT_SETTINGS = 'edit_settings',
  /**
   * The user has acces to all reservation related settings
   */
  RESERVATION = 'reservation',
  /**
   * The user has access to download analysis csv data and analysis module
   */
  ANALYSIS_CSV_EXPORT = 'analysis_csv_export',
  /**
   * The user has access to camera detection license plate whitelisting related data and settings
   */
  LICENSE_PLATE_WHITELIST = 'license_plate_whitelist',
  /**
   * The user has access to live camera detection view
   */
  CAMERA_SNAPSHOT = 'camera_snapshot',
  /**
   * The user has access to download camera detection related operation data
   */
  CAMERA_PARKING_OPERATIONS_ZIP_DOWNLOAD = 'camera_parking_operations_zip_download',
  /**
   * The user has access to open a barrier on camera detection installed parking lot
   */
  CAMERA_BARRIER = 'camera_barrier',
  /**
   * The user has access to use analysis module for camera operations
   */
  CAMERA_PARKING_OPERATIONS = 'camera_parking_operations',
  /**
   * The user has access to open livestream of jpeg images of installed cameras
   */
  CAMERA_STREAM = 'camera_stream',
  /**
   * The user has access to look up on last camera events of all installed cameras on parking lot
   */
  CAMERA_EVENTS = 'camera_events',
  /**
   * The user has access to retrieve data coming from camera parking lot state websocket 
   */
  CAMERA_PARKING_LOT_STATE = 'camera_parking_lot_state',
  /**
   * The user has access to edit the evse id mapped to the charing point 
   */
  CHARGING_POINT_EDIT = 'charging_point_edit'
}

export enum USER_PERMISSION {
  /**
   * The user owns permission to generate an api-key
   */
  API_KEY = 'generate_api_key',
  /**
   * The user owns permission to access SLA status
   */
  SYSTEM_STATUS = 'system_status'
}
