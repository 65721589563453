<div *transloco="let t">
  <h3>{{ t('search_parkinglot_header') }}</h3>
  <p>{{ t('parking_time_setting_description') }}</p>
  <mat-form-field id="plid-control-wrapper" >
    <input
      #inputField
      matInput
      placeholder="{{ t('search_parkinglot') }}"
      [formControl]="plid"
      [matAutocomplete]="auto"
      (keyup.enter)="getParkingTimeSettings($event.target.value)"
    />
    <mat-icon
      style="margin-left: 6px; cursor: pointer"
      matSuffix
      (click)="getParkingTimeSettings(inputField.value)"
      >search</mat-icon
    >
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let parkingLot of parkingLotListFilter"
        [value]="parkingLot.name"
        style="line-height: 19px"
        (click)="getParkingTimeSettings(parkingLot.id)"
      >
        <div fxLayout="column">
          <small>PLID: {{ parkingLot.id }}</small>
          <span>{{ parkingLot.name }}</span>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
