import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { API_BASE_URL, Failure } from '@frontend-monorepo/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DurationSetting } from './models/duration-settings.model';
import { ParkingDurationPreset } from './models/parking-duration-preset.model';
import { PresetModel } from './models/preset.model';
import { ParkingLotPresetBase } from './parking-lot-preset.base';

export class ParkingLotPresetRepository extends ParkingLotPresetBase {
  constructor(private http: HttpClient) {
    super();
  }
  /**
   * Fetches the active pre-set for the given parking lot id or default preset withouth duration restrictions
   * if there is not a pre-set active
   * @param parkingLotId Id of parking lot
   * @requires parking_lot_permission access
   */
  fetchActiveParkingLotPreset(parkingLotId: number): Observable<ParkingDurationPreset> {
    return this.http
      .get<ParkingDurationPreset>(
        API_BASE_URL + `parkinglots/${parkingLotId}/parking-duration-presets/active`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let message = '';
          switch (error.status) {
            case 404:
              message = '404_parking_lot_not_exist';
              break;
            default:
              message = 'error_fetch_active_preset';
              break;
          }
          throw new Failure(error.status, message);
        })
      );
  }

  /**
   * creates new pre-set for the given parking lot id or default preset withouth duration restrictions
   * if there is not a pre-set active
   * @param parkingLotId Id of parking lot
   * @param name name of preset
   * @param reset_after_free_parking Settings this to true resets the parking duration everytime after
   * leaving a free parking time interval and entering a restricted interval
   * @param color color used to visualize the pre-set
   * @requires parking_lot_permission edit-settings
   */
  createParkingLotPreset(
    parkingLotId: number,
    name: string,
    reset_after_free_parking: boolean,
    color?: string
  ): Observable<ParkingDurationPreset> {
    let colorString = color == null ? '' : encodeURI(color);
    let params = new HttpParams()
      .append('preset_name', name.toString())
      .append('reset_after_free_parking', reset_after_free_parking.toString())
      .append('color', colorString);

    return this.http
      .post<ParkingDurationPreset>(
        API_BASE_URL + `parkinglots/${parkingLotId}/parking-duration-presets`,
        {},
        { params }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw new Failure(error.status, '404_create_new_preset');
        })
      );
  }

  /**
   * Fetches all available pre-sets for the given parking lot id that can be set for the
   * this parking lot, parking spaces or whitelistings
   * @param parkingLotId Id of parking lot
   * @requires parking_lot_permission access
   */
  fetchAvailableParkingLotPreset(parkingLotId: number): Observable<Array<PresetModel>> {
    return this.http
      .get<Array<PresetModel>>(
        API_BASE_URL + `parkinglots/${parkingLotId}/parking-duration-presets`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let message = '';
          switch (error.status) {
            case 404:
              message = '404_parking_lot_not_exist';
              break;
            default:
              message = 'error_fetch_available_preset';
              break;
          }
          throw new Failure(error.status, message);
        })
      );
  }

  /**
   * updates the parking duration preset of the parking to the given pre-set
   * @param parkingLotId Id of parking lot
   * @param presetId id of preset
   * @requires parking_lot_permission edit-settings
   */
  updateParkingLotPreset(parkingLotId: number, presetId: number): Observable<any> {
    let params = new HttpParams().append('preset_id', presetId.toString());
    return this.http
      .put<any>(
        API_BASE_URL + `parkinglots/${parkingLotId}/parking-duration-presets`,
        {},
        { params }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw new Failure(error.status, '404_update_parkinglot_preset');
        })
      );
  }

  /**
   * Preset can only get deleted if it isn't getting referenced by the parking lot or any of its parking spaces
   * @param parkingLotId Id of parking lot
   * @param presetId id of preset
   * @requires parking_lot_permission edit-settings
   */
  deleteParkingLotPreset(parkingLotId: number, presetId: number): Observable<any> {
    let params = new HttpParams().append('presetId', presetId.toString());

    return this.http
      .delete<any>(API_BASE_URL + `parkinglots/${parkingLotId}/parking-duration-presets`, {
        params
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let message = '';
          switch (error.status) {
            case 400:
              if (error.error == 'Preset does not exist') {
                message = '400_preset_not_exist';
              } else {
                message = '400_cannot_delete';
              }
            case 404:
              message = '404_parking_lot_not_exist';
              break;
            default:
              message = 'delete_preset_error';
              break;
          }
          throw new Failure(error.status, message);
        })
      );
  }

  /**
   *
   * @param parkingLotId
   * @param parkingSpaceId
   * @param presetId
   */
  udpateParkingSpacePresetByPresetId(
    parkingLotId: number,
    parkingSpaceId: number,
    presetId: number
  ): Observable<DurationSetting> {
    throw new Error('Method not implemented.');
  }

  /**
   * Updates the duration-settings of given preset id
   * @param parkingLotId Id of parking lot
   * @param presetId Id of preset
   * @param name New name of the preset, make sure to url-encode it
   * @param duration_setting
   * @param color Color used to visualize the preset in frontend applications.
   * The currently set color will be removed if the field is not specified
   * @requires parking_lot_permission edit-settings
   */
  updateDurationSettingsByPresetId(
    parkingLotId: number,
    presetId: number,
    name: string,
    duration_setting: DurationSetting[],
    resetAfterFreeParking: boolean,
    color?: string
  ): Observable<DurationSetting> {
    let colorString = color == null ? '' : encodeURI(color);
    let params = new HttpParams().append('name', name.toString()).append('reset_after_free_parking', `${resetAfterFreeParking}`);
    if (color != null) {
      params = params.append('color', colorString);
    }

    let json = {};

    let jsonObj = [
      {
        Field: 'duration_settings',
        Value: duration_setting
      }
    ];
    jsonObj.map((item) => (json[item.Field] = item.Value));

    return this.http
      .put<any>(
        API_BASE_URL + `parkinglots/${parkingLotId}/parking-duration-presets/${presetId}`,
        JSON.stringify(json),
        { params }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let message = '';
          switch (error.status) {
            case 404:
              message = '404_update_duration_setting';
              break;
            default:
              message = 'error_update_duration_settings';
              break;
          }
          throw new Failure(error.status, message);
        })
      );
  }

  /**
   * Fetches duration-settings by a parking lot duration preset
   * @param parkingLotId Id of parking lot
   * @param presetId Id of preset
   * @requires parking_lot_permission access
   */
  fetchPresetByPresetId(parkingLotId: number, presetId: number): Observable<ParkingDurationPreset> {
    return this.http
      .get<ParkingDurationPreset>(
        API_BASE_URL + `parkinglots/${parkingLotId}/parking-duration-presets/${presetId}`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let message = '';
          switch (error.status) {
            case 404:
              message = '404_preset_parking_lot_not_exist';
              break;
            default:
              message = 'error_fetch_preset_id';
              break;
          }
          throw new Failure(error.status, message);
        })
      );
  }

  /**
   * searches a preset by given name
   * The name matching is not case-sensitive. Make sure to url-encode the name parameter.
   * @param name non case-sensitive name of the presets to query
   */
  searchPresetbyName(name: string): Observable<Array<PresetModel>> {
    let params = new HttpParams().append('name', name.toString());
    return this.http
      .get<Array<PresetModel>>(API_BASE_URL + `parking-duration-presets/name`, { params })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw new Failure(error.status, 'error_search_preset_name');
        })
      );
  }

  /**
   * searches a preset by given name
   * The name wildcard matching is not case-sensitive. Make sure to url-encode the name parameter.
   * All returned presets will have a name that contains the queried name_part
   * @param name_part non case-sensitive name of the presets to query
   */
  searchPresetbyWildcard(name_part: string): Observable<Array<PresetModel>> {
    let params = new HttpParams().append('name_part', name_part.toString());
    return this.http
      .get<Array<PresetModel>>(API_BASE_URL + `parking-duration-presets/name-wildcard`, { params })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw new Failure(error.status, 'error_search_preset_name');
        })
      );
  }
}
