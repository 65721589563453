import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * Simple Confirmation Dialog which solely displays header, optional description, confirm button and cancel button
 * 
 * @example
 * `confirmText`: string
 * `description`: string,
 * `header`: string,
 * `confirmColor`: string,
 * `cancelText`: string
 * 
 * Simply pass the above variables to dialog data when opening this dialog
 *  
 */

@Component({
  selector: 'frontend-monorepo-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  header: string;
  description: string;
  buttonColor: string;
  confirmButtonText: string;
  cancelButtonText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      confirmText: string,
      description: string,
      header: string,
      confirmColor: string,
      cancelText: string
    },
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
    this.header = data.header;
    this.description = data.description;
    this.buttonColor = data.confirmColor;
    this.confirmButtonText = data.confirmText;
    this.cancelButtonText = data.cancelText;
  }

  ngOnInit() {}

  /**
   * closes dialog on confirm-button and passes `true` to parent component
   */
  onConfirm() {
    this.dialogRef.close({ data: true });
  }

  /**
   * closes dialog on confirm-button and passes `false` to parent component
   */
  onCancel() {
    this.dialogRef.close({ data: false });
  }
}
