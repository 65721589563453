import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { interval, timer } from 'rxjs';

@Component({
  selector: 'frontend-monorepo-rocket-fab',
  templateUrl: './rocket-fab.component.html',
  styleUrls: ['./rocket-fab.component.scss']
})
export class RocketFabComponent implements OnInit {
  @ViewChild('fab', { static: true })
  private _fab: ElementRef<HTMLDivElement>;

  constructor(private renderer: Renderer2, private elRef: ElementRef) {}

  ngOnInit(): void {
    this._setupWiggleAnimation();
  }

  onMouseEnter(event: any): void {
    this.renderer.addClass(event.target, 'rocket_idle');
  }

  onMouseLeave(event: any): void {
    this.renderer.removeClass(event.target, 'rocket_idle');
  }

  onClick(event: any): void {
    this.renderer.addClass(event.target, 'rocket_fly');
    timer(500).subscribe((_) => {
      window.open('https://dashboard-v2.smart-city-system.com/login', '_blank');
      this.renderer.removeClass(event.target, 'rocket_fly');
    });
  }

  private _setupWiggleAnimation(): void {
    interval(30000).subscribe(() => {
      this.renderer.removeClass(this._fab.nativeElement, 'wiggle_animation');
      setTimeout(() => this.renderer.addClass(this._fab.nativeElement, 'wiggle_animation'), 1000);
    });
  }
}
