import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@frontend-monorepo/core';
import { AuthResponse, createAuthResponse } from 'libs/core/src/lib/interfaces/Authentication';
import { RepositoryService } from 'libs/core/src/lib/services/repository/repository.service';
import { UserDataService } from 'libs/core/src/lib/services/user-data/user-data.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { Location } from '@angular/common';
import { Networking } from 'apps/customer-dashboard/src/app/helper/networking';

/**
 * Service for setting up Data-
 */
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private _subscription: Subscription;

  constructor(
    private repositoryService: RepositoryService,
    private userDataService: UserDataService,
    private router: Router,
    private networking: Networking
  ) {
    this._subscription = new Subscription();
  }

  setupBasicData(): Observable<boolean> {
    return new Observable((subscriber) => {
      this._subscription = forkJoin({
        customerPlidMap: this.repositoryService.loginRepository().fetchCustomerPLIDMapping(),
        userData: this.repositoryService.userRepository().fetchAccountInfo(),
        parkingLotBasic: this.networking.fetchAllParkinglots()
      }).subscribe((data) => {
        this.userDataService.setCustomerPlidList(data.customerPlidMap);
        this.userDataService.setUserData(data.userData);
        this.userDataService.setParkingLotBasicData = data.parkingLotBasic;
        subscriber.next(true)
      });
    });
  }

  setupCookie(response: AuthResponse) {
    this._subscription = this.repositoryService
      .loginRepository()
      .setCookie(response)
      .subscribe(
        (_) => this.repositoryService.authService().setCookieStatus(true),
        (error) => console.log('setup Cookie failed')
      );
  }

  resetCookie() {
    this._subscription = this.repositoryService
      .loginRepository()
      .setCookie(createAuthResponse(''))
      .subscribe((_) => {
        this.repositoryService.authService().setToken(undefined);
        this.repositoryService.authService().setCookieStatus(false);
        location.reload();
        this.repositoryService.authRepository().doLogout().subscribe(
          (res) => {
            // console.log("logout");
          },
          (err) => {}
        );
        this._unsubscribe();
      });
  }

  _unsubscribe(): void {
    this._subscription.unsubscribe();
  }
}
