import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { RepositoryService } from 'libs/core/src/lib/services/repository/repository.service';

@Component({
  selector: 'frontend-monorepo-preset-add-setting',
  templateUrl: './preset-add-setting.component.html',
  styleUrls: ['./preset-add-setting.component.scss']
})
export class PresetAddSettingComponent implements OnInit {

  constructor(
    private repositoryService: RepositoryService,
    private snackbar: MatSnackBar,
    private transloco: TranslocoService
  ) { }

  ngOnInit(): void {
  }

  addSetting() {
    let foundNotAppliedState = Array.from(this.repositoryService.presetService().getPresetAppliedState.values()).filter( (_entry) => _entry == false)
    if (foundNotAppliedState.length > 0) {
      this.snackbar.open(this.transloco.translate('apply_setting_first'))
    } else {
      if (this.repositoryService.presetService().getActiveDurationPreset.size < 5) {
        this.repositoryService.presetService().addDurationSetting();
      } else {
        this.snackbar.open(this.transloco.translate('maxSettingNumber'))
      }
    }
  }

}
