import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ParkingLotModel } from '@frontend-monorepo/core';
import { skip, startWith, map } from 'rxjs/operators';

@Component({
  selector: 'frontend-monorepo-parking-lot-list',
  templateUrl: './parking-lot-list.component.html',
  styleUrls: ['./parking-lot-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParkingLotListComponent implements OnInit, OnChanges {
  /**
   * List of all available parkinglots with parking lot permission "camera_events"
   */
  @Input() parkingLotList: ParkingLotModel[];
  /**
   * Tag determining whether selected items should be active or not
   */
  @Input() selectedListStatus: boolean;
  /**
   * notify and sends selected parking lots to `ExportCameraEventsComponent`
   */
  @Output() sendSelectedList = new EventEmitter();

  selectedParkingLots: ParkingLotModel[];
  parkingLotListFilter: ParkingLotModel[];
  searchControl = new FormControl();

  // checkbox state
  selectAllIndeterminateState: boolean;
  selectAllState: boolean;
  singleItemState: boolean;

  constructor(private cd: ChangeDetectorRef) {
    this.selectedParkingLots = [];
    this.parkingLotListFilter = [];
    this.selectAllIndeterminateState = false;
    this.selectAllState = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedListStatus.currentValue) {
      this.selectAll(false);
    }
  }

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        skip(1),
        startWith(''),
        map((pl) => {
          if (this.parkingLotList.length > 0) {
            return pl ? this.filterStates(pl) : this.parkingLotList.slice();
          } else {
            return [];
          }
        })
      )
      .subscribe((result) => {
        this.parkingLotListFilter = result;
      });
  }

  selectAll(checked: boolean) {
    this.selectedParkingLots = [];
    if (checked) {
      this.selectedParkingLots = [...this.parkingLotList];
    } else {
      this.selectAllIndeterminateState = false;
    }
    this.selectAllState = checked;
    this.singleItemState = checked;
    this.sendSelectedList.emit(this.selectedParkingLots);
  }

  selectParkinglot(checked: boolean, selectedItem: ParkingLotModel) {
    if (checked) {
      this.selectedParkingLots.push(selectedItem);
    } else {
      let index = this.selectedParkingLots.findIndex((_elemement) => selectedItem == _elemement);
      this.selectedParkingLots.splice(index, 1);
    }
    this.selectAllIndeterminateState =
      this.selectedParkingLots.length > 0 &&
      this.selectedParkingLots.length < this.parkingLotList.length;
    this.selectAllState = this.selectedParkingLots.length == this.parkingLotList.length;
    this.sendSelectedList.emit(this.selectedParkingLots);
  }

  filterStates(value: string): ParkingLotModel[] {
    const filterValue = value.toLowerCase();

    let list = this.parkingLotList.filter(
      (pl) =>
        pl.name.toLowerCase().includes(filterValue) ||
        pl.id.toString().toLowerCase().includes(filterValue)
    );

    if (list.length === 0) {
      list = this.parkingLotList.filter((pl) => pl.id.toString().includes(filterValue));
    }
    return list;
  }
}
