import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSearchBarComponent } from './component/multi-search-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';

import de from './i18n/de.json';
import en from './i18n/en.json';

import { TranslocoService, TranslocoModule } from '@ngneat/transloco';

/**
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@NgModule({
    declarations: [
        MultiSearchBarComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatSelectModule,
        TranslocoModule
    ],
    exports: [
        MultiSearchBarComponent
    ],
    providers: [],
})
export class MultiSearchbarModule {
    constructor(translocoService: TranslocoService) {
        translocoService.setTranslation(en, 'en');
        translocoService.setTranslation(de, 'de');
    }
}