  export const COLOR_LIST: string[] = [
    '#F1F1F1', // none
    '#000000', //black
    '#546e7a', // blue-grey 
    '#a1887f', // brown
    '#ff8a65', // deep-orange
    '#ffd54f', // amber
    '#fff176', // yellow
    '#dce775', // lime
    '#81c784', // green
    '#4db6ac', // teal
    '#4dd0e1', // cyan
    '#7986cb', // indigo
    '#ba68c8', // purple
    '#e57373', // red
  ]
  export const COLOR_LIST_2: string[] = [
    '#dce775', // lime
    '#81c784', // green
    '#4db6ac', // teal
    '#4dd0e1', // cyan
    '#7986cb', // indigo
    '#ba68c8', // purple
    '#e57373', // red
  ]

