import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ParkingLotsDataService, Device, ParkingLot, ParkingSpace, Repeater, Gateway } from '@frontend-monorepo/core';


export enum SEARCHBAR_RESULT_TYPE {
  PARKING_LOT,
  DEVICE,
  PARKING_SPACE,
  REPEATER,
  GATEWAY
}


export interface SearchBarResult {
  parkingLotName: string;
  element: Device | ParkingLot | ParkingSpace | Repeater | Gateway;
  type: SEARCHBAR_RESULT_TYPE;
}


/**
 * 
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@Component({
  selector: 'mp-multi-search-bar',
  templateUrl: './multi-search-bar.component.html',
  styleUrls: ['./multi-search-bar.component.scss']
})
export class MultiSearchBarComponent implements OnInit {
  stateCtrl = new FormControl();
  searchResults: Observable<any>;
  SEARCHBAR_RESULT_TYPE = SEARCHBAR_RESULT_TYPE;

  constructor(private parkingLotsDataService: ParkingLotsDataService) {
    this.searchResults = this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        map(state => this._filterAllEntities(state || ''))
      );
  }

  ngOnInit() {
  }

  /**
   * Filtering all entities (parking spaces, parking lots, devices, repeaters etc. ) by a queryString.
   * 
   * @param queryString 
   */
  private _filterAllEntities(queryString: string): Array<SearchBarResult> {

    const parkingLots = this.parkingLotsDataService.parkingLotsSubject.value;

    const parkingSpacesMap = this.parkingLotsDataService.parkingSpacesOfPLSubjectMap;
    const sensorsMap = this.parkingLotsDataService.installedDevicesOfPLSubjectMap;
    const repeatersMap = this.parkingLotsDataService.repeatersOfPLSubjectMap;
    const gatewaysMap = this.parkingLotsDataService.gatewaysOfPLSubjectMap;

    const filteredParkingLots = this.filterParkingLots(parkingLots, queryString);
    const filteredDevices = this.filterElementsMap(parkingLots, sensorsMap, SEARCHBAR_RESULT_TYPE.DEVICE, queryString);
    const filteredRepeaters = this.filterElementsMap(parkingLots, repeatersMap, SEARCHBAR_RESULT_TYPE.REPEATER, queryString);
    const filteredGateways = this.filterElementsMap(parkingLots, gatewaysMap, SEARCHBAR_RESULT_TYPE.GATEWAY, queryString);
    const filteredParkingSpaces = this.filterElementsMap(parkingLots, parkingSpacesMap, SEARCHBAR_RESULT_TYPE.PARKING_SPACE, queryString);

    return [
      ...filteredParkingLots,
      ...filteredParkingSpaces,
      ...filteredDevices,
      ...filteredRepeaters,
      ...filteredGateways
    ];
  }


  /**
   * Filtering an array of parking lots by a query string.
   * Wrapping the matching parking lots in SearchBarResult-elements.
   * @param parkingLots 
   * @param searchQuery 
   */
  filterParkingLots(parkingLots: Array<ParkingLot>, searchQuery: string): Array<SearchBarResult> {
    const loweredQuery = searchQuery.toLowerCase();
    return parkingLots
      .filter(parkingLot => parkingLot.name.toLowerCase().indexOf(loweredQuery) !== -1 || parkingLot.id.toString().indexOf(loweredQuery) !== -1)
      .map(parkingLot => <SearchBarResult>{ element: parkingLot, type: SEARCHBAR_RESULT_TYPE.PARKING_LOT, parkingLotName: parkingLot.name });
  }


  /**
   * Filtering a map by the queryString.
   * Returns an array of elements, which´s IDs contain the searchQuery.
   * 
   * @param parkingLots Array of parking lots
   * @param map Map of elements (sensors, gateways, repeaters etc.) from the `ParkingLotsDataService`
   * @param resultType `SEARCHBAR_RESULT_TYPE` of the search result 
   * @param searchQuery String to filter the elements by.
   */
  filterElementsMap(
    parkingLots: Array<ParkingLot>,
    map: Map<number, BehaviorSubject<Array<Device>>> | Map<number, BehaviorSubject<Array<Repeater>>> | Map<number, BehaviorSubject<Array<Gateway>>> | Map<number, BehaviorSubject<Array<ParkingSpace>>>,
    resultType: SEARCHBAR_RESULT_TYPE,
    searchQuery: string): Array<SearchBarResult> {

    let filteredElements = new Array<SearchBarResult>();

    map.forEach((elementsSubject, parkingLotID) => {
      const elements = elementsSubject.value;
      if (elements !== undefined) {
        const parkingLotName = parkingLots.find(parkingLot => parkingLot.id === parkingLotID).name;
        filteredElements = filteredElements.concat(elements
          .filter(element => {
            const id = element['id'] === undefined ? element['device_id'] : element['id'];
            return id.toString().indexOf(searchQuery) !== -1;
          })
          .map(element => <SearchBarResult>{ element: element, type: resultType, parkingLotName: parkingLotName })
        );
      }
    });
    return filteredElements;
  }
}
