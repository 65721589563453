import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatChip } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DurationSetting,
  MainDataService,
  RepositoryService,
  WEEKDAY,
  WEEKDAY_DISPLAY
} from '@frontend-monorepo/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'frontend-monorepo-preset-duration-setup',
  templateUrl: './preset-duration-setup.component.html',
  styleUrls: ['./preset-duration-setup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresetDurationSetupComponent implements OnInit {
  @Input() durationSetting: DurationSetting[];
  @Input() settingTag: number;

  newDurationSetting: DurationSetting;

  parkingDurationsForm: FormGroup;
  timeFormGroup: FormGroup;

  buttonDisableState: boolean;
  showOverdueSetting: boolean;

  parkinglotType: string;

  private weekdayList: string[] = [
    WEEKDAY.MONDAY,
    WEEKDAY.TUESDAY,
    WEEKDAY.WEDNESDAY,
    WEEKDAY.THRUSDAY,
    WEEKDAY.FRIDAY,
    WEEKDAY.SATURDAY,
    WEEKDAY.SUNDAY
  ];

  private defaultDaySelection: string[] = [
    WEEKDAY.MONDAY,
    WEEKDAY.TUESDAY,
    WEEKDAY.WEDNESDAY,
    WEEKDAY.THRUSDAY,
    WEEKDAY.FRIDAY,
    WEEKDAY.SATURDAY,
    WEEKDAY.SUNDAY
  ];

  private selectedDayList: string[] = [];

  constructor(
    private transloco: TranslocoService,
    private repositoryService: RepositoryService,
    private mainDataService: MainDataService,
    private cd: ChangeDetectorRef,
    private snackbar: MatSnackBar
  ) {
    this.buttonDisableState = true;
  }

  ngOnInit(): void {
    this._checkParkingLotType();
    this._initialiseFormGroups(this.durationSetting);

    /**
     * setup selected day
     */
    this._setupWeekday(this.durationSetting);

    this.parkingDurationsForm.valueChanges.subscribe((_value) => {
      /**
       * prevent negative numbers
       */
      if (_value.dueDuration < 0) {
        this.parkingDurationsForm.get('dueDuration').setValue(0);
      }
      if (_value.overdueDuration < 0) {
        this.parkingDurationsForm.get('overdueDuration').setValue(0);
      }
      if (_value.soonDueDuration < 0) {
        this.parkingDurationsForm.get('soonDueDuration').setValue(0);
      }
      this.repositoryService
        .presetService()
        .presetAppliedStateList.next(
          this.repositoryService
            .presetService()
            .presetAppliedStateList.getValue()
            .set(this.settingTag, false)
        );
      this.parkingDurationsForm.get('soonDueDuration').updateValueAndValidity({ onlySelf: true });
      this.parkingDurationsForm.get('overdueDuration').updateValueAndValidity({ onlySelf: true });
      this.buttonDisableState = false;
    });

    this.timeFormGroup.valueChanges.subscribe(() => {
      this.repositoryService
        .presetService()
        .presetAppliedStateList.next(
          this.repositoryService
            .presetService()
            .presetAppliedStateList.getValue()
            .set(this.settingTag, false)
        );
      this.timeFormGroup.get('startTime').updateValueAndValidity({ onlySelf: true });
      this.buttonDisableState = false;
    });
  }

  private _checkParkingLotType() {
    let plid = this.repositoryService.presetService().getCurrentPlid;
    if (plid != null && plid != 0) {
      this.parkinglotType = this.mainDataService.listOPL
        .getValue()
        .get(plid)
        .getValue().parking_lot_device_type;
    } else {
      this.parkinglotType = 'other';
    }
  }

  private _setupWeekday(durationSetting: DurationSetting[]) {
    if (durationSetting[0].preset_id == -1) {
      this.selectedDayList = this.defaultDaySelection;
    } else {
      durationSetting.map((setting) => this.selectedDayList.push(WEEKDAY_DISPLAY[setting.day]));
    }
  }

  private setupOverdueSetting(status: boolean) {
    this.showOverdueSetting = status;
    if (!status) {
      this.parkingDurationsForm.get('overdueDuration').setValue(0);
    }
    this.buttonDisableState = false;
    this.cd.detectChanges();
  }

  /**
   * set up forms for duration and time range settings
   */
  private _initialiseFormGroups(durationSetting: DurationSetting[]): void {
    console.log( durationSetting[0].due_warning_duration)
    this.showOverdueSetting =
      durationSetting[0].max_overdue_duration != null &&
      durationSetting[0].max_overdue_duration != 0;
    this.parkingDurationsForm = new FormGroup({
      soonDueDuration: new FormControl(
        durationSetting[0].due_warning_duration != 0
          ? (durationSetting[0].max_parking_duration - durationSetting[0].due_warning_duration) /
              60 ?? 0
          : 0,
        [this.checkPrevDurationExceeding()]
      ),
      dueDuration: new FormControl(durationSetting[0].max_parking_duration / 60 || 0, [
        Validators.max(31 * 24 * 60),
        Validators.min(1)
      ]),
      overdueDuration: new FormControl(durationSetting[0].max_overdue_duration / 60 || null, [
        this.checkMaxDuration()
      ])
    });
    this.timeFormGroup = new FormGroup({
      startTime: new FormControl(durationSetting[0].start_time || null, [
        Validators.required,
        this.checkTimeRange(),
        this.checkForOverlapSetting()
      ]),
      endTime: new FormControl(durationSetting[0].end_time || null, [Validators.required])
    });
  }

  /**
   * Weekday selection
   */
  private getSelectedDay(day: string): boolean {
    return this.selectedDayList.includes(day);
  }

  private onWeekdaySelection(chip: MatChip, weekday: string) {
    if (!this.selectedDayList.includes(weekday)) {
      this.selectedDayList.push(weekday);
    } else if (this.selectedDayList.includes(weekday)) {
      let index = this.selectedDayList.findIndex((day) => day === weekday);
      this.selectedDayList.splice(index, 1);
    }
    this.buttonDisableState = false;
    // update preset applied state
    this.repositoryService
      .presetService()
      .presetAppliedStateList.next(
        this.repositoryService
          .presetService()
          .presetAppliedStateList.getValue()
          .set(this.settingTag, false)
      );
    this.timeFormGroup.get('startTime').updateValueAndValidity({ onlySelf: true });
    chip.toggleSelected();
  }

  private deleteSetting() {
    let booleanList = Array.from(
      this.repositoryService.presetService().getPresetAppliedState.entries()
    ).filter((_entry) => _entry[0] != this.settingTag);
    let foundNotAppliedState = booleanList.filter((_entry) => _entry[1] == false);
    if (foundNotAppliedState.length > 0) {
      this.snackbar.open(this.transloco.translate('apply_setting_first'));
    } else {
      this.repositoryService.presetService().deleteDurationSetting(this.settingTag);
    }
  }

  private updateSetting() {
    this.checkForNull();

    // check if any day was selected
    if (this.selectedDayList.length < 1) {
      this.snackbar.open(this.transloco.translate('DURATION_SETUP.no_selected_days_error'));
      this.buttonDisableState = true;
      // update preset applied state
      this.repositoryService
        .presetService()
        .presetAppliedStateList.next(
          this.repositoryService
            .presetService()
            .presetAppliedStateList.getValue()
            .set(this.settingTag, true)
        );
      return;
    } else {
      let newList = this.selectedDayList.map((_day) => {
        let dayInNumber = WEEKDAY_DISPLAY.findIndex(
          (arrayDay) => arrayDay == _day && _day != WEEKDAY_DISPLAY[0]
        );

        if (dayInNumber > 0) {
          let soonDueStoreValue =
            this.parkingDurationsForm.get('soonDueDuration').value > 0
              ? (this.parkingDurationsForm.get('dueDuration').value ?? 0) -
                (this.parkingDurationsForm.get('soonDueDuration').value ?? 0)
              : 0;
          console.log(soonDueStoreValue);
          return {
            preset_id: this.durationSetting[0].preset_id,
            day: WEEKDAY_DISPLAY.findIndex(
              (arrayDay) => arrayDay == _day && _day != WEEKDAY_DISPLAY[0]
            ),
            max_parking_duration: (this.parkingDurationsForm.get('dueDuration').value ?? 0) * 60,
            due_warning_duration: soonDueStoreValue * 60,
            ...(this.parkingDurationsForm.get('overdueDuration').value != null &&
            this.parkingDurationsForm.get('overdueDuration').value != 0
              ? {
                  max_overdue_duration: this.parkingDurationsForm.get('overdueDuration').value * 60
                }
              : {}),
            start_time: `${this.timeFormGroup.get('startTime').value}`,
            end_time: `${this.timeFormGroup.get('endTime').value}`
          };
        }
      });
      this.repositoryService.presetService().updateDurationSetting(
        this.settingTag,
        newList.filter((item) => item != null).sort((a, b) => a.day - b.day)
      );
      this.buttonDisableState = true;
      this.showOverdueSetting =
        this.parkingDurationsForm.get('overdueDuration').value != null &&
        this.parkingDurationsForm.get('overdueDuration').value != 0;
      this.cd.detectChanges();
    }
  }

  /**
   * Form validations
   */

  checkForOverlapSetting(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.timeFormGroup === undefined) {
        return;
      }

      let list = Array.from(this.repositoryService.presetService().getActiveDurationPreset)
        .filter(([key, value]) => this.settingTag != key)
        .map(([key, value]) => value);

      let flattenList = [];
      if (list.length != 0) {
        flattenList = [...list.reduce((list, nextItem) => list.concat(nextItem))];
        let startTime: string = this.timeFormGroup.get('startTime').value;
        let endTime: string =
          this.timeFormGroup.get('endTime').value == '00:00'
            ? '24:00'
            : this.timeFormGroup.get('endTime').value;

        if (startTime != '' && startTime != null && endTime != '' && endTime != undefined) {
          let findOverlap = flattenList.find((_setting) => {
            if (this.selectedDayList.includes(WEEKDAY_DISPLAY[_setting.day])) {
              return (
                (startTime >= _setting.start_time &&
                  startTime < (_setting.end_time == '00:00' ? '24:00' : _setting.end_time)) ||
                (endTime > _setting.start_time &&
                  endTime <= (_setting.end_time == '00:00' ? '24:00' : _setting.end_time)) ||
                (startTime <= _setting.start_time &&
                  endTime >= (_setting.end_time == '00:00' ? '24:00' : _setting.end_time))
              );
            }
          });

          return findOverlap ? { overlapError: true } : null;
        }
      }
    };
  }

  checkTimeRange(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.timeFormGroup === undefined) {
        return;
      }
      let startTime: string = this.timeFormGroup.get('startTime').value;
      let endTime: string = this.timeFormGroup.get('endTime').value;

      if (endTime > '00:00' && startTime > endTime) {
        return {
          timeError: true
        };
      }
    };
  }

  checkForNull() {
    if (this.timeFormGroup === undefined) {
      return;
    }

    let startTime: string = this.timeFormGroup.get('startTime').value;
    let endTime: string = this.timeFormGroup.get('endTime').value;

    if (endTime == null || startTime == null) {
      this.snackbar.open(this.transloco.translate('DURATION_SETUP.inputNullError'));
      return;
    }
  }

  checkMaxDuration(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.parkingDurationsForm === undefined) {
        return;
      }
      let due: number = this.parkingDurationsForm.get('dueDuration').value;
      let overdue: number = this.parkingDurationsForm.get('overdueDuration').value;

      let maxDurationInMin = 31 * 24 * 60;

      if (overdue + due > maxDurationInMin) {
        return {
          maxDurationError: true
        };
      }
    };
  }

  checkPrevDurationExceeding(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.parkingDurationsForm === undefined) {
        return;
      }
      let soonDue: number = this.parkingDurationsForm.get('soonDueDuration').value;
      let due: number = this.parkingDurationsForm.get('dueDuration').value;

      if (soonDue > due && due > 0 && soonDue > 0) {
        return {
          prevDurationExceedingError: true
        };
      }
    };
  }
}
