export enum TWO_FACTOR_STATUS {
    ACTIVATED = 'activated', 
    DEACTIVATED = 'deactivated' 
}

export enum TWO_FACTOR_ACTION {
    ENABLE = "enable",
    DISABLE = "disable",
    LOGIN = "login"
}

export enum TWO_FACTOR_INPUT_STATUS {
    INPUT_SUCCESS,
    INPUT_FAILED
  }