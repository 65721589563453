<mp-routing-progress-indicator></mp-routing-progress-indicator>
<ng-container *transloco="let t; read: 'APP'">
  <div style="width: 100%; height: 100%; display: flex; flex-direction: column">
    <div style="display: flex; flex-grow: 2">
      <mat-drawer-container style="background-color: #f1f1f1; flex-grow: 2">
        <mat-drawer #drawer mode="over" autoFocus="false" id="mat_drawer">
          <div fxLayout="column" fxLayoutGap="36px">
            <div fxLayout="row" fxLayoutGap="5px">
              <div fxLayoutAlign="start center">
                <button mat-icon-button (click)="drawer.toggle()">
                  <mat-icon>arrow_back</mat-icon>
                </button>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" class="nav-bar-title">
                {{ t('nav_menu') }}
              </div>
            </div>
            <div fxLayout="column" fxLayoutGap="8px">
              <frontend-monorepo-drawer-content (toggleDrawer)="drawer.toggle()">
              </frontend-monorepo-drawer-content>
            </div>
          </div>
        </mat-drawer>
        <mat-drawer-content class="main_container">
          <div *ngIf="normalMode" class="toolbar_content">
            <mat-toolbar *ngIf="router.url !== '/login'" color="primary">
              <mat-toolbar-row>
                <div class="toolbar-container" fxLayout="row">
                  <div fxFlex="45" fxLayout="row" fxLayoutGap="8px">
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <button mat-button id="centered" (click)="drawer.toggle()">
                        <div fxLayout="row" fxLayoutGap="5px">
                          <div fxLayoutAlign="center center">
                            <mat-icon>menu</mat-icon>
                          </div>
                          <div class="nav-bar-title">{{ t('nav_menu') }}</div>
                        </div>
                      </button>
                      <button mat-button id="centered" (click)="returnToHomeScreen()">
                        <div fxLayout="row" fxLayoutGap="5px">
                          <div fxLayoutAlign="center center">
                            <mat-icon>home</mat-icon>
                          </div>
                          <div class="nav-bar-title">Home</div>
                        </div>
                      </button>
                    </div>
                  </div>

                  <div
                    class="no_focus"
                    style="cursor: pointer; border-color: transparent; width: 24rem"
                    fxLayoutAlgin="center center"
                    (click)="returnToHomeScreen()"
                  >
                    <img
                      style="
                        height: 2rem;
                        text-align: center;
                        margin-top: 0.5rem;
                        margin-left: 1rem;
                      "
                      src="{{ imagePath }}"
                      (error)="onImagePathError('../assets/icons/default_logo.png')"
                    />
                  </div>
                  <div fxFlex="90" fxLayout="row">
                    <ng-container *ngTemplateOutlet="searchbar"></ng-container>
                    <button
                      mat-button
                      [matMenuTriggerFor]="menu"
                      style="margin-left: 5px"
                      matTooltip="{{ t('help') }}"
                    >
                      <mat-icon class="help_icon">help</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <a href="{{ dashboardGuide }}" target="_blank" mat-menu-item>{{
                        t('dashboard_guide')
                      }}</a>
                      <a
                        href="https://www.wrike.com/frontend/requestforms/index.html?token=eyJhY2NvdW50SWQiOjU2NzYwMjcsInRhc2tGb3JtSWQiOjgyMzQ4MH0JNDgzMzE3NzYzMzg2NAk0OTI2YTQ0NjFiMzU2MmVmMjVjZjMyMzQzODQ1YjRhNGQ4ZGIxMmI5NzIzZDkzODk0YmI2MGMxOTRjN2ZhNmE1"
                        target="_blank"
                        mat-menu-item
                        >{{ t('support') }}</a
                      >
                    </mat-menu>
                    <button
                      mat-button
                      style="background-color: white™"
                      [matMenuTriggerFor]="langMenu"
                    >
                      <mat-icon svgIcon="{{ languageIcon }}"></mat-icon>
                    </button>
                    <mat-menu #langMenu="matMenu">
                      <button mat-menu-item (click)="changeLanguage('de-DE')">
                        <mat-icon svgIcon="flag_de"></mat-icon>
                        <span>{{ t('language_de') }}</span>
                      </button>
                      <button mat-menu-item (click)="changeLanguage('en-GB')">
                        <mat-icon svgIcon="flag_en"></mat-icon>
                        <span>{{ t('language_en') }}</span>
                      </button>
                      <button mat-menu-item (click)="changeLanguage('en-US')">
                        <mat-icon svgIcon="flag_us"></mat-icon>
                        <span>{{ t('language_us') }}</span>
                      </button>
                    </mat-menu>
                    <div fxLayoutAlign="center center">
                      <a
                        mat-button
                        style="margin-right: 0.7rem"
                        [routerLink]="'/login'"
                        (click)="logout()"
                      >
                        {{ t('logout') }}
                      </a>
                    </div>
                  </div>
                </div>
              </mat-toolbar-row>
            </mat-toolbar>
          </div>
          <router-outlet id="mainOutlet"></router-outlet>
          <div style="position: absolute; bottom: 8px; right: 8px">
            <frontend-monorepo-dashboard-v2></frontend-monorepo-dashboard-v2>
          </div>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>

    <ng-container *transloco="let t; read: 'FOOTER'">
      <footer style="padding: 0.25rem 0.5rem 0.25rem 0.5rem; display: flex">
        <div style="display: flex; flex-wrap: wrap">
          <span style="padding-right: 15px">
            {{ t('copyright') }}
          </span>
          <span style="padding-right: 15px"> | </span>
          <span (click)="openImprintDialog()" style="cursor: pointer; padding-right: 15px">
            {{ t('imprint') }}
          </span>
          <span (click)="openPolicyDialog()" style="cursor: pointer">
            {{ t('privacy_policy') }}
          </span>
        </div>
        <!-- <div>
          {{ t('powered_by_scs') }}
        </div> -->
      </footer>
    </ng-container>
  </div>

  <ng-template #searchbar style="width: 100%">
    <div style="width: 100%">
      <mat-form-field style="font-size: 11pt; float: right" *ngIf="parkingLotList.length > 0">
        <mat-label style="font-size: 11pt; color: #919191">{{ t('parkinglot_search') }}</mat-label>
        <input
          #inputField
          matInput
          placeholder="{{ t('search_input') }}"
          [matAutocomplete]="auto"
          [formControl]="searchControl"
          (keyup.enter)="searchRequest($event.target.value)"
        />
        <mat-icon
          style="margin-left: 6px; cursor: pointer"
          matSuffix
          (click)="searchRequest(inputField.value)"
          >search</mat-icon
        >
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let pl of parkingLotListFilter; trackBy: trackById"
            [value]="pl.name"
            style="line-height: 15px"
            (click)="selectPLID(pl.id)"
          >
            <span style="font-size: 10pt">{{ pl.name }}</span> <br />
            <span style="font-size: 9pt">PLID: {{ pl.id }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </ng-template>

  <ng-template #searchbarAuto style="width: 100%">
    <mat-form-field
      style="font-size: 11pt; float: right; margin-top: 0.7rem"
      *ngIf="parkingLotList.length > 0"
    >
      <mat-label style="font-size: 11pt; color: #919191">{{ t('parkinglot_search') }}</mat-label>
      <input
        #inputField
        matInput
        placeholder="{{ t('search_input') }}"
        [matAutocomplete]="auto"
        [formControl]="searchControl"
        (keyup.enter)="searchRequest($event.target.value)"
      />
      <mat-icon
        style="margin-left: 6px; cursor: pointer"
        matSuffix
        (click)="searchRequest(inputField.value)"
        >search</mat-icon
      >
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let pl of parkingLotListFilter; trackBy: trackById"
          [value]="pl.name"
          style="line-height: 15px"
          (click)="selectPLID(pl.id)"
        >
          <span style="font-size: 10pt">{{ pl.name }}</span> <br />
          <span style="font-size: 9pt">PLID: {{ pl.id }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </ng-template>
</ng-container>
