import { FlatTreeControl } from '@angular/cdk/tree';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener} from '@angular/material/tree';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Variable } from 'apps/customer-dashboard/src/app/helper/variable';
import { DRAWER_TREE_DATA } from '../utils/drawer-content';
import { URL_DRAWER_CONTENT_MAP } from '../utils/url-link-map';

/**
 * Navigation data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface NavNode {
  name: string;
  children?: NavNode[];
}


/** Flat node with expandable and level information */
interface NavFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'frontend-monorepo-drawer-content',
  templateUrl: './drawer-content.component.html',
  styleUrls: ['./drawer-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerContentComponent implements OnInit {
  @Output() toggleDrawer = new EventEmitter();

  private transformer = (node: NavNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level
    };
  };

  treeControl = new FlatTreeControl<NavFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  activeLink: string;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    this.dataSource.data = DRAWER_TREE_DATA;
  }

  ngOnInit(): void {
    let urlArray = Array.from(URL_DRAWER_CONTENT_MAP.entries());
    this.router.events.subscribe( (link) => {
      if (link instanceof NavigationEnd) {
        let currentLink = urlArray?.find( (_node) => link.url.includes(_node[1]));
        this.activeLink = !currentLink ? "" : currentLink[0];
        let activeNode = this.treeControl.dataNodes.findIndex((item) => item.name === this.activeLink)
        this.treeControl.expand(this.treeControl.dataNodes[activeNode]);
        this.cd.detectChanges();
      }
    })
    
  }

  hasChild = (_: number, node: NavFlatNode) => node.expandable;


  navigateTo(node: NavFlatNode) {
    this.toggleDrawer.emit();
    if (node.name == 'occupancy' || node.name == 'parking_violations') {
      localStorage.setItem(Variable.LIST_SELECTION_URL, URL_DRAWER_CONTENT_MAP.get(node.name))
    }
    this.activeLink = node.name;
    this.router.navigateByUrl(URL_DRAWER_CONTENT_MAP.get(node.name));

  }

  getActiveNode(node: NavFlatNode): boolean {
    return this.activeLink === node.name;
  }
}
