import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription, timer, BehaviorSubject, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ParkingLotsDataService,
  ParkinglotWebsocket,
  ParkingspaceWebsocketService,
  MainDataService,
  RepositoryService,
  CameraParkinglotStateWebsocketService
} from '@frontend-monorepo/core';
import { Router } from '@angular/router';
import {
  DeviceSpaceService,
  PARKING_LOT_PERMISSION,
  UserDataService
} from '@frontend-monorepo/parking-lot-map';
import { Variable } from 'apps/customer-dashboard/src/app/helper/variable';
import { LocalDataService } from 'apps/customer-dashboard/src/app/services/data/local-data.service';

@Component({
  selector: 'scs-guard-loading-dialog',
  templateUrl: './guard-loading-dialog.component.html',
  styleUrls: ['./guard-loading-dialog.component.scss']
})
export class GuardLoadingDialogComponent implements OnInit, OnDestroy {
  private mainSubscription: Subscription;
  private psSubscription: Subscription;
  private plSubscription: Subscription;
  private plCameraSubscription: Subscription;
  private defaulTimerSubscription: Subscription;
  private listOPLComplete: boolean = false;
  private listCameraOPLComplete: boolean = false;
  private listOPSComplete: boolean = false;
  private fetchesComplete: boolean = false;
  private deviceSpaceComplete: boolean = false;
  private timeout: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private parkingLotsDataService: ParkingLotsDataService,
    private repositoryService: RepositoryService,
    private dialogRef: MatDialogRef<GuardLoadingDialogComponent>,
    private plWebsocket: ParkinglotWebsocket,
    private psWebsocket: ParkingspaceWebsocketService,
    private cameraParkinglotWebsocket: CameraParkinglotStateWebsocketService,
    private mainDataService: MainDataService,
    private router: Router,
    private deviceSpaceService: DeviceSpaceService,
    private userDataService: UserDataService
  ) {
    this.mainSubscription = new Subscription();
    this.psSubscription = new Subscription();
    this.plSubscription = new Subscription();
    this.plCameraSubscription = new Subscription();
  }

  ngOnInit() {
    this.defaulTimerSubscription = timer(20000, 10000).subscribe(() => {
      this.defaulTimerSubscription.add(this.timeoutAction());
    });

    // if authentication error happens -> close guard
    this.mainSubscription.add(
      this.router.events.subscribe((route) => {
        this.timeoutAction();
      })
    );

    if (this.cameraParkinglotWebsocket.isRunning()) {
      if (this.mainDataService.listCameraOPL.getValue().size > 0) {
        this.listCameraOPLComplete = true;
      } else {
        this.cameraParkinglotWebsocket.stop();
        this.loadParkingLotCameraData();
      }
    } else {
      this.loadParkingLotCameraData();
    }

    if (this.plWebsocket.isRunning()) {
      if (this.mainDataService.listOPL.getValue().size > 0) {
        this.listOPLComplete = true;
        this.close();
        this.fetchRequiredData();
      } else {
        this.plWebsocket.stop();
        this.loadParkingLotData();
      }
    } else {
      this.loadParkingLotData();
    }

    if (this.psWebsocket.isRunning()) {
      let listWithPLID = Array.from(this.mainDataService.listOPS.values()).filter(
        (x) => x.getValue().plid === +this.data
      );

      if (listWithPLID.length > 0) {
        this.listOPSComplete = true;
        this.close();
      } else {
        this.psWebsocket.stop();
        this.loadParkingSpaceData();
      }
    } else {
      this.loadParkingSpaceData();
    }
  }

  private loadParkingLotCameraData() {
    this.plCameraSubscription.add(
      this.mainDataService.listCameraOPL.subscribe((result) => {
        if (Array.from(result.keys()).find((x) => x === +this.data) != undefined) {
          this.plCameraSubscription.unsubscribe();
          this.listCameraOPLComplete = true;
          this.close();
        }
        this.listCameraOPLComplete = true;
      })
    );

    this.cameraParkinglotWebsocket.start();
  }

  private loadParkingLotData() {
    this.plSubscription.add(
      this.mainDataService.listOPL.subscribe((result) => {
        if (Array.from(result.keys()).find((x) => x === +this.data) != undefined) {
          this.listOPLComplete = true;
          this.plSubscription.unsubscribe();
          this.fetchRequiredData();
          this.close();
        }
      })
    );
    this.plWebsocket.start();
  }

  private loadParkingSpaceData() {
    this.psSubscription.add(
      this.mainDataService.parkingLotDurations.subscribe((result) => {
        let listWithPLID = Array.from(this.mainDataService.listOPS.values()).filter(
          (x) => x.getValue().plid === +this.data
        );
        if (listWithPLID.length > 0) {
          this.listOPSComplete = true;
          this.psSubscription.unsubscribe();
          this.close();
        }
      })
    );
    this.psWebsocket.start(+this.data);
  }

  private fetchRequiredData() {
    this.mainSubscription = forkJoin({
      parkingLots: this.repositoryService.parkingLotRepository().fetchParkingLots(),
      parkingSpaces: this.repositoryService
        .parkingSpaceRepository()
        .fetchParkingSpacesOfPL(+this.data, 5)
    }).subscribe({
      next: (result) => {
        this.parkingLotsDataService.parkingLotsSubject.next(result.parkingLots);
        let parkingLot = result.parkingLots.filter((x) => x.id === +this.data);

        if (this.parkingLotsDataService.parkingSpacesOfPLSubjectMap.has(+this.data)) {
          this.parkingLotsDataService.parkingSpacesOfPLSubjectMap
            .get(+this.data)
            .next(result.parkingSpaces);
        } else {
          this.parkingLotsDataService.parkingSpacesOfPLSubjectMap.set(
            +this.data,
            new BehaviorSubject(result.parkingSpaces)
          );
        }
        this.fetchDeviceSpaceData(parkingLot[0].id);
        if (parkingLot.length > 0) {
          this.fetchesComplete = true;
          this.close();
        }
      }
    });
  }

  private fetchDeviceSpaceData(plid: number) {
    this.deviceSpaceService.storeCameraSpaces(plid);
    this.deviceSpaceComplete = true;
    this.listOPSComplete = true;
  }

  private timeoutAction() {
    this.timeout = true;
    this.close();
  }

  private close() {
    if (
      this.listOPLComplete &&
      this.listOPSComplete &&
      this.listCameraOPLComplete &&
      this.fetchesComplete &&
      this.deviceSpaceComplete
    ) {
      this.defaulTimerSubscription.unsubscribe();
      this.dialogRef.close({
        data: Variable.LOADING_DATA_SUCCESSFUL
      });
    }

    if (this.timeout) {
      this.defaulTimerSubscription.unsubscribe();
      this.dialogRef.close({
        data: Variable.LOADING_DATA_UNSUCCESSFUL
      });
    }
  }

  ngOnDestroy(): void {
    this.mainSubscription.unsubscribe();
    this.defaulTimerSubscription.unsubscribe();
  }
}
