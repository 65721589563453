export class Variables {


    static SEARCH_USER_URL: string = "https://api.parking-pilot.com/account/search?query=";
    static SEARCH_USER_PAGE: string = "&page=";
    static SEARCH_USER_ID_URL: string = "https://api.parking-pilot.com/account/";

    static SEARCH_ID: number = 0;
    static SEARCH_USER: number = 1;




}