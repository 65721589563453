import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { trigger, style, animate, transition, query, stagger, keyframes } from '@angular/animations';
import { Level } from '@frontend-monorepo/parking-lot-map';

/**
 * FAB-Button for switching a parking lot's deck to display in the ParkingLotMapComponent.
 */
@Component({
  selector: 'mp-switch-parking-lot-levels-fab',
  templateUrl: './switch-parking-lot-levels-fab.component.html',
  styleUrls: ['./switch-parking-lot-levels-fab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('speedDialStagger', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), { optional: true }),
        query(':enter', stagger('40ms',
          [
            animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              keyframes(
                [
                  style({ opacity: 0, transform: 'translateY(10px)' }),
                  style({ opacity: 1, transform: 'translateY(0)' }),
                ]
              )
            )
          ]
        ), { optional: true }),
        query(':leave',
          animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
            keyframes([
              style({ opacity: 1 }),
              style({ opacity: 0 }),
            ])
          ), { optional: true }
        )
      ])
    ]),
    trigger('fabContent', [
      transition('void => *', [
        style({
          transform: ' translate(-50%, -50%) rotate(180deg) scale(0)',
          opacity: 0
        }),
        animate('.3s', style({
          transform: 'translate(-50%, -50%) rotate(0) scale(1) ',
          opacity: 1
        }),
        )
      ]),
      transition('* => void', [
        animate('.3s', style({
          transform: 'translate(-50%, -50%) rotate(-180deg) scale(0)',
          opacity: 0
        }))
      ])
    ])
  ]
})
export class SwitchParkingLotLevelsFabComponent implements OnInit, OnChanges {

  @Input() levels: Array<Level>;

  @Input() activeDeck: Level;
  @Output() activeDeckChange = new EventEmitter<Level>();

  @Input() layerViewActive: boolean;
  @Output() layerViewActiveChange = new EventEmitter<boolean>();

  _levels: Array<Level>;

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.layerViewActive !== undefined) {
      const currentLayerViewActiveChange = changes.layerViewActive.currentValue;
      const didLayerViewActiveChange = !changes.layerViewActive.isFirstChange();
      if (!currentLayerViewActiveChange && didLayerViewActiveChange) {
        this._levels = undefined;
      }
    }
  }

  toggleFabChildren() {
    this._levels = this._levels !== undefined ? undefined : this.levels;
    this.layerViewActiveChange.emit(this._levels !== undefined);
  }

  onDeckSelect(level: Level) {
    this.activeDeckChange.emit(level);
    this.toggleFabChildren();
  }

}
