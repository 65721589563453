import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import de from './i18n/de.json';
import en from './i18n/en.json';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslocoService } from '@ngneat/transloco';
import { MaterialComponentsModule } from 'apps/customer-dashboard/src/app/pages/materialComponents.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SearchPresetByParkinglotComponent } from './search-preset-by-parkinglot/search-preset-by-parkinglot.component';

@NgModule({
  declarations: [SearchPresetByParkinglotComponent],
  imports: [CommonModule, MaterialComponentsModule, FlexLayoutModule],
  exports: [SearchPresetByParkinglotComponent]
})
export class PresetSearchPresetModule {
  constructor(translocoService: TranslocoService) {
    translocoService.setTranslation(en, 'en');
    translocoService.setTranslation(de, 'de');
  }
}
