import { Failure } from "@frontend-monorepo/core";
import { Observable } from "rxjs";
import { TotpCode } from "./models/totp-code.model";
import { TotpKey } from "./models/totp-key.model";

export abstract class Account2faBase {
    abstract disableTotp(): Observable<any | Failure>;
    abstract disableTotpById(userId: boolean): Observable<any> | Failure;
    abstract enableTotp(totopCode: number): Observable<any | Failure>;
    abstract generateTotpKey(): Observable<TotpKey | Failure>;
}