import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieDirective } from './cookie.directive';



@NgModule({
  declarations: [
    CookieDirective
  ],
  imports: [
    CommonModule
  ], 
  exports: [
    CookieDirective
  ]
})
export class CookieModule { }
