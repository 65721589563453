import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { API_BASE_URL, Failure } from '@frontend-monorepo/core';
import { User } from '@frontend-monorepo/parking-lot-map';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { query } from '@angular/animations';
import { InvitationResponse } from './models/invitation-response.model';
import { InvitedUser } from './models/invited-user.model';
import { UserInvitationBase } from './user-invitation.base';

export class UserInvitationRepository extends UserInvitationBase {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * adds authentication to invited user
   * @param invite_token
   * @param username
   * @param password
   */
  postAcceptInvite(invite_token: string, username: string, password: string): Observable<any> {
    let json = {
      username: username,
      password: password
    };
    return this.http
      .post<Map<number, number>>(
        API_BASE_URL + 'account/accept-invite?invite_token=' + invite_token,
        JSON.stringify(json)
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let message = '';
          if (error.status == 400) {
            message = 'password_not_met_requirements';
          } else if (error.status == 403) {
            message = 'invalid_token';
          }
          throw new Failure(error.status, message);
        })
      );
  }

  /**
   * Gets a list of invited users that have not accepted the invite yet
   * @returns List of `InvitedUser`
   */
  getInvitedUsers(): Observable<Array<InvitedUser>> {
    return this.http.get<Array<InvitedUser>>(`${API_BASE_URL}account/invited-users`).pipe(
      catchError((error: HttpErrorResponse) => {
        throw new Failure(error.status, 'getInvitedUsersError');
      })
    );
  }

  /**
   * Creates a new user without any authentication.
   *
   * @requires userPermission: admin or the parent_account_id must always equal the userId of the calling user
   * @param body user specific data and assign permissions as JSON format
   * @param send_email determines whether the generated token should be send to user by email
   * @param brand determines which dashboard branding the email should be send to
   * @param parent_user_id id of calling user
   */
  createNewUser(
    body: string,
    send_email: boolean,
    brand: string,
    parent_user_id: number
  ): Observable<InvitationResponse> {
    let params = new HttpParams()
      .append('token_expiry_days', '14')
      .append('parent_account_id', parent_user_id.toString())
      .append('send_email', send_email.toString())
      .append('brand', brand.toString());
    return this.http
      .post<InvitationResponse>(`${API_BASE_URL}account/invite`, body, { params })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let message = '';
          switch (error.status) {
            case 400:
              if (error.error == 'User with that email already exists') {
                message = 'create_user_error';
              } else if (
                error.error == 'sending the email failed probably because the email is invalid'
              ) {
                message = 'error_sending_email';
              } else {
                message = error.error
              }
              break;
            case 403:
              message = 'parent_not_owning_permissions';
              break;
            case 409:
              message = 'reached_limit_of_20';
              break;
            default:
              message = error.error;
              break;
          }
          throw new Failure(error.status, message);
        })
      );
  }

  /**
   * get user by invite token
   * @param invite_token
   */
  getAccountInvite(invite_token: string): Observable<User> {
    return this.http.get<User>(`${API_BASE_URL}account/invite?invite_token=${invite_token}`).pipe(
      catchError((error: HttpErrorResponse) => {
        let message = '';
        switch (error.status) {
          case 403:
            message = 'invalid_token';
            break;
          case 404:
            message = 'user_no_longer_exists';
            break;
          default:
            message = 'error';
            break;
        }
        throw new Failure(error.status, message);
      })
    );
  }
  /*
   * Generates an invite token for an existing account
   * if send_email is set to true it will be sent to the users email address. Sending the email usually takes 0.5s - 3s.
   * @param id of invited user
   * @param token_expiry_days number of days the token will be valid before it expires and a new one will have to generated. The user will not be deleted upon expiry
   * @param send_email determines whether the generated token should be send to user by email
   * @param brand determines which dashboard branding the email should be send to
   */
  generateNewInvitation(
    id: number,
    send_email: boolean,
    brand: string
  ): Observable<InvitationResponse> {
    let params = new HttpParams()
      .append('token_expiry_days', '14')
      .append('send_email', send_email.toString())
      .append('brand', brand.toString());

    return this.http
      .post<InvitationResponse>(`${API_BASE_URL}account/${id}/invite`, '', { params })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let message = '';
          switch (error.status) {
            case 400:
              if (error.error == 'there is a user with that email address already') {
                message = 'user_has_password_and_name';
              } else if (
                error.error == 'sending the email failed probably because the email is invalid'
              ) {
                message = 'error_sending_email';
              }
              break;
            case 403:
              message = 'not_allowed';
              break;
            case 404:
              message = 'could_not_find_user';
              break;
            default:
              message = 'generateNewInvitationError';
              break;
          }
         throw new Failure(error.status, message);
        })
      );
  }
}
