import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CirclePercentageIndicatorComponent } from './component/circle-percentage-indicator.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import de from './i18n/de.json';
import en from './i18n/en.json';


/**
 * 
 * 
 * 
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@NgModule({
    declarations: [
        CirclePercentageIndicatorComponent
    ],
    imports: [
        CommonModule,
        TranslocoModule
    ],
    exports: [
        CirclePercentageIndicatorComponent
    ],
})
export class CirclePercentageIndicatorModule {
    constructor(translocoService: TranslocoService) {
        translocoService.setTranslation(en, 'en');
        translocoService.setTranslation(de, 'de');
    }
}