import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { Failure } from 'libs/core/src/lib/repository/http-response/failure.model';
import { Comment } from 'libs/core/src/lib/repository/parking-space-comments/models/parking-space-comment.model';
import { RepositoryService } from 'libs/core/src/lib/services/repository/repository.service';
import { Subscription } from 'rxjs';
import moment from 'moment';

/**
 * Dialog to add and edit device space comment of a device space id
 */
@Component({
  selector: 'frontend-monorepo-add-parking-space-comment-dialog',
  templateUrl: './add-parking-space-comment-dialog.component.html',
  styleUrls: ['./add-parking-space-comment-dialog.component.scss']
})
export class AddParkingSpaceCommentDialogComponent implements OnInit, OnDestroy {
  commentText: FormControl;
  deleteOnFree: boolean;
  logComment: boolean;

  private _subscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { psid: number; _currentComment: Comment; action: string },
    private dialogRef: MatDialogRef<AddParkingSpaceCommentDialogComponent>,
    private repository: RepositoryService,
    private snackbar: MatSnackBar,
    private transloco: TranslocoService
  ) {
    this._subscription = new Subscription();
  }
  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.commentText = new FormControl(
      this.data._currentComment?.comment ?? '',
      Validators.required
    );
    this.deleteOnFree =
      this.data._currentComment != null ? this.data._currentComment.delete_on_free : false;
    this.logComment =
      this.data._currentComment != null ? this.data._currentComment.user_retrievable : false;
  }

  addComment() {
    let commentJson = { comment: this.commentText.value, delete_on_free: this.deleteOnFree, log_on_delete: this.logComment};
    this._subscription = this.repository
      .parkingSpaceCommentRepository()
      .putCommentbyDeviceSpaceId(this.data.psid, JSON.stringify(commentJson))
      .subscribe({
        next: (_successful) => {
          this.snackbar.open(this.transloco.translate('add_comment_succsessful'));
          let newComment: Comment = {
            device_space_id: this.data.psid,
            delete_on_free: this.deleteOnFree,
            comment: this.commentText.value,
            user_retrievable: this.logComment,
            timestamp:
              this.data._currentComment == null
                ? moment().unix()
                : this.data._currentComment.timestamp
          } as Comment;
          this.dialogRef.close(newComment);
        },
        error: (_error: Failure) =>
          this.snackbar.open(this.transloco.translate(_error.errorMessage))
      });
  }
}
