import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CameraEventBase } from './camera-event.base';
import { catchError } from 'rxjs/operators';
import { Failure } from '@frontend-monorepo/core';

export class CameraEventRepository extends CameraEventBase {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * POST Call for downloading a zip file containing all non expired events including their images
   * 
   * @param parking_lot_id Id of parking lot
   * @param start time in unix 
   * @param end time in unix 
   * @returns zip file 
   */
  postLiveEvents(
    parking_lot_id: number,
    start: number,
    end: number
  ): Observable<HttpResponse<Blob>> {
    let url = 'https://api.parking-pilot.com/parkinglots/' + parking_lot_id + '/cameras/live-events';

    let params = new HttpParams()
      .append('start', start.toString())
      .append('end', end.toString())

    return this.http.post<Blob>(url, {}, {
      params: params,
      observe: 'response',
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }).pipe(
        catchError( (error: HttpErrorResponse) => {
            let message = 'postLiveEventError';
            if (error.status == 400) {
              message = 'date_error';
            } else if (error.status == 404) {
                message = 'parking_lot_not_found';
              }
            throw new Failure(error.status, message);
        })
    );
  }
}
