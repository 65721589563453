import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Failure,
  ParkingDurationPreset,
  PresetModel,
  RepositoryService
} from '@frontend-monorepo/core';
import { TranslocoService } from '@ngneat/transloco';
import { DurationSetting } from 'libs/core/src/lib/repository/parking-lot-preset/models/duration-settings.model';
import { from, Subscription } from 'rxjs';
import { concatMap, delay, retry } from 'rxjs/operators';
import { PresetParkingLot } from '../../viewmodel/preset-parkinglot.model';
import { CheckExistingPresetDialogComponent } from '../check-existing-preset-dialog/check-existing-preset-dialog.component';

@Component({
  selector: 'frontend-monorepo-preset-setting-dialog',
  templateUrl: './preset-setting-dialog.component.html',
  styleUrls: ['./preset-setting-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresetSettingDialogComponent implements OnInit, OnDestroy {
  preset: ParkingDurationPreset;
  durationSettingList: Array<[number, DurationSetting[]]>;

  loading: boolean;
  private reset_after_free_parking: boolean;

  applyButtonDisableState: boolean;

  // list for storing preset found with same name
  presetList: PresetModel[];

  private _subscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public parkingLotID: number,
    private repositoryService: RepositoryService,
    private dialog: MatDialog,
    private translocoService: TranslocoService,
    private snackbar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<PresetSettingDialogComponent>
  ) {
    this._subscription = new Subscription();
    this.applyButtonDisableState = true;
    this.loading = false;
    this.durationSettingList = [];
  }
  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this._subscription = this.repositoryService
      .presetService()
      .presetResetSetting.subscribe((_setting) => {
        this.reset_after_free_parking = _setting;
      });
    this._subscription = this.repositoryService
      .presetService()
      .activeDurationPreset.subscribe((_duration_settings) => {
        if (_duration_settings != null) {
          this.durationSettingList = Array.from(_duration_settings.entries());
          this.applyButtonDisableState = false;
          this.cd.detectChanges();
        } else {
          this.repositoryService.presetService().addDurationSetting();
        }
      });
  }

  /**
   * closes dialog and updates duration setting info container
   * if setting was changed by user
   *
   * @param changedDuration state defining whether duration has been changed by user
   */
  close(changedDuration: boolean): void {
    this.dialogRef.close(changedDuration);
  }

  /**
   * steps and conditions to update/create a preset
   * 1. check if the default "classic" setting is being modified
   * 1.1 if the above case applies, then make it not appliceable for other parking lots and
   *     go to step 2 - `checkForSameNamePreset()`
   * 1.2 if the above case does NOT apply then go further to step 3
   *     to check preset on the same name `updatePreset()`
   * 2. check for existing preset with the same name and return the a list of parking lot ids
   *    which should apply the new preset setting
   * 3. check if you are updating an existing preset or creating a new preset
   * 3.1 On updating an existing preset:
   *     - directly use updateDurationSettingsByPresetId to update the preset
   *     - apply updated preset current parking lot and on additonal chosen parking lots
   *     - then update parking lot with the updated preset id
   * 3.2 On creating a new preset
   *     - create a new preset with createParkingLotPreset
   *     - apply duration setting on given preset
   *     - apply updated preset current parking lot and on additonal chosen parking lots
   *     - then update parking lot with the updated preset id
   */
  applyChanges(): void {
    if (this.reset_after_free_parking == null) {
      this.snackbar.open(this.translocoService.translate('select_reset_parking_duration_option'));
      return;
    }
    this.loadingState(true);
    let newPreset = this.repositoryService.presetService().getActivePreset;
    let name = this.repositoryService.presetService().getName;
    let color = this.repositoryService.presetService().getColor;
    let durationSetttingList =
      this.repositoryService.presetService().getActiveDurationPreset.size == 0
        ? []
        : [
            ...Array.from(
              this.repositoryService.presetService().getActiveDurationPreset.values()
            ).reduce((list, _items) => list.concat(_items))
          ];

    // check on preset with same name
    // update preset afterwards
    if (name !== 'legacy_duration_preset') {
      this.checkForSameNamePreset(
        newPreset,
        name,
        this.reset_after_free_parking,
        color,
        durationSetttingList
      );
    } else {
      //skip checking for same name preset on changing default setting
      this.updatePreset(
        newPreset,
        name,
        this.reset_after_free_parking,
        color,
        durationSetttingList,
        [
          {
            preset_id: newPreset?.preset?.preset_id,
            name: name,
            parking_lot_id: this.parkingLotID,
            parking_lot_name: ''
          }
        ]
      );
    }
  }

  /**
   * checks for existing preset with the same name and return the a list of parking lot ids
   * which should apply the new preset setting
   * @param newPreset
   *  @param name
   * @param reset_after_free_parking
   * @param color
   * @param durationSetttingList
   * @returns
   * 1. if there is no preset with the same name, then return an empty list
   * 2. if there is a preset with the same name, then return a list of parking lot ids
   *   which should apply the new preset setting
   * 3. if there is a preset with the same name and the same parking lot id, then return an empty list
  */
  checkForSameNamePreset(
    newPreset: ParkingDurationPreset,
    name: string,
    reset_after_free_parking: boolean,
    color: string,
    durationSetttingList: DurationSetting[]
  ) {
    this._subscription = this.repositoryService
      .parkingLotPresetRepository()
      .searchPresetbyName(name)
      .subscribe({
        next: (_preset: PresetModel[]) => {
          let presetList = _preset.filter((item) => item.parking_lot_id != this.parkingLotID);
          if (presetList.length > 0) {
            const dialogRef = this.dialog.open(CheckExistingPresetDialogComponent, {
              minWidth: '40vw',
              maxWidth: '60vw',
              maxHeight: '90vh',
              autoFocus: false,
              data: {
                presetList: presetList
              }
            });
            dialogRef.afterClosed().subscribe((_parkingLotList: PresetParkingLot[]) => {
              if (_parkingLotList == null) {
                this.loadingState(false);
                return;
              }
              let selectedParkingLots = [..._parkingLotList];
              selectedParkingLots.push({
                preset_id: newPreset?.preset?.preset_id,
                name: name,
                parking_lot_id: this.parkingLotID,
                parking_lot_name: ''
              });

              // update preset
              this.updatePreset(
                newPreset,
                name,
                reset_after_free_parking,
                color,
                durationSetttingList,
                selectedParkingLots
              );
            });
          } else {
            // update preset
            this.updatePreset(
              newPreset,
              name,
              reset_after_free_parking,
              color,
              durationSetttingList,
              [
                {
                  preset_id: newPreset?.preset?.preset_id,
                  name: name,
                  parking_lot_id: this.parkingLotID,
                  parking_lot_name: ''
                }
              ]
            );
          }
        },
        error: (_error: Failure) => {
          this.snackbar.open(this.translocoService.translate(_error.errorMessage));
          this.loadingState(false);
        }
      });
  }

  /**
   * acutally updates the preset
   *
   * steps are as follows
   * 1. on newly created preset
   * 2. on updating existing preset
   *
   * @param newPreset
   * @param name
   * @param reset_after_free_parking
   * @param color
   * @param durationSettingList
   * @param selectedParkingLots
   */
  updatePreset(
    newPreset: ParkingDurationPreset,
    name: string,
    resetAfterFreeParking: boolean,
    color: string,
    durationSettingList: DurationSetting[],
    selectedParkingLots: PresetParkingLot[]
  ) {
    this._subscription = this.repositoryService
      .parkingLotPresetRepository()
      .fetchAvailableParkingLotPreset(this.parkingLotID)
      .subscribe((_availPresets) => {
        /**
         * check if preset with the same name already exists for the selected parking lot
         * exists: directly go to the step to update duration settings
         * does not exist: create new preset with the name and update the duration settings afterwards
         */
        let foundPreset = _availPresets.find((_preset) => _preset.name === name);

        if (newPreset == null || foundPreset == null) {
          this.createNewPreset(
            name,
            resetAfterFreeParking,
            color,
            durationSettingList,
            selectedParkingLots
          );
        } else {
          // update existing preset
          this.updateDurationSettingForParkingLots(
            selectedParkingLots,
            foundPreset.preset_id,
            foundPreset.name,
            durationSettingList,
            resetAfterFreeParking,
            color
          );
        }
      });
  }

  /**
   * Creates new preset for calling parking lot
   * steps are as follows
   * - POST create new preset
   * - UPDATE duration setting on created preset id
   * - UPDATE parking lot with neww preset id
   *
   * @param name
   * @param resetAfterFreeParking
   * @param color
   * @param durationSettingList
   */
  createNewPreset(
    name: string,
    resetAfterFreeParking: boolean,
    color: string,
    durationSettingList: DurationSetting[],
    selectedParkingLots: PresetParkingLot[]
  ) {
    this.repositoryService
      .parkingLotPresetRepository()
      .createParkingLotPreset(this.parkingLotID, name, resetAfterFreeParking, color)
      .subscribe(
        (_newPreset) => {
          this.updateDurationSettingForParkingLots(
            selectedParkingLots,
            _newPreset.preset.preset_id,
            _newPreset.preset.name,
            durationSettingList,
            resetAfterFreeParking,
            color
          );
        },
        (_error: Failure) => {
          this.snackbar.open(this.translocoService.translate(`${_error.errorMessage}`));
          this.loadingState(false);
        }
      );
  }

  /**
   * Update duratiion settings for selected parking lot
   * - UPDATE duration setting either existing single preset or for a list of parking lots
   * - UPDATE current parking lot with new preset id
   *
   * @param parkingLotIdList
   * @param preset_id
   * @param name
   * @param durationSettingList
   */
  updateDurationSettingForParkingLots(
    parkingLotIdList: PresetParkingLot[],
    preset_id: number,
    name: string,
    durationSettingList: DurationSetting[],
    resetAfterFreeParking: boolean,
    color: string
  ) {
    let requestList = [];

    let settingList = [];
    durationSettingList.map((_setting) =>
      settingList.push({
        preset_id: preset_id,
        day: _setting.day,
        max_parking_duration: _setting.max_parking_duration,
        due_warning_duration: _setting.due_warning_duration,
        max_overdue_duration: _setting.max_overdue_duration,
        start_time: _setting.start_time,
        end_time: _setting.end_time
      })
    );

    // on single preset update
    if (parkingLotIdList.length == 1) {
      this.repositoryService
        .parkingLotPresetRepository()
        .updateDurationSettingsByPresetId(
          parkingLotIdList[0].parking_lot_id,
          preset_id,
          name,
          settingList,
          resetAfterFreeParking,
          color
        )
        .subscribe({
          next: (_resp) => {},
          error: (_error: Failure) => {
            this.snackbar.open(this.translocoService.translate(_error.errorMessage));
            this.loadingState(false);
          },
          complete: () => {
            this.snackbar.open(this.translocoService.translate('successfully_updated_preset'));
            // update parking lot with newly updated preset settings
            this.updateCurrentParkingLotWithPreset(preset_id);
          }
        });
    } else {
      /**
       * on multiple parking lot preset udpate
       * store request in list
       */
      parkingLotIdList.map((_parkingLot) =>
        requestList.push(
          this.repositoryService
            .parkingLotPresetRepository()
            .updateDurationSettingsByPresetId(
              _parkingLot.parking_lot_id,
              _parkingLot.preset_id,
              name,
              this._updateDurationSetttingWithPresetId(_parkingLot.preset_id, durationSettingList),
              resetAfterFreeParking,
              color
            )
        )
      );

      /**
       * send each request with a short delay to update each presets
       */
      this._subscription = from(requestList)
        .pipe(
          concatMap((request) => request.pipe(delay(200))),
          retry(3)
        )
        .subscribe({
          next: (_resp) => {
            this.snackbar.open(
              this.translocoService.translate('successfully_applied_to_multiple_parkinglots')
            );
            this.loadingState(false);
          },
          error: (_error: Failure) => {
            this.snackbar.open(this.translocoService.translate(_error.errorMessage));
            this.loadingState(false);
          }
        });
    }
  }

  /**
   * Update the duration settings for multiple parking lot presets
   *
   * The duration settings list has as default presetId, the id of the current preset.
   * If you want to update presets with the same name on other parking lots the preset id will be different.
   * Therefore this method adapts the preset id for the new duration settings and returns it
   *
   * @param presetId
   * @param durationSettings
   * @returns DurationSetting[]
   */
  private _updateDurationSetttingWithPresetId(
    presetId: number,
    durationSettings: DurationSetting[]
  ): DurationSetting[] {
    const durations = [...durationSettings];
    durations.forEach((element) => (element.preset_id = presetId));
    return durations;
  }

  /**
   * Update current Parking lot newly created/updated preset settings
   *
   * @param preset_id
   */
  updateCurrentParkingLotWithPreset(preset_id: number) {
    this._subscription = this.repositoryService
      .parkingLotPresetRepository()
      .updateParkingLotPreset(this.parkingLotID, preset_id)
      .subscribe({
        next: (_) => {
          this.close(true);
          this.loadingState(false);
        },
        error: (_error: Failure) => {
          this.snackbar.open(this.translocoService.translate(_error.errorMessage));
          this.loadingState(false);
        }
      });
  }

  /**
   * loading state for progress bar
   * @param state 
   */
  loadingState(state: boolean) {
    this.loading = state;
    this.cd.detectChanges();
  }
}
