import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { CoreApiService, ParkingLotWithOccupancies, ParkingLot, RepositoryService } from '@frontend-monorepo/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { ResetParkingDurationSettingsComponent } from './reset-parking-duration-settings/reset-parking-duration-settings.component';

/**
 * Popup for editing the duration settings of a parking lot.
 *
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@Component({
  selector: 'mp-parking-durations-settings-dialog',
  templateUrl: './parking-durations-settings-dialog.component.html',
  styleUrls: ['./parking-durations-settings-dialog.component.scss'],
})
export class ParkingDurationsSettingsDialogComponent implements OnInit, OnDestroy {
  parkingDurationsForm: FormGroup;

  private _subscriptions: Subscription;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public parkingLot: ParkingLot | ParkingLotWithOccupancies,
    private dialogRef: MatDialogRef<ParkingDurationsSettingsDialogComponent>,
    private apiService: CoreApiService,
    private repositoryService: RepositoryService,
    private snackbar: MatSnackBar,
    private transloco: TranslocoService,
    private resetDialog: MatDialog,
    private settingDialog: MatDialog
  ) {
    this._subscriptions = new Subscription();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  ngOnInit() {
    this._initializeParkingDurationsForm();
    this._initializeMaxParkingDurationChangeSubscription();
  }

  /**
   * Validating that `earlyWarningTime` is not greater than `maxParkingDuration`.
   * @param dueWarningDurationControl
   */
  validateEarlyWarningTime(dueWarningDurationControl: FormControl): ValidationErrors | null {
    if (this.parkingDurationsForm != null) {
      const currentDueWarningDuration = dueWarningDurationControl.value;
      const currentMaxParkingDuration = this.parkingDurationsForm.get('maxParkingDuration').value;
      if (currentDueWarningDuration >= currentMaxParkingDuration) {
        return {
          validateEarlyWarningTime: true,
        };
      }
    }
    return null;
  }

  /**
   * Returning a copy of the parking lot with the current parking lot durations from the input fields.
   */
  getParkingLotWithNewDurations(): ParkingLot | ParkingLotWithOccupancies {
    return Object.assign(
      { ...this.parkingLot },
      {
        max_overdue_duration: this.parkingDurationsForm.get('maxOverdueDuration').value * 60,
        max_parking_duration: this.parkingDurationsForm.get('maxParkingDuration').value * 60 || 0,
        due_warning_duration: this.parkingDurationsForm.get('earlyWarningTime').value * 60 || 0,
      },
    );
  }

  onParkingDurationsSubmit() {
    const updatedParkingLot = this.getParkingLotWithNewDurations();
    this.repositoryService.parkingLotSettingsRepository().updateDurationSettings(updatedParkingLot).subscribe(
      (res) => {
        this.snackbar.open(this.transloco.translate('snackbarSuccess'));
        this._closeDialog(updatedParkingLot);
      },
      (err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.snackbar.open(this.transloco.translate('snackbarError400'));
        } else if (err.status === 401) {
          this.snackbar.open(this.transloco.translate('snackbarError401'));
        } else {
          this.snackbar.open(
            this.transloco.translate('snackbarErrorUnknown', { errorCode: err.status }),
          );
        }
        this._closeDialog();
      },
    );
  }

  /**
   * Initializing a subscription to valueChanges of the `maxParkingDuration`
   * to update the `earlyWarningTime`´s validity whenever the `maxParkingDuration`
   * changes.
   */
  private _initializeMaxParkingDurationChangeSubscription(): void {
    this._subscriptions.add(
      this.parkingDurationsForm.get('maxParkingDuration').valueChanges.subscribe((res) => {
        this.parkingDurationsForm
          .get('earlyWarningTime')
          .updateValueAndValidity({ onlySelf: true });
      }),
    );
  }

  /**
   * Initializing the component´s form group for editing a parking lot´s parking durations.
   *
   * Using the `parkingLot`´s durations as initial values.
   */
  private _initializeParkingDurationsForm(): void {
    this.parkingDurationsForm = new FormGroup({
      maxParkingDuration: new FormControl(
        this.parkingLot.max_parking_duration / 60,
        Validators.required,
      ),
      earlyWarningTime: new FormControl(
        this.parkingLot.due_warning_duration / 60 || 0,
        this.validateEarlyWarningTime.bind(this),
      ),
      maxOverdueDuration: new FormControl(this.parkingLot.max_overdue_duration / 60 || 0),
    });
  }
  
  /**
   * Returning a copy of the parking lot input 0 to reset the settings.
   */
  getResetParkingLotWithNewDurations(): ParkingLot | ParkingLotWithOccupancies {
    return Object.assign(
      { ...this.parkingLot },
      {
        max_overdue_duration: 0,
        max_parking_duration: 0,
        due_warning_duration: 0,
      },
    );
  }

  private _reset() {
    const dialogRef = this.resetDialog.open(ResetParkingDurationSettingsComponent, {
      minWidth: '60px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[0] === true) {
        const updatedParkingLot = this.getResetParkingLotWithNewDurations();
        this.repositoryService.parkingLotSettingsRepository().updateDurationSettings(updatedParkingLot).subscribe(
          (res) => {
            this.snackbar.open(this.transloco.translate('snackbarResetSuccess'));
            this._closeDialog(updatedParkingLot);
          },
          (err: HttpErrorResponse) => {
            if (err.status === 400) {
              this.snackbar.open(this.transloco.translate('snackbarError400'));
            } else if (err.status === 401) {
              this.snackbar.open(this.transloco.translate('snackbarError401'));
            } else {
              this.snackbar.open(
                this.transloco.translate('snackbarErrorUnknown', { errorCode: err.status }),
              );
            }
          },
        );
      }
    });
  }


  /**
   * Cancel action by calling dialogRef's `close` function.
   */
  private _cancel(){
    this.dialogRef.close();
  }

  /**
   * Closing the dialog by calling dialogRef's `close` function.
   */
  private _closeDialog(updatedParkingLot?: ParkingLot | ParkingLotWithOccupancies | undefined) {
    this.dialogRef.close([true, updatedParkingLot]);
  }
}
