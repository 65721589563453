export const DRAWER_TREE_DATA = [
    //Overview
    {
      // name: this.translocoService.translate('overview'),
      name: 'overview',
      children: [
        {
          // name: this.translocoService.translate('occupancy')
          name: 'occupancy'
        },
        {
          // name: this.translocoService.translate('parking_violations')
          name: 'parking_violations'
        }
      ]
    },
    //Analysis
    {
      name: 'analysis',
      children: [
        {
          name: 'parking_lot_usage',
          children: [
            {
              name: 'sensors',
              children: [
                {
                  name: 'interactive_analysis_sensor'
                },
                {
                  name: 'csv_export'
                }
              ]
            },
            {
              name: 'license_plate_detections',
              children: [
                {
                  name: 'interactive_analysis_camera'
                }
              ]
            }
          ]
        },
        {
          name: 'parking_lot_control',
          children: [
            {
              name: 'sensors',
              children: [
                {
                  name: 'search_for_violations'
                },
                {
                  name: 'export_violation_potential_analysis'
                }
              ]
            },
            {
              name: 'license_plate_detections',
              children: [
                // {
                //   name: 'validate_parking_violations'
                // },
                {
                  name: 'export_parking_violations'
                },
                {
                  name: 'export_camera_events'
                }
              ]
            }
          ]
        }
      ]
    },
    //Parking lot management
    {
      name: 'parking_lot_management',
      children: [
        {
          name: 'system_status'
        },
        {
          name: 'customer_plids'
        },
        {
          name: 'permitted_parking_durations'
        },
        {
          name: 'email_notifications'
        }
      ]
    },
    //User management
    {
      name: 'user_management',
      children: [
        {
          name: 'manage_sub_user',
          children: [
            {
              name: 'create_new_user',
              children: [
                {
                  name: 'via_invitation_link'
                },
                {
                  name: 'via_username_password'
                }
              ]
            },
            {
              name: 'change_user_password'
            },
            {
              name: 'delete_user'
            },
            {
              name: 'edit_user_permission'
            },
            {
              name: 'assign_parkinglot'
            },
            {
              name: 'edit_parkinglot_permission'
            }
          ]
        },
        {
          name: 'manage_personal_account',
          children: [
            {
              name: 'manage_api_key'
            },
            {
              name: 'change_personal_password'
            },
            {
              name: 'manage_2_factor_authentication'
            }
          ]
        }
      ]
    }
];