<ng-container *transloco="let t">
    <div fxLayout="column" fxLayoutGap="10ox">
      <h2 mat-dialog-title>{{ t('comment_history_dialog_header') }}</h2>
      <p>{{t('comment_history_dialog_description')}}</p>
      <frontend-monorepo-comment-history-list [parkingLotId]="parkingLotId"></frontend-monorepo-comment-history-list>
    </div>
    <div>
      <button mat-flat-button color="cancel" [mat-dialog-close]>{{t('close')}}</button>
    </div>
  </ng-container>
  