import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../helper/models';
import { Variable } from '../../helper/variable';

@Injectable({
  providedIn: 'root'
})
export class LocalDataService {

  userLanguage: BehaviorSubject<String>;
  userList: BehaviorSubject<User[]>;
  loading_indicator: BehaviorSubject<boolean>;
  
  constructor() {
    this.userLanguage = new BehaviorSubject(Variable.DE_DE);
    this.userList = new BehaviorSubject(new Array());
    this.loading_indicator = new BehaviorSubject<boolean>(false);
  }

  // #region userList
  fillUserList(tmpUserList: User[]){
    this.userList.next(tmpUserList);
  };
  // #endregion 

  resetAll(){
    this.userLanguage = new BehaviorSubject(Variable.DE_DE);
    this.userList = new BehaviorSubject(new Array());
  }
}
