import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { RepositoryService } from '@frontend-monorepo/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatDialog } from '@angular/material/dialog';
import { PresetResetSettingDialogComponent } from '../../../preset-configuration/components/preset-reset-setting-dialog/preset-reset-setting-dialog.component';

@Component({
  selector: 'frontend-monorepo-parking-duration-reset-info',
  templateUrl: './parking-duration-reset-info.component.html',
  styleUrls: ['./parking-duration-reset-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParkingDurationResetInfoComponent implements OnInit, OnDestroy {
  private _subscription: Subscription;

  reset_text: string;

  constructor(
    private translocoService: TranslocoService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private repositoryService: RepositoryService
  ) {
    this._subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.repositoryService.presetService().presetResetSetting.subscribe((_resetStatus) => {
      if (_resetStatus == null) {
        this.reset_text = '';
      } else {
        this.reset_text = _resetStatus
          ? this.translocoService.translate('yes')
          : this.translocoService.translate('no');
      }

      this.cd.detectChanges();
    });
  }

  openInfoDialog() {
    const dialogRef = this.dialog.open(PresetResetSettingDialogComponent, {
      minWidth: '40vw',
      maxWidth: '60vw',
      maxHeight: '60vh',
      autoFocus: false
    });
  }
}
