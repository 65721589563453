import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  ParkingLotMapService,
  DeviceSpaceService,
  DeviceSpace
} from '@frontend-monorepo/parking-lot-map';
@Component({
  selector: 'frontend-monorepo-device-space',
  templateUrl: './device-space.component.html',
  styleUrls: ['./device-space.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceSpaceComponent implements OnInit {
  /**
   * Input of one device space element from the parking lot map
   */
  @Input() deviceSpace!: DeviceSpace;

  constructor(
    private parkingLotMapService: ParkingLotMapService,
    private deviceSpaceService: DeviceSpaceService
  ) {}

  ngOnInit(): void {}

  /**
   * adds device space to selected list on click
   */
  onDeviceSpaceClick(): void {
    if (this.deviceSpaceService.checkDeviceSpace(this.deviceSpace.deviceSpaceId)) {
      this.parkingLotMapService.addSelectedDeviceSpace(this.deviceSpace.deviceSpaceId);
    }
  }

  /**
   * @returns boolean determining whether device is a camera device
   */
  checkDeviceType(): boolean {
    return this.deviceSpaceService.checkDeviceSpace(this.deviceSpace.deviceSpaceId);
  }
}
