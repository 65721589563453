import { Injectable } from "@angular/core";
import { ParkingSpaceMapInfo } from "@frontend-monorepo/parking-lot-map";
import { BehaviorSubject } from "rxjs";


@Injectable({ providedIn: 'root' })
export class ConnectedPSHelper {

    connectedParkingSpaceSubject: BehaviorSubject<ConncetedParkingSpace[]>;

     constructor() {
         this.connectedParkingSpaceSubject = new BehaviorSubject<ConncetedParkingSpace[]>(null);
     }

    //  _connectedParkingSpacesMapping(parkingSpaces: ParkingSpaceMapInfo[]) {

    //      let isConnectedParkingSpace = parkingSpaces.find((item) => item.connectedXmlId != null);
        
    //      if (isConnectedParkingSpace) {
    //         let tmpMapping: ConncetedParkingSpace[] = [];

    //         parkingSpaces.forEach( (element) => {
    //             let actualXmlId = element.xmlId;
    //             let tmpList: number[] = [];
    //             if (element.connectedXmlId != null) {
    //                 tmpList.push(element.xmlId);
    //                 let smallestXmlId = this._getSmallestConntectPSID(element, parkingSpaces, tmpList);
    //                 tmpMapping.push({actual_xmlId: smallestXmlId, connected_xmlId: element.xmlId, actual_psid: null});
    //             } else {
    //                 tmpMapping.push({actual_xmlId: actualXmlId,  connected_xmlId: null, actual_psid: null});
    //             }
    //         });
    //         tmpMapping.map( (ps) => ps.actual_psid = parkingSpaces.find( (element) => element.xmlId == ps.actual_xmlId).parkingSpaceId)
    //         this.connectedParkingSpaceSubject.next(tmpMapping);
    //      }
    //  }

    //  _getSmallestConntectPSID(actualXmlId: ParkingSpaceMapInfo, parkingSpaces: ParkingSpaceMapInfo[], connectedList: number[]): number {
    //      let nextObject = parkingSpaces.find((element) => element.xmlId === actualXmlId.connectedXmlId);
    //      connectedList.push(nextObject.xmlId);
        
    //      if (connectedList.includes(nextObject.connectedXmlId)) {
    //          return Math.min(...connectedList);
    //      } else {
    //          return this._getSmallestConntectPSID(nextObject, parkingSpaces, connectedList);
    //      }
    //  }

    // export interface ConncetedParkingSpace {
    //     actual_xmlId: number;
    //     connected_xmlId: number;
    //     actual_psid: number;
    // }


    _connectedParkingSpacesMapping(parkingSpaces: ParkingSpaceMapInfo[]) {
        let isConnectedParkingSpace = parkingSpaces.find((item) => item.connectedXmlId != null);

        if (isConnectedParkingSpace) {
            let tmpMapping: ConncetedParkingSpace[] = [];
            parkingSpaces.filter(item => item.connectedXmlId === item.xmlId).forEach( ps => {
                tmpMapping.push({xmlid: ps.xmlId, psid: ps.parkingSpaceId});
            })
            this.connectedParkingSpaceSubject.next(tmpMapping);
        }
    }
    
}



export interface ConncetedParkingSpace {
    xmlid: number;
    psid: number;
}

