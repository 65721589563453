<div
  #parkingSpaceDiv
  class="parkingSpace parkingLotImage"
  [ngClass]="{ long: isParkingSpaceLongLine() }"
  (click)="toggleParkingSpaceSelection($event)"
  (touchstart)="toggleParkingSpaceSelection($event)"
>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div #centeredID class="centeredId">
      <div *ngIf="isConnectedPS()">
        {{ parkingSpaceMapInfo.xmlId }}
      </div>
    </div>
  </div>
  <ng-container #contentRef></ng-container>
</div>
