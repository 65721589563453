import { AuthService, PresetService, RepositoryService } from '@frontend-monorepo/core';
import { Account2faRepository } from '../../repository/account-2fa/account-2fa.repository';
import { AuthRepository } from '../../repository/auth/auth.repository';
import { CameraSnapshotRepository } from '../../repository/camera-snapshot/camera-snapshot.repository';
import { LoginRepository } from '../../repository/login/login.repository';
import { OpenBarrierRepository } from '../../repository/open-barrier/open-barrier.repository';
import { ParkingLotPresetRepository } from '../../repository/parking-lot-preset/parking-lot-preset.repository';
import { ParkingLotSettingsBase } from '../../repository/parking-lot-settings/parking-lot-settings.base';
import { ParkingLotBase } from '../../repository/parking-lot/parking-lot.base';
import { ParkingSpacesBase } from '../../repository/parking-spaces/parking-spaces.base';
import { UserInvitationRepository } from '../../repository/user-invitation/user-invitation.repository';
import { UserRepository } from '../../repository/user/user.repository';
import { CameraEventRepository } from '../../repository/camera-event/camera-event.repository';
import { EvseIdRepository } from '../../repository/evse-id/evse-id.repository';

export abstract class RepositoryBase {
  abstract parkingLotRepository(): ParkingLotBase;
  abstract parkingLotSettingsRepository(): ParkingLotSettingsBase;
  abstract parkingSpaceRepository(): ParkingSpacesBase;
  abstract loginRepository(): LoginRepository;
  abstract cameraRepository(): CameraSnapshotRepository;
  abstract userRepository(): UserRepository;
  abstract account2faRepository(): Account2faRepository;
  abstract authRepository(): AuthRepository;
  abstract authService(): AuthService;
  abstract userInvitationRepository(): UserInvitationRepository;
  abstract openBarrierRepostory(): OpenBarrierRepository;
  abstract parkingLotPresetRepository(): ParkingLotPresetRepository;
  abstract cameraEventsRepository(): CameraEventRepository;
  abstract evseIdRepository(): EvseIdRepository;
  abstract presetService(): PresetService;
}
