import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { HEATMAP_COMPARISON_TYPE } from '@frontend-monorepo/parking-lot-map';
import { Drawables } from 'libs/parking-lot-map/projects/parking-lot-map/core/src/public_api';

@Component({
  selector: 'mp-drawables',
  templateUrl: './drawables.component.html',
  styleUrls: ['./drawables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawablesComponent implements OnInit {
  @ViewChild('drawableDiv', { static: true }) private _drawableDiv: ElementRef<HTMLDivElement>;

  @Input() drawable: Drawables;
  @Input() heatmapGroupComparison: HEATMAP_COMPARISON_TYPE;

  hideHeatmapRelatedIcons: boolean;

  constructor() {}

  ngOnInit(): void {
    this.hideHeatmapRelatedIcons =
      this.heatmapGroupComparison == HEATMAP_COMPARISON_TYPE.MULTI ||
      this.heatmapGroupComparison == HEATMAP_COMPARISON_TYPE.SINGLE;
  }
}
