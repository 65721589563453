import { Injectable } from '@angular/core';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { Subscription, throwError, timer, interval } from 'rxjs';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment';
import { WebSocketBase } from './websocket_base.interface';
import { MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material/snack-bar';
import { AUTHENTICATION_FAILURE, AuthService, MainDataService, RepositoryService, WebsocketMessageModel, WebSocketObject } from '@frontend-monorepo/core';


@Injectable({
  providedIn: 'root'
})
export class ParkingspaceWebsocketService implements WebSocketBase {

  private parkingSpaceWS: WebSocketSubject<any>;
  private mainSubscription: Subscription;
  private websocketMessage: WebSocketObject;
  private actualPLID: number;
  private actualApikey: string;
  private reconnectTrials: number = 0;
  private intervalSubscription: Subscription;
  private pongSubscription: Subscription;
  private reconnectSubscription: Subscription;
  private snackbarRef: MatSnackBarRef<SimpleSnackBar>;

  constructor(
    private repositoryService: RepositoryService,
    private mainDataService: MainDataService,
    private snackbar: MatSnackBar,
    private router: Router,
    private translocoService: TranslocoService
  ) { }


  start(plid?: number, apikey?: string) {
    if ((plid != undefined && this.repositoryService.authService().getToken() != undefined) || (plid != undefined && apikey != undefined)){
      this.mainSubscription = new Subscription();
      
      this.actualPLID = plid;
      this.actualApikey = apikey;
      this.parkingSpaceWS = webSocket({
        url: 'wss://api.parking-pilot.com/parkinglots/'+ plid +'/parkingspace-state-websocket',
        openObserver: {
          next: () => {
            if (apikey != null) {
              this.parkingSpaceWS.next(JSON.parse('{"' + "api_key" + '":"' + apikey + '"}'));
            } else if (this.repositoryService.authService().isTwoFactorEnabled) {
              const tokens = this.repositoryService.authService().getTokens();
              this.parkingSpaceWS.next({
                token: tokens?.token,
                two_factor_token: tokens?.two_factor_token
              })
            } else {
              this.parkingSpaceWS.next({
                token: this.repositoryService.authService().getToken(),
              })
            }
          }
        },
      });
  
      this.mainSubscription.add(
        this.parkingSpaceWS.subscribe(parkingSpaces => {
          this.websocketMessage = parkingSpaces;
          this.mainDataService.updateListOPS(parkingSpaces, plid);

          if (this.websocketMessage.type == "pong"){
            // console.log("pong unsubscribe");
            this.pongSubscription.unsubscribe();
            this.pongSubscription = null;
          }

          if (this.actualApikey != undefined){
            // console.log("stop reconnect");
            this.stopReconnect();
          }

          if (this.snackbarRef != null){
            this.snackbarRef.dismiss();
            this.snackbarRef = null;
          }
        },
        (err) => {
          this.stop();
          if (this.actualApikey == undefined){
            this.errorAction();
          }
        })
      );

      let browserName = this.checkBrowser(window.navigator.userAgent.toLowerCase());
      if (browserName === "safari"){
        window.addEventListener('online', () => {
          // console.log('came online');
          this.start(this.actualPLID, this.actualApikey);
        });
        window.addEventListener('offline', () => {
          // console.log('came offline');
          this.stop();
          this.showSnackbarNoConnection();
        });
      }
      
    } else {
      console.log("ParkingspaceWebsocketService needs a PLID");
    }
  }


  private sendMessageContinously(){
    this.intervalSubscription = interval(10000).subscribe(() => {
      // console.log("sendMessage");
      this.parkingSpaceWS.next(JSON.parse('{"token":"ping"}'));
      this.startTimerWaitingForPong();
    });
  }

  private startTimerWaitingForPong() {
    this.pongSubscription = timer(2000).subscribe(() => {
      // console.log("No Pong request");
      // this.stop();
      if (this.reconnectSubscription == null){
        if (this.snackbarRef == null){
          this.showSnackbarNoConnection();
        }
        
        this.tryReconnect();
      }
    });
  }


  private tryReconnect(){
    this.stop();
    this.reconnectSubscription = interval(8000).subscribe(() => {
      // console.log("tryReconnect");
      this.stop();
      this.start(this.actualPLID, this.actualApikey);
    });
  }

  private stopReconnect(){
    if (this.reconnectSubscription != null){
      // console.log("stopReconnect Functions");
      this.reconnectSubscription.unsubscribe();
      this.reconnectSubscription = null;
    }
  }

  private errorAction(){
    if (this.websocketMessage.type === AUTHENTICATION_FAILURE){
      let snackBarRef = this.snackbar.open(this.translocoService.translate('SERVICE.auth_error'), this.translocoService.translate('SERVICE.login'), {duration: 8000});
      snackBarRef.onAction().subscribe(() => this.router.navigate(["/login"]));
    } else {
      this.tryReconnectMultipleTimes();
    }
  }



  private tryReconnectMultipleTimes(){
    if (this.reconnectTrials === 5) {
      let snackBarRef = this.snackbar.open(this.translocoService.translate('SERVICE.reconnect'), this.translocoService.translate('SERVICE.login'), {duration: undefined});
      snackBarRef.onAction().subscribe(() => {
        this.router.navigate(["/login"]);
      });
    } else {
      this.reconnectTrials += 1;
      this.start(this.actualPLID);
    }
    
  }

  stop() {
    if (this.parkingSpaceWS != undefined){
      this.parkingSpaceWS.complete();
      this.parkingSpaceWS.unsubscribe();
    }

    this.parkingSpaceWS = undefined;
    this.mainSubscription.unsubscribe();

    if (this.intervalSubscription != null){
      this.intervalSubscription.unsubscribe();
    }
  }


  private showSnackbarNoConnection(){
    let actualTime: String = moment().format("HH:mm:ss, DD.MM.YY");
    this.snackbarRef= this.snackbar.open(
      this.translocoService.translate('SERVICE.error_uptodate_data') + "\n" + this.translocoService.translate('SERVICE.last_update') + " " + actualTime,
      '', {
        duration: 0,
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        panelClass: ['snackbar_color_ps_ws'],
    });
  }

  private checkBrowser(browserMsg: String): String {
    if (browserMsg.includes('chrome') && browserMsg.includes('safari')) {
      return "chrome";
    } else if (browserMsg.includes('chrome') && browserMsg.includes('safari') && browserMsg.includes('edg')){
      return "edge";
    } else if (browserMsg.includes('firefox')) {
      return "firefox";
    } else if (browserMsg.includes('safari')){
      return "safari";
    } else {
      return "chrome";
    }
  }

  isRunning(): boolean {
    return this.parkingSpaceWS == undefined ? false : !this.parkingSpaceWS.isStopped;
  }
}
