<mat-toolbar>
  <ng-container *transloco="let t;">

    <div id="parkingLotViewNavigation" *ngIf="isParkingLotRouteActive; else appName">
      <a [routerLink]="[ '/landing' ]" class="navigationTitle">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <div id="nameAndIdContainer">
          <span id="plNameContainer" class="mat-body-strong">{{parkingLotName}}</span>
          <span id="plIdContainer" class="mat-caption">PLID: {{plid}}</span>
        </div>
      </a>
    </div>
    <mat-button-toggle-group id="parkingLotMapNavigation"
      *ngIf="isParkingLotRouteActive && !hideParkingLotMapNavigation">
      <mat-button-toggle [routerLink]="[ '/parking-lot/'+plid+'/renting' ]" routerLinkActive="active">
        {{ t('rentalToggleButton') }}
      </mat-button-toggle>
      <mat-button-toggle [routerLink]="[ '/parking-lot/'+plid+'/data' ]" routerLinkActive="active">
        {{ t('analyticsToggleButton') }}
      </mat-button-toggle>
    </mat-button-toggle-group>
    <ng-template #appName>
      <span id="dashboardLogo">
        <ng-content></ng-content>
      </span>
    </ng-template>
    <div id="navigationLinkContainer">
      <a [routerLink]="[ '/landing' ]" routerLinkActive="active">
        {{ t('homeRouterLink') }}
      </a>
      <a id="logoutButton" [routerLink]="['/login']" (click)="doLogout()">
        {{ t('signOffRouterLink') }}
      </a>
    </div>
    <mp-multi-search-bar *ngIf="!hideMultiSearchbar"></mp-multi-search-bar>
    <mp-language-picker></mp-language-picker>
  </ng-container>
</mat-toolbar>