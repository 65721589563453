<ng-container *transloco="let t">
  <h1 mat-dialog-title>{{ t('dialogTitle') }}</h1>
  <form
    [formGroup]="parkingDurationsForm"
    autocomplete="off"
  >
    <div mat-dialog-content fxLayout="column">
      <span style="font-size: 10pt;">{{ t('maxParkingDurationCaption') }}</span>
      <mat-form-field>
        <mat-label>{{ t('maxParkingDurationPlaceholder') }}</mat-label>
        <input
          matInput
          type="number"
          placeholder="{{ t('maxParkingDurationPlaceholder') }}"
          formControlName="maxParkingDuration"
          step="5"
          min="0"
          required
        />
      </mat-form-field>
      <span style="font-size: 10pt;">{{ t('dueWarningDurationCaption') }}</span>
      <mat-form-field>
        <mat-label>{{ t('dueWarningDurationLabel') }}</mat-label>
        <input
          matInput
          type="number"
          placeholder="{{ t('dueWarningDurationPlaceholder') }}"
          formControlName="earlyWarningTime"
          step="5"
          min="0"
        />
        <mat-error
          *ngIf="parkingDurationsForm.get('earlyWarningTime').hasError('validateEarlyWarningTime')"
        >
          {{ t('duaWarningDurationError') }}
        </mat-error>
      </mat-form-field>
      <span style="font-size: 10pt;">{{ t('maxOverdueDurationCaption') }}</span>
      <mat-form-field>
        <mat-label>{{ t('maxOverdueDurationLabel') }}</mat-label>
        <input
          matInput
          type="number"
          placeholder="{{ t('maxOverdueDurationPlaceholder') }}"
          formControlName="maxOverdueDuration"
          step="5"
          min="0"
        />
      </mat-form-field>
    </div>
    <mp-parking-durations-color-beam
      [parkingLot]="getParkingLotWithNewDurations()"
    ></mp-parking-durations-color-beam>
    <div mat-dialog-actions fxLayoutAlign="space-between"> 
        <button
        id="button"
        mat-flat-button
        color="cancel"
        (click)="_cancel()"
        title="{{ t('cancel')}}">
        {{ t('cancel')}}
        </button>
        <span class="flex-spacer"></span>
        <div fxLayout="row" fxLayoutGap="5px">
          <button
          mat-flat-button
          color="warn"
          title="{{ t('reset_settings')}}"
          (click)="_reset()">
          {{ t('reset_settings')}}
        </button>
        <button
          [disabled]="parkingDurationsForm.invalid"
          mat-flat-button
          color="primary"
          title="{{ t('saveParkingDurationsButtonTitle') }}"
          (click)=" onParkingDurationsSubmit()"
          [attr.aria-label]="t('saveParkingDurationsButtonTitle')"
        >
          {{ t('saveParkingDurationsButton') }}
        </button>
        </div>
      </div>
  </form>
</ng-container>
