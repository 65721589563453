import {
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Router } from '@angular/router';
import { RepositoryService } from '@frontend-monorepo/core';
import { UserDataService } from 'libs/core/src/lib/services/user-data/user-data.service';
import { Subject, Subscription } from 'rxjs';
import { LoginService } from '../login-service/login.service';

/**
 * Structural Directive checking whether token was stored in Cookie-Server
 * and determines whether login-page should be skipped accordingly
 */

@Directive({
  selector: '[cookie]'
})
export class CookieDirective {
  /**
   * Event Emitter for notifying {@link LoginComponent} wether cookie is enabled or not
   */
  @Output() responseStatus: EventEmitter<string> = new EventEmitter<string>();
  @Input() set cookie(router_url: string) {
    this.checkCookie(router_url);
  }

  private _subscription: Subscription;

  constructor(private repositoryService: RepositoryService, private loginService: LoginService) {
    this._subscription = new Subscription();
  }

  checkCookie(router_url: string) {
    this._subscription = this.repositoryService
      .loginRepository()
      .getCookie()
      .subscribe(
        (cookie) => {
          if (cookie != undefined || !cookie.token.includes('undefined') || cookie.token.length > 0) {
            let isTwoFactorEnabled = cookie.token.includes('totp') ? true : false;
            this.repositoryService.authService().setTwoFactorEnabled(isTwoFactorEnabled);

            let token = "";
            if (isTwoFactorEnabled) {
              token = cookie.token.replace('totp', '')
            } else {
              token = cookie.token.replace('undefined', '');
            }
            this.repositoryService.authService().setCookieStatus(true);
            this.repositoryService.authService().setToken(token);
            this.responseStatus.emit('HAS_COOKIE');
          } else {
            this.responseStatus.emit('NO_COOKIE');
            this._subscription.unsubscribe();
          }
        },
        (error) => {
          this.responseStatus.emit('NO_COOKIE');
        }
      );
  }
}
