import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Optional, Inject } from '@angular/core';
import { Router, ActivationEnd, ActivatedRouteSnapshot, Route } from '@angular/router';

import { Subscription } from 'rxjs';
import { AuthService, CoreApiService, ParkingLotsDataService, ParkingLot, RepositoryService } from '@frontend-monorepo/core';
import { HIDE_PARKING_LOT_MAP_NAVIGATION } from '../tokens/hideParkingLotMapNavigation.token';
import { HIDE_MULTI_SEARCHBAR } from '../tokens/hideMultiSearchbar.token';
import { LoginService } from '../../../login/src/helper/login-service/login.service';

/**
 * App´s navigation bar that consists of both all direct router links and a multi-purpose search bar.
 * The navigationBar is not shown on the login-page.
 * 
 * **If you want to inject a logo, title or any component at the center-left position of the `NavigationbarComponent`, follow the following example.**
 * 
 * @example How to add a logo/title/components to the center-left position of the `NavigationbarComponent`.
 * 
 * <mp-navigationbar>
 *              <!-- YOUR_LOGO / YOUR_COMPONENT / ANY HTML TAG -->
 * </mp-navigationbar>
 * 
 * 
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@Component({
  selector: 'mp-navigationbar',
  templateUrl: './navigationbar.component.html',
  styleUrls: ['./navigationbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationbarComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription;

  isParkingLotRouteActive: boolean = false;

  parkingLotName: string;

  plid: number;

  constructor(
    @Inject(HIDE_PARKING_LOT_MAP_NAVIGATION) public hideParkingLotMapNavigation: boolean,
    @Inject(HIDE_MULTI_SEARCHBAR) public hideMultiSearchbar: boolean,
    private repositoryService: RepositoryService,
    private loginService: LoginService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private parkingLotsDataService: ParkingLotsDataService,
  ) {
    this._subscriptions = new Subscription();
  }

  ngOnInit() {
    this._subscriptions.add(
      this.router.events.subscribe(routingEvent => {
        if (routingEvent instanceof ActivationEnd) {
          this._handleNewSnapshot(routingEvent.snapshot);
        }
      })
    );

    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  /**
   * Calling apiService´s doLogout function.
   * After successfully logging out, the auth-token is set to undefined.
   */
  doLogout(): void {
    this.repositoryService.authRepository().doLogout().subscribe(res => {
      this.loginService.resetCookie();
    });
  }

  /**
   * Handling new routing events.
   * 
   * If the app's new route contains `parking-lot`, `isParkingLotRouteActive` is set to true and both `parkingLotName` and `plid` are set from the currently active parking lot.
   * Otherwise, `isParkingLotRouteActive` is set to false and the other parameters are set to undefined.
   *  
   * @param snapshot The `ActivatedRouteSnapshot`-object
   */
  private _handleNewSnapshot(snapshot: ActivatedRouteSnapshot): void {
    if (this._isParkingLotRouteActive(snapshot)) {
      const plid: number = parseInt(<string>snapshot.params.PLID);
      const parkingLot = this._getParkingLotByID(plid);
      this.parkingLotName = parkingLot.name;
      this.plid = plid;
      this.isParkingLotRouteActive = true;
    } else {
      this.parkingLotName = undefined;
      this.plid = undefined;
      this.isParkingLotRouteActive = false;
    }

    this.cd.detectChanges();
  }

  /**
   * Returning true if the current route's path includes `parking-lot'`, otherwise false
   * @param snapshot The `ActivatedRouteSnapshot`-object which's path is checked.
   */
  private _isParkingLotRouteActive(snapshot: ActivatedRouteSnapshot): boolean {
    return snapshot !== undefined && snapshot.routeConfig.path.includes('parking-lot');
  }

  /**
   * Searching for and returning the `ParkingLot` object by a plid.
   * @param plid ID of the parking lot which is desired to be returned.
   * @returns The `ParkingLot` object whichs id matches the queried plid.
   */
  private _getParkingLotByID(plid: number): ParkingLot {
    return this.parkingLotsDataService.parkingLotsSubject.getValue().find(parkingLot => parkingLot.id === plid);
  }
}
