import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountDownPipe } from './count-down-pipe';



@NgModule({
  declarations: [CountDownPipe],
  imports: [
    CommonModule
  ],
  exports: [
    CountDownPipe
  ]
})
export class CountDownModule { }
