<ng-container *transloco="let t; read: 'switchLevelsFab'">

  <button id="switchButton" mat-fab color="primary" (click)="toggleFabChildren()" title="{{ t('toggleFabChildrenButtonTitle') }}"
    [attr.aria-label]="t('toggleFabChildrenButtonTitle')">
    <mat-icon class="animatedFabContent" [@fabContent]="layerViewActive" *ngIf="layerViewActive; else levelIndicator">
      layers</mat-icon>
    <ng-template #levelIndicator><span class="animatedFabContent"
        [@fabContent]="layerViewActive">{{activeDeck.level}}</span></ng-template>
  </button>
  <div id="secondaryButtonRow" [@speedDialStagger]="layerViewActive">
    <button *ngFor="let level of _levels" title="{{ t('onDeckSelectButtonTitle', {level: levels?.level}) }}"
      [attr.aria-label]="t('onDeckSelectButtonTitle', {level: levels?.level})" mat-mini-fab color="primary"
      (click)="onDeckSelect(level)">
      {{level.level}}
    </button>
  </div>
</ng-container>