import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AnalysisFilterModel, InitialDataObject } from '@frontend-monorepo/algorithm';
import { SatDatePickerModel } from 'apps/customer-dashboard/src/app/helper/models';
import { FilteredDataModel } from 'libs/algorithm/src/lib/models/filtered_data_model';
import { BehaviorSubject, Subject } from 'rxjs';
import { ANALYSIS_TYPE, CHART, FILTER_OPTIONS, GROUP_A } from '../helper/constants';
import { HeatmapType } from '../helper/models';
import { AnalysisTableData } from '../parkinglot-analysis-table/models';

@Injectable({
  providedIn: 'root'
})
export class HeatmapDataService {
  private plidName: string;
  private plid: number;
  private initHeatmap: boolean = true;
  private numberOfParkingSpaces: Array<number>;
  analysis_type: string;
  tableData: BehaviorSubject<MatTableDataSource<AnalysisTableData>>;
  date_range: BehaviorSubject<SatDatePickerModel[]>;
  question_type_detail: BehaviorSubject<string>;
  heatmap_type: BehaviorSubject<HeatmapType>;
  merge_resets: Subject<boolean>;
  mergeReset: boolean = true;

  /**
   * BehaviorSubject for passing and updating data filter object for QUESTION_TYPE_4
   */
  filter_object: BehaviorSubject<InitialDataObject[]>;

  /** 
   * BehaviorSubject for passing and updating filter options
   */
  filter_options: BehaviorSubject<number[]>;
  /** 
   * BehaviorSubject for passing and updating activ filter options (applied on analysis)
   */
  activ_filter_options: BehaviorSubject<number[]>;

  /**
   * BehaviorSubject for passing and updating filtered and calculated data for heatmap-page display
   */
  filtered_data: BehaviorSubject<FilteredDataModel[]>;
  /**
   * Subject
   */
  data_filtered: Subject<boolean>;

  /**
   * 
   */
  webworker_message_subject: Subject<string>;


  loading_status: Subject<boolean>;

  private analysisType: ANALYSIS_TYPE;

  constructor() {
    this.date_range = new BehaviorSubject([]);
    this.question_type_detail = new BehaviorSubject(null);
    this.filtered_data = new BehaviorSubject(null);
    this.filter_object = new BehaviorSubject(null);
    this.filter_options = new BehaviorSubject([1,0,0,0]);
    this.activ_filter_options = new BehaviorSubject([1,0,0,0]);
    this.heatmap_type = new BehaviorSubject({ analysis_type: CHART.UTILISATION, group: GROUP_A });
    this.data_filtered = new Subject();
    this.webworker_message_subject = new Subject();
    this.merge_resets = new Subject();
    this.loading_status = new Subject();
  }

  getInitHeatmap(): boolean {
    return this.initHeatmap;
  }

  setInitHeatmap(set: boolean) {
    this.initHeatmap = set;
  }

  resetPlid(): void {
    this.plid = -1;
  }

  setPlid(plid: number): void {
    this.plid = plid;
  }

  getPlid(): number {
    return this.plid;
  }

  setPlName(name: string): void {
    this.plidName = name;
  }

  getPlName(): string {
    return this.plidName;
  }

  getNumberOfParkingSpaces(): Array<number> {
    return this.numberOfParkingSpaces;
  }

  setNumberOfParkingSpaces(numberOfPS: Array<number>) {
    this.numberOfParkingSpaces = numberOfPS;
  }

  get getAnalysisType(): ANALYSIS_TYPE {
    return this.analysisType;
  }

  set setAnlysisType(type: ANALYSIS_TYPE) {
    this.analysisType = type;
  }

  resetData() {
    this.date_range = new BehaviorSubject([]);
    this.question_type_detail = new BehaviorSubject(null);
    this.filtered_data = new BehaviorSubject(null);
    this.filter_object = new BehaviorSubject(null);
    this.activ_filter_options = new BehaviorSubject(null);
    this.heatmap_type = new BehaviorSubject({ analysis_type: CHART.UTILISATION, group: GROUP_A });
    this.data_filtered = new Subject();
    this.loading_status = new Subject();
  }
}
