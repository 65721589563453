<div *transloco="let t">
  <div fxLayout="row" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutGap="15px">
      <span class="smaller-text">{{ t('filter_parking_spaces') }}</span>
      <div
        fxLayout="row"
        (click)="isOpen = !isOpen"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        class="cursor"
      >
        <span class="filter-text">{{ t('filter') }}</span>
        <mat-icon>tune</mat-icon>
      </div>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="isOpen"
        (backdropClick)="isOpen = false"
      >
        <mat-card [class.mat-elevation-z2]="true">
          <mat-form-field class="psid-input">
            <mat-label>{{ t('parking_spaces') }}</mat-label>
            <input
              matInput
              placeholder="{{ t('parking_spaces') }}"
              (keyup)="changeValuePsid($event.target.value)"
            />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
          <mat-selection-list #psids (selectionChange)="onSelectPsid($event)">
            <mat-list-option
              *ngFor="let psid of psidList"
              [value]="psid"
              [selected]="selectedPsidList.includes(psid)"
            >
              {{ psid }}
            </mat-list-option>
          </mat-selection-list>
          <div>
            <button mat-flat-button color="cancel" (click)="isOpen = !isOpen">
              {{ t('close') }}
            </button>
          </div>
        </mat-card>
      </ng-template>
    </div>

    <div fxLayout="column" fxLayoutGap="15px">
      <span class="smaller-text">
        {{ t('active_parking_space_filter') }}
      </span>
      <mat-chip-list selectable multiple>
        <mat-chip
          [class.mat-elevation-z2]="enableWeekdayFilter"
          #d="matChip"
          *ngFor="let psid of selectedPsidList"
          [value]="psid"
        >
          <div fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
            {{ psid }}
            <mat-icon class="cursor" (click)="remove(psid)">close</mat-icon>
          </div>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
