import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportCameraEventsComponent } from './components/export-camera-events/export-camera-events.component';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import de from './i18n/de.json';
import en from './i18n/en.json';
import { TranslocoService } from '@ngneat/transloco';
import { MaterialComponentsModule } from 'apps/customer-dashboard/src/app/pages/materialComponents.module';
import { ParkingLotListComponent } from './components/parking-lot-list/parking-lot-list.component';
import { DownloadProgressBarComponent } from './components/download-progress-bar/download-progress-bar.component';
import { DateTimePipeModule } from '../dateTimePipe/date-time.module';



@NgModule({
  declarations: [
    ExportCameraEventsComponent,
    ParkingLotListComponent,
    DownloadProgressBarComponent
  ],
  imports: [
    CommonModule,
    BreadcrumbsModule,
    MaterialComponentsModule,
    DateTimePipeModule
  ],
  exports: [
    ExportCameraEventsComponent,
    ParkingLotListComponent,
    DownloadProgressBarComponent
  ]
})
export class ExportCameraEventsModule {
  constructor(translocoService: TranslocoService) {
    translocoService.setTranslation(en, 'en');
    translocoService.setTranslation(de, 'de');
  }
 }
