<div
class="label"
  [style.width]="label.width + 'px'"
  [style.height]="label.height + 'px'"
  [style.top]="label.y + 'px'"
  [style.left]="label.x + 'px'"
  [style.transform]="'rotate(' + label.rotation + 'deg)'"
  [style.fontSize]="label.fontSize + 'px'"
  *ngIf="label.type === 'Label'"
><div style="position: absolute; width: 100%; text-align: center;" [style.top]="label.height / 2 - label.fontSize / 2 + 'px'">{{label.text}}</div></div>
