import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ParkingLotsDataService,
  ParkingLot,
  ParkinglotWebsocket,
  ParkingspaceWebsocketService,
  MainDataService,
  RepositoryService
} from '@frontend-monorepo/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, timer, interval } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map, take, skip } from 'rxjs/operators';
import { MessageService } from './services/message/message.service';
import { MessageType, Variable } from './helper/variable';
import { TranslocoService } from '@ngneat/transloco';
import { SwUpdate } from '@angular/service-worker';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDrawer } from '@angular/material/sidenav';
import { LocalDataService } from './services/data/local-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ImprintDialogComponent } from './pages/footer/imprint-dialog/imprint-dialog.component';
import { PolicyDialogComponent } from './pages/footer/policy-dialog/policy-dialog.component';
import { HeatmapDataService } from 'libs/heatmap-ui/src/lib/services/local-data.service';
import { UserDataService } from '@frontend-monorepo/parking-lot-map';
import { LoginService } from 'libs/common/projects/common-lib/login/src/helper/login-service/login.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'scs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('myDiv', { static: false }) myDiv: ElementRef;

  parkingLotListFilter: ParkingLot[] = undefined;
  searchControl = new FormControl();
  parkingLotList: ParkingLot[] = new Array<ParkingLot>();
  languageIcon: string = Variable.ICON_DE;
  normalMode: boolean = false;

  imagePath: string = '../assets/icons/logo.png';

  dashboardGuide: string =
    'https://smart-city-system.notion.site/Leistungsbeschreibung-Dashboard-87c9c4e4a818414d8ad37fc0471c95ee';

  private mainSubscription: Subscription;
  private langSubscription: Subscription;

  constructor(
    public router: Router,
    private activRoute: ActivatedRoute,
    private repositoryService: RepositoryService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dataService: ParkingLotsDataService,
    private userDataService: UserDataService,
    private snackbar: MatSnackBar,
    private websocket: ParkinglotWebsocket,
    private psWebsocket: ParkingspaceWebsocketService,
    private mainDataService: MainDataService,
    private transloco: TranslocoService,
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private swUpdate: SwUpdate,
    private localDataService: LocalDataService,
    private dialog: MatDialog,
    private heatmapDataService: HeatmapDataService,
    private loginService: LoginService
  ) {
    this.matIconRegistry
      .addSvgIcon(
        'flag_en',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/flag_great_britain.svg')
      )
      .addSvgIcon(
        'flag_de',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/flag_germany.svg')
      )
      .addSvgIcon(
        'flag_us',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/flag_usa.svg')
      )
      .addSvgIcon(
        'no_sensor',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/no_sensor.svg')
      )
      .addSvgIcon(
        'flag_remember_for_later',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/flag-24px.svg')
      )
      .addSvgIcon(
        'access_time',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/access_time.svg')
      )
      .addSvgIcon(
        'block',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/block.svg')
      )
      .addSvgIcon(
        'utilisation',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/utilisation.svg')
      )
      .addSvgIcon(
        'operation',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/operation.svg')
      )
      .addSvgIcon(
        'avgDuration',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/avgDuration.svg')
      )
      .addSvgIcon(
        'router',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/router.svg')
      )
      .addSvgIcon(
        'electrocharger',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/electroCharger.svg')
      )
      .addSvgIcon(
        'family',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/family.svg')
      )
      .addSvgIcon(
        'disabled',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/disabled.svg')
      )
      .addSvgIcon(
        'electrocharger_white',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/electroCharger_white.svg')
      )
      .addSvgIcon(
        'family_white',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/family_white.svg')
      )
      .addSvgIcon(
        'disabled_white',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/disabled_white.svg')
      )
      .addSvgIcon(
        'electrocharger_outlined',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../assets/icons/electroCharger_outlined.svg'
        )
      )
      .addSvgIcon(
        'family_outlined',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/family_outlined.svg')
      )
      .addSvgIcon(
        'disabled_outlined',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/disabled_outlined.svg')
      )
      .addSvgIcon(
        'analysis_info',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/analysis_info.svg')
      )
      .addSvgIcon(
        'directions_car',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/directions_car.svg')
      )
      .addSvgIcon(
        'leaving_car',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/leaving_car.svg')
      )
      .addSvgIcon(
        'timer',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/timer.svg')
      );

    this.mainSubscription = new Subscription();
    this.langSubscription = Subscription.EMPTY;

    this.newVersionAvailable();
    this._checkContinouslyForNewVersion();
  }

  ngOnInit() {
    localStorage.removeItem(Variable.SEARCH_SORT);
    if (window.location.pathname)
      if (localStorage.getItem(Variable.LIST_SELECTION_URL) == null) {
        localStorage.setItem(Variable.LIST_SELECTION_URL, 'landing/parking-violations');
      }

    if (window.location.pathname.includes('landing')) {
      this.returnToHomeScreen();
    }
    this.checkBrowserLanguage();
    this.setupMessageService();
  }

  private _checkContinouslyForNewVersion() {
    if (this.swUpdate.isEnabled) {
      interval(10000).subscribe(() =>
        this.swUpdate.checkForUpdate().then((_) => {
          // console.log('checking for updates_1');
          // console.log('checking for updates_2');
          // console.log('checking for updates_3');
        })
      );
    }

    this.swUpdate.available.subscribe((event) => console.log('updates Available'));
  }

  ngAfterViewInit() {
    this.mainSubscription.add(
      this.dataService.parkingLotsSubject.subscribe((parkingLots) => {
        this.parkingLotList = parkingLots;
      })
    );

    this.searchControl.valueChanges
      .pipe(
        skip(1),
        startWith(''),
        map((pl) => {
          if (this.parkingLotList.length > 0) {
            return pl ? this.filterStates(pl) : this.parkingLotList.slice();
          } else {
            return [];
          }
        })
      )
      .subscribe((result) => {
        this.parkingLotListFilter = result;
        this.cd.detectChanges();
      });
  }

  trackById(index: number, item: ParkingLot) {
    return item.id;
  }

  filterStates(value: string): ParkingLot[] {
    const filterValue = value.toLowerCase();

    let list = this.parkingLotList.filter(
      (pl) =>
        pl.name.toLowerCase().includes(filterValue) ||
        pl.id.toString().toLowerCase().includes(filterValue)
    );

    if (list.length === 0) {
      list = this.parkingLotList.filter((pl) => pl.id.toString().includes(filterValue));
    }
    return list;
  }

  logout() {
    this.logoutAction();
    this.loginService.resetCookie();
  }

  private logoutAction() {
    this.mainDataService.resetBehaviorSubjects();
    this.dataService.parkingLotsSubject.next(new Array());
    localStorage.removeItem(Variable.SEARCH_SORT);

    if (this.psWebsocket.isRunning()) {
      this.psWebsocket.stop();
    }

    if (this.websocket.isRunning()) {
      this.websocket.stop();
    }
  }

  private newVersionAvailable() {
    this.swUpdate.available.subscribe((event) => {
      if (this.normalMode) {
        const snackBarRef = this.snackbar.open(
          this.transloco.translate('APP.new_version'),
          this.transloco.translate('APP.update'),
          { duration: undefined }
        );
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
          window.location.reload();
        });
      } else {
        timer(3000).subscribe(() => {
          window.location.reload();
        });
      }
    });
  }

  toggleParkinglotFeatures(drawer?: MatDrawer) {
    this.heatmapDataService.setInitHeatmap(true);
    if (drawer != null) {
      drawer.toggle();
    }
  }

  ngOnDestroy() {
    this.mainSubscription.unsubscribe();
    this.langSubscription.unsubscribe();
    this.userDataService.setUserData(null);
  }

  private checkBrowserLanguage() {
    if (localStorage.getItem('app-language') != null) {
      this.changeLanguage(localStorage.getItem('app-language') || Variable.EN_US);
    } else {
      if (navigator.language.toLowerCase().includes(Variable.DE)) {
        this.changeLanguage('de');
      } else if (navigator.language.toLowerCase().includes(Variable.US)) {
        this.changeLanguage('us');
      } else {
        this.changeLanguage('en');
      }
    }
  }

  changeLanguage(language: string) {
    switch (language) {
      case Variable.DE_DE:
        this.setLanguage('de');
        this.languageIcon = Variable.ICON_DE;
        this.dashboardGuide =
          'https://smart-city-system.notion.site/Leistungsbeschreibung-Dashboard-87c9c4e4a818414d8ad37fc0471c95ee';
        localStorage.setItem('app-language', Variable.DE_DE);
        this.localDataService.userLanguage.next(Variable.DE_DE);
        break;
      case Variable.EN_GB:
        this.setLanguage('en');
        this.languageIcon = Variable.ICON_EN;
        this.localDataService.userLanguage.next(Variable.EN_GB);
        localStorage.setItem('app-language', Variable.EN_GB);
        this.dashboardGuide =
          'https://smart-city-system.notion.site/Dashboard-User-Guide-797d60f5478a40e19566837527c49418';
        break;
      case Variable.EN_US:
        this.setLanguage('en');
        this.languageIcon = Variable.ICON_US;
        this.localDataService.userLanguage.next(Variable.EN_US);
        localStorage.setItem('app-language', Variable.EN_US);
        this.dashboardGuide =
          'https://smart-city-system.notion.site/Dashboard-User-Guide-797d60f5478a40e19566837527c49418';
        break;
    }
  }

  private setLanguage(language: string) {
    this.langSubscription.unsubscribe();
    this.langSubscription = this.transloco
      .load(language)
      .pipe(take(1))
      .subscribe(() => {
        this.transloco.setActiveLang(language);
        this.langSubscription.unsubscribe();
      });
  }

  searchRequest(value: string) {
    let preferredMapView = localStorage
      .getItem(Variable.LIST_SELECTION_URL)
      .includes('parking-violations')
      ? 'parkingControl'
      : 'occupancy';
    if (value !== null) {
      let entry = this.parkingLotList.find((item) => item.name === value);
      if (entry != null) {
        this.router.navigateByUrl(`/parking-lot/${entry.id.toString()}?tag=${preferredMapView}`);
      } else {
        this.snackbar.open(this.transloco.translate('APP.no_request'));
      }
    } else {
      this.snackbar.open(this.transloco.translate('APP.select_item'));
    }
  }

  selectPLID(id: number) {
    let preferredMapView = localStorage
      .getItem(Variable.LIST_SELECTION_URL)
      .includes('parking-violations')
      ? 'parkingControl'
      : 'occupancy';
    if (id != null) {
      this.router.navigateByUrl(`/parking-lot/${id.toString()}?tag=${preferredMapView}`);
    }
  }

  private setupMessageService() {
    this.mainSubscription.add(
      this.messageService.messageSubject.subscribe((message) => {
        if (message.type === MessageType.HEADER) {
        } else if (message.type === MessageType.IFRAME) {
          this.normalMode = false;
          this.cd.detectChanges();
        } else if (message.type === MessageType.NORMAL) {
          this.normalMode = true;
          this.cd.detectChanges();
        }
      })
    );
  }

  onImagePathError(defaultPath: string) {
    this.imagePath = defaultPath;
    this.cd.detectChanges();
  }

  returnToHomeScreen(): void {
    if (localStorage.getItem(Variable.LIST_SELECTION_URL) != null) {
      this.router.navigateByUrl(localStorage.getItem(Variable.LIST_SELECTION_URL));
    } else {
      this.router.navigateByUrl('landing/parking-violations');
    }
  }

  openImprintDialog() {
    this.dialog.open(ImprintDialogComponent, {
      minWidth: '40vw',
      maxWidth: '50vw',
      minHeight: '80vh',
      maxHeight: '80vh',
      autoFocus: false
    });
  }

  openPolicyDialog() {
    this.dialog.open(PolicyDialogComponent, {
      minWidth: '40vw',
      maxWidth: '50vw',
      minHeight: '80vh',
      maxHeight: '80vh',
      autoFocus: false
    });
  }
}
