import { Observable } from "rxjs";
import { Comment } from "./models/parking-space-comment.model";
import { CommentLog } from "./models/parking-space-comment-log.model";

export abstract class ParkingSpaceCommentsBase {
    abstract getCommentbyDeviceSpaceId(device_space_id: number): Observable<Comment>;
    abstract putCommentbyDeviceSpaceId(device_space_id: number, body: string): Observable<any>;
    abstract deleteCommentbyDeviceSpaceId(device_space_id: number): Observable<any>;
    abstract getAllCommentbyParkingLot(parking_lot_id: number): Observable<Array<Comment>>;
    abstract getCommentLogByParkinglot(parking_lot_id: number, start: number, end: number): Observable<Array<CommentLog>>;
}