import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LanguagePickerComponent } from './component/language-picker.component';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import de from './i18n/de.json';
import en from './i18n/en.json';

/**
 * Provides a [MatMenu] for changing the app's language.
 * 
 * Currently supported languages: 'de' & 'en'
 */
@NgModule({
    declarations: [
        LanguagePickerComponent
    ],
    imports: [
        CommonModule,
        MatSelectModule,
        MatIconModule,
        MatMenuModule,
        FormsModule,
        MatButtonModule,
        TranslocoModule
    ],
    exports: [
        LanguagePickerComponent
    ],
    providers: [],
})
export class LanguagePickerModule {
    constructor(translocoService: TranslocoService) {
        translocoService.setTranslation(en, 'en');
        translocoService.setTranslation(de, 'de');
    }
}