import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[noSpecialChar]'
})
export class NoSpecialCharDirective {

  constructor() { }

  @HostListener('keypress', ['$event']) 
  onKeyPress(event) {
   return new RegExp('^[a-zA-ZäöüÄÜÖß ]+$').test(event.key)
  }

}
