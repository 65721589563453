export enum CAMERA_LIVESTREAM_STATES {
  /**
   * authentication was successful
   */
  AUTHENTICATION_SUCCESS = 'authentication_success',
  /**
   * authentication has failed
   */
  AUTHENTICATION_FAILURE = 'authentication_failure',
  /**
   * camera stream has ended
   */
  CLOSE = 'close'
}

export enum CAMERA_LIVESTREAM_MESSAGE {
  /**
   * notification when received data
   */
  DATA_READY = 'ready',
  /**
   * camera stream not sending images
   */
  NO_DATA = 'no_data',
  /**
   * camera stream has ended
   */
  CLOSE = 'close'
}

export enum CAMERA_LIVESTREAM_CLOSE_REASONS {
  /**
   * the camera stopped the stream, for example the connection dropped
   */
  CLOSE_REASON_STREAM_ENDED = 'stream_ended',
  /**
   * the main user has used too much of their quota
   */
  CLOSE_REASON_USER_QUOTA_REACHED = 'user_quota_reached',
  /**
   * the camera has used too much of its stream quota
   */
  CLOSE_REASON_CAMERA_QUOTA_REACHED = 'camera_quota_reached',
  /**
   * the camera has not sent any image within the last 2 minutes
   */
  CLOSE_REASON_CAMERA_TIMEOUT = 'camera_timeout'
}

export enum CAMERA_EVENT_STATES {
  /**
   * authentication was successful
   */
  AUTHENTICATION_SUCCESS = 'authentication_success',
  /**
   * authentication has failed
   */
  AUTHENTICATION_FAILURE = 'authentication_failure',
  /**
   * camera event message on success
   */
  START_STATUS = 'camera_event',
  /**
   * camera stream has ended
   */
  STOP = 'status'
}

export enum CAMERA_EVENT_MESSAGES {
  /**
   * camera event message on success
   */
  SUCCESS = 'success',
  /**
   * notification when received data
   */
  DATA_READY = 'ready',
  /**
   * camera stream has ended
   */
  STOP = 'status'
}
