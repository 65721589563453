<div *transloco="let t">
  <h2>{{ t('setup_preset_header') }}</h2>
  <div fxLayout="row" fxLayoutGap="15px" class="container-wrapper">
    <div class="left-container" fxLayout="column" fxLayoutGap="20px">
      <frontend-monorepo-search-preset-by-parkinglot></frontend-monorepo-search-preset-by-parkinglot>
      <frontend-monorepo-configuration></frontend-monorepo-configuration>
      <div *ngFor="let setting of durationSettingList" class="duration-setup-wrapper">
        <frontend-monorepo-preset-duration-setup
          *ngIf="durationSettingList.length > 0"
          [settingTag]="setting[0]"
          [durationSetting]="setting[1]"
        ></frontend-monorepo-preset-duration-setup>
      </div>
      <frontend-monorepo-preset-add-setting></frontend-monorepo-preset-add-setting>
    </div>
    <div class="right-container">
      <h3>{{ t('summary_header') }}</h3>
      <frontend-monorepo-preset-summary></frontend-monorepo-preset-summary>
    </div>
  </div>
  <div *transloco="let t" fxLayout="row" fxLayoutAlign="space-between" class="button-wrapper">
    <button mat-flat-button color="cancel" (click)="close(false)">{{ t('cancel') }}</button>
    <div>
      <button
        mat-flat-button
        color="primary"
        (click)="applyChanges()"
        [disabled]="applyButtonDisableState || reset_after_free_parking == null"
      >
        {{ t('update_preset') }}
      </button>
      <mat-progress-bar color="primary" *ngIf="loading" [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  </div>
</div>
