import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  API_BASE_URL,
  Failure,
  SnapshotImage,
  SnapshotRequest
} from '@frontend-monorepo/core';
import { Observable } from 'rxjs';
import { CameraSnapshotBase } from './camera-snapshot.base';

/**
 * Repository containing Camera Snaphsot Feature related API's
 */
export class CameraSnapshotRepository extends CameraSnapshotBase {
  counter$: Observable<number>;

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * POST Call for sending a request to an installed camera to get a request id for polling snapshot image
   * @requires `camera_snapshot`parking` lot permission on the parking lot
   *
   * @param device_space_id Id of the device space that has a camera installed
   * @param width optional target width dimension of the snapshot image, by default 1920
   * @param height optional target height dimension of the snapshot image, by default 1080
   * @returns a `request_id` including a `request_timeout`
   */
  postSnapshot(
    device_space_id: number,
    width?: number,
    height?: number
  ): Observable<SnapshotRequest> {
    let observable = new Observable<SnapshotRequest>((subscriber) => {
      this.http
        .post<SnapshotRequest>(
          API_BASE_URL +
            `devicespaces/${device_space_id}/camera/snapshot?width=${width}&height=${height}`,
          ''
        )
        .subscribe({
          next: (result) => {
            subscriber.next(result);
          },
          error: (error: HttpErrorResponse) => {
            let message = 'not_successful';
            if (error.status == 400) {
              message = 'no_camera_installed';
            } else if (error.status == 404) {
              message = 'device_space_not_found';
            } else if (error.status == 409) {
              message = 'disconnected';
            }
            subscriber.error(new Failure(error.status, message));
          }
        });
    });
    return observable;
  }

  /**
   * GET Call to get the snapshot image
   *
   * @param request_id Id of the request that has been sent to an installed camera before
   * @returns base64 image
   */
  fetchSnapshotRequest(request_id: string): Observable<SnapshotImage | Failure> {
    let observable = new Observable<SnapshotImage | Failure>((subscriber) => {
      setTimeout(() => {
        this.http
          .get<SnapshotImage>(API_BASE_URL + `cameras/requests?request_id=${request_id}`)
          .subscribe({
            next: (result) => {
              subscriber.next(<SnapshotImage>result);
            },
            error: (error: HttpErrorResponse) => {
              let message = 'not_successful';
              if (error.status == 404) {
                subscriber.error(new Failure(error.status, 'no_response_yet'));
              }
            }
          });
      }, 1000);
    });
    return observable;
  }
}
