import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ParkingLotInternalGuardService } from './pages/parking-lot/guards/parking-lot-internal-guard.service';
import { ParkinglotWebsocketGuard } from './guards/parkinglot-websocket.guard';
import { AdminGuard } from './pages/admin/guard/admin.guard';
import { ParkingLotFeaturesGuard } from './pages/parking-lot-features/guard/parking-lot-features.guard';
import { AuthGuard, ResetPasswordGuard, UserInviteGuard} from '@frontend-monorepo/common';
import { AnalysisModule } from './pages/analysis/analysis.module';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'landing',
    data: {
      breadcrumb: 'overview'
    },
    loadChildren: () => import('./pages/landing/landing.module').then((m) => m.LandingModule),
    canActivate: [AuthGuard, ParkinglotWebsocketGuard]
  },
  {
    path: 'parking-lot/:PLID',
    loadChildren: () =>
      import('./pages/parking-lot/parking-lot.module').then((m) => m.ParkingLotModule),
    canActivate: [ParkingLotInternalGuardService]
  },
  {
    path: 'user-management',
    data: {
      breadcrumb: 'user_management'
    },
    loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminGuard]
  },
  {
    path: 'manage-personal-account',
    data: {
      breadcrumb: 'manage_personal_account'
    },
    loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminGuard]
  },
  {
    path: 'analysis',
    data: {
      breadcrumb: 'analysis'
    },
    loadChildren: () =>
      import('./pages/analysis/analysis.module').then(
        (m) => m.AnalysisModule
      ),
    canActivate: [ParkingLotFeaturesGuard]
  },
    {
    path: 'parking-lot-management',
    data: {
      breadcrumb: 'parking_lot_management'
    },
    loadChildren: () =>
      import('./pages/parking-lot-features/parking-lot-features.module').then(
        (m) => m.ParkingLotFeaturesModule
      ),
    canActivate: [ParkingLotFeaturesGuard]
  },
  {
    path: 'iframe/:PLID',
    loadChildren: () =>
      import('./pages/iframe-parkinglot/iframe-parkinglot.module').then(
        (m) => m.IFrameParkinglotModule
      )
  },
  {
    path: 'user-invite',
    loadChildren: () =>
      import('./pages/user-invite/user-invite.module').then((m) => m.UserInviteModule),
    canActivate: [UserInviteGuard]
  },

  {
    path: 'reset-password',
    loadChildren: () =>
      import('./pages/login/login-reset-password.module').then((m) => m.LoginResetPasswordModule),
    canActivate: [ResetPasswordGuard]
  },

  // Router uses a first-match wins strategy
  // Therefore the wildcard needs to be placed at the very end
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes
      // ,{ enableTracing: true } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
