import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslocoService } from '@ngneat/transloco';
import { Failure } from 'libs/core/src/lib/repository/http-response/failure.model';
import { RepositoryService, Comment, CommentLog } from '@frontend-monorepo/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import moment from 'moment';
import { DeviceSpaceService } from 'libs/parking-lot-map/projects/parking-lot-map/core/src/public_api';
import { Networking } from 'apps/customer-dashboard/src/app/helper/networking';

@Component({
  selector: 'frontend-monorepo-comment-history-list',
  templateUrl: './comment-history-list.component.html',
  styleUrls: ['./comment-history-list.component.scss']
})
export class CommentHistoryListComponent implements OnInit {
  @Input() parkingLotId: number;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  private columns: string[] = [
    'delete_on_free',
    'psid',
    'xml',
    'creation_time',
    'deleted_time',
    'comment'
  ];
  displayedColumns: string[];

  searchValue: string;
  tableData: MatTableDataSource<CommentLog>;
  unfilteredTableData: CommentLog[];
  isLoading: boolean;

  parkingSpaceIdMap: BehaviorSubject<Map<number, number>>;

  psidList: number[];

  private _subscription: Subscription;

  constructor(
    private cd: ChangeDetectorRef,
    private repository: RepositoryService,
    private snackbar: MatSnackBar,
    private transloco: TranslocoService,
    private networking: Networking
  ) {
    this.displayedColumns = this.columns;
    this._subscription = new Subscription();
    this.tableData = new MatTableDataSource<CommentLog>();
    this.parkingSpaceIdMap = new BehaviorSubject(new Map());
    this.isLoading = true;
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.getXmlIds();
    this._setupTableData(moment().subtract(1, 'week').startOf('day').unix(), moment().unix());
  }

  private _setupTableData(start: number, end: number) {
    this._subscription = this.repository
      .parkingSpaceCommentRepository()
      .getCommentLogByParkinglot(this.parkingLotId, start, end)
      .subscribe({
        next: (_comments) => {
          this.parkingSpaceIdMap.subscribe((_map) => {
            if (_map != undefined) {
              this.unfilteredTableData = _comments;
              this.unfilteredTableData = [...this.unfilteredTableData].map((_comment) => {
                _comment.xml_id = _map.get(_comment.device_space_id);
                return _comment;
              });
              this.tableData = new MatTableDataSource<CommentLog>(
                Object.assign(this.unfilteredTableData)
              );
              this.filterPsidList(this.unfilteredTableData);
              this.tableData.sort = this.sort;
              this.isLoading = false;
              this.cd.detectChanges();
            }
          });
        },
        error: (_error: Failure) => {
          this.snackbar.open(this.transloco.translate(`${_error.errorMessage}`));
          this.isLoading = false;
        }
      });
  }

  getXmlIds() {
    this.networking.fetchParkingSpacesOfPLApiKey(this.parkingLotId).subscribe({
      next: (_devices) => {
        const parkingSpaceIdMap = new Map<number, number>();
        _devices.forEach((obj) => {
          parkingSpaceIdMap.set(obj.id, obj.xml_id);
        });
        this.parkingSpaceIdMap.next(parkingSpaceIdMap);
      }
    });
  }

  getPsidSelection(psidSelectionList: number[]) {
    let filteredDataList = this.unfilteredTableData;
    if (psidSelectionList.length > 0) {
      filteredDataList = this.unfilteredTableData.filter((_item) =>
        psidSelectionList.includes(_item.xml_id)
      );
    }
    this.tableData = new MatTableDataSource<CommentLog>(Object.assign(filteredDataList));
    this.tableData.sort = this.sort;
    this.cd.detectChanges();
  }

  getDateSelection(dateInUnix: number[]) {
    this.isLoading = true;
    if (dateInUnix.length == 0) {
      this.tableData = new MatTableDataSource<CommentLog>(Object.assign(this.unfilteredTableData));
      this.filterPsidList(this.unfilteredTableData);
      this.tableData.sort = this.sort;
      this.isLoading = false;
      this.cd.detectChanges();
    } else if (dateInUnix[0] != null && dateInUnix[1] != null) {
      this._subscription = this.repository
        .parkingSpaceCommentRepository()
        .getCommentLogByParkinglot(this.parkingLotId, dateInUnix[0], dateInUnix[1])
        .subscribe({
          next: (_comments) => {
            this.parkingSpaceIdMap.subscribe((_map) => {
              if (_map != undefined) {
                let commentList = _comments.map((_comment) => {
                  _comment.xml_id = _map.get(_comment.device_space_id);
                  return _comment;
                });
                this.tableData = new MatTableDataSource<CommentLog>(Object.assign(commentList));
                this.filterPsidList(commentList);
                this.tableData.sort = this.sort;
                this.cd.detectChanges();
                this.isLoading = false;
              }
            });
          },
          error: (_error: Failure) => (this.isLoading = false)
        });
    }
  }

  filterComment(filterSubstring: string) {
    let filteredDataList = this.unfilteredTableData;
    if (filterSubstring.length > 0) {
      filteredDataList = this.unfilteredTableData.filter((_comment) =>
        _comment.comment.toLowerCase().includes(filterSubstring.toLowerCase())
      );
    }
    this.tableData = new MatTableDataSource<CommentLog>(Object.assign(filteredDataList));
    this.filterPsidList(filteredDataList);
    this.tableData.sort = this.sort;
    this.cd.detectChanges();
  }

  filterPsidList(commentList: CommentLog[]) {
    this.psidList = [...new Set(commentList.map((_entry) => _entry.xml_id))];
  }
}
