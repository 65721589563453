import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GatewaysOfParkingLotComponent } from './component/gateways-of-parking-lot.component';

/**
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@NgModule({
    declarations: [
        GatewaysOfParkingLotComponent
    ],
    imports: [CommonModule],
    exports: [
        GatewaysOfParkingLotComponent
    ],
})
export class GatewaysOfParkingLotModule { }