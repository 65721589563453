import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from '@frontend-monorepo/core';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from 'libs/core/src/lib/services/auth.service';
import { CoreApiService } from 'libs/core/src/lib/services/coreApi.service';
import { MainDataService } from 'libs/core/src/lib/services/main-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'frontend-monorepo-login-reset-password',
  templateUrl: './login-reset-password.component.html',
  styleUrls: ['./login-reset-password.component.scss']
})
export class LoginResetPasswordComponent implements OnInit, OnDestroy {

  resetPasswordForm: FormGroup;
  private mainSubscription: Subscription;

  loading: boolean = false;
  hide: boolean = true;

  private token: string;
  private email: string;

  private specialCharacter: string = "!?=@#&*;:-_+§£()><|.,}{^°";
  private password_min_length = false;
  private password_upper_case = false;
  private password_lower_case = false;
  private password_number = false;
  private password_special_char = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private translocoService: TranslocoService,
    private auth: AuthService,
    private cd: ChangeDetectorRef,
    private coreApiService: CoreApiService,
    private repositoryService: RepositoryService
  ) {
    this.mainSubscription = new Subscription();
    this.token = "";
    this.resetPasswordForm = this.formBuilder.group({
      new_password: this.formBuilder.control('', [this.validatorUpperCase(), this.validatorLowerCase(), this.validatorNumber(), this.validatorSpecialChar(), this.validatorMinLength()]),
      repeat_new_password: this.formBuilder.control('', [Validators.minLength(8), this.passwordValidator()])
    })
  }
  ngOnDestroy(): void {
    this.mainSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.setupQueryParams();
  }

  private setupQueryParams(){
    this.mainSubscription.add(
      this.activeRoute.queryParams.subscribe(params => {
        this.token = params['token'];
        this.email = params['email'];
      })
    );
  }

  backToLogin() {
    this.router.navigateByUrl('login');
  }

  resetPassword(){
    if (this.resetPasswordForm.controls["new_password"].value != null){
      let newPassword = this.resetPasswordForm.controls["new_password"].value;
      this.toggleLoading();
        this.mainSubscription.add(
          this.repositoryService.loginRepository().postResetPassword(this.email, this.token, this.resetPasswordForm.controls["new_password"].value).subscribe( resp => {
            this.toggleLoading();
                this.snackbar.open(this.translocoService.translate('password_changed_successful'));
                this.router.navigateByUrl('login');
          }, (err: HttpErrorResponse) => {
            if (err.status == 404) {
              this.snackbar.open(this.translocoService.translate('token_not_valid'),'', {
                duration: 7*1000,
              });
            } else if (err.status == 400) {
              this.snackbar.open(this.translocoService.translate('error_password_should_not_match_username'));
            }
          
            this.toggleLoading();
            this.cd.detectChanges();
          })
        );
      
    } else {
      this.snackbar.open(this.translocoService.translate('invalid_password'));
    }
  }

  changeValuePassword(value: string){
    let password = this.resetPasswordForm.controls['new_password'];
    
    if (password.value != null){
      this.password_upper_case = !password.hasError('validateUpperCase');
      this.password_lower_case = !password.hasError('validateLowerCase');
      this.password_number = !password.hasError('validateNumber');
      this.password_special_char = !password.hasError('validateSpecialChar');
      this.password_min_length = !password.hasError('validatorLength');
    }
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.resetPasswordForm !== undefined) {
        const condition = this.resetPasswordForm.controls['new_password'].value !== this.resetPasswordForm.controls['repeat_new_password'].value;
        return condition ? {passwordsDoNotMatch: { valid: false, value: true}} : null;
      }
    };
  }

  private validatorUpperCase(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.resetPasswordForm !== undefined) {
        const regEx = new RegExp('^(?=[^A-Z]*[A-Z])');
        const value: string = this.resetPasswordForm.controls['new_password'].value;
        const condition = regEx.test(value);

        return condition ? null : {validateUpperCase: { valid: false, value: true}};
      }
    };
  }

  private validatorLowerCase(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.resetPasswordForm !== undefined) {
        const regEx = new RegExp('(?=[^a-z]*[a-z])');
        const value: string = this.resetPasswordForm.controls['new_password'].value;
        const condition = regEx.test(value);

        return condition ? null : {validateLowerCase: { valid: false, value: true}};
      }
    };
  }

  private validatorNumber(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.resetPasswordForm !== undefined) {
        const regEx = new RegExp('(?=\\D*\\d)');
        const value: string = this.resetPasswordForm.controls['new_password'].value;
        const condition = regEx.test(value);

        return condition ? null : {validateNumber: { valid: false, value: true}};
      }
    };
  }

  private validatorSpecialChar(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.resetPasswordForm !== undefined) {
        const regEx = new RegExp('(?=.*[!?=()€£/~@#\\$%\\^&\\*;:\\-_+§<>|.,}{^^°])');
        const value: string = this.resetPasswordForm.controls['new_password'].value;
        const condition = regEx.test(value);

        return condition ? null : {validateSpecialChar: { valid: false, value: true}};
      }
    };
  }

  private validatorMinLength(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.resetPasswordForm !== undefined) {
        const regEx = new RegExp('[A-Za-z\\d!?=()~$%/@#£€*?&;:\\-_+§<>|.,}{^^°]{8,}$');
        const value: string = this.resetPasswordForm.controls['new_password'].value;
        const condition = regEx.test(value);

        return condition ? null : {validatorLength: { valid: true, value: true}};
      }
    };
  }

  // private validatorPasswordMatchesUsername(): ValidatorFn {
  //   return (control: AbstractControl): {[key: string]: any} | null => {
  //     if (this.resetPasswordForm !== undefined) {
  //       const condition = 

  //       return condition ? null : {validatorLength: { valid: true, value: true}};
  //     }
  //   };
  // }

  private toggleLoading(){
    this.loading = !this.loading;
  }

}
