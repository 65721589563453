import { Subscription } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { WebSocketBase } from './websocket_base.interface';
import { WebsocketMessageModel, MainDataService, AuthService } from '@frontend-monorepo/core';

const SLA_URL = 'wss://api.parking-pilot.com/device-health/parkinglot-health-state-websocket'

/**
 * Websocket
 */
export class SlaDeviceHealthWebsocketService implements WebSocketBase {

  private mainSubscription: Subscription;
  private slaDeviceHealthWS: WebSocketSubject<any>

  constructor(
    private authService: AuthService,
    private mainDataService: MainDataService
  ) { 
    this.mainSubscription = new Subscription();
  }

  start() {
    this.slaDeviceHealthWS = webSocket({
      url: SLA_URL,
      openObserver: {
        next: () => {
          if (this.authService.isTwoFactorEnabled) {
            const tokens = this.authService.getTokens();
            this.slaDeviceHealthWS.next({
              token: tokens?.token,
              two_factor_token: tokens?.two_factor_token
            })
          } else {
            this.slaDeviceHealthWS.next({
              token: this.authService.getToken(),
            })
          }
        }
      }
    });

    this.mainSubscription.add(
      this.slaDeviceHealthWS.subscribe(data => {
        this.mainDataService.updateSLADeviceHealth(data);
      }, (err) => {
        this.stop();
      })
    )
  }

  stop() {
    this.slaDeviceHealthWS.complete();
    this.slaDeviceHealthWS = undefined;
    this.mainSubscription.unsubscribe();
  }

  isRunning(): boolean {
    return this.slaDeviceHealthWS == undefined ? false : true; 
  }
}
