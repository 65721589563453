import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ParkingSpaceComponent } from '../components/parking-space/parking-space.component';
import { OverlayingSidebarComponent } from '../components/overlaying-sidebar/overlaying-sidebar.component';
import { LeftSideBarComponent } from '../components/left-side-bar/left-side-bar.component';
import { ParkingLotMapLegendComponent } from '../components/parking-lot-map-legend/parking-lot-map-legend.component';


export type ParkingSpaceUpdateFunction = (parkingSpaceComponent: ParkingSpaceComponent) => void;
export type LeftSidebarUpdateFunction = (leftSidebarComponent: LeftSideBarComponent) => void;
export type RightSidebarUpdateFunction = (overlayingSidebarComponent: OverlayingSidebarComponent) => void;
export type LegendUpdateFuntion = (parkingLotMapLegendComponent: ParkingLotMapLegendComponent) => void;

@Injectable({ providedIn: 'root' })
export class ParkingLotMapComponentInjectorService {


  private _parkingSpaceContentFn: BehaviorSubject<ParkingSpaceUpdateFunction>;
  private _leftSidebarContentFn: BehaviorSubject<LeftSidebarUpdateFunction>;
  private _rightSidebarContentFn: BehaviorSubject<RightSidebarUpdateFunction>;
  private _legendContentFn: BehaviorSubject<LegendUpdateFuntion>;

  constructor() {
    this._parkingSpaceContentFn = new BehaviorSubject<ParkingSpaceUpdateFunction>(null);
    this._leftSidebarContentFn = new BehaviorSubject<LeftSidebarUpdateFunction>(null);
    this._rightSidebarContentFn = new BehaviorSubject<RightSidebarUpdateFunction>(null);
    this._legendContentFn = new BehaviorSubject<LegendUpdateFuntion>(null);
  }



  updateParkingSpaceContentFn(parkingSpaceUpdateFunction: ParkingSpaceUpdateFunction): void {
    this._parkingSpaceContentFn.next(parkingSpaceUpdateFunction);
  }

  getParkingSpaceContentFn(): Observable<ParkingSpaceUpdateFunction> {
    return this._parkingSpaceContentFn.asObservable();
  }



  updateLeftSidebarContentFn(leftSidebarUpdateFunction: LeftSidebarUpdateFunction): void {
    this._leftSidebarContentFn.next(leftSidebarUpdateFunction);
  }

  getLeftSidebarContentFn(): Observable<LeftSidebarUpdateFunction> {
    return this._leftSidebarContentFn.asObservable();
  }



  updateRightSidebarContentFn(rightSidebarUpdateFunction: RightSidebarUpdateFunction): void {
    this._rightSidebarContentFn.next(rightSidebarUpdateFunction);
  }

  getRightSidebarContentFn(): Observable<RightSidebarUpdateFunction> {
    return this._rightSidebarContentFn.asObservable();
  }

  updateLegendContentFn(legendUpdateFuntion: LegendUpdateFuntion): void {
    this._legendContentFn.next(legendUpdateFuntion);
  }

  getLegendContentFn(): Observable<LegendUpdateFuntion> {
    return this._legendContentFn.asObservable();
  }
}
