import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable, Observer, Subscription } from 'rxjs';
import { AuthService, CameraParkinglotStateWebsocketService, CoreApiService, ParkingLotsDataService, ParkinglotWebsocket, RepositoryService } from '@frontend-monorepo/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '../../../services/message/message.service';
import { GuardAuthDialogComponent } from '../../../guards/guard-auth-dialog/guard-auth-dialog.component';
import { MessageType } from '../../../helper/variable';

@Injectable({
  providedIn: 'root'
})
export class ParkingLotFeaturesGuard implements CanActivate, OnDestroy {

  private mainSubscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private plWebsocket: ParkinglotWebsocket,
    private cameraParkinglotWebsocket: CameraParkinglotStateWebsocketService,
    private repositoryService: RepositoryService,
    private parkingLotsDataService: ParkingLotsDataService,
    private messageService: MessageService,
    private router: Router
  ){
      this.mainSubscription = new Subscription();
  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return Observable.create((observer: Observer<boolean>) => {
      
      if (this.repositoryService.authService().getToken() != undefined){
        this.setupRequiredData(observer);
      } else {
        let dialogRef = this.dialog.open(GuardAuthDialogComponent, 
          {disableClose: true});
        
        this.mainSubscription.add(
          dialogRef.afterClosed().subscribe(x => {
            this.setupRequiredData(observer);
          })
        )
      } 
    });
  }

  private setupRequiredData(observer: Observer<boolean>){
    if (!this.cameraParkinglotWebsocket.isRunning()) {
      this.cameraParkinglotWebsocket.start();
    }
    if (!this.plWebsocket.isRunning()){
      this.plWebsocket.start();
    } else {
      // console.log("Websocket still running");
    }
    this.mainSubscription.add(
      this.repositoryService.parkingLotRepository().fetchParkingLots().subscribe(parkingLots => {
        this.parkingLotsDataService.parkingLotsSubject.next(parkingLots);
        this.messageService.sendMessage({type: MessageType.NORMAL, data: "parkinglot-features"});
        observer.next(true);
        observer.complete();
        
      },
      (err) => {
        observer.next(false);
        observer.complete();
      })
    )
  }

  ngOnDestroy(): void {
    this.mainSubscription.unsubscribe();
  }
}
