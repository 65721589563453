import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Renderer2,
  OnDestroy,
  ComponentFactoryResolver,
  ViewContainerRef
} from '@angular/core';
import { AuthService, ParkingSpace } from '@frontend-monorepo/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ParkingLotMapComponentInjectorService } from '../../services/parking-lot-map-component-injector.service';
import {
  ParkingSpaceMapInfo,
  ParkingLotMapService,
  HEATMAP_COMPARISON_TYPE,
  ParkingLotMapState
} from '@frontend-monorepo/parking-lot-map';
import { ConnectedPSHelper } from '../parking-lot-map/connectedPSHelper';

const SELECTED_CLASS = 'parkingSpaceSelected';

/**
 * Component for dispalying a parking space in the [ParkingLotMapComponent].
 *
 * Clicking on a parking space sets it as selected, hereby opening the [OverlayingSidebarComponent].
 * Furthermore, multiple parking spaces can be selected by holding the ctrl-key (Windows) or cmd-key (Mac) while clicking on different parking spaces.
 *
 *
 * Other components can/should be injected into this component via the {@link ParkingLotMapExtension#parkingSpaceFn}
 *
 */
@Component({
  selector: 'mp-parking-space',
  templateUrl: './parking-space.component.html',
  styleUrls: ['./parking-space.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParkingSpaceComponent implements OnInit, OnDestroy {
  /**
   * The parkingSpace's container-div which is rotated, positioned and scaled according to the parking lot map's layout
   *
   */
  @ViewChild('parkingSpaceDiv', { static: true })
  private _parkingSpaceDiv: ElementRef<HTMLDivElement>;

  /**
   * The parkingSpace's ID which is displayed at the bottom center of the parking space.
   */
  @ViewChild('centeredID', { static: true }) private _centeredIDSpan: ElementRef<HTMLDivElement>;

  /**
   * The parking space's ViewContainerRef, which is used by the  {@link ParkingLotMapExtension#parkingSpaceFn} to inject/create child components.
   */
  @ViewChild('contentRef', { static: false, read: ViewContainerRef }) vcRef: ViewContainerRef;

  @Input() parkingSpaceMapInfo: ParkingSpaceMapInfo;
  /**
   * Defines whether built dashboard is `CUSTOMER_DASHBOARD` or `EVENT_DASHBOARD`
   */
  @Input() dashboard_tag: String;
  /**
   * PARKING LOT ANALYSIS FEATURE
   * Optional id to seperate each displayed heatmap to adjust the parking space overlay coloring
   * A none defined number defaults to parking lot display on overview or iFrame
   */
  @Input() optionalParkingSpaceType: number;
  /**
   * PARKING LOT ANALYSIS FEATURE
   * Optional tag deciding whether a heatmap comparison question type is selected and adjusts the border coloring accordingly
   * A none defined tag defaults to `SELECTED_CLASS` border coloring
   */
  @Input() heatmapGroupComparison: HEATMAP_COMPARISON_TYPE;

  private _clickableState: ParkingLotMapState = ParkingLotMapState.everythingClickable;

  parkingSpace: ParkingSpace;
  isSelectedSubject: BehaviorSubject<boolean>;

  private _subscriptions: Subscription;
  private _stateSubscription: Subscription;

  constructor(
    public cd: ChangeDetectorRef,
    public componentFactoryResolver: ComponentFactoryResolver,
    private renderer2: Renderer2,
    private parkingLotMapService: ParkingLotMapService,
    private parkingLotMapComponentInjectorService: ParkingLotMapComponentInjectorService,
    private connectedPSHelper: ConnectedPSHelper
  ) {
    this._subscriptions = new Subscription();
    this._stateSubscription = new Subscription();
    this.isSelectedSubject = new BehaviorSubject<boolean>(false);
  }

  ngOnInit() {
    this._setInitialTransformation();
    if (this.dashboard_tag == 'CUSTOMER_DASHBOARD') {
      this._setupCustomerDashboard();
    } else if (this.dashboard_tag == 'EVENT_DASHBOARD') {
      this._setupEventDashboard();
    }
  }

  private _setupCustomerDashboard() {
    try {
      this.parkingSpace = this.parkingLotMapService.getParkingSpaceFromXmlID(
        this.parkingSpaceMapInfo.xmlId
      );
      this.cd.detectChanges();
    } catch (e) {
      console.error(e);
      // Parking space could not be retrieved.
      // Don't know what to do other than keeping it's value `undefined`.
    }

    this._subscriptions.add(
      this.parkingLotMapService.selectedParkingSpacesSubject.subscribe((selectedParkingSpaces) => {
        let isSelected =
          selectedParkingSpaces !== undefined &&
          selectedParkingSpaces.indexOf(this.parkingSpace.id) !== -1;

        this.isSelectedSubject.next(isSelected);
        this._setSelectedClass(isSelected);
        this.cd.detectChanges();
      })
    );

    this._subscriptions.add(
      this.parkingLotMapComponentInjectorService
        .getParkingSpaceContentFn()
        .subscribe((newContentFn) => {
          if (this.parkingSpaceMapInfo.connectedXmlId != null) {
            if (this.parkingSpaceMapInfo.xmlId == this.parkingSpaceMapInfo.connectedXmlId) {
              if (newContentFn) {
                newContentFn(this);
              }
            }
          } else {
            if (newContentFn) {
              newContentFn(this);
            }
          }
        })
    );

    /**
     * Subscription for the parking lot map state
     */
    this._stateSubscription.add(
      this.parkingLotMapService.parkingLotMapObjectState.subscribe((state) => {
        this._clickableState = state;
      })
    );
  }

  private _setupEventDashboard() {
    this._subscriptions.add(
      this.parkingLotMapService.selectedParkingSpacesSubject.subscribe((selectedParkingSpaces) => {
        const isSelected =
          selectedParkingSpaces !== undefined &&
          selectedParkingSpaces.indexOf(this.parkingSpaceMapInfo.xmlId) !== -1;
        this.isSelectedSubject.next(isSelected);
        this._setSelectedClass(isSelected);
        this.cd.detectChanges();
      })
    );

    this._subscriptions.add(
      this.parkingLotMapComponentInjectorService
        .getParkingSpaceContentFn()
        .subscribe((newContentFn) => {
          if (newContentFn) {
            newContentFn(this);
          }
        })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  isConnectedPS(): boolean {
    if (this.parkingSpaceMapInfo.connectedXmlId != null) {
      let connectedList = this.connectedPSHelper.connectedParkingSpaceSubject.getValue();
      let parkingspace = connectedList.find(
        (element) => element.xmlid === this.parkingSpaceMapInfo.xmlId
      );
      return parkingspace != undefined;
    } else {
      return true;
    }
  }

  private _setSelectedClass(isParkingSpaceSelected: boolean): void {
    if (this.heatmapGroupComparison == HEATMAP_COMPARISON_TYPE.SINGLE) {
      if (isParkingSpaceSelected) {
        this.renderer2.addClass(this._parkingSpaceDiv.nativeElement, 'singleHeatmapType');
      } else {
        this.renderer2.removeClass(this._parkingSpaceDiv.nativeElement, 'singleHeatmapType');
      }
    } else {
      if (isParkingSpaceSelected) {
        this.renderer2.addClass(this._parkingSpaceDiv.nativeElement, SELECTED_CLASS);
      } else {
        this.renderer2.removeClass(this._parkingSpaceDiv.nativeElement, SELECTED_CLASS);
      }
    }
  }

  /**
   * Setting the parking space's style as defined in the JSON
   *
   * Setting the parking space's attributes width, height, top, left and transform.
   * Rotating the ID (which is a child div of the parking space) in the opposite direction to ensure its readability.
   */
  private _setInitialTransformation(): void {
    if (this.parkingSpaceMapInfo.connectedXmlId != null) {
      let connectedList = this.connectedPSHelper.connectedParkingSpaceSubject.getValue();
      if (connectedList.find((element) => element.xmlid === this.parkingSpaceMapInfo.xmlId)) {
        this.renderer2.setStyle(
          this._parkingSpaceDiv.nativeElement,
          'width',
          `${this.parkingSpaceMapInfo.width}px`
        );
        this.renderer2.setStyle(
          this._parkingSpaceDiv.nativeElement,
          'height',
          `${this.parkingSpaceMapInfo.height}px`
        );
        this.renderer2.setStyle(
          this._parkingSpaceDiv.nativeElement,
          'top',
          `${this.parkingSpaceMapInfo.y}px`
        );
        this.renderer2.setStyle(
          this._parkingSpaceDiv.nativeElement,
          'left',
          `${this.parkingSpaceMapInfo.x}px`
        );
        this.renderer2.setStyle(
          this._parkingSpaceDiv.nativeElement,
          'transform',
          `rotate(${this.parkingSpaceMapInfo.rotation}deg)`
        );
        this.renderer2.setStyle(
          this._centeredIDSpan.nativeElement,
          'transform',
          `rotate(${-this.parkingSpaceMapInfo.rotation}deg)`
        );
      }
    } else {
      this.renderer2.setStyle(
        this._parkingSpaceDiv.nativeElement,
        'width',
        `${this.parkingSpaceMapInfo.width}px`
      );
      this.renderer2.setStyle(
        this._parkingSpaceDiv.nativeElement,
        'height',
        `${this.parkingSpaceMapInfo.height}px`
      );
      this.renderer2.setStyle(
        this._parkingSpaceDiv.nativeElement,
        'top',
        `${this.parkingSpaceMapInfo.y}px`
      );
      this.renderer2.setStyle(
        this._parkingSpaceDiv.nativeElement,
        'left',
        `${this.parkingSpaceMapInfo.x}px`
      );
      this.renderer2.setStyle(
        this._parkingSpaceDiv.nativeElement,
        'transform',
        `rotate(${this.parkingSpaceMapInfo.rotation}deg)`
      );
      this.renderer2.setStyle(
        this._centeredIDSpan.nativeElement,
        'transform',
        `rotate(${-this.parkingSpaceMapInfo.rotation}deg)`
      );
    }
  }

  toggleParkingSpaceSelection(event: MouseEvent) {
    if (this.dashboard_tag == 'CUSTOMER_DASHBOARD') {
      this._toggleCustomerDashboard(event);
    } else if (this.dashboard_tag == 'EVENT_DASHBOARD') {
      this._toggleEventDashbaord(event);
    } else {
      console.log('Error: Dashboard_Tag wurde nicht gesetzt');
    }
  }

  private _toggleCustomerDashboard(event) {
    let currentlySelectedParkingSpaces =
      this.parkingLotMapService.selectedParkingSpacesSubject.getValue();
    const index = currentlySelectedParkingSpaces.indexOf(this.parkingSpace.id);

    if (
      this._clickableState == ParkingLotMapState.deviceSpacesClickable ||
      this._clickableState == ParkingLotMapState.nothingClickable
    ) {
      return;
    }

    let multipleSelectedEnabled: boolean = false;

    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0) {
      multipleSelectedEnabled = event.metaKey === true;
    } else {
      multipleSelectedEnabled = event.ctrlKey === true;
    }

    if (multipleSelectedEnabled && this.optionalParkingSpaceType != -1) {
      if (index !== -1) {
        currentlySelectedParkingSpaces.splice(index, 1);
      } else {
        currentlySelectedParkingSpaces.push(this.parkingSpace.id);
      }
    } else {
      if (
        currentlySelectedParkingSpaces.length === 1 &&
        currentlySelectedParkingSpaces[0] === this.parkingSpace.id
      ) {
        currentlySelectedParkingSpaces = [];
      } else {
        currentlySelectedParkingSpaces = [this.parkingSpace.id];
      }
    }
    this.parkingLotMapService.selectedDeviceSpaceSubject.next([]);
    this.parkingLotMapService.selectedParkingSpacesSubject.next(currentlySelectedParkingSpaces);
  }

  /**
   * checks if parking lot consists of connected parking spaces (truck parking space)
   * @returns true if parking space type is `LongLineSpace`
   */
  isParkingSpaceLongLine(): boolean {
    let types: String = '';
    if (this.parkingSpaceMapInfo.connectedXmlId != null) {
      types = 'LongLineSpace';
    } else {
      types = this.parkingSpaceMapInfo.type;
    }
    return types === 'LongLineSpace';
  }

  /**
   * church use case
   */

  private _toggleEventDashbaord(event) {
    let currentlySelectedParkingSpaces =
      this.parkingLotMapService.selectedParkingSpacesSubject.getValue();
    const index = currentlySelectedParkingSpaces.indexOf(this.parkingSpaceMapInfo.xmlId);

    let multipleSelectedEnabled: boolean = false;

    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0) {
      multipleSelectedEnabled = event.metaKey === true;
    } else {
      multipleSelectedEnabled = event.ctrlKey === true;
    }

    if (multipleSelectedEnabled) {
      if (index !== -1) {
        currentlySelectedParkingSpaces.splice(index, 1);
      } else {
        currentlySelectedParkingSpaces.push(this.parkingSpaceMapInfo.xmlId);
      }
    } else {
      if (
        currentlySelectedParkingSpaces.length === 1 &&
        currentlySelectedParkingSpaces[0] === this.parkingSpaceMapInfo.xmlId
      ) {
        currentlySelectedParkingSpaces = [];
      } else {
        currentlySelectedParkingSpaces = [this.parkingSpaceMapInfo.xmlId];
      }
    }

    this.parkingLotMapService.selectedParkingSpacesSubject.next(currentlySelectedParkingSpaces);
  }
}
