import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import de from './i18n/de.json';
import en from './i18n/en.json';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MaterialComponentsModule } from 'apps/customer-dashboard/src/app/pages/materialComponents.module';
import { DatePickerComponent } from './component/date-picker.component';



@NgModule({
  declarations: [
    DatePickerComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    MaterialComponentsModule,
    MatDatepickerModule,
    SatDatepickerModule,
    SatNativeDateModule
  ],
  exports: [
    DatePickerComponent
  ]
})
export class DatePickerModule { 
  constructor(translocoService: TranslocoService) {
    translocoService.setTranslation(en, 'en');
    translocoService.setTranslation(de, 'de');
  }
}
