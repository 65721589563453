import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DurationSetting, MainDataService, ParkingDurationPreset, PARKING_LOT_DEVICE_TYPE, RepositoryService } from '@frontend-monorepo/core';
import { Subscription } from 'rxjs';

/**
 * Component displaying active collective preset for corresponding parking lot
 *
 */
@Component({
  selector: 'frontend-monorepo-parking-duration-info',
  templateUrl: './parking-duration-info.component.html',
  styleUrls: ['./parking-duration-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParkingDurationInfoComponent implements OnInit, OnDestroy {
  @Input() activeDurationPresetList: Array<DurationSetting[]>;

  activeDurationPreset: Map<number, DurationSetting[]>; 

  preset: ParkingDurationPreset;

  private isSensor: boolean;
  private _subscription: Subscription;

  constructor(
    private repositoryService: RepositoryService,
    private mainDataService: MainDataService,
    private cd: ChangeDetectorRef
  ) {
    this._subscription = new Subscription();
  }
  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this.repositoryService.presetService().presetResetSetting.next(false);
    
  }

  ngOnInit(): void {
    this.isSensor= this.getParkingLotType(this.repositoryService.presetService().currentPlid) == PARKING_LOT_DEVICE_TYPE.SENSOR || this.getParkingLotType(this.repositoryService.presetService().currentPlid) == PARKING_LOT_DEVICE_TYPE.MIX
    this.repositoryService.presetService().activeDurationPreset.subscribe((_presetList) => {
      this.activeDurationPreset = _presetList;
      this.cd.detectChanges();
    });
    if (this.activeDurationPresetList == null) {
      this._subscription = this.repositoryService
        .presetService()
        .activeDurationPreset.subscribe((_settingList) => {
          if (_settingList) {
            this.activeDurationPresetList = Array.from(_settingList.values())
              .map((entry) =>
                entry.filter((_setting) => _setting.start_time != '' && _setting.end_time != '')
              )
              .filter((_entry) => _entry.length > 0);
            this.cd.detectChanges();
          }
        });
    }
  }

   /**
   * checks parking lot type
   */
    private getParkingLotType(plid: number): string {
      return (
        this.mainDataService.listOPL?.getValue()?.get(plid)?.getValue()?.parking_lot_device_type ??
        PARKING_LOT_DEVICE_TYPE.SENSOR
      );
    }
}
