<mp-left-side-bar></mp-left-side-bar>

<mp-parking-lot-map
  *ngIf="parkingLotID !== undefined"
  [parkingLotID]="parkingLotID"
  [dashboard_tag]="dashboard_tag"
  [initialScale]="initialScale"
  [optionalParkingSpaceType]="optionalParkingSpaceType"
  [heatmapGroupComparison]="heatmapGroupComparison"
  [showDeviceSpaces]="true"
>
</mp-parking-lot-map>

<mp-overlaying-sidebar id="rightSidebar"></mp-overlaying-sidebar>
