import { NgModule, ModuleWithProviders, InjectionToken, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationbarComponent } from './component/navigationbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { HIDE_PARKING_LOT_MAP_NAVIGATION } from './tokens/hideParkingLotMapNavigation.token';
import { NavigationBarModuleConfig } from './interfaces/navigationBarModuleConfig';
import { HIDE_MULTI_SEARCHBAR } from './tokens/hideMultiSearchbar.token';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';


import de from './i18n/de.json';
import en from './i18n/en.json';
import { MultiSearchbarModule } from '../../multiSearchbar/src/public_api';
import { LanguagePickerModule } from '../../languagePicker/src/public_api';
/**
 * Exports the {@link NavigationbarComponent}
 * 
 * If you want to hide the `parkingLotMapNavigation` or the {@link MultiSearchbarComponent}, use the static [`withConfig`-method]{@link NavigationBarModule#withConfig}
 * 
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@NgModule({
    declarations: [
        NavigationbarComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        RouterModule,
        MatButtonToggleModule,
        MatIconModule,
        MultiSearchbarModule,
        LanguagePickerModule,
        TranslocoModule
    ],
    exports: [
        NavigationbarComponent
    ]
})
export class NavigationBarModule {

    /**
     * Creates a module with configuration possibilities to increase both the navigationBar's versatility and flexibility.
     * 
     * @param config A `NavigationBarModuleConfig` to control the `NavigationbarComponent`'s contents.
     */
    static withConfig(config: NavigationBarModuleConfig): ModuleWithProviders<any> {

        let providers: Array<Provider> = new Array<Provider>();

        if (config.withoutParkingLotMapNavigation !== undefined) {
            providers.push({
                provide: HIDE_PARKING_LOT_MAP_NAVIGATION,
                useValue: config.withoutParkingLotMapNavigation
            });
        }

        if (config.withoutMultiSearchbar !== undefined) {
            providers.push({
                provide: HIDE_MULTI_SEARCHBAR,
                useValue: config.withoutMultiSearchbar
            });
        }

        return {
            ngModule: NavigationBarModule,
            providers: providers
        };
    }

    constructor(translocoService: TranslocoService) {
        translocoService.setTranslation(en, 'en');
        translocoService.setTranslation(de, 'de');
    }
}


