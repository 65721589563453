import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Renderer2,
  SimpleChanges,
  OnChanges,
  HostListener
} from '@angular/core';
import { PARKING_SPACE_DISPLAY_TYPE } from '../../constants';
import {
  ParkingLotMapService,
  ParkingLotMapApiService,
  ParkingLotMap,
  Level,
  HEATMAP_COMPARISON_TYPE,
  DeviceSpaceService,
  UserDataService,
  PARKING_LOT_PERMISSION
} from '@frontend-monorepo/parking-lot-map';
import {
  ParkingLotsDataService,
  CoreApiService,
  RepositoryService,
  MainDataService
} from '@frontend-monorepo/core';
import { finalize } from 'rxjs/operators';
import { PanZoomAPI, PanZoomConfig, PanZoomConfigOptions, PanZoomModel } from 'ngx-panzoom';
import { Subscription } from 'rxjs';
import { ApiService } from '@frontend-monorepo/core-event';
import { ConnectedPSHelper } from './connectedPSHelper';

/**
 * Interactive map of the parking lot.
 *
 * The map displays all elements of a parking lot that are contained in the XML-file,
 * fetched by {@link ParkingLotMapApiService#fetchMapOfPL},
 * thus consisting of not only parkingSpaces but also bushes, walls, trees etc.
 *
 * With the help of the {@link panZoom library}, the map is both zoomable and scalable with the mouse-controls.
 *
 * This component should only be used as a child component of the {@link ParkingLotMapContainerComponent}.
 */
@Component({
  selector: 'mp-parking-lot-map',
  templateUrl: './parking-lot-map.component.html',
  styleUrls: ['./parking-lot-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParkingLotMapComponent implements OnInit, OnChanges {
  @ViewChild('divContainer', { static: false }) _divContainer: ElementRef<HTMLDivElement>;
  @ViewChild('mapContainer', { static: false }) _mapContainer: ElementRef<HTMLDivElement>;

  /**
   * The ID of the parking lot for which the map should be displayed.
   */
  @Input() parkingLotID: number;

  /**
   * The tag is used to define, which project/backend is being build.
   */
  @Input() dashboard_tag: string;

  @Input() displayType: PARKING_SPACE_DISPLAY_TYPE = PARKING_SPACE_DISPLAY_TYPE.OCCUPATION;

  /**
   * Defines initial scale of parking lot map
   */
  @Input() initialScale: number;

  @Input() optionalParkingSpaceType: number = 0;

  /**
   * Determines whether heatmap type of single data input or mutli data input is selected
   */
  @Input() heatmapGroupComparison: HEATMAP_COMPARISON_TYPE;

  /**
   * Determines whether device space should be displayed or not
   */
  @Input() showDeviceSpaces: boolean;

  public mapWidth: number;
  public mapHeight: number;

  /**
   * pan and zoom directive default configurations
   */
  private panZoomConfigOptions: PanZoomConfigOptions = {
    zoomLevels: 5,
    scalePerZoomLevel: 2.0,
    zoomStepDuration: 0.2,
    freeMouseWheelFactor: 0.001,
    zoomToFitZoomLevelFactor: 1,
    zoomOnMouseWheel: true,
    zoomOnDoubleClick: false
  };
  panzoomConfig: PanZoomConfig = new PanZoomConfig(this.panZoomConfigOptions);

  private apiSubscription: Subscription;
  private modelChangedSubscription: Subscription;
  private panZoomAPI: PanZoomAPI;
  public panZoomModel: PanZoomModel;

  parkingLotMap: ParkingLotMap;
  PARKING_SPACE_DISPLAY_TYPE = PARKING_SPACE_DISPLAY_TYPE;

  layerViewActive = false;
  currentMapScale: number;
  scale: number;
  activeDeck: Level;

  map_loading_error: boolean;

  constructor(
    private eventCoreApiService: ApiService,
    private userDataService: UserDataService,
    private mainDataService: MainDataService,
    private repositoryService: RepositoryService,
    private parkingLotMapApiService: ParkingLotMapApiService,
    private parkingLotMapService: ParkingLotMapService,
    private parkingLotsDataService: ParkingLotsDataService,
    private cd: ChangeDetectorRef,
    private renderer2: Renderer2,
    private connectedPSHelper: ConnectedPSHelper,
    private deviceSpaceService: DeviceSpaceService
  ) {
    this.map_loading_error = false;
  }

  ngDoCheck(): void {
    // console.log('ngDoCheck');

    if (this.parkingLotMap != undefined) {
      // console.log('ngDoCheck_2');

      const containerRect = this._divContainer.nativeElement.parentElement.getBoundingClientRect();
      const containerWidth = containerRect.width;
      const containerHeight = containerRect.height;

      this.scale = Math.min(
        containerWidth / this.parkingLotMap.width,
        containerHeight / this.parkingLotMap.height
      );

      let transformY = 0;
      let transformX = 0;
      if (containerWidth / this.parkingLotMap.width < containerHeight / this.parkingLotMap.height) {
        transformY = containerHeight / 2 - (this.parkingLotMap.height * this.scale) / 2;
      } else {
        transformX = containerWidth / 2 - (this.parkingLotMap.width * this.scale) / 2;
      }

      this.scale = this.scale * this.initialScale;
      this.renderer2.setStyle(
        this._mapContainer.nativeElement,
        'transform',
        `translateX(${transformX}px) translateY(${transformY}px) scale(${this.scale})`
      );
      this.cd.detectChanges();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    console.log('onResize');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.parkingLotID &&
      !changes.parkingLotID.firstChange &&
      changes.parkingLotID.currentValue !== changes.parkingLotID.previousValue
    ) {
      this.ngOnInit();
      this.resetParkingLotMapPosition();
      this.parkingLotMapService.selectedParkingSpacesSubject.next([]);
    }
  }

  onLayerViewActiveChange(layerViewActive: boolean) {
    this.layerViewActive = layerViewActive;
  }

  ngOnInit() {
    this.apiSubscription = this.panzoomConfig.api.subscribe(
      (api: PanZoomAPI) => (this.panZoomAPI = api)
    );
    this.modelChangedSubscription = this.panzoomConfig.modelChanged.subscribe(
      (model: PanZoomModel) => this.onModelChanged(model)
    );
    this.checkDashboardTag();
  }

  /**
   * checks which project is build to get the right api
   */
  checkDashboardTag() {
    if (this.dashboard_tag == 'CUSTOMER_DASHBOARD') {
      this._getCustomerDashboardApi();
    } else if (this.dashboard_tag == 'EVENT_DASHBOARD') {
      this._getEventDashboardApi();
    }
  }

  /**
   *  base url is 'https://events.smart-city-system.com/'
   */
  private _getEventDashboardApi() {
    this.eventCoreApiService
      .fetchEventLocationMap(this.parkingLotID)
      .pipe(
        finalize(() => {
          this.selectDockForActiveView();
          this.cd.detectChanges();
        })
      )
      .subscribe((parkingLotMap) => {
        this.parkingLotMap = parkingLotMap;
        this.parkingLotMapService.fillPSMapCollection(this.parkingLotMap.levels);

        const containerRect =
          this._divContainer.nativeElement.parentElement.getBoundingClientRect();
        const containerWidth = containerRect.width;
        const containerHeight = containerRect.height;

        this.scale = Math.min(
          containerWidth / parkingLotMap.width,
          containerHeight / parkingLotMap.height
        );
        this.scale = this.scale * this.initialScale;

        let transformY = 0;
        let transformX = 0;
        if (this.initialScale > 1) {
          transformY = (containerHeight - parkingLotMap.height * this.scale) / 2;
          transformX = (containerWidth - parkingLotMap.width * this.scale) / 2;
        } else {
          if (containerWidth / parkingLotMap.width < containerHeight / parkingLotMap.height) {
            transformY = containerHeight / 2 - (parkingLotMap.height * this.scale) / 2;
          } else {
            transformX = (containerWidth - parkingLotMap.width * this.scale) / 2;
          }
        }

        this.renderer2.setStyle(
          this._mapContainer.nativeElement,
          'transform',
          `translateX(${transformX}px) translateY(${transformY}px) scale(${this.scale})`
        );
      });
  }

  /**
   *  base url is 'https://api.parking-pilot.com/';
   */
  private _getCustomerDashboardApi() {
    this.parkingLotMapApiService
      .fetchMapOfPL(this.parkingLotID)
      .pipe(
        finalize(() => {
          if (
            this.parkingLotsDataService.parkingSpacesOfPLSubjectMap === undefined ||
            this.parkingLotsDataService.parkingSpacesOfPLSubjectMap.get(this.parkingLotID) ===
              undefined
          ) {
            this.repositoryService
              .parkingSpaceRepository()
              .fetchParkingSpacesOfPL(this.parkingLotID, 0)
              .subscribe((parkingSpaces) => {
                this.parkingLotsDataService.updateParkingSpacesOfParkingLot(
                  this.parkingLotID,
                  parkingSpaces
                );
                this.parkingLotMapService.setParkingLotAsActive(this.parkingLotID);
                this.selectDockForActiveView();
                this.cd.detectChanges();
              });
          } else {
            this.parkingLotMapService.setParkingLotAsActive(this.parkingLotID);
            this.selectDockForActiveView();
            this.cd.detectChanges();
          }
        })
      )
      .subscribe({
        next: (parkingLotMap) => {
          this.map_loading_error = false;
          this.connectedPSHelper._connectedParkingSpacesMapping(
            parkingLotMap.levels[0].parkingSpaces
          );
          this.parkingLotMap = parkingLotMap;
          this.parkingLotMapService.fillPSMapCollection(this.parkingLotMap.levels);
          if (
            !this.repositoryService.authService().useApiKey() &&
            this.userDataService.getParkinglotPermissionsByPLID(this.parkingLotID) != null
          ) {
            this.deviceSpaceService.storeCameraSpaces(this.parkingLotID);
          }

          const containerRect =
            this._divContainer.nativeElement.parentElement.getBoundingClientRect();
          const containerWidth = containerRect.width;
          const containerHeight = containerRect.height;

          this.scale = Math.min(
            containerWidth / parkingLotMap.width,
            containerHeight / parkingLotMap.height
          );

          let transformY = 0;
          let transformX = 0;
          if (containerWidth / parkingLotMap.width < containerHeight / parkingLotMap.height) {
            transformY = containerHeight / 2 - (parkingLotMap.height * this.scale) / 2;
          } else {
            transformX = containerWidth / 2 - (parkingLotMap.width * this.scale) / 2;
          }

          this.scale = this.scale * this.initialScale;
          this.renderer2.setStyle(
            this._mapContainer.nativeElement,
            'transform',
            `translateX(${transformX}px) translateY(${transformY}px) scale(${this.scale})`
          );
        },
        error: (error) => (this.map_loading_error = true)
      });
  }

  onModelChanged(model: PanZoomModel): void {
    this.panZoomModel = deepCopy(model);
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  private getCssScale(zoomLevel: any): number {
    return Math.pow(
      this.panzoomConfig.scalePerZoomLevel,
      zoomLevel - this.panzoomConfig.neutralZoomLevel
    );
  }

  selectDockForActiveView(level: number = 0) {
    const levels = this.parkingLotMap.levels;
    this.activeDeck = levels.find((levels) => levels.level === level) || levels[0];
    this.layerViewActive = false;
  }

  /**
   * reseting parking lot map position to its initial view
   */
  resetParkingLotMapPosition() {
    this.panZoomAPI.resetView();
  }

  onActiveDeckChange(level: Level) {
    this.activeDeck = level;
  }

  checkParkingLotType(): boolean {
    return (
      this.mainDataService.listOPL?.getValue()?.get(this.parkingLotID)?.getValue()
        ?.parking_lot_device_type === 'camera' ?? false
    );
  }
}

export function deepCopy(o: object) {
  // taken from https://jsperf.com/deep-copy-vs-json-stringify-json-parse/5
  let newO, i;
  if (typeof o !== 'object') {
    return o;
  }
  if (!o) {
    return o;
  }
  if ('[object Array]' === Object.prototype.toString.apply(o)) {
    newO = [];
    for (i = 0; i < (o as Array<any>).length; i += 1) {
      newO[i] = deepCopy(o[i]);
    }
    return newO;
  }
  newO = {};
  for (i in o) {
    if (o.hasOwnProperty(i)) {
      newO[i] = deepCopy(o[i]);
    }
  }
  return newO;
}
