import { InvitedUser, InvitationResponse } from "@frontend-monorepo/core";
import { User } from "@frontend-monorepo/parking-lot-map";
import { Observable } from "rxjs/internal/Observable";

export abstract class UserInvitationBase {
    abstract postAcceptInvite(invite_token: string, username: string, password: string): Observable<any>;
    abstract getAccountInvite(invite_token: string): Observable<User>;
    abstract getInvitedUsers(): Observable<Array<InvitedUser>>;
    abstract createNewUser(body: string, send_email?: boolean, brand?: string, parent_user_id?: number): Observable<InvitationResponse>;
    abstract generateNewInvitation(id: number, send_email?: boolean, brand?: string): Observable<InvitationResponse>;
}

