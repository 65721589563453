import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@frontend-monorepo/core';
import { Account2faRepository } from '../../repository/account-2fa/account-2fa.repository';
import { AuthRepository } from '../../repository/auth/auth.repository';
import { CameraSnapshotRepository } from '../../repository/camera-snapshot/camera-snapshot.repository';
import { LoginRepository } from '../../repository/login/login.repository';
import { OpenBarrierRepository } from '../../repository/open-barrier/open-barrier.repository';
import { ParkingLotPresetRepository } from '../../repository/parking-lot-preset/parking-lot-preset.repository';
import { ParkingLotSettingsBase } from '../../repository/parking-lot-settings/parking-lot-settings.base';
import { ParkingLotSettingsRepository } from '../../repository/parking-lot-settings/parking-lot-settings.repository';
import { ParkingLotRepository } from '../../repository/parking-lot/parking-lot.repository';
import { ParkingSpaceCommentRepository } from '../../repository/parking-space-comments/parking-space-comment.repository';
import { ParkingSpacesBase } from '../../repository/parking-spaces/parking-spaces.base';
import { ParkingSpacesRepository } from '../../repository/parking-spaces/parking-spaces.repository';
import { UserInvitationRepository } from '../../repository/user-invitation/user-invitation.repository';
import { UserRepository } from '../../repository/user/user.repository';
import { PresetService } from '../parking-duration-preset/preset.service';
import { RepositoryBase } from './repository.base';
import { CameraEventRepository } from '../../repository/camera-event/camera-event.repository';
import { EvseIdRepository } from '../../repository/evse-id/evse-id.repository';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService extends RepositoryBase {
 
  constructor(private http: HttpClient, private _authService: AuthService, private _presetService: PresetService) {
    super();
  }
  parkingLotRepository(): ParkingLotRepository {
    return new ParkingLotRepository(this.http, this._authService);
  }

  parkingLotSettingsRepository(): ParkingLotSettingsBase {
    return new ParkingLotSettingsRepository(this.http);
  }

  parkingSpaceRepository(): ParkingSpacesBase {
    return new ParkingSpacesRepository(this.http);
  }

  loginRepository(): LoginRepository {
    return new LoginRepository(this.http);
  }

  cameraRepository(): CameraSnapshotRepository {
    return new CameraSnapshotRepository(this.http);
  }

  userRepository(): UserRepository {
    return new UserRepository(this.http);
  }

  account2faRepository(): Account2faRepository {
   return new Account2faRepository(this.http);
  }

  authRepository(): AuthRepository {
    return new AuthRepository(this.http); 
  }

  authService(): AuthService {
    return this._authService;
  }

  userInvitationRepository(): UserInvitationRepository {
    return new UserInvitationRepository(this.http);
  }
 
  openBarrierRepostory(): OpenBarrierRepository {
    return new OpenBarrierRepository(this.http);
  }

  parkingLotPresetRepository(): ParkingLotPresetRepository {
    return new ParkingLotPresetRepository(this.http);
  }

  parkingSpaceCommentRepository(): ParkingSpaceCommentRepository {
    return new ParkingSpaceCommentRepository(this.http);
  }
  
  cameraEventsRepository(): CameraEventRepository {
    return new CameraEventRepository(this.http);
  }

  evseIdRepository() {
    return new EvseIdRepository(this.http);
  }

  presetService(): PresetService {
    return this._presetService;
  }

}
