import { ParkingLotMapComponentInjectorService } from "../services/parking-lot-map-component-injector.service";
import { LeftSideBarComponent } from '../components/left-side-bar/left-side-bar.component';
import { ParkingSpaceComponent } from '../components/parking-space/parking-space.component';
import { ParkingLotMapLegendComponent } from '../components/parking-lot-map-legend/parking-lot-map-legend.component';
import { OverlayingSidebarComponent } from '../components/overlaying-sidebar/overlaying-sidebar.component';

/**
 * Provives functions which enable individual content to be injected into several children of the parkingLotMap. 
 * 
 * Thus, this class should be extended by components, which need to change the parkingLotMap's contents. 
 * 
 * This class consists of functions which can/should be overwritten to inject content into the following child-components of the parking lot map:
 * 
 * * LeftSideBarComponent
 * * OverlayingSidebarComponent
 * * ParkingSpaceComponent
 * * ParkingLotMapLegendComponent
 */
export abstract class ParkingLotMapExtension {

    constructor(parkingLotMapComponentInjectorService: ParkingLotMapComponentInjectorService, ) {
        parkingLotMapComponentInjectorService.updateLeftSidebarContentFn(this.leftSidebarFn);
        parkingLotMapComponentInjectorService.updateParkingSpaceContentFn(this.parkingSpaceFn);
        parkingLotMapComponentInjectorService.updateRightSidebarContentFn(this.rightSidebarFn);
        parkingLotMapComponentInjectorService.updateLegendContentFn(this.legendFn);
    }

    /**
     * Function for updating the content of the parking-lot-map's left sidebar. {@link LeftSideBarComponent}.
     * @param leftSideBarComponent The parkingLotMap's LeftSideBarComponent.
     * @override Override this function, if you want to inject a component into the LeftSideBarComponent. Otherwise the content the sidebar is being cleared.
     * 
     * #### Example of how to use this function:
     * 
     * @example
     * 
     * ```ts 
     * 
     * leftSidebarFn(leftSidebarComponent: LeftSidebarComponent) {
     * 
     *      const compFactory = leftSideBarComponent.componentFactoryResolver.resolveComponentFactory(YourComponentToInject);
     *      leftSideBarComponent.vcr.clear();
     * 
     *      const compRef = leftSideBarComponent.vcr.createComponent(compFactory);
     *      const instance = <YourComponentToInject>(compRef.instance);
     *      leftSideBarComponent.cd.detectChanges();
     * 
     * }
     * ```
     */
    leftSidebarFn(leftSideBarComponent: LeftSideBarComponent) {
        leftSideBarComponent.vcr.clear();
        leftSideBarComponent.cd.detectChanges();
    }

    /**
     * Function for updating the content of the parking-lot-map's right sidebar/overlaying siderbar. ({@link OverlayingSidebarComponent}).
     * 
     * @param overlayingSidebarComponent The parkingLotMap's OverlayingSidebarComponent, which slides in from the right.
     * @override Override this function, if you want to inject a component into the OverlayingSidebarComponent. Otherwise the content the sidebar is being cleared.
     * 
     * #### Example of how to use this function:
     * 
     * @example
     * 
     * ```ts
     *
     *  rightSidebarFn(overlayingSidebarComponent: OverlayingSidebarComponent) {
     *      const compFactory = overlayingSidebarComponent.componentFactoryResolver.resolveComponentFactory(YourComponentToInject);
     *      overlayingSidebarComponent.vcRef.clear();
     *      const compRef = overlayingSidebarComponent.vcRef.createComponent(compFactory);
     *      const instance = <YourComponentToInject>(compRef.instance);
     *      overlayingSidebarComponent.cd.detectChanges();
     *  }
     * ```
     */
    rightSidebarFn(overlayingSidebarComponent: OverlayingSidebarComponent) {
        overlayingSidebarComponent.vcRef.clear();
        overlayingSidebarComponent.cd.detectChanges();
    }


    /**
     * Function for updating the content of the parking-lot-map's parking spaces ({@link ParkingSpaceComponent}).
     * @param parkingSpaceComponent The parkingLotMap's ParkingSpaceComponent
     * @override Override this function, if you want to inject a component into the ParkingSpaceComponent. Otherwise the content of all parking spaces is being cleared.
     * 
     * #### Example of how to use this function:
     * @example
     * 
     * ```ts
     * 
     * parkingSpaceFn(parkingSpaceComponent: ParkingSpaceComponent) {
     * 
     *      const compFactory = parkingSpaceComponent.componentFactoryResolver.resolveComponentFactory(YourComponentToInject);
     *      parkingSpaceComponent.vcRef.clear();
     *
     *      const compRef = parkingSpaceComponent.vcRef.createComponent(compFactory);
     *      const instance = <YourComponentToInject>(compRef.instance);
     *      parkingSpaceComponent.cd.detectChanges();
     * }
     * ```
     */
    parkingSpaceFn(parkingSpaceComponent: ParkingSpaceComponent) {
        parkingSpaceComponent.vcRef.clear();
        parkingSpaceComponent.cd.detectChanges();
    }


    /**
     * Function for updating the content of the parking-lot-map's legend ({@link ParkingLotMapLegendComponent}).
     * 
     * @param legendComponent The parkingLotMap's ParkingLotMapLegendComponent
     * @override Override this function, if you want to inject a component into the ParkingLotMapLegendComponent.     
     * 
     * #### Example of how to use this function:
     * 
     * @example 
     * 
     * ```ts
     * 
     * legendFn(legendComponent: ParkingLotMapLegendComponent) {
     * 
     *      const compFactory = legendComponent.componentFactoryResolver.resolveComponentFactory(YourComponentToInject);
     *      legendComponent.vcr.clear();
     *   
     *      const compRef = legendComponent.vcr.createComponent(compFactory);
     *      const instance = <YourComponentToInject>(compRef.instance);
     *      legendComponent.cd.detectChanges();
     * }
     * ```
     */
    legendFn(legendComponent: ParkingLotMapLegendComponent) {
        legendComponent.vcr.clear();
        legendComponent.cd.detectChanges();
    }
}