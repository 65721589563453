import { InjectionToken } from '@angular/core';

/**
 * Token for injecting a boolean whether to hide/show the buttons for navigating between the different parking lot map modules.
 * 
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
export const HIDE_PARKING_LOT_MAP_NAVIGATION = new InjectionToken<boolean>('Boolean for hiding/showing the buttons for navigating between the different parking lot map modules', {
    providedIn: 'root',
    factory: () => { return false; }
})