import { RepositoryService } from '@frontend-monorepo/core';
import { Subscription } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { CameraLivestreamService } from '../../services/camera-livestream/camera-livestream.service';
import {
  CAMERA_EVENT_STATES} from '../../utils/constants/camera-livestream-websocket.constants';
import { WebSocketBase } from '../websocket_base.interface';

/**
 *
 */
export class CameraEventsWebsocketService implements WebSocketBase {
  private _subscription: Subscription;
  private cameraEventsWS: WebSocketSubject<any>;

  constructor(
    private repositoryService: RepositoryService,
    private cameraLivestreamService: CameraLivestreamService
  ) {
    this._subscription = new Subscription();
  }

  start(plid?: number) {
    if (plid != undefined && this.repositoryService.authService().getToken() != undefined) {
      this.cameraEventsWS = webSocket({
        url: `wss://api.parking-pilot.com/parkinglots/${plid}/camera-events/websocket?overview_image=true`,
        openObserver: {
          next: () => {
            if (this.repositoryService.authService().isTwoFactorEnabled) {
              const tokens = this.repositoryService.authService().getTokens();
              this.cameraEventsWS.next({
                token: tokens?.token,
                two_factor_token: tokens?.two_factor_token
              });
            } else {
              this.cameraEventsWS.next({
                token: this.repositoryService.authService().getToken()
              });
            }
          }
        }
      });

      this._subscription.add(
        this.cameraEventsWS.subscribe(
          (data) => {
            {
              switch (data.type) {
                case CAMERA_EVENT_STATES.AUTHENTICATION_SUCCESS:
                  break;
                case CAMERA_EVENT_STATES.START_STATUS:
                  this.cameraLivestreamService.updateCameraEventList(data);
                  break;

                case CAMERA_EVENT_STATES.AUTHENTICATION_FAILURE:
                  this.stop();
                  break;
                case CAMERA_EVENT_STATES.STOP:
                  this.stop();
                  break;
                default:
                  break;
              }
            }
          },
          (err) => {
            console.log(err)
            this.stop();
          }
        )
      );
    }
  }

  stop() {
    if (this.cameraEventsWS != undefined) {
      this.cameraEventsWS.complete();
      this.cameraEventsWS.unsubscribe();
    }

    this.cameraEventsWS = undefined;
    this._subscription.unsubscribe();
  }

  isRunning(): boolean {
    throw new Error('Method not implemented.');
  }
}
