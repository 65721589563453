import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvseIdButtonComponent } from './components/evse-id-button/evse-id-button.component';
import { EditEvseIdDialogComponent } from './components/edit-evse-id-dialog/edit-evse-id-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import de from './i18n/de.json';
import en from './i18n/en.json';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DeleteEvseIdDialogComponent } from './components/delete-evse-id-dialog/delete-evse-id-dialog.component';
import { MaterialComponentsModule } from 'apps/customer-dashboard/src/app/pages/materialComponents.module';

@NgModule({
  declarations: [EvseIdButtonComponent, EditEvseIdDialogComponent, DeleteEvseIdDialogComponent],
  imports: [
    CommonModule,
    MaterialComponentsModule
  ],
  exports: [EvseIdButtonComponent, EditEvseIdDialogComponent]
})
export class ParkingSpaceEvseIdModule {
  constructor(translocoService: TranslocoService) {
    translocoService.setTranslation(en, 'en');
    translocoService.setTranslation(de, 'de');
  }
}
