import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'frontend-monorepo-comment-history-dialog',
  templateUrl: './comment-history-dialog.component.html',
  styleUrls: ['./comment-history-dialog.component.scss']
})
export class CommentHistoryDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public parkingLotId,
  ) {}

  ngOnInit(): void {
  }
}
