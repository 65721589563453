import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PresetSettingDialogComponent } from './components/preset-setting-dialog/preset-setting-dialog.component';

import de from './i18n/de.json';
import en from './i18n/en.json';
import { TranslocoService } from '@ngneat/transloco';
import { PresetSummaryModule } from '../preset-summary/preset-summary.module';
import { MaterialComponentsModule } from 'apps/customer-dashboard/src/app/pages/materialComponents.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PresetSearchPresetModule } from '../preset-search-preset/preset-search-preset.module';
import { PresetConfigurationModule } from '../preset-configuration/preset-configuration.module';
import { CheckExistingPresetDialogComponent } from './components/check-existing-preset-dialog/check-existing-preset-dialog.component';

@NgModule({
  declarations: [PresetSettingDialogComponent, CheckExistingPresetDialogComponent],
  imports: [
    CommonModule,
    PresetSummaryModule,
    MaterialComponentsModule,
    FlexLayoutModule,
    PresetSearchPresetModule,
    PresetConfigurationModule
  ],
  exports: [PresetSettingDialogComponent, CheckExistingPresetDialogComponent]
})
export class PresetSettingDialogModule {
  constructor(translocoService: TranslocoService) {
    translocoService.setTranslation(en, 'en');
    translocoService.setTranslation(de, 'de');
  }
}
