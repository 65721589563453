import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyNumbersDirective } from './only-numbers/only-numbers.directive';
import { NoSpecialCharDirective } from './no-special-char/no-special-char.directive';

@NgModule({
  declarations: [OnlyNumbersDirective, NoSpecialCharDirective],
  imports: [CommonModule],
  exports: [OnlyNumbersDirective, NoSpecialCharDirective]
})
export class DirectivesModule {}
