<div
  class="camera parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Camera'"
></div>
<div
  class="parkingSpaceNA parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Parking-space-na'"
></div>
<div
  class="ground parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Ground'"
></div>
<div
  class="wall parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Wall'"
></div>
<div
  class="hedge parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Hedge'"
></div>
<div
  class="conifer parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Conifer'"
></div>
<div
  class="covey parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Covey'"
></div>
<div
  class="hedgerow parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Hedgerow'"
></div>
<div
  class="palmtree parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Palm-tree'"
></div>
<div *ngIf="!hideHeatmapRelatedIcons">
  <div
    class="disabled parkingLotImage"
    [style.width]="drawable.width + 'px'"
    [style.height]="drawable.height + 'px'"
    [style.top]="drawable.y + 'px'"
    [style.left]="drawable.x + 'px'"
    [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
    *ngIf="drawable.type === 'Disabled'"
  ></div>
  <div
    class="family parkingLotImage"
    [style.width]="drawable.width + 'px'"
    [style.height]="drawable.height + 'px'"
    [style.top]="drawable.y + 'px'"
    [style.left]="drawable.x + 'px'"
    [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
    *ngIf="drawable.type === 'Family'"
  ></div>
  <div
    class="electroCharger parkingLotImage"
    [style.width]="drawable.width + 'px'"
    [style.height]="drawable.height + 'px'"
    [style.top]="drawable.y + 'px'"
    [style.left]="drawable.x + 'px'"
    [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
    *ngIf="drawable.type === 'Electro-charger'"
  ></div>
</div>
<div
  class="loadingzone parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Loading-zone-sign'"
></div>
<div
  class="agbSign parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Agb-sign'"
></div>
<div
  class="noticeSign parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Notice-sign'"
></div>
<div
  class="agbNoticeSign parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Agb-notice-sign'"
></div>
<div
  class="shoppingCart parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Shopping-cart'"
></div>
<div
  class="chargingStation parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Charging-station'"
></div>
<div
  class="parkingTerminal parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Parking-terminal'"
></div>
<div
  class="arrow parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Entry-arrow'"
></div>
<div
  class="stone parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Stone'"
></div>
<div
  class="railway parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Railway'"
></div>
<div
  class="compass parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Compass'"
></div>
<div
  class="patch parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Patch'"
></div>
<div
  class="carsharing parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Carsharing'"
></div>
<div
  class="bicycle parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Bicycle'"
></div>
<div
  class="sidewalk parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Sidewalk'"
></div>
<div
  class="waterwave parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Waterwave'"
></div>
<div
  class="ledsign parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Led-sign'"
></div>
<div
  class="cameraView parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Camera-view'"
></div>
<div
  class="antenna parkingLotImage"
  [style.width]="drawable.width + 'px'"
  [style.height]="drawable.height + 'px'"
  [style.top]="drawable.y + 'px'"
  [style.left]="drawable.x + 'px'"
  [style.transform]="'rotate(' + drawable.rotation + 'deg)'"
  *ngIf="drawable.type === 'Antenna'"
></div>
