import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentParkingSpaceCommentComponent } from './components/current-parking-space-comment/current-parking-space-comment.component';
import { AddParkingSpaceCommentDialogComponent } from './components/add-parking-space-comment-dialog/add-parking-space-comment-dialog.component';
import { ParkingSpaceCommentListComponent } from './components/parking-space-comment-list/parking-space-comment-list.component';
import { MaterialComponentsModule } from 'apps/customer-dashboard/src/app/pages/materialComponents.module';
import { TranslocoService } from '@ngneat/transloco';
import de from './i18n/de.json';
import en from './i18n/en.json';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AllParkingSpaceCommentDialogComponent } from './components/all-parking-space-comment-dialog/all-parking-space-comment-dialog.component';
import { ConfirmationDialogModule, DateTimePipeModule } from '@frontend-monorepo/common';
import { ParkingSpaceFilterComponent } from './components/parking-space-filter/parking-space-filter.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommentDatePickerComponent } from './components/comment-date-picker/comment-date-picker.component';
import { ExpansionContentComponent } from './components/expansion-content/expansion-content.component';
import { CommentHistoryDialogComponent } from './components/comment-history-dialog/comment-history-dialog.component';
import { CommentHistoryListComponent } from './components/comment-history-list/comment-history-list.component';
// import { MatChipsModule, MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';




@NgModule({
  declarations: [
    CurrentParkingSpaceCommentComponent,
    AddParkingSpaceCommentDialogComponent,
    ParkingSpaceCommentListComponent,
    AllParkingSpaceCommentDialogComponent,
    ParkingSpaceFilterComponent,
    CommentDatePickerComponent,
    ExpansionContentComponent,
    CommentHistoryDialogComponent,
    CommentHistoryListComponent
  ],
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FlexLayoutModule,
    DateTimePipeModule,
    ConfirmationDialogModule,
    OverlayModule
  ],
  exports: [
    CurrentParkingSpaceCommentComponent,
    AddParkingSpaceCommentDialogComponent,
    ParkingSpaceCommentListComponent,
    AllParkingSpaceCommentDialogComponent,
    ParkingSpaceFilterComponent,
    ExpansionContentComponent
  ],
})
export class ParkingSpaceCommentModule { 
  constructor(translocoService: TranslocoService) {
    translocoService.setTranslation(en, 'en');
    translocoService.setTranslation(de, 'de');
  }
}
