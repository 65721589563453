import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Optional,
  Inject,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DASHBOARD_TAG, RepositoryService } from '@frontend-monorepo/core';
import { MP_VERSION_TOKEN } from '../tokens/version.token';
import { TranslocoService } from '@ngneat/transloco';
import { ApiService } from '@frontend-monorepo/core-event';
import { Observable, Subscription, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { UserDataService } from '@frontend-monorepo/parking-lot-map';
import { LoginService } from '../helper/login-service/login.service';
import { Variable } from 'apps/customer-dashboard/src/app/helper/variable';

/**
 * Page for authorizing a user.
 *
 * Consists of a simple form to enable the user to enter his credentials and submit them to retrieve an auth-token.
 *
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@Component({
  selector: 'mp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  emailForm: FormGroup;
  resetPasswordActiv: boolean;
  login_attempts: number;
  private timerSubscription: Subscription;
  private _subscription: Subscription;

  checkCookie: boolean;

  counter$: Observable<number>;
  lockedUserList: LockedUser[];
  counter: number;
  counter_type: string;
  showError: Boolean;
  showButton: Boolean;

  isTwoFactorEnabled: boolean;

  constructor(
    @Optional() @Inject(MP_VERSION_TOKEN) public version: number | string,
    @Optional() @Inject(Boolean) public isEventDashboard: boolean,
    @Optional() @Inject(String) public projectTag: string,
    private eventApiService: ApiService,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private router: Router,
    private transloco: TranslocoService,
    private cd: ChangeDetectorRef,
    private repositoryService: RepositoryService,
    private userDataService: UserDataService,
    private loginService: LoginService
  ) {
    this.timerSubscription = new Subscription();
    this._subscription = new Subscription();
    this.resetPasswordActiv = false;
    this.lockedUserList = [];
    this.login_attempts = 0;
    this.showError = false;
    this.showButton = true;
    this.counter_type = '';
    this.isTwoFactorEnabled = false;
    this.loginForm = this.formBuilder.group({
      username: this.formBuilder.control('', Validators.required),
      password: this.formBuilder.control('', Validators.required)
    });
    this.emailForm = this.formBuilder.group({
      email: this.formBuilder.control('', [Validators.email, Validators.required])
    });
  }
  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this.loginService._unsubscribe();
  }

  ngOnInit() {}

  /**
   * Start listening to loginForm changes if cookie was not set
   * @param response coming in from {@link CookieDirective} determinating whether cookie is set
   */
  getCookieResponse(response: string) {
    if (this.projectTag != 'event_dashboard') {
      if (response == 'NO_COOKIE') {
        this.loginForm.get('username').valueChanges.subscribe((user) => {
          let lockedUser = this.lockedUserList.find((item) => item.username == user);
          if (user.length == 0 && user != null) {
            this.showButton = true;
            this.showError = false;
          } else if (lockedUser != null && lockedUser.username == user) {
            this.showError = true;
            this.showButton = false;
            this.errorHandling(
              this.lockedUserList.find((item) => item.username == user).errorObject
            );
          } else {
            this.showError = false;
            this.showButton = true;
            this.loginForm.get('password').enable();
          }
          this.cd.detectChanges();
        });
      } else if (response == 'HAS_COOKIE') {
        this.isTwoFactorEnabled = this.repositoryService.authService().isTwoFactorEnabled;
        // setup data if
        if (!this.isTwoFactorEnabled) {
          this.loginService.setupBasicData().subscribe((ready) => {
            this.navigateToHomeScreen();
          });
        }
      }
    }
  }

  /**
   * Triggering AuthService´s doLogin function with the entered credentials.
   *
   * If the user was logged in successfully, the user´s account info is being fetched
   * prior to the navigation to the landing page.
   *
   * Otherwise a snackBar is shown to inform about incorrect credentials.
   *
   */
  submitCredentials() {
    const username = this.loginForm.get('username').value;
    const password = this.loginForm.get('password').value;

    if (this.projectTag == 'event_dashboard') {
      this.loginEventDashboard(username, password);
    } else if (this.projectTag == 'demokit') {
      this.loginDemokit(username, password);
    } else if (this.projectTag == 'demo_account') {
      this.loginDemoAccount(username, password);
    } else if (this.projectTag == 'customer-dashboard' || this.projectTag == 'vodafone') {
      this.loginDashboard(username, password);
    }
  }

  /**
   * LoginDashboard is requesting the login to the normal dashboard backend
   * Used backend url = https://api.parking-pilot.com/
   * @param username --> username of the user
   * @param password --> password of the user
   */
  private loginDashboard(username: string, password: string) {
    this.repositoryService
      .authRepository()
      .doLogin(username, password, this.checkCookie)
      .subscribe({
        next: (response) => {
          this.repositoryService.authService().setToken(response.token);
          this.isTwoFactorEnabled = response.two_factor_authentication_type !== undefined;
          this.repositoryService.authService().setTwoFactorEnabled(this.isTwoFactorEnabled);

          this.cd.detectChanges();

          // set cookie if user chose to
          if (this.checkCookie) {
            this.loginService.setupCookie(response);
          }
          if (!this.isTwoFactorEnabled) {
            this.loginService.setupBasicData().subscribe((ready) => {
              this.navigateToHomeScreen();
            });
          }
        },
        error: (err: HttpErrorResponse) => {
          if (err.status == 403) {
            this.snackbar.open(this.transloco.translate('account_locked'));
          } else {
            this.errorHandling(err);
          }
        }
      });
  }

  /**
   * LoginDemoAccount is requesting the login to the normal dashboard backend,
   * Access only with Demo-Account
   * Used backend url = https://api.parking-pilot.com/
   * @param username --> username of the user
   * @param password --> password of the user
   */
  private loginDemoAccount(username: string, password: string) {
    if (
      username == 'Demo_Dashboard' ||
      username == 'Henkel_Praesentation' ||
      username == 'Daimler_DEMO_Dashboard'
    ) {
      this.repositoryService
        .authRepository()
        .doLogin(username, password, false)
        .subscribe(
          (response) => {
            this.repositoryService.authService().setToken(response.token);
            this.repositoryService
              .userRepository()
              .fetchAccountInfo()
              .subscribe((userData) => {
                this.userDataService.setUserData(userData);
                this.router.navigateByUrl('landing');
              });
          },
          (err: HttpErrorResponse) => {
            this.errorHandling(err);
          }
        );
    } else {
      this.snackbar.open(this.transloco.translate('demo_error'));
    }
  }

  /**
   * LoginEventDashboard is used for the custom event dashboard login
   * Backend url = https://events.smart-city-system.com/
   * @param username --> username of the user
   * @param password --> password of the user
   */
  private loginEventDashboard(username: string, password: string) {
    this.eventApiService.doLogin(username, password).subscribe(
      (response) => {
        this.repositoryService.authService().setToken(response.token);
        this.userDataService.setUserData({
          id: response.user.userId,
          email: '',
          locked: false,
          name: response.user.name,
          parent_user_id: null,
          username: response.user.username,
          settings: null,
          parking_lot_permissions: new Map(),
          user_permissions: []
        });

        this.router.navigateByUrl('landing');
      },
      (error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.snackbar.open(this.transloco.translate('snackbarError401'));
        }
      }
    );
  }

  /**
   * LoginDemokit is used for the custom demokit login
   * Backend url = https://api.parking-pilot.com/
   * @param username --> username of the user
   * @param password --> password of the user
   */
  private loginDemokit(username: string, password: string) {
    this.repositoryService
      .authRepository()
      .doLogin(username, password, false)
      .subscribe(
        (response) => {
          this.repositoryService.authService().setToken(response.token);
          this.router.navigateByUrl('calibration');
        },
        (err: HttpErrorResponse) => {
          this.errorHandling(err);
        }
      );
  }

  /**
   * show reset password form and switches back to login
   */
  toggleResetPassword() {
    this.resetPasswordActiv = !this.resetPasswordActiv;
  }

  /**
   * Reset password
   */
  resetPassword() {
    this.resetPasswordActiv = false;
    let json = JSON.stringify({ email: this.emailForm.get('email').value, brand: DASHBOARD_TAG });
    this.repositoryService
      .loginRepository()
      .postGeneratePasswordResetToken(json)
      .subscribe(
        (resp) => {
          this.snackbar.open(this.transloco.translate('reset_password_email_send'));
        },
        (error: HttpErrorResponse) => {
          this.snackbar.open(this.transloco.translate('error_reset_password_email'));
        }
      );
  }

  /**
   * Handles error on failed attempt to login with a backoff-timer
   * @param err error Response
   */
  private errorHandling(err: HttpErrorResponse) {
    if (err.status == 401) {
      if (err.error.locked_until != null) {
        this.setLoginFormState('disabled');
        this.addUserToList(this.loginForm.get('username').value, err);
        let count = +err.error.locked_until - Math.floor(Date.now() / 1000);
        if (count < 0) {
          this.setLoginFormState('enable');
          this.showError = false;
        } else {
          this.counter = count;
          if (count == 0) {
            this.snackbar.open(this.transloco.translate('snackbarError401'));
          } else {
            this.counter$ = timer(0, 1000).pipe(
              take(count + 1),
              map(() => {
                if (count == 0) {
                  this.setLoginFormState('enable');
                  this.counter = count;
                }
                --count;
                return count;
              })
            );
            this.login_attempts = err.error.failed_login_attempts;
            this.cd.detectChanges();
          }
        }
      } else {
        this.snackbar.open(this.transloco.translate('snackbarError401'));
      }
    }
  }

  /**
   * disables and enables loginFrom
   * @param state action
   */
  private setLoginFormState(state: string) {
    if (state == 'enable') {
      this.loginForm.get('password').enable();
      this.removeUserFromList(this.loginForm.get('username').value);
      this.showButton = true;
      this.showError = false;
    } else {
      this.loginForm.get('password').disable();
      this.showError = true;
      this.showButton = false;
    }
    this.cd.detectChanges();
  }

  /**
   * Adds locked user to the list to retrieve and display when user account changes back
   * @param username the username of currently selected account
   * @param errObject http error response containing the failed login lock data
   */
  private addUserToList(username: string, errObject: HttpErrorResponse) {
    let user: LockedUser = {
      username: username,
      errorObject: errObject
    };
    if (this.lockedUserList.find((user) => user.username == username) == null) {
      this.lockedUserList.push(user);
    }
  }

  /**
   * Removes locked user from the list, when the countdown is finished
   * @param username which due to failed login attempts is temporary locked
   */
  private removeUserFromList(username: String) {
    let currentUser = this.lockedUserList.findIndex((item) => item.username === username);
    this.lockedUserList.splice(currentUser, 1);
  }

  /**
   * checks for expired token and logs the user out if it applies
   */
  private _checkForTokenExpiration(expirationTime: number) {
    if (this.repositoryService.authService().getToken() != null) {
      if (this.timerSubscription != null) {
        this.timerSubscription.unsubscribe();
        this.timerSubscription = null;
      }
      let counter = expirationTime;

      this.timerSubscription = timer(0, 1000).subscribe((_) => {
        if (counter == 0) {
          this.repositoryService
            .authRepository()
            .doLogout()
            .subscribe(
              (res) => {
                this.loginService.resetCookie();
              },
              (err) => {}
            );
          this.router.navigateByUrl('login');
          this.snackbar.open(this.transloco.translate('token_expired_notice'));
          this.timerSubscription.unsubscribe();
          this.timerSubscription = null;
        }
        --counter;
        this.cd.detectChanges();
      });
    }
  }

  private startTimer(err: HttpErrorResponse) {
    this.login_attempts = err.error.failed_login_attempts;
    this.setLoginFormState('disabled');

    if (this.timerSubscription != null) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }

    this.addUserToList(this.loginForm.get('username').value, err);
    let count = +err.error.locked_until - Math.floor(Date.now() / 1000);
    this.counter = count;

    this.timerSubscription = timer(0, 1000).subscribe((_) => {
      if (this.counter == 0) {
        this.timerSubscription.unsubscribe();
        this.timerSubscription = null;
        this.setLoginFormState('enable');
      }

      --this.counter;
      this.cd.detectChanges();
    });
  }

  /**
   * activates cookie setting
   * @param checked
   */
  private activateCookie(checked: boolean): void {
    this.checkCookie = checked;
    this.cd.detectChanges();
  }

  navigateToHomeScreen(): void {
    if (localStorage.getItem(Variable.LIST_SELECTION_URL) != null) {
      this.router.navigateByUrl(localStorage.getItem(Variable.LIST_SELECTION_URL));
    } else {
      this.router.navigateByUrl('landing/parking-violations');
    }
  }
}

export interface LockedUser {
  username: string;
  errorObject: HttpErrorResponse;
}
