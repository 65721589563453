/**
 * The expected response of {@link CoreApiService#doLogin}
 * Consists of the bearer-token which is neccessary for future API-requests.
 */
export interface AuthResponse {
  /**
   * The bearer token neccessary for future API-requests.
   */
  token: string;
  /**
   * Two-factor authentication hint. At the moment will always be totp if 2FA is enabled and null if it is not.
   */
  two_factor_authentication_type?: string;
}

export function createAuthResponse(token: string, two_factor_token?: string) {
  return {
    token,
    two_factor_token
  } as AuthResponse;
}
