import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParkingDurationsSettingsDialogComponent } from './component/parking-durations-settings-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';

import de from './i18n/de.json';
import en from './i18n/en.json';
import { ParkingDurationsColorBeamModule } from '@frontend-monorepo/common';
import { ResetParkingDurationSettingsComponent } from './component/reset-parking-duration-settings/reset-parking-duration-settings.component';
import { FlexLayoutModule } from '@angular/flex-layout';


/**
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@NgModule({
    declarations: [
        ParkingDurationsSettingsDialogComponent,
        ResetParkingDurationSettingsComponent
    ],
    imports: [
        CommonModule,
        MatTooltipModule,
        MatButtonModule,
        MatDialogModule,
        MatInputModule,
        ReactiveFormsModule,
        MatIconModule,
        ParkingDurationsColorBeamModule,
        TranslocoModule,
        FlexLayoutModule
    ],
    exports: [
        ParkingDurationsSettingsDialogComponent
    ],
})
export class ParkingDurationsSettingsModule {
    constructor(translocoService: TranslocoService) {
        translocoService.setTranslation(en, 'en');
        translocoService.setTranslation(de, 'de');
    }
}