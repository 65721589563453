import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserInviteGuard implements CanActivate {

  private token: string = '';

  constructor(private readonly router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot): boolean {
      this.token = route.queryParamMap.get('invite_token');
  
      if (this.token != null) {
        return true;
      } else {
        this.router.navigateByUrl('login');
      }
    }
}
