import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API_BASE_URL, AuthResponse, Failure, serializeTotpCode } from '@frontend-monorepo/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthBase } from './auth.base';

export class AuthRepository extends AuthBase {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * After TOTP has been enabled, this route needs to be used to verify that the user has access to the TOTP key by providing a valid TOTP code.
   * On success, a two-factor token is returned that is valid for a short period of time (currently 14 hours).
   * The token must be provided in the header "X-Two-Factor-Token" in addition to the "X-Auth-Token" header used for the standard bearer token.
   *
   * @param totpCode TOTP code generated using authenticator app
   */
  postAuthTotp(totpCode: number): Observable<any | Failure> {
    let object = this.http
      .post<any | Failure>(API_BASE_URL + 'auth-totp', serializeTotpCode(totpCode))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw new Failure(error.status, '2fa_code_invalid');
        })
      );
    return object;
  }

  /**
   * Retrieving an auth-token by posting the user´s credentials.
   * @param username
   * @param password
   */
  doLogin(username: string, password: string, remember: boolean): Observable<AuthResponse> {
    return this.http
      .post<AuthResponse>(
        API_BASE_URL + `auth?remember=${remember == undefined ? false : remember}`,
        `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        }
      );
  }

  /**
   * User log out
   */
  doLogout(): Observable<any> {
    let url = 'https://api.parking-pilot.com/logout';
    return this.http.post<any>(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }
}
