import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { USE_API_KEY } from './tokens/useApiKey.token';
import { AuthInterceptor, authInterceptorProviders } from './interceptors/auth.interceptor';
import { BrowserModule } from '@angular/platform-browser';

/**
 * The {@link CoreModule} 's configuration which is used by the static function {@link CoreModule#withConfig}.
 *
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
export interface CoreModuleConfig {
  /**
   * Whether or not to use an api key instead of a bearer token for authentication.
   *
   * #### Important:
   * If you want to use an API key for authentication, calling  [CoreApiService#setApiKey]{@link CoreApiService#setApiKey} is ultimately necessary.
   */
  useApiKey?: boolean;
}

@NgModule({
  imports: [CommonModule, HttpClientModule]
})
export class CoreModule {
  /**
   * Creates a configurable {@link CoreModule}
   *
   * If you want to configure the CoreModule to use an API key instead of a bearer token for authentication, two things have to be done:
   * 1. Firstly import the {@link CoreModule} in your root module like this:
   *
   * ```
   * CoreModule.withConfig({
   *      useApiKey: true
   * });
   * ```
   *
   * 2. Call [CoreApiService#setApiKey]{@link CoreApiService#setApiKey} in your app to globally set the api key for future HTTP requests.
   *
   * @param config `CoreModuleConfig` object for configuring the `CoreModule`.
   *
   */
  static withConfig(config: CoreModuleConfig): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: USE_API_KEY,
          useValue: config.useApiKey
        }
      ]
    };
  }
}
