import { InjectionToken } from "@angular/core";

/**
 * Whether or not to use an api key instead of a bearer token for authentication.
 * 
 * #### Important:
 * If you want to use an API key for authentication, calling  {@link CoreApiService#setApiKey} in your app is ultimately necessary.
 * 
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
export const USE_API_KEY = new InjectionToken('Whether or not to use an api key instead of a bearer token for authentication');