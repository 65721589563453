<ng-container *transloco="let t">
  <div fxLayout="column" fxLayoutGap="10ox">
    <h2 mat-dialog-title>{{ t('add_dialog_header') }}</h2>
    <mat-form-field class="comment-wrapper">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <input
          matInput
          type="text"
          autocomplete="off"
          placeholder=" {{ t('comment_input') }}"
          [formControl]="commentText"
          #comment
          maxlength="50"
        />
        <mat-hint class="hint">{{ comment.value.length }} / 50</mat-hint>
      </div>
    </mat-form-field>
    <div fxLayout="row" fxLayoutGap=" 5px">
      <mat-checkbox color="primary" [(ngModel)]="deleteOnFree">
        {{ t('delete_on_free') }}
      </mat-checkbox>
      <mat-icon svgIcon="leaving_car"></mat-icon>
    </div>

    <mat-checkbox color="primary" [(ngModel)]="logComment">
      {{ t('log_comment') }}
    </mat-checkbox>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
    <button mat-flat-button color="cancel" [mat-dialog-close]>
      {{ t('cancel') }}
    </button>
    <span id="flex-spacer"></span>
    <button mat-flat-button color="primary" (click)="addComment()" [mat-dialog-close]>
      {{ t(data.action) }}
    </button>
  </div>
</ng-container>
