<div *transloco="let t">
  <div *ngIf="!hideDownloadProgress" fxLayout="column" fxLayoutGap="15px">
    <h2>Download</h2>
    <p>{{ t('download_text') }}</p>

    <div class="progress-container">
      <mat-progress-spinner
        mode="determinate"
        [diameter]="200"
        [strokeWidth]="10"
        [value]="progressValue$ | async"
      ></mat-progress-spinner>
      <!-- <span class="percentage">{{ progressValue$ | async }} %</span> -->
      <div class="percentage">
        <span>{{ downloadValue$ | async }} {{ t('of') }} {{ selectedParkingLotList?.length }}</span>
        <br />
        <span>{{ t('parkinglots') }}</span>
      </div>
    </div>
    <div>
      <button mat-flat-button color="cancel" (click)="close('cancel')">{{ t('cancel') }}</button>
    </div>
  </div>

  <div *ngIf="hideDownloadProgress">
    <div *ngIf="(progressValue$ | async) == 100">
      <h2>{{ t('download_completed') }}</h2>
      <div
        fxLayout="column"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
        class="successful-text"
      >
        <div fxLayout="row">
          <span>
            {{ t('download_successful') }}
          </span>
          <mat-icon color="primary">check_circle</mat-icon>
        </div>
        <div class="timer-wrapper">{{ t('close_in') }} {{ counter$ | async }}s</div>
        <div fxFlexAlign="start">
          <button mat-flat-button color="primary" (click)="close('finish')">
            {{ t('completed') }}
          </button>
        </div>
      </div>
    </div>
    <!-- error message -->
    <div *ngIf="errorMessage" fxLayout="column" fxLayoutAlign="start center">
      <h2>Download</h2>
      <div fxLayout="row">
        <span>
          {{ errorMessage }}
        </span>
        <mat-icon color="warn">error</mat-icon>
      </div>
      <div fxFlexAlign="start">
        <button mat-flat-button color="cancel" (click)="close('cancel')">{{ t('cancel') }}</button>
      </div>
    </div>
  </div>
</div>
