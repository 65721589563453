import { AuthResponse, Failure } from "@frontend-monorepo/core";
import { Observable } from "rxjs";
import { CookieResponse } from "./models/cookieResponse";

export abstract class LoginBase {
    abstract fetchCustomerPLIDMapping(): Observable<Map<number, number>>;
    abstract postGeneratePasswordResetToken(json: string): Observable<any>;
    abstract postResetPassword(email: String, token: String, password: String): Observable<any>;
    abstract setCookie(response: AuthResponse): Observable<any>;
    abstract getCookie(): Observable<CookieResponse>;
}