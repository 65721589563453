<ng-container [cookie]="'landing'" (responseStatus)="getCookieResponse($event)" *transloco="let t">
  <div
    *ngIf="projectTag == 'customer-dashboard' || projectTag == 'vodafone'"
    class="background"
    [ngClass]="{
      backgroundNormal: projectTag == 'customer-dashboard',
      backgroundVodafone: projectTag === 'vodafone'
    }"
  ></div>
  <div *ngIf="!isTwoFactorEnabled">
    <div *ngIf="!resetPasswordActiv" class="login mat-elevation-z3 mp-widget">
      <div class="logo-wrapper" fxLayoutAlgin="center center"></div>
      <!-- <img class="logo-wrapper" src = "../../../../../src/assets/scs_logo.svg"/> -->
      <!-- login -->
      <form [formGroup]="loginForm" class="mp-widget" (ngSubmit)="submitCredentials()">
        <div fxLayout="column">
          <div fxLayout="column" fxLayoutGap="8px">
            <mat-form-field>
              <input
                matInput
                placeholder="{{ t('usernameInputPlaceholder') }}"
                name="username"
                formControlName="username"
                autocomplete="name"
                required
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="{{ t('passwordInputPlaceholder') }}"
                name="password"
                formControlName="password"
                type="password"
                autocomplete="current-password"
                required
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="projectTag == 'customer-dashboard' || projectTag == 'vodafone'"
            id="additional-setting-wrapper"
            fxLayout="row"
            fxLayoutAlign="space-between"
          >
            <mat-checkbox
              color="primary"
              [checked]="checkCookie"
              (change)="activateCookie($event.checked)"
            >
              {{ t('remember_me') }}
            </mat-checkbox>
            <a (click)="toggleResetPassword()">{{ t('forgot_password') }}</a>
          </div>
          <button
            id="login-button"
            mat-flat-button
            color="primary"
            autofocus
            [disabled]="!loginForm.valid || !showButton"
            type="submit"
          >
            {{ t('loginButton') }}
          </button>

          <mat-error>
            <ng-container *ngIf="showError">
              <div class="error-wrapper">
                {{ t('failedLoginLockUser_1') }}
                <br />
                {{ t('failedLoginLockUser_2') }}
                <br />
                <br />
                {{ t('attempts') }} {{ login_attempts }}
                <br />
                <br />
                {{ t('failedLoginLockUser_3') }}
                <br />
                <div *ngIf="counter != 0; else login_retry">
                  {{ t('failedLoginLockUser_4') }}
                  <span>{{ counter$ | async | countDown }} {{ t('failedLoginLockUser_5') }}</span>
                </div>
              </div>
            </ng-container>
          </mat-error>
        </div>

        <span *ngIf="version != null" id="version" class="mat-caption">v{{ version }}</span>
      </form>
    </div>

    <!-- reset password -->
    <div class="email mat-elevation-z3 mp-widget" *ngIf="resetPasswordActiv">
      <h3>{{ t('reset_password') }}</h3>

      <p style="font-size: small">
        {{ t('reset_password_info') }}
      </p>

      <form class="mp-widget" [formGroup]="emailForm" (ngSubmit)="resetPassword()">
        <mat-form-field id="email-wrapper">
          <input
            matInput
            placeholder="{{ t('emailInputPlaceholder') }}"
            name="email"
            formControlName="email"
            autocomplete="name"
            required
          />
        </mat-form-field>
      </form>
      <div fxLayout="row" fxLayoutAlign="space-between" class="button-wrapper">
        <button
          mat-flat-button
          color="cancel"
          (click)="toggleResetPassword()"
          style="padding-left: 0"
        >
          {{ t('back_to_login') }}
        </button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="!emailForm.valid"
          (click)="resetPassword()"
        >
          {{ t('request_link') }}
        </button>
      </div>
    </div>
    <ng-template #login_retry>
      {{ t('retry_login') }}
    </ng-template>
  </div>

  <div *ngIf="isTwoFactorEnabled">
    <frontend-monorepo-two-factor-authentication></frontend-monorepo-two-factor-authentication>
  </div>
</ng-container>
