import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { ParkingLot, ParkingLotWithOccupancies } from '@frontend-monorepo/core';

/**
 * Displaying a parking lot's durations (`max_overdue_duration`, `max_parking_duration` and `due_warning_duration`)
 * as a linear colored beam.
 * 
 * 
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@Component({
  selector: 'mp-parking-durations-color-beam',
  templateUrl: './parking-durations-color-beam.component.html',
  styleUrls: ['./parking-durations-color-beam.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParkingDurationsColorBeamComponent implements OnChanges {

  @Input() parkingLot: ParkingLot | ParkingLotWithOccupancies;



  maxParkingDuration: number;
  maxOverdueDuration: number;
  duWarningDuration: number;

  constructor() { }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.parkingLot != null) {
      this._setDurations();
    }
  }


  private _setDurations(): void {
    this.maxParkingDuration = this.parkingLot.max_parking_duration || 0;
    this.maxOverdueDuration = this.parkingLot.max_overdue_duration || 0;
    this.duWarningDuration = this.parkingLot.due_warning_duration || 0;
  }

}
