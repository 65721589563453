export class Variable {
  static PORTER_MODE: string = 'PORTER_MODE';
  static PORTER_MODE_ON: string = 'PORTER_MODE_ON';
  static PORTER_MODE_OFF: string = 'PORTER_MODE_OFF';

  static PORTER_MODE_AUDIO: string = 'PORTER_MODE_AUDIO';
  static PORTER_MODE_AUDIO_ON: string = 'PORTER_MODE_AUDIO_ON';
  static PORTER_MODE_AUDIO_OFF: string = 'PORTER_MODE_AUDIO_OFF';

  static PORTER_MODE_PS: string = 'PORTER_MODE_PS';
  static PORTER_MODE_PS_ON: string = 'PORTER_MODE_PS_ON';
  static PORTER_MODE_PS_OFF: string = 'PORTER_MODE_PS_OFF';

  static RESET_MODE: string = 'RESET_MODE';
  static RESET_MODE_ON: string = 'RESET_MODE_ON';
  static RESET_MODE_OFF: string = 'RESET_MODE_OFF';

  // app
  static APP_HEADER: string = 'APP_HEADER';
  static DE_DE: string = 'de-DE';
  static EN_GB: string = 'en-GB';
  static EN_US: string = 'en-US';
  static DE: string = 'de';
  static GB: string = 'gb';
  static US: string = 'us';

  // parkinglotDeviceList
  static SEARCH_SORT: string = 'SEARCH_SORT';

  // guards
  static LOADING_DATA_SUCCESSFUL: string = 'LOADING_DATA_SUCCESSFUL';
  static LOADING_DATA_UNSUCCESSFUL: string = 'LOADING_DATA_UNSUCCESSFUL';

  //icons
  static ICON_DE: string = 'flag_de';
  static ICON_EN: string = 'flag_en';
  static ICON_US: string = 'flag_us';

  static APP_SETTINGS: string = 'APP_SETTINGS';

  // iframe
  static KEY_GLMANUFAKTUR: string = 'GMDDRESDEN';
  static KEY_TEST: string = 'ED622A2EE35BEE49E1A781BBDCFB377F';
  static KEY_SCS_WEBSITE_1 = 'hhltzo84Hf9YvrzTifzQHNBsz6durwY1fWggV8PqSDA=';
  static KEY_SCS_WEBSITE_2 = 'qAnDB6FOZLD2uWGL6EPpWemucXxHtRfK3WlJ-o3oeTA=';
  static KEY_ISARFUNK = 'OHquN7fAwyiNix75eSLDEpiHVkHsRBBH4od9GDNE9Xo=';
  static KEY_BP = 'Z0tc0NgdzfDwwCw5aBixaqJqtvTT5RH278zRmTuNRNw=';
  static KEY_ALDI_NORD = 'O3r3bkNsAvpIILSRJDe4_ebR9A8aA3IWj6AfbYZdu6M=';
  static KEY_LIDL = 'hOhhlhgG3AQPtrgPId7qvnPJ2Do5c1grt8ETL_f0NCc=';
  static KEY_VVO = 'Atdhp5cvLqBe5TePMT9z27KhkoDLrPD3ayeguFrs2G4=';
  static KEY_AUTOKINO_NUERNBERG = 'f1u9mLQbh3z9lXBF-nJ_Q5ah6LeyUW_kdmZVDhR2cK0=';
  static KEY_WERKEINS = 'kqkQdguY1QEQf9Cj5HZTcSPXalJ4QCb1sK5QhxK23wQ=';
  static KEY_SP_ALDI_NORD = 'GRppnXgF5XbtD1PNJk7yT0DuVarV5kUw1oSDzBhvVpo=';
  static KEY_BP_HANNOVER = 'xwnH0nRaXkhsufEIHXMVJnTICL894ka0_lqYTKlokwI=';
  static KEY_ZDE = '70_HTvcyBm3xhLP9mI4jub3npu4id4A6HpafE6ZZC_k=';
  static KEY_AMBOS_IO = 'x671-EKdPk9AXvuNyTmRULaHDPx1aGkFTyki_sGlTP8=';
  static KEY_AMBOS_IO_2 = '6Dpu182KZIrusqG8ifmdyqZM25qiKQxUyIy_StgkKQM=';
  static KEY_AMBOS_IO_3 = '43biK1L0AdaOs5aoIPTE085xPOJdWPZMWm7ytOOybOI=';
  static KEY_BERLIN_GSG = 'XrbEGM8zbC-qpQTqy0XjPcHr6gTGhCMCXVcvhf6n-ng=';
  static KEY_VOLKSBANK = 'WZzrS6Wzq_xMXqxG8iENwP9Vn1Cr5ZWu41nbzOMRblE=';
  static KEY_RHENUS = 'RcIaJJ8gDorxSc_oNjTxTQAlZwJ7yjJLXleJwHXsUa4=';
  static KEY_IMAS = 'pbx5amEBcYOd7_wPrwqbvFP46nJ6ME2ppsmKqp7Kmd4=';
  static KEY_HENKEL = 'V7s6uTogN6K-5huWOwU7_sLIJeTAsYPBkUTEnRjP0Eo=';
  static DR_Harderwijk_Oosteinde_27 = 'pEqEc0wP_XkQEbcttdOrA3Rcid82Clk6L0HI22oC3xQ=';
  static KEY_CHEMNITZ = 'zB4EmZZvdJxQ64iigRhR9qfBQUtxoeIjAYQPQ7ZXPW0=';
  static KEY_SWSG = 'itkfXSxhXGk1MUtm-vjm3bAM-1-rbXq5KU4wEZCFMW0=';
  static KEY_SPORTBOOTCLUB_ERLANGEN = 'GymI5sDVhcGJAI0GNtlI-jouAITcDApryxo5gFfPaL4=';

  // customer_plid
  static NO_CUSTOMER_ID_AVAILABLE: number = -99;

  // set parking time
  static RESPONSE_ERROR: string = 'RESPONSE_ERROR';
  static RESPONSE_SUCCESS: string = 'RESPONSE_SUCCESS';

  // landing
  static LIST_SELECTION_URL: string = 'LIST_SELECTION_URL';
  static PAGINATOR_SIZE: string = 'PAGINATOR_SIZE';
  static OCC_SORT_DIRECTION: string = 'OCC_SORT_DIRECTION';
  static OCC_SORT_ACTIVE: string = 'OCC_SORT_ACTIVE';
  static MANAG_SORT_DIRECTION: string = 'MANAG_SORT_DIRECTION';
  static MANAG_SORT_ACTIVE: string = 'MANAG_SORT_ACTIVE';

  // permissions
  static PL_ASSIGN_PERMISSION_FOR_ALL: string = 'PL_ASSIGN_PERMISSION_FOR_ALL';

  // reservation setting
  static DEFAULT_DURATION_SET: string = 'DEFAULT_DURATION_SET';

  // reconnect service
  static RECONNECT_SERVICE_ONLINE: string = 'RECONNECT_SERVICE_ONLINE';
  static RECONNECT_SERVICE_OFFLINE: string = 'RECONNECT_SERVICE_OFFLINE';

  // dashboard tag to identify which project is built
  static PROJECT_TAG: string = 'CUSTOMER_DASHBOARD';

  // reset
  static RESET_TAB: string = 'RESET_TAB';
  static WHITEL_SORT_ACTIVE: string = 'WHITEL_SORT_ACTIVE';
  static WHITEL_SORT_DIRECTION: string = 'WHITEL_SORT_DIRECTION';

  // sla system status table
  static SYSTEM_STATUS_SORT_ACTIVE: string = 'SYSTEM_STATUS_SORT_ACTIVE';
  static SYSTEM_STATUS_SORT_DIRECTION: string = 'SYSTEM_STATUS_SORT_DIRECTION';
}

export enum MessageType {
  LOGIN,
  HEADER,
  IFRAME,
  NORMAL
}

export enum OverdueFormat {
  DAYS,
  HOURS,
  MINUTES,
  SECONDS
}

export enum ADMIN_ACTION {
  CREATE_USER_VIA_LINK,
  CREATE_USER,
  ASSIGN_PL,
  CHANGE_PERM
}

export enum SYSTEM_STATUS_TYPE {
  EXTERNAL_MAINTENANCE = 'external_maintenance',
  EXTERNAL_DISRUPTION = 'external_disruption',
  DISRUPTION_FIXING = 'disruption_fixing'
}
