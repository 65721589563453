<ng-container *transloco="let t">
  <div fxLayout="column" fxLayoutGap="10ox">
    <h2 mat-dialog-title>{{ t('all_parking_space_comments_header') }}</h2>
    <p>{{t('parking_space_comments_dialog_description')}}</p>
    <frontend-monorepo-parking-space-comment-list [parkingLotId]="parkingLotId"></frontend-monorepo-parking-space-comment-list>
  </div>
  <div>
    <button mat-flat-button color="cancel" [mat-dialog-close]>{{t('close')}}</button>
  </div>
</ng-container>
