<ng-container *transloco="let t; read: 'PARKINGLOT'">
  <!-- login -->

  <div [cookie]="'authGuard'" (responseStatus)="getCookieResponse($event)">
      <div *ngIf="!isTwoFactorEnabled">
        <div
          *ngIf="!resetPasswordActiv"
          fxLayout="column"
          fxLayoutGap="10px"
          class="container mat-elevation-z3 mp-widget"
        >
          <form [formGroup]="loginForm" id="window">
            <div fxLayout="column" fxLayoutGap="5px">
              <mat-form-field>
                <input
                  matInput
                  placeholder="{{ t('username') }}"
                  name="username"
                  formControlName="username"
                  required
                />
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  placeholder="{{ t('password') }}"
                  name="password"
                  formControlName="password"
                  type="password"
                  required
                />
              </mat-form-field>
              <div
              id="additional-setting-wrapper"
              fxLayout="row"
              fxLayoutAlign="space-between"
            >
              <mat-checkbox
                color="primary"
                [checked]="checkCookie"
                (change)="activateCookie($event.checked)"
              >
              {{ t('remember_me') }}
              </mat-checkbox>
              <a (click)="toggleResetPassword()">{{ t('forgot_password') }}</a>
            </div>
              <!-- <div id="forgot-password-wrapper" fxFlexAlign="end">
                <a (click)="toggleResetPassword()">{{ t('forgot_password') }}</a>
              </div> -->
              <button
                mat-raised-button
                id="buttonCorner"
                color="primary"
                [disabled]="!loginForm.valid"
                (click)="login()"
              >
                {{ t('login') }}
              </button>
            </div>

            <mat-error class="error-wrapper">
              <ng-container *ngIf="showError">
                {{ t('failedLoginLockUser_1') }}
                <br />
                {{ t('failedLoginLockUser_2') }}
                <br />
                <br />
                {{ t('attempts') }} {{ login_attempts }}
                <br />
                <br />
                {{ t('failedLoginLockUser_3') }}
                <br />
                <div *ngIf="counter != 0; else login_retry">
                  {{ t('failedLoginLockUser_4') }}
                  <span
                    >{{ counter$ | async | countDown }} {{ t('failedLoginLockUser_5') }}</span
                  >
                </div>
              </ng-container>
            </mat-error>
          </form>
          <mat-progress-bar
            *ngIf="loading"
            color="primary"
            [mode]="'indeterminate'"
          ></mat-progress-bar>
          <ng-template #login_retry>
            {{ t('retry_login') }}
          </ng-template>
        </div>

        <!-- reset password -->
        <div class="container email mat-elevation-z3 mp-widget" *ngIf="resetPasswordActiv">
          <h3>{{ t('reset_password') }}</h3>

          <p style="font-size: small">
            {{ t('reset_password_info') }}
          </p>

          <form class="mp-widget" [formGroup]="emailForm" (ngSubmit)="resetPassword()">
            <mat-form-field id="email-wrapper">
              <input
                matInput
                placeholder="{{ t('emailInputPlaceholder') }}"
                name="email"
                formControlName="email"
                autocomplete="name"
                required
              />
            </mat-form-field>
          </form>
          <div fxLayout="row" fxLayoutAlign="space-between" class="button-wrapper">
            <button
              mat-flat-button
              color="cancel"
              (click)="toggleResetPassword()"
              style="padding-left: 0"
            >
              {{ t('back_to_login') }}
            </button>
            <button
              mat-flat-button
              color="primary"
              [disabled]="!emailForm.valid"
              (click)="resetPassword()"
            >
              {{ t('request_link') }}
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="isTwoFactorEnabled" class="container two-fa-container mat-elevation-z3 mp-widget">
        <h3>{{ t('2fa_input_label') }}</h3>
        <div fxLayout="column" fxLayoutGap="10px">
          <form [formGroup]="totpCodeForm" class="mp-widget">
            <mat-form-field>
              <mat-label>{{ t('2fa_code_label') }}</mat-label>
              <input
                onlyNumbers
                #twoFactorInputToken
                (input)="authenticateUsingTotp(twoFactorInputToken.value)"
                type="number"
                matInput
                placeholder="{{ t('2fa_code_label') }}"
                formControlName="twoFactorCode"
                required
              />
            </mat-form-field>
          </form>
          <button
            [disabled]="totpCodeForm.invalid"
            mat-flat-button
            color="primary"
            (click)="authenticateUsingTotp(twoFactorInputToken.value)"
          >
            {{ t('send') }}
          </button>
        </div>
      </div>
    </div>
</ng-container>
∏
