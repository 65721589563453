<div *transloco="let t" class="datepicker-container">
  <div fxLayout="column" fxLayoutGap="5px">
    <span class="smaller-text">{{ t(headerText) }}</span>
    <mat-form-field style="width: 18rem">
      <div fxLayout="row">
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="{{ t('startDate') }}" />
          <input
            matEndDate
            formControlName="end"
            placeholder="{{ t('endDate') }}"
            (dateChange)="onDateRangeChange($event)"
          />
        </mat-date-range-input>
        <div
          *ngIf="range.controls.end.value != null"
          (click)="clearInput()"
          class="clear-button"
        >
          X
        </div>
      </div>
      <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
</div>
