import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment'

@Pipe({
  name: 'dateTimePipe'
})
export class DateTimePipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  transform(durationInS: number, dateFormat: string): string {
    if (dateFormat == "date") {
      if (this.translocoService.getActiveLang() == "de") {
        return moment.unix(durationInS).format('DD.MM.YYYY');
      } else {
        return moment.unix(durationInS).format('DD/MM/YYYY');
      }
    } else if(dateFormat == "date_time"){
      if (this.translocoService.getActiveLang() == "de") {
        return moment.unix(durationInS).format('DD.MM.YYYY - HH:mm:ss');
      } else {
        return moment.unix(durationInS).format('DD/MM/YYYY - HH:mm:ss');
      }
    } else {
      if (this.translocoService.getActiveLang() == "de") {
        return moment.unix(durationInS).format('DD.MM.YYYY - HH:mm:ss');
      } else {
        return moment.unix(durationInS).format('DD/MM/YYYY - HH:mm:ss');
      }
    }
   
  }

}
