import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RepositoryService } from 'libs/core/src/lib/services/repository/repository.service';
import { PRESET_COLOR_SCHEME } from 'libs/core/src/lib/utils/constants/preset-color-scheme';
import { Subscription } from 'rxjs';
import { COLOR_LIST } from '../../utils/color-list';
import { PresetResetSettingDialogComponent } from '../preset-reset-setting-dialog/preset-reset-setting-dialog.component';

@Component({
  selector: 'frontend-monorepo-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationComponent implements OnInit, OnDestroy {
  private _subscription: Subscription;
  private reset_parking_duration_true: boolean;
  private reset_parking_duration_false: boolean;
  presetForm: FormGroup;

  isOpen = false;
  presetColor: string = PRESET_COLOR_SCHEME[0];
  colorPalette = COLOR_LIST;

  constructor(
    private repositoryService: RepositoryService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) {
    this._subscription = new Subscription();
    this.reset_parking_duration_true = false;
    this.reset_parking_duration_false = false;
    this.presetForm = new FormGroup({
      presetNameControl: new FormControl('', Validators.required)
    });
  }
  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.setupPreset();
    this.presetForm
      .get('presetNameControl')
      .valueChanges.subscribe((_value) =>
        this.repositoryService.presetService().presetName.next(_value)
      );
  }

  private setupPreset() {
    this._subscription = this.repositoryService
      .presetService()
      .activePreset.subscribe((_activePreset) => {
        if (_activePreset != null) {
          if (_activePreset.preset.name != 'legacy_duration_preset') {
            this.presetForm.controls['presetNameControl'].setValue(_activePreset.preset.name);
          }
          this.reset_parking_duration_true = _activePreset.preset.reset_after_free_parking
            ? true
            : false;
          this.reset_parking_duration_false = _activePreset.preset.reset_after_free_parking
            ? false
            : true;
          this.cd.detectChanges();
        }
      });

    this._subscription = this.repositoryService.presetService().presetColor.subscribe((_color) => {
      if (_color) {
        this.presetColor = decodeURI(_color);
      } else {
        this.presetColor = PRESET_COLOR_SCHEME[0];
      }
      this.cd.detectChanges();
    });
  }

  selectColor(color: string) {
    this.repositoryService.presetService().presetColor.next(color);
    this.presetColor = color;
    this.isOpen = false;
  }

  checkResetSettingTrue(checked: boolean) {
    if (checked) {
      this.reset_parking_duration_false = false;
      this.reset_parking_duration_true = true;
      this.repositoryService.presetService().presetResetSetting.next(true);
    } else {
      this.reset_parking_duration_true = false;
      this.repositoryService.presetService().presetResetSetting.next(null);
    }
    this.cd.detectChanges();
  }

  checkResetSettingFalse(checked: boolean) {
    if (checked) {
      this.reset_parking_duration_false = true;
      this.reset_parking_duration_true = false;
      this.repositoryService.presetService().presetResetSetting.next(false);
    } else {
      this.reset_parking_duration_false = false;
      this.repositoryService.presetService().presetResetSetting.next(null);
    }
    this.cd.detectChanges();
  }

  openInfoDialog() {
    const dialogRef = this.dialog.open(PresetResetSettingDialogComponent, {
      minWidth: '40vw',
      maxWidth: '60vw',
      maxHeight: '60vh',
      autoFocus: false
    });
  }
}
