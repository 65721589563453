<div *transloco="let t; read: 'CONFIGURATION'">
  <h3>{{ t('preset_configuration') }}</h3>

  <!-- color and name -->
  <h4>{{ t('name_and_color') }}</h4>
  <p>{{ t('name_and_color_description') }}</p>

  <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
    <form [formGroup]="presetForm" (submit)="searchPreset()">
      <mat-form-field>
        <input matInput placeholder="{{ t('preset_name') }}" formControlName="presetNameControl" />
      </mat-form-field>
    </form>

    {{ t('color') }}

    <div
      id="color-box"
      [ngStyle]="{ 'background-color': presetColor }"
      (click)="isOpen = !isOpen"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
    ></div>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      (backdropClick)="isOpen = false"
    >
      <mat-card class="color-list" fxLayout="row" fxLayoutGap="10px">
        <div
          *ngFor="let color of colorPalette; let first = first"
          id="color-box"
          [ngStyle]="{ 'background-color': color }"
          [ngClass]="{ first: first }"
          (click)="selectColor(color)"
        ></div>
      </mat-card>
    </ng-template>
  </div>

  <!-- validity period setup -->
  <h4>{{ t('validity_description_header') }}</h4>
  <p>{{ t('validity_description_1') }}</p>
  <p>{{ t('validity_description_2') }}</p>
  <p>
    {{ t('validity_description_3') }}
    <a (click)="openInfoDialog()">{{ t('more_information') }}</a
    >.
  </p>

  <div fxLayout="row" fxLayoutGap="15px">
    <mat-checkbox
      color="primary"
      [checked]="reset_parking_duration_true"
      (change)="checkResetSettingTrue($event.checked)"
    >
      {{ t('reset_parking_duration') }}
    </mat-checkbox>
    <mat-checkbox
      color="primary"
      [checked]="reset_parking_duration_false"
      (change)="checkResetSettingFalse($event.checked)"
    >
      {{ t('do_not_reset_parking_duration') }}
    </mat-checkbox>
  </div>
  <span
    *ngIf="!reset_parking_duration_false && !reset_parking_duration_true"
    class="reset-option-note"
    >{{ t('please_select_one_option') }}</span
  >
</div>
