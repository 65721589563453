import { Observable } from 'rxjs';
import { Failure } from '../http-response/failure.model';
import { EvseIdBase } from './evse-id.base';
import { ChargingPointIdModel } from './models/evse-id.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API_BASE_URL } from '../../utils/constants/constants';
import { catchError } from 'rxjs/operators';
import { ChargingPointIdMapping } from './models/evse-id-mapping.model';

export class EvseIdRepository extends EvseIdBase {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * The function fetches the charging point ID for a given parking space ID from an API and returns it as an
   * Observable.
   * @requires parking_lot_permission: charging_point_edit
   * @param {number} parking_space_id - The `parking_space_id` parameter is a number that represents
   * the ID of a parking space.
   * @returns The fetchChargingPointId function returns an Observable that emits either a Failure object or an
   * ChargingPointIdModel object.
   */
  fetchChargingPointId(parking_lot_id: number): Observable<Failure | ChargingPointIdModel> {
    return this.http
      .get<ChargingPointIdModel>(
        API_BASE_URL + `chargingpoint/parkinglots/${parking_lot_id}/charging-point-id-mappings`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw new Failure(error.status, 'getEvseIdError');
        })
      );
  }

  /**
   * The function `editChargingPointId` sends a PUT request to update the charging point ID mapping for a parking
   * space, and returns an Observable of type `ChargingPointIdMapping` or `Failure`.
   * @requires parking_lot_permission: charging_point_edit
   * @param {number} parking_space_id - The `parking_space_id` parameter is a number that represents the
   * ID of a parking space. It is used to identify a specific parking space in the system.
   * @param {string} charging_point_id - The `charging_point_id` parameter is a string that represents
   * the ID of a charging point.
   * @returns an Observable that emits either an ChargingPointIdMapping object or a Failure object.
   */
  editChargingPointId(
    parking_space_id: number,
    charging_point_id: string
  ): Observable<ChargingPointIdMapping | Failure> {
    return this.http
      .put<ChargingPointIdMapping>(
        API_BASE_URL + `chargingpoint/parkingspaces/${parking_space_id}/charging-point-id-mapping`,
        {
          charging_point_id: charging_point_id
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw new Failure(error.status, 'getEvseIdError');
        })
      );
  }

  /**
   * The function `deleteChargingPointId` sends a DELETE request to a specific API endpoint to delete a mapping
   * between a parking space and a charging point, and returns an Observable that emits the response or
   * throws a Failure object if an error occurs.
   * @requires parking_lot_permission: charging_point_edit
   * @param {number} parking_space_id - The `parking_space_id` parameter is a number that represents
   * the ID of a parking space.
   * @returns an Observable that emits either any type of value or a Failure object.
   */
  deleteChargingPointId(parking_space_id: number): Observable<any | Failure> {
    return this.http
      .delete<any>(
        API_BASE_URL + `chargingpoint/parkingspaces/${parking_space_id}/charging-point-id-mapping`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let message = 'eleteEvseIdError';
          if (error.status == 404) {
            message = 'deleteEvseIdError404';
          }
          throw new Failure(error.status, message);
        })
      );
  }
}
