<ng-container *transloco="let t">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <div
        fxlayout="column"
        fxLayoutAlign="center center"
        (click)="navigateTo(node)"
        class="cursor"
        [class.active]="getActiveNode(node)"
      >
        <!-- {{node.name}} -->
        {{ t(node.name) }}
      </div>
    </mat-tree-node>
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
      style="cursor: pointer"
    >
      <div matTreeNodeToggle>
        <button mat-icon-button [attr.aria-label]="'toggle ' + node.name">
          <mat-icon  >
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        {{ t(node.name) }}
      </div>
    </mat-tree-node>
  </mat-tree>
</ng-container>
