export interface DeviceObject {
    device_id: number,
    device_type: number,
    device_version: number,
    batch: number,
    last_contact: number
}

export enum DEVICE_SPACE_TYPE {
    PARKING_SPACE = 'parking_space',
    GATEWAY = 'gateway',
    DISPLAY = 'display',
    CAMERA = 'camera',
    REPEATER = 'repeater'
}