export enum WEEKDAY {
  MONDAY = 'mo',
  TUESDAY = 'tue',
  WEDNESDAY = 'wed',
  THRUSDAY = 'thu',
  FRIDAY = 'fri',
  SATURDAY = 'sut',
  SUNDAY = 'sun'
}

export enum WEEKDAY_JSON {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THRUSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7
}

export const WEEKDAY_DISPLAY: string[] = [
  'placeholder',
  'mo',
  'tue',
  'wed',
  'thu',
  'fri',
  'sut',
  'sun'
];
