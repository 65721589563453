import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'frontend-monorepo-reset-parking-duration-settings',
  templateUrl: './reset-parking-duration-settings.component.html',
  styleUrls: ['./reset-parking-duration-settings.component.scss']
})
export class ResetParkingDurationSettingsComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ResetParkingDurationSettingsComponent>
  ) { }
  
  ngOnInit(): void {
  }
  
  _confirm() {
    this.dialogRef.close([true]);
  }

  _cancelAction() {
    this.dialogRef.close([false]);
  }

}
