import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './component/login.component';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MP_VERSION_TOKEN } from './tokens/version.token';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { FlexLayoutModule } from '@angular/flex-layout';

import de from './i18n/de.json';
import en from './i18n/en.json';
import { LoginResetPasswordComponent } from './component/login-reset-password/login-reset-password.component';
import { MatIconModule } from '@angular/material/icon';
import { TwoFactorAuthenticationComponent } from './component/two-factor-authentication/two-factor-authentication.component';
import { CountDownModule } from './helper/count-down-pipe/count-down.module';
import { Send2faCodeModule } from './helper/send-2fa-code-directive/send-2fa-code.module';
import { MatCheckbox } from '@angular/material/checkbox';
import { MaterialComponentsModule } from 'apps/customer-dashboard/src/app/pages/materialComponents.module';
import { CookieModule } from './helper/cookie-directive/cookie.module';

/**
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@NgModule({
    declarations: [
        LoginComponent,
        LoginResetPasswordComponent,
        TwoFactorAuthenticationComponent,
        // CountDownPipe
    ],
    imports: [
        CommonModule,
        TranslocoModule,
        CountDownModule,
        Send2faCodeModule,
        CookieModule,
        MaterialComponentsModule
    ], 
    exports: [
        LoginComponent,
        MatSnackBarModule,
        MatButtonModule,
        MatInputModule,
        ReactiveFormsModule,
        TwoFactorAuthenticationComponent
        // CountDownPipe
    ],
    providers: [],
})
export class LoginModule {

    /**
     * Creates a `LoginModule` that displays a version number at the bottom of the login screen
     * @param version The version that is displayed at the bottom of the login screen.
     */
    static withExtras(version: string | number, projectTag: string): ModuleWithProviders<any> {
        return {
            ngModule: LoginModule,
            providers: [
                {
                    provide: MP_VERSION_TOKEN,
                    useValue: version
                },
                {
                    provide: String,
                    useValue: projectTag
                }
            ]
        }
    }

    constructor(translocoService: TranslocoService) {
        translocoService.setTranslation(en, 'en');
        translocoService.setTranslation(de, 'de');
    }
}