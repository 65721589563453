import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  TWO_FACTOR_ACTION,
  TWO_FACTOR_INPUT_STATUS,
  TWO_FACTOR_STATUS
} from '@frontend-monorepo/common';
import {
  AuthService,
  RepositoryService,
  Failure,
  createAuthResponse
} from '@frontend-monorepo/core';
import { TranslocoService } from '@ngneat/transloco';
import { TotpToken } from 'libs/core/src/lib/repository/auth/models/totp-token';
import { UserDataService } from 'libs/core/src/lib/services/user-data/user-data.service';
import { forkJoin, Subscription } from 'rxjs';
import { LoginService } from '../login-service/login.service';

@Directive({
  selector: '[send2FACode]'
})
export class Send2FACodeDirective implements OnDestroy {
  /**
   * Determines whether action is a simple login or to activate/deactivate 2FA
   */
  @Input() action: string;
  /**
   * Event Emitter for further process
   */
  @Output() responseStatus: EventEmitter<TWO_FACTOR_INPUT_STATUS> =
    new EventEmitter<TWO_FACTOR_INPUT_STATUS>();

  private _subscription: Subscription;

  constructor(
    private repositoryService: RepositoryService,
    private authService: AuthService,
    private loginService: LoginService,
    private el: ElementRef,
    private snackbar: MatSnackBar,
    private translocoService: TranslocoService
  ) {
    this._subscription = new Subscription();
  }
  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  @HostListener('input') onInput(event) {
    let input: string = this.el.nativeElement.value;
    if (input.length == 6) {
      if (this.action === TWO_FACTOR_ACTION.LOGIN) {
        this.two_FA_login(Number(input));
      } else if (this.action === TWO_FACTOR_ACTION.ENABLE) {
        this.activate2FA(Number(input));
      }
    }
  }

  private two_FA_login(input: number) {
    this.repositoryService
      .authRepository()
      .postAuthTotp(input)
      .subscribe({
        next: (resp) => {
          this.authService.setTwoFactorToken(resp.two_factor_token);
          this.loginService.setupBasicData().subscribe((ready) => {
            ready
              ? this.responseStatus.emit(TWO_FACTOR_INPUT_STATUS.INPUT_SUCCESS)
              : this.responseStatus.emit(TWO_FACTOR_INPUT_STATUS.INPUT_FAILED);
          });

          // this._subscription = this.repositoryService
          //   .loginRepository()
          //   .fetchCustomerPLIDMapping()
          //   .subscribe((customerPlid) => this.userDataService.setCustomerPlidList(customerPlid));

          // this._subscription = this.repositoryService
          //   .userRepository()
          //   .fetchAccountInfo()
          //   .subscribe((userData) => {
          //     this.userDataService.setUserData(userData);
          //     this.responseStatus.emit(TWO_FACTOR_INPUT_STATUS.INPUT_SUCCESS);
          //     this._subscription.unsubscribe();
          //   });
        },
        error: (err: Failure) => {
          this.responseStatus.emit(TWO_FACTOR_INPUT_STATUS.INPUT_FAILED);
          this.snackbar.open(this.translocoService.translate(`${err.errorMessage}`));
        }
      });
  }

  private activate2FA(input: number) {
    this.repositoryService
      .account2faRepository()
      .enableTotp(input)
      .subscribe({
        next: (resp) => {
          if (this.repositoryService.authService().getCookieStatus()) {
            this.loginService.resetCookie();
          }
          this.responseStatus.emit(TWO_FACTOR_INPUT_STATUS.INPUT_SUCCESS);
        },
        error: (err: Failure) => {
          this.responseStatus.emit(TWO_FACTOR_INPUT_STATUS.INPUT_FAILED);
          this.snackbar.open(this.translocoService.translate(`TWO_FACTOR_AUTHENTICATION_MANAGEMENT.${err.errorMessage}`));
          // this.snackbar.open(this.translocoService.translate('activation_error'));
        }
      });
  }
}
