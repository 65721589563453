import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoutingProgressIndicatorComponent } from './component/routing-progress-indicator.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';

/**
 * @author Maximilian Fossler <maximilian.fossler@marco-parco.com>
 */
@NgModule({
    declarations: [
        RoutingProgressIndicatorComponent
    ],
    imports: [
        CommonModule,
        MatProgressBarModule,
        RouterModule
    ],
    exports: [
        RoutingProgressIndicatorComponent
    ],
})
export class RoutingProgressIndicatorModule { }