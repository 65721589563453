import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DurationSetting } from 'libs/core/src/lib/repository/parking-lot-preset/models/duration-settings.model';
import { RepositoryService } from 'libs/core/src/lib/services/repository/repository.service';
import { WEEKDAY_DISPLAY } from 'libs/core/src/lib/utils/enums/weekday.enum';

@Pipe({
  name: 'presetDayTimeConverter'
})
export class PresetDayTimeConverterPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  transform(durationSetting: DurationSetting[]): string {
    let filterDefaultSetting = durationSetting.filter(
      (_setting) => _setting.start_time != '' && _setting.end_time != ''
    );
    if (filterDefaultSetting.length == 0) {
      return '';
    } else {
      return this.transformText(filterDefaultSetting);
    }
  }

  transformText(durationSetting: DurationSetting[]) {
    const output: string[] = [];
    let rangeStart: number | null = null;
    for (let i = 0; i < durationSetting.length; i++) {
      if (i > 0 && durationSetting[i].day !== durationSetting[i - 1].day + 1) {
        if (rangeStart !== null) {
          const rangeEnd: number = durationSetting[i - 1].day;
          if (rangeEnd - rangeStart > 0) {
            output.push(
              `${this.translocoService.translate(
                WEEKDAY_DISPLAY[rangeStart]
              )} - ${this.translocoService.translate(WEEKDAY_DISPLAY[rangeEnd])}`
            );
          } else {
            output.push(
              `${this.translocoService.translate(WEEKDAY_DISPLAY[rangeStart])} 
           `
            );
          }
          rangeStart = null;
        } else {
          output.push(
            `${this.translocoService.translate(WEEKDAY_DISPLAY[durationSetting[i].day])}`
          );
        }
      }
      if (rangeStart === null && durationSetting[i].day <= 7) {
        rangeStart = durationSetting[i].day;
      }

      if (i === durationSetting.length - 1 && rangeStart !== null) {
        const rangeEnd: number = durationSetting[i].day;
        if (rangeEnd - rangeStart > 0) {
          output.push(
            `${this.translocoService.translate(
              WEEKDAY_DISPLAY[rangeStart]
            )} - ${this.translocoService.translate(WEEKDAY_DISPLAY[rangeEnd])}  
   `
          );
        } else {
          output.push(`${this.translocoService.translate(WEEKDAY_DISPLAY[rangeStart])}`);
        }
      }
    
      if(i < durationSetting.length) {
        output.join(',')
      }
    }
    let endtime =
      durationSetting[0].end_time.toString() == '00:00'
        ? '24:00'
        : durationSetting[0].end_time.toString();
    output.push(
      ` ${this.translocoService.translate(
        'between'
      )} ${durationSetting[0].start_time.toString()} ${this.translocoService.translate(
        'and_time'
      )} ${endtime} ${this.translocoService.translate('time')}`
    );

    return output.join(' ');
  }
}
