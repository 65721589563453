import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';

@Component({
  selector: 'frontend-monorepo-dashboard-v2',
  templateUrl: './dashboard-v2.component.html',
  styleUrls: ['./dashboard-v2.component.scss'],
  animations: [
    trigger('minimize', [
      // state('max', style({
      //   'max-width': '400px',

      // })),
      state(
        'min',
        style({
          height: '56px',
          width: '56px',
          padding: '0px'
        })
      ),
      transition('* => min', animate('400ms ease-in-out'))
    ])
  ]
})
export class DashboardV2Component implements OnInit {
  public animation = '';
  public minimized = false;

  public url = '';

  constructor(private renderer: Renderer2, private cd: ChangeDetectorRef, public router: Router) {}

  ngOnInit(): void {
    this.url = this.router.url;
    this.router.events.subscribe((e) => {
      this.url = this.router.url;
    });
  }

  onMouseEnter(event: any): void {
    this.renderer.addClass(event.target, 'rocket_hover');
  }

  onMouseLeave(event: any): void {
    this.renderer.removeClass(event.target, 'rocket_hover');
  }

  onClick(event: any): void {
    this.renderer.addClass(event.target, 'rocket_click');
    timer(500).subscribe((_) => {
      window.open('https://dashboard-v2.smart-city-system.com/login', '_blank');
      this.renderer.removeClass(event.target, 'rocket_click');
    });
  }

  onMinimize(): void {
    this.animation = 'min';
  }

  onMinimizeEnd(): void {
    if (this.animation === 'min') {
      this.minimized = true;
      this.cd.detectChanges();
    }
  }
}
