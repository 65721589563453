import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

/** 
 * 
 * Transforming a duration in seconds into a mm:ss format.
 * 
 * @example Let a component's `durationInMs`-parameter be 62:
 *  `<span> {{ durationInMs | countDown }} </span>`
 * 
 *   is rendered as:
 * 
 * `<span> 01:02 </span>`
*/

@Pipe({name: 'countDown'})
export class CountDownPipe implements PipeTransform {

    /**
     * Function help transforming the duration
     * @param durationInS A time period's amount of seconds, which is transformed into mm:ss format.
     */
    transform(durationInS: number): string {
        return moment.unix(durationInS).format('m:ss');
    }
}