import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Failure, RepositoryService } from '@frontend-monorepo/core';
import { TranslocoService } from '@ngneat/transloco';
import { DeviceSpaces } from 'libs/core/src/lib/repository/parking-lot/models/device-spaces.model';
import { BehaviorSubject } from 'rxjs';
import { DeviceSpaceServiceBase } from './device-space-service.base';


/**
 * Simple Service for storing and passing camera space objects
 */
@Injectable({
  providedIn: 'root'
})
export class DeviceSpaceService extends DeviceSpaceServiceBase {
  /**
   * BehaviorSubject for storing and updating all device spaces on parkinglot
   */
  deviceSpacesList: BehaviorSubject<Array<DeviceSpaces>> = new BehaviorSubject([]);

  constructor(
    private repositoryService: RepositoryService,
    private snackbar: MatSnackBar,
    private transloco: TranslocoService
  ) {
    super();
  }

  /**
   * This function calls the device objects request and stores the result in @param deviceObjectMap
   * @param parkingLotId
   */
  storeCameraSpaces(parkingLotId: number): void {
    this.repositoryService
      .parkingLotRepository()
      .fetchAllDeviceSpacesByPlid(parkingLotId)
      .subscribe({
        next: (deviceSpaces) => {
          this.deviceSpacesList.next(deviceSpaces);
        },
        error: (err: Failure) => {
          this.snackbar.open(this.transloco.translate(`parkingLotMap.${err.errorMessage}`));
        }
      });
  }

  /**
   * checking wheter active device space is a camera device
   * @param deviceSpaceId active device space id
   * @returns true/false
   */
  checkDeviceSpace(deviceSpaceId: number): boolean {
    return (
      this.deviceSpacesList.getValue()?.find((_item) => _item.device_space_id === deviceSpaceId) !=
      null
    );
  }

  /**
   * @param deviceSpaceId active device space Id
   * @returns object of type `DeviceSpaces` when existing
   */
  getDeviceSpaceById(deviceSpaceId: number): DeviceSpaces {
    return this.deviceSpacesList
      .getValue()
      ?.find((_item) => _item.device_space_id === deviceSpaceId);
  }
}
