<ng-container *transloco="let t;">
  <svg *ngIf="parkingLot !== undefined" xmlns="http://www.w3.org/2000/svg"
    [attr.viewBox]="'-100 -100 ' + (maxParkingDuration + maxOverdueDuration + 700) + ' 200'" preserveAspectRatio="none">
    <!-- MAFO:  -->
    <!-- TODO: Adjust colors! -->
    <g>
      <line *ngIf="maxParkingDuration != 0"
        matTooltip="{{ t('maxParkingDurationTooltip', { maxParkingDuration: (maxParkingDuration/60) } ) }}"
        class="roundedEdges" x1="0" y1="0" [attr.x2]="maxParkingDuration" y2="0" stroke="#00d453" />
      <line *ngIf="duWarningDuration != 0" class="squareEdges"
        matTooltip="{{ t('duWarningDurationTooltip', { duWarningDuration: (duWarningDuration / 60) }) }}"
        [attr.x1]="maxParkingDuration - duWarningDuration" y1="0" [attr.x2]="maxParkingDuration" y2="0"
        stroke="yellow" />
      <line *ngIf="maxOverdueDuration != 0" [attr.x1]="maxParkingDuration" y1="0"
        [attr.x2]="maxParkingDuration + maxOverdueDuration" y2="0" stroke="red" />
      <path *ngIf="maxOverdueDuration != 0" transform="translate(0.000000, 0.000000)"
        matTooltip="{{ t('maxOverdueDurationTooltip', { maxOverdueDuration: (maxOverdueDuration / 60) }) }}"
        [attr.d]="'M ' + (maxParkingDuration + maxOverdueDuration) + ', -100 h 500 l -120 100  l 120 100 h -500'"
        fill="darkred" />
    </g>
  </svg>
</ng-container>