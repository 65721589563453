<ng-container *transloco="let t;">
    <button title="{{ t('menuTriggerButtonTitle') }}" [attr.aria-label]="t('menuTriggerButtonTitle')"
        id="langMenuTriggerButton" mat-icon-button [matMenuTriggerFor]="langMenu">
        <mat-icon>language</mat-icon>
    </button>
    <mat-menu #langMenu="matMenu">
        <button id="langDe" class="langButton" mat-menu-item [ngClass]="{active: selectedLanguage === 'de'}"
            (click)="setActiveLang('de')">
            <div id="de_icon" class="lang-icon"></div>
            <span>{{t('language_de')}}</span>
        </button>
        <button id="langEn" class="langButton" mat-menu-item [ngClass]="{active: selectedLanguage === 'en' }"
            (click)="setActiveLang('en')">
            <div class="lang-icon" id="en_icon"></div>
            <span>{{t('language_en')}}</span>
        </button>
    </mat-menu>
</ng-container>