import { CAMERA_LIVESTREAM_CLOSE_REASONS, CAMERA_LIVESTREAM_STATES, RepositoryService } from '@frontend-monorepo/core';
import { Subscription } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { CameraLivestreamService } from '../../services/camera-livestream/camera-livestream.service';
import { WebSocketBase } from '../websocket_base.interface';

/**
 * Websocket
 */

export class CameraLivestreamWebsocketService implements WebSocketBase {
  private _subscription: Subscription;
  private cameraLivestreamWS: WebSocketSubject<any>;

  constructor(
    private repositoryService: RepositoryService,
    private cameraLivestreamService: CameraLivestreamService
  ) {
    this._subscription = new Subscription();
  }

  start(deviceSpaceId?: number) {
    if (
      deviceSpaceId != undefined &&
      this.repositoryService.authService().getToken() != undefined
    ) {
      this.cameraLivestreamWS = webSocket({
        url: `wss://api.parking-pilot.com/cameras/${deviceSpaceId}/jpeg-stream`,
        openObserver: {
          next: () => {
            if (this.repositoryService.authService().isTwoFactorEnabled) {
              const tokens = this.repositoryService.authService().getTokens();
              this.cameraLivestreamWS.next({
                token: tokens?.token,
                two_factor_token: tokens?.two_factor_token
              });
            } else {
              this.cameraLivestreamWS.next({
                token: this.repositoryService.authService().getToken()
              });
            }
          }
        },
        // image stream as Blob
        deserializer: ({ data }) => data
      });
    }
  

    this._subscription = this.cameraLivestreamWS.subscribe(
      (message) => {
        if (typeof message === 'string' || message instanceof String) {
          const messageJSON = JSON.parse(message as string);
          if (messageJSON.type === CAMERA_LIVESTREAM_STATES.AUTHENTICATION_SUCCESS) {
            return;
          } else if (messageJSON.type === CAMERA_LIVESTREAM_STATES.AUTHENTICATION_FAILURE) {
            this.cameraLivestreamService.startJpegStream(CAMERA_LIVESTREAM_STATES.AUTHENTICATION_FAILURE)
            this.stop();
            return;
          } else if (messageJSON.type === CAMERA_LIVESTREAM_STATES.CLOSE) {
            this.cameraLivestreamService.startJpegStream(messageJSON.data)
            this.stop();
            return;
          }
        }
        this.cameraLivestreamService.startJpegStream(URL.createObjectURL(message))
      },
      (err) => {
        this.stop();
      }
    );
  }

  stop() {
    if (this.cameraLivestreamWS != undefined) {
      this.cameraLivestreamWS.complete();
      this.cameraLivestreamWS.unsubscribe();
    }

    this.cameraLivestreamWS = undefined;
    this._subscription.unsubscribe();
  }

  isRunning(): boolean {
    throw new Error('Method not implemented.');
  }
}
