import { Component, OnInit, ViewChild, ViewContainerRef, OnDestroy, ComponentFactoryResolver, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ParkingLotMapComponentInjectorService } from '../../services/parking-lot-map-component-injector.service';


/**
 * Sidebar that is displayed on the left side of the screen besides the parking lot map.
 * 
 * Other components can/should be injected into this sidebar via the {@link ParkingLotMapExtension#leftSidebarFn}
 */
@Component({
  selector: 'mp-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  styleUrls: ['./left-side-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftSideBarComponent implements OnInit, OnDestroy {

  /**
   * The sidebar's ViewContainerRef, which is used by the  {@link ParkingLotMapExtension#leftSidebarFn} to inject/create child components.
   */
  @ViewChild('sidebarContent', { static: true, read: ViewContainerRef }) vcr: ViewContainerRef;

  private _subscriptions: Subscription;

  constructor(
    private parkingLotMapComponentInjectorService: ParkingLotMapComponentInjectorService,
    public componentFactoryResolver: ComponentFactoryResolver,
    public cd: ChangeDetectorRef
  ) {
    this._subscriptions = new Subscription();
  }

  ngOnInit() {
    this._subscriptions.add(
      this.parkingLotMapComponentInjectorService.getLeftSidebarContentFn().subscribe(componentUpdateFn => {
        if (componentUpdateFn) {
          componentUpdateFn(this);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
